export function filterYears(data) {
  let ar = [];
  for (let i = 0; i < data.length; i++) {
    if (ar.includes(data[i].date.split("-")[0])) {
      continue;
    } else {
      ar.push(data[i].date.split("-")[0]);
    }
  }
  return ar.sort((a, b) => a - b);
}

export const dataConverstion = function (bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) {
    return "n/a";
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i == 0) {
    return "(" + bytes + " " + sizes[i] + ")";
  } else if (i == 2) {
    return "(" + parseInt(bytes / Math.pow(1024, i).toFixed(0)) + " " + sizes[i] + ")";
  }
  return "(" + (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i] + ")";
};

export const formatDate = (date) => {
  const parts = date.split("T");
  const time = parts[1].split(":").slice(0, 2).join(":");
  const formattedTime = time ? (time === "00:00" ? " " : time) : "";
  return parts[0] + " " + formattedTime;
};

export const colors = [
  "#1D7CF2",
  "#3b4263",
  "#FF8C00",
  "#FFD700",
  "#e9b184",
  "#DE85B1",
  "#B0E0E6",
  "#738c87",
  "#aaee00",
  "#FF34B3",
  "#D0A9AA",
  "#00CED1",
  "#00B050",
  "#E6E8FA",
  "#551A8B",
  "#8F8FBC",
  "#E6BDFF",
  "#2AFB4B",
  "#cd9aa5",
  "#cd9aa5",
  "#7a6728",
  "#0e5127",
  "#D92258",
  "#A838E5",
  "#E30B28",
  "#856F71",
  "#ff8682",
  "#fcc1b1",
  "#4EEDA7",
  "#C16F7A",
  "#532e3a"
];

export const formatDateNew = (date) => {
  const parts = date !== undefined && date?.split(" ");
  const formattedTime = parts[1] ? (parts[1] === "00:00" ? " " : parts[1]) : "";
  return parts[0] + " " + formattedTime;
};

export const hoverDateFormatForTrial = (item) => {
  let hoverText = new Date(item).toString().split(" ");
  let text = hoverText[1] + " " + hoverText[2] + ", " + hoverText[3];
  return text;
};

export const FlightDatetFormatForPopUp = (item) => {
  let hoverText = new Date(item).toString().split(" ");
  let text = hoverText[1] + " " + hoverText[2] + " " + hoverText[3];
  return text;
};

export const formatFlightDate = (flightDate) => {
  let date = flightDate?.split("-");
  let dateString =
    date && date?.length === 3
      ? date[0] + "-" + date[1] + "-" + date[2]
      : date[0] + "-" + date[1] + "-" + date[2] + " " + date[3];

  return dateString;
};

export const formatFlightDateForLayouts = (flightDate) => {
  let date = flightDate?.split("-");
  let dateString =
    date && date?.length === 3
      ? date[2] + "-" + date[1] + "-" + date[0]
      : date[2] + "-" + date[1] + "-" + date[0] + " " + date[3];

  return dateString;
};

export const formatFlightDateWithoutTimeStamp = (flightDate) => {
  let date = flightDate?.split("-");
  let dateString = date && date[0] + "-" + date[1] + "-" + date[2];

  return dateString;
};

export const dateConverter = (date) => {
  const [dates, time] = date.split(" ");
  const [day, month, year] = dates.split("-");
  return new Date(`${year}-${month}-${day}T${time}`);
};

export const dateConverterForSites = (date) => {
  const [dates, time] = date.split(" ");
  const [year, month, day] = dates.split("-");
  return new Date(`${year}-${month}-${day}T${time}`);
};

import "../assests/Styles/loader.css";
export const Spinner = () => {
  return <span className="new-loader"></span>;
};

export const droneAssement = {
  "Drone Assessments": [
    {
      date: "2022-05-31-13:51",
      isDrone: true,
      isManual: false,
      assessmentID: 1739
    },
    {
      date: "2022-06-08",
      isDrone: true,
      isManual: false,
      assessmentID: 1782
    },
    {
      date: "2022-06-15",
      isDrone: true,
      isManual: false,
      assessmentID: 1812
    },
    {
      date: "2022-06-23",
      isDrone: true,
      isManual: false,
      assessmentID: 1873
    }
  ],
  "Planting Date": [
    {
      date: "2022-05-12",
      isDrone: true,
      isManual: false,
      assessmentID: 1739
    },
    {
      date: "2023-06-08-10:49",
      isDrone: true,
      isManual: false,
      assessmentID: 1782
    },
    {
      date: "2052-06-15",
      isDrone: true,
      isManual: false,
      assessmentID: 1812
    },
    {
      date: "2062-06-23-14:40",
      isDrone: true,
      isManual: false,
      assessmentID: 1873
    }
  ],
  "Pct Yellow vs Check": [
    {
      date: "2022-05-31",
      isDrone: true,
      isManual: false,
      assessmentID: 1739
    },
    {
      date: "2092-06-08-10:49",
      isDrone: true,
      isManual: false,
      assessmentID: 1782
    },
    {
      date: "2022-06-09",
      isDrone: true,
      isManual: false,
      assessmentID: 1812
    },
    {
      date: "2022-06-12-14:40",
      isDrone: true,
      isManual: false,
      assessmentID: 1873
    }
  ],
  "Pct White vs Check": [
    {
      date: "2001-05-31-10:51",
      isDrone: true,
      isManual: false,
      assessmentID: 1739
    },
    {
      date: "2002-06-08",
      isDrone: true,
      isManual: false,
      assessmentID: 1782
    },
    {
      date: "2003-06-15",
      isDrone: true,
      isManual: false,
      assessmentID: 1812
    },
    {
      date: "2004-06-23",
      isDrone: true,
      isManual: false,
      assessmentID: 1873
    }
  ],
  "Pct Brown vs Check": [
    {
      date: "2018-05-13-06:51",
      isDrone: true,
      isManual: false,
      assessmentID: 1739
    },
    {
      date: "2022-06-08-10:49",
      isDrone: true,
      isManual: false,
      assessmentID: 1782
    },
    {
      date: "2027-06-15-18:10",
      isDrone: true,
      isManual: false,
      assessmentID: 1812
    },
    {
      date: "2029-06-23-14:40",
      isDrone: true,
      isManual: false,
      assessmentID: 1873
    }
  ],
  "Pct NonGreen vs Check": [
    {
      date: "2022-08-31",
      isDrone: true,
      isManual: false,
      assessmentID: 1739
    },
    {
      date: "2022-06-08-10:49",
      isDrone: true,
      isManual: false,
      assessmentID: 1782
    },
    {
      date: "1999-06-15",
      isDrone: true,
      isManual: false,
      assessmentID: 1812
    },
    {
      date: "1998-06-23",
      isDrone: true,
      isManual: false,
      assessmentID: 1873
    }
  ],
  "Pct Yellow NoCheck": [
    {
      date: "2022-09-30",
      isDrone: true,
      isManual: false,
      assessmentID: 1739
    },
    {
      date: "1985-06-08",
      isDrone: true,
      isManual: false,
      assessmentID: 1782
    },
    {
      date: "1990-06-15-12:10",
      isDrone: true,
      isManual: false,
      assessmentID: 1812
    },
    {
      date: "2022-06-23-14:40",
      isDrone: true,
      isManual: false,
      assessmentID: 1873
    }
  ]
};

export const customTabletDimensions = () => {
  // Check for iPad in the user agent
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isIpadUA = /iPad|Macintosh/.test(userAgent) && "ontouchend" in document;

  // Additional check for iPadOS 13+
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
  const isStandalone = window.navigator.standalone;
  const screenRatio = window.screen.width / window.screen.height;

  const isIpadScreenSize = screenRatio >= 1.3 && screenRatio <= 1.6; // Approximate aspect ratio for iPads
  const isGenericTablet =
    /android|tablet|kindle|silk/i.test(userAgent) && !/mobile/i.test(userAgent);

  return isIpadUA || (isIOS && isSafari && !isStandalone && isIpadScreenSize) || isGenericTablet;
};

export const envFormatDate = (date, b = 0) => {
  if (date !== undefined) {
    const yes = new Date(date);
    const formattedDate = yes.toISOString().slice(0, 10);
    return b == 0 ? `${formattedDate}T00:00:00.000+00:00` : `${formattedDate}T23:00:00.000+00:00`;
  }
};

export const envBackwardDay = (date, day, b = 0) => {
  if (date !== undefined) {
    const yes = new Date(date);
    const result = yes.setDate(yes.getDate() - day);
    return envFormatDate(result, b);
  }
};

export const envForwarddDay = (date, day, b = 0) => {
  if (date !== undefined) {
    const yes = new Date(date);
    const result = yes.setDate(yes.getDate() + day);
    return envFormatDate(result, b);
  }
};

export const toFixedIfNecessary = (value, dp) => {
  return +parseFloat(value).toFixed(dp);
};

export const DateFormatingForReportingModal = (date) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  const hours = String(inputDate.getHours()).padStart(2, "0");
  const minutes = String(inputDate.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}-${hours}:${minutes}`;
};

export const soilApi = (selectedTrial, page, soilDeviceStartDate, soilDeviceEndDate, metric) => {
  return ` ${process.env.REACT_APP_ART_URL}/gaia/api/v1/gaia/soil?trialId=${selectedTrial}&size=1000&page=${page}&startDate=${soilDeviceStartDate}&endDate=${soilDeviceEndDate}&metric=${metric}`;
};

export const weatherApi = () => {
  return `${process.env.REACT_APP_ART_URL}/weather/api/v1/weather`;
};

export const attachmentAPIIdToken = "eyJraWQiOiJrb3RqUFZiZDdGTjVVZSsyQVc4TSs0aHNWc3FON2FtSjAwalI4cHR4dlM4PSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiWHY0SENzbXpXM3VyeE1MZ0NwLVRYZyIsInN1YiI6IjVkOGU1NTdmLWU1YzEtNGViNi04MmM5LTRhYzcyMDlkNmRjYSIsImNvZ25pdG86Z3JvdXBzIjpbInVzLWVhc3QtMV9CZE1UM3Q2c1RfQXp1cmUtQUQtY3B0cmlhbGluZyJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9CZE1UM3Q2c1QiLCJjb2duaXRvOnVzZXJuYW1lIjoiQXp1cmUtQUQtY3B0cmlhbGluZ19zMTI0MzM3OCIsInByZWZlcnJlZF91c2VybmFtZSI6InMxMjQzMzc4IiwiZ2l2ZW5fbmFtZSI6IlJvaGFuIiwibWlkZGxlX25hbWUiOiJHaG9kYWtlIiwibm9uY2UiOiJNa1RXeFdGdHdTZ25CSGZBbFVtV09ja201NnFGUlhUUG5OX1hvY3A0blpaUUtrZTBlXzA2Z1Y1Q2ZCcGhobDFQMlo1azZkbXNnT3dsVkZWTXBVaW91bE5ZOFBVS1h1U2luUUtidFl0eG5IYkdLc3Z2b0NjWXNxeGNJSGZubmlpUUtMeU5CdVh4Zk1YODFYQTFMTG1ETzQzU3BpQy12dndhZnBGblpvUHR0RFEiLCJhdWQiOiI3dGhwcTEyN2ZyMjB0N3Q3M2NwNWpoZzk0NCIsImlkZW50aXRpZXMiOlt7InVzZXJJZCI6InMxMjQzMzc4IiwicHJvdmlkZXJOYW1lIjoiQXp1cmUtQUQtY3B0cmlhbGluZyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwczpcL1wvc3RzLndpbmRvd3MubmV0XC8wNjIxOWE0YS1hODM1LTQ0ZDUtYWZhZi0zOTI2MzQzYmZiODlcLyIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNzM4MTQ4ODY3MjM4In1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTczODIzNzc0MiwibmFtZSI6InMxMjQzMzc4IiwiZXhwIjoxNzM4MjQxMzQyLCJpYXQiOjE3MzgyMzc3NDJ9.xGGZTXHgsnRa4YGUXFheROkUXeItqUp737Yz5-H3whqLNIMN2gyeKlc6xR6UaoRqXkJ-sSVTrks3qaCH6_AgFnIYCnvAB5lZqjvwlM2FXDl7R9PuVv-I9ngjJjRQShTrPAeEuRn7Ben-qb1WB9sk89Vs2wz1rSj3jqRWFHZhmFnqjLgnDd9QT0Z679yqBh1qe2NU76RsGZCFXlEl281-9MtTM12pjLBSEm2XnOQ1Ia2TA3CNaPklDS6is2SlCcOLL0y8Dl7f_xtb6wJ6QFdjPNmmk0jq-C_8BKUEqIUqGNgU8T_ws_MCirb5pVRwlfpogXd_LDCnDwK8icNDj50Tbw"

export const DL_EMAIL_GLOBAL = "dlglobalinseasoninsights@syngenta.com";

export const DL_EMAIL_DDC = "DDCOperationsTeam@syngenta.com";
