import "bootstrap/dist/css/bootstrap.min.css";
import { Amplify } from "aws-amplify";
import { signInWithRedirect, fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState } from "react";

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: "us-east-1_BdMT3t6sT",
      userPoolClientId: "4al4fageua4qp8g19k62s6300t",
      mandatorySignIn: true,
      loginWith: {
        oauth: {
          domain: "cptrialing-prod-userpool.auth.us-east-1.amazoncognito.com",
          scopes: ["openid", "profile", "aws.cognito.signin.user.admin"],
          redirectSignIn: ["https://trialviewer.syngentadigitalapps.com/app"],
          redirectSignOut: ["https://trialviewer.syngentadigitalapps.com/app"],
          responseType: "token",
          "redirectUri ": ["http://localhost:3000/app"]
        }
      }
    }
  }
};

Amplify.configure(amplifyConfig);
export default function CognitoTest() {
  const [email, setEmail] = useState("");
  const signin = () => {
    const provider = {
      custom: "Azure-AD-cptrialing"
    };
    signInWithRedirect({ provider });
  };
  useEffect(() => {
    const fetchUser = async () => {
      const session = await fetchAuthSession();
      if (session?.tokens?.idToken?.payload?.email) {
        setEmail(session.tokens.idToken.payload.email);
      }
      console.log("session", session);
    };
    fetchUser();
  }, []);
  return (
    <div>
      {email && <h1>login with {email}</h1>}
      <button onClick={signin}>Cognito login</button>
    </div>
  );
}
