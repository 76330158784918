import { Route, Routes } from "react-router-dom";
import SignOutButton from "../components/Logout";
import Sidebar from "../components/Sidebar";
import SitesList from "../pages/Site/SitesList";
import ViewerComponent from "../components/ViewerComponent";
import SiteDetails from "../pages/Site/SiteDetails";
import ProtocolList from "../pages/Protocol/ProtocolList";
import ProtocolDetails from "../pages/Protocol/ProtocolDetails";
import TrailDetails from "../pages/Trail/TrailDetails";
import TrailList from "../pages/Trail/TrailList";
import BarChart from "../components/BarChart";
import NewViewer from "../components/NewViewerPage";
import TrialOwner from "../pages/Trail/TrialOwner";
import EditViewer from "../components/EditViewer";
import LandingPage from "../components/LandingPage";
import DroneFlights from "../components/DroneFlights";
import CognitoTest from "../components/CognitoTest";

function Pages() {
  return (
    <Routes>
      <Route path="/home" exact element={<Sidebar />}>
        <Route path="/home/logout" element={<SignOutButton />} />
        <Route path="/home/sites/:selectedyear" element={<SitesList />}>
          <Route path="/home/sites/:selectedyear/:id" element={<SiteDetails />} />
        </Route>
        <Route path="/home/protocol/:selectedyear" element={<ProtocolList />} />
        <Route path="/home/protocol/:selectedyear/:id" element={<ProtocolDetails />} />
        <Route path="/home/trials/:selectedyear" element={<TrailList />} />
        <Route path="/home/trials/:selectedyear/:id" element={<TrailDetails />} />
        <Route path="/home/trialsOwner/:selectedyear" exact element={<TrialOwner />} />
        <Route path="/home/trialsOwner/:selectedyear/:id" element={<TrailDetails />} />
        <Route path="/home/protocolsOwner/:selectedyear" exact element={<TrialOwner />} />
        <Route path="/home/protocolsOwner/:selectedyear/:id" element={<ProtocolDetails />} />
        <Route path="/home/crops/:selectedyear" exact element={<TrialOwner />} />
        <Route path="/home/crops/:selectedyear/:id" element={<TrailDetails />} />
      </Route>
      <Route path="/" exact element={<LandingPage />} />
      <Route path="/cognito" exact element={<CognitoTest />} />
      <Route path="/oldviewer" exact element={<ViewerComponent />} />
      <Route path="/barchart" exact element={<BarChart />} />
      <Route path="/viewer" exact element={<NewViewer />} />
      <Route path="/ploteditor" exact element={<EditViewer />} />
      <Route path="/droneflight" exact element={<DroneFlights />} />
    </Routes>
  );
}
export default Pages;
