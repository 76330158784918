import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Fullscreen, Icon0Square } from "react-bootstrap-icons"; 
import propTypes from "prop-types";

export default function MobileTools({
  isMobileLandscape,
  ShowFullMap,
  rotateMapZeroDegreeClick,
  rotateMapZeroDegreeDisable,
  rotateMapZeroDegreeHide
}) {
  return (
    <div
      className="d-flex flex-column mobile-tools-container"
      style={{
        backgroundColor: "transparent",
        position: "fixed",
        bottom: isMobileLandscape ? "2%" : "15%",
        zIndex: 997
      }}>
      {!rotateMapZeroDegreeHide && 
        <Button title="Rotate map zero degree" className="mobile-icon"
          onClick={rotateMapZeroDegreeClick} disabled={rotateMapZeroDegreeDisable}>
          <Icon0Square color="#0D1A40" height={18} width={18} />
        </Button>
      }
      <Button className="mobile-icon" onClick={ShowFullMap}>
        <Fullscreen color="black" height={18} width={18} />
      </Button>
    </div>
  );
}
MobileTools.propTypes = {
  isMobileLandscape: propTypes.any,
  ShowFullMap: propTypes.any,
  rotateMapZeroDegreeClick: propTypes.any,
  rotateMapZeroDegreeDisable: propTypes.any,
  rotateMapZeroDegreeHide: propTypes.any
};
