/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { CheckCircleFill, X, Paperclip } from "react-bootstrap-icons";
import { saveReport, sendReport } from "../services/API";
import { DateFormatingForReportingModal, DL_EMAIL_GLOBAL } from "../services/Common";
import { useCookies } from "react-cookie";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

export default function GeneralReportModalMobile({
  openGeneralReportModal,
  setOpenGeneralReportModal,
  successModal,
  setSuccessModal,
  isMobile,
  isMobileLandscape
}) {
  const [cookies] = useCookies(["userEmail", "cognitoFirstName", "cognitoLastName"]);
  const userEmail = cookies?.userEmail;
  const firstName = cookies?.cognitoFirstName;
  const lastName = cookies?.cognitoLastName;
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileUpload = async (selectedFiles) => {
    const uniqueFiles = [...new Set(selectedFiles?.map((file) => file?.name))].map((name) =>
      selectedFiles.find((file) => file?.name === name)
    );
    let allImages = [];
    uniqueFiles?.forEach((file) => {
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: file?.name,
        Body: file,
        ContentType: file?.type,
        ServerSideEncryption: "AES256"
      };
      allImages.push(s3.upload(params).promise());
    });

    try {
      const imageResults = await Promise.all(allImages);
      setSelectedFiles([]);
      const imageUrls = imageResults.map((result) => result?.Location);
      return imageUrls;
    } catch (err) {
      console.log("Empty images", err);
      return [];
    }
  };

  const handleFileChange = (e) => {
    let files = e.target.files ? Array.from(e.target.files) : [];
    const filteredFiles = files?.filter((file) => {
      const fileExtension = file?.name?.split(".").pop().toLowerCase();
      return !["exe", "dmg", "bat", "sh", "msi"].includes(fileExtension);
    });
    const uniqueFilteredFiles = filteredFiles.filter(
      (file) => !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );
    setSelectedFiles([...selectedFiles, ...uniqueFilteredFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let uploadedUrls = [];
    if (selectedFiles?.length > 0) {
      uploadedUrls = await fileUpload(selectedFiles);
    }

    let sendFeedbackPayload = {
      receiver_email: DL_EMAIL_GLOBAL,
      subject: "In-season Insights Feedback",
      body: "In-season Insights User's Feedback Details",
      page_url: window.location.href,
      name: firstName + " " + lastName,
      email: userEmail,
      current_trial: "",
      flight_date: "",
      feedback_type: "",
      details: "",
      descriptions: description,
      row_no: "",
      submitted_datetime: DateFormatingForReportingModal(new Date()),
      attachment_urls: uploadedUrls?.length > 0 ? uploadedUrls : []
    };
    sendReport(
      sendFeedbackPayload,
      (res) => {
        if (res?.status_code == 200) {
          setOpenGeneralReportModal(false);
          setSuccessModal(true);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleClose = () => {
    setOpenGeneralReportModal(false);
  };

  return (
    <>
      <Modal
        className={
          isMobile
            ? "mobile-general-trial-report-modal"
            : "mobile-landscape-general-trial-report-modal"
        }
        show={openGeneralReportModal}
        onHide={handleClose}
        backdrop="true"
        centered="true">
        <Modal.Header>
          <Modal.Title className="mb-2 ml-2">
            <span className="font-size-large report-title">Report an issue</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ml-2 mr-2 report-modal-body overflow-x-hidden">
          <div className="mb-3 deep-cove-color font-size-small font-weight-400">
            Have any issues or thoughts on how we improve In-Season Insights, send us a note here
            and we will take actions or follow-up with you to get more details.
          </div>
          <div>
            <input
              type="file"
              id="fileInput"
              data-testid="fileInput"
              className="file-display"
              multiple
              onChange={handleFileChange}
              accept="*/*"
            />
            <label htmlFor="fileInput" className="cursor-pointer" style={{ opacity: 1 }}>
              <span className="attach-file-text">
                Attach Files <Paperclip width={16} height={16} />
                <span className="ml-1 optional-text">(Optional)</span>
              </span>
            </label>
            {selectedFiles?.length > 0 ? (
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  {selectedFiles?.map((file, index) => (
                    <div
                      key={index}
                      className="file-name-pill d-flex align-items-center justify-content-between mr-2 mt-1">
                      <span className="mr-2 file-name">{file.name}</span>
                      <X
                        data-testid="remove-file"
                        className="cursor-pointer"
                        onClick={() =>
                          setSelectedFiles(selectedFiles?.filter((_, i) => i !== index))
                        }
                      />
                    </div>
                  ))}
                </div>
                {selectedFiles?.length > 1 && (
                  <div className="ml-3 remove-all-text" onClick={() => setSelectedFiles([])}>
                    REMOVE ALL
                  </div>
                )}
              </div>
            ) : (
              <div className="description-text">
                Attach screenshots, screen recordings or other relevant files illustrated the issue.
                Visual aid can help the team to understand the issue more effectively.
              </div>
            )}
          </div>
          <div className="mt-3 mobile-description-textarea">
            <label className="mb-2 font-size-small">
              <strong> Description</strong>
            </label>
            <textarea
              data-testid="description-textarea"
              rows={isMobileLandscape ? "4" : "6"}
              cols="45"
              placeholder="Type your description here"
              className="form-control description-box mb-3"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={handleClose} className="report-modal-cancel">
            <span className="deep-cove-color">CANCEL</span>
          </button>
          <button
            onClick={handleSubmit}
            className="report-modal-submit"
            style={{
              opacity: description === "" ? 0.3 : 1
            }}
            disabled={description === ""}>
            <span>SUBMIT</span>
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="mobile-success-modal"
        show={successModal}
        // onHide={() => setSuccessModal(false)}
        centered>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div className="mb-4 d-flex flex-column justify-content-center align-items-center py-5">
            <CheckCircleFill color="#2EB800" height={48} width={48} />
            <div className="pt-3">
              <span className={isMobile ? "font-16 deep-cove-color" : "font-24 deep-cove-color"}>
                Issue has been reported successfully
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => {
              setSuccessModal(false);
            }}
            className="success-modal-ok">
            <span>OK</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

GeneralReportModalMobile.propTypes = {
  openGeneralReportModal: propTypes.any,
  setOpenGeneralReportModal: propTypes.any,
  successModal: propTypes.any,
  setSuccessModal: propTypes.any,
  isMobile: propTypes.any,
  isMobileLandscape: propTypes.any
};
