import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { signInWithRedirect, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "aws-amplify/auth/enable-oauth-listener";
import { BrowserRouter } from "react-router-dom";
import { callMsGraph } from "../services/API";
import { InteractionStatus } from "@azure/msal-browser";
import Pages from "./Routes";
import { CookiesProvider, useCookies } from "react-cookie";
import { datadogRum } from "@datadog/browser-rum";
import { amplifyConfig } from "../services/authConfig";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FAFBFE"
    },
    secondary: {
      main: "#e9ecef"
    }
  },
  typography: {
    fontFamily: ["HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"].join(","),
    lineHeight: "1.6",
    fontWeight: "400"
  },
  FormControl: {
    "& .MuiInputBase-root": {
      backgroundColor: "green",
      color: "#fff",
      borderColor: "green",
      height: "30px",
      justifyContent: "center",
      fontSize: ".76563rem",
      lineHeight: "1.5",
      borderRadius: "0.2rem",
      paddingRight: "0px"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      padding: "0 0 0 11px",
      paddingRight: "0px !important"
    },
    list: {
      fontSize: ".875rem",
      "& li.Mui-selected": {
        background: "#e9ecef"
      },
      "& li.Mui-selected:hover": {
        background: "#e9ecef"
      }
    },
    select: {
      "&:after": {
        borderColor: "#e9ecef"
      }
    },
    selected: {
      "&.Mui-selected": {
        backgroundColor: "#e9ecef",
        color: "white",
        fontWeight: 600,
        paddingRight: 0
      }
    }
  }
});

Amplify.configure(amplifyConfig);

export default function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [cookies, setCookie] = useCookies([
    "cognitoAuthenticated",
    "msalAuthenticated",
    "idToken",
    "accessToken",
    "userEmail"
  ]);

  useEffect(() => {
    const fetchUser = async () => {
      const session = await fetchAuthSession();
      if (!session?.userSub && !cookies?.cognitoAccessToken) {
        const currentPath = window.location.href;
        localStorage.setItem("redirectPath", currentPath);
        const provider = {
          custom: process.env.REACT_APP_COGNITO_PROVIDER
        };
        signInWithRedirect({ provider });
      } else if (!isAuthenticated) {
        if (session?.userSub) {
          setCookie("cognitoAuthenticated", true, { secure: true, sameSite: "none" });
          setCookie("cognitoAccessToken", session?.tokens?.accessToken?.toString(), {
            secure: true,
            sameSite: "none"
          });
          setCookie("cognitoIdToken", session?.tokens?.idToken?.toString(), {
            secure: true,
            sameSite: "none"
          });
          setCookie("cognitoFirstName", session?.tokens?.idToken?.payload?.given_name, {
            secure: true,
            sameSite: "none"
          });
          setCookie("cognitoLastName", session?.tokens?.idToken?.payload?.middle_name, {
            secure: true,
            sameSite: "none"
          });
          const currentUser = await getCurrentUser();
          if (currentUser) {
            setCookie("cognitoUserId", currentUser?.userId, { secure: true, sameSite: "none" });
          }
        }
        const redirectPath = localStorage.getItem("redirectPath");
        if (redirectPath) {
          localStorage.removeItem("redirectPath");
          window.location.href = redirectPath;
        }
        if (accounts?.length === 0) {
          await instance.initialize();
          await instance.loginRedirect();
        }
        setCookie("msalAuthenticated", true, { maxAge: 1, sameSite: "none" });
      } else {
        const redirectPath = localStorage.getItem("redirectPath");
        if (redirectPath) {
          localStorage.removeItem("redirectPath");
          window.location.href = redirectPath;
        }
      }
    };
    // if(!cookies?.cognitoIdToken || !cookies?.idToken) {
    fetchUser();
    // }
  }, [isAuthenticated]);

  useEffect(() => {
    if (accounts?.length > 0 && inProgress === InteractionStatus.None) {
      onLoad();
    }
  }, [isAuthenticated, accounts, inProgress]);

  const onLoad = async () => {
    const request = {
      scopes: ["User.Read"],
      account: accounts[0]
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          setCookie("idToken", response.idToken, { secure: true, sameSite: "none" });
          setCookie("accessToken", response.accessToken, { secure: true, sameSite: "none" });
          callMsGraph(response.accessToken).then((response1) => {
            sessionStorage.setItem("userId", response1?.id);
            sessionStorage.setItem("firstName", response1?.givenName);
            sessionStorage.setItem("lastName", response1?.surname);
          });
        })
        .catch(() => {
          if (inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect(request).then((response) => {
              setCookie("idToken", response.idToken, { secure: true, sameSite: "none" });
              setCookie("accessToken", response.accessToken, { secure: true, sameSite: "none" });
            });
          }
        });
    }
    if (accounts?.[0]) {
      setCookie("userEmail", accounts[0]?.username, { secure: true, sameSite: "none" });
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      datadogRum.init({
        applicationId: "fd3e1baa-0bb7-43b6-8f06-8bde08922a68",
        clientToken: "pub2fd5b2f0d4472141a7b66c81006938c8",
        // site refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: "datadoghq.eu",
        service: "in-season-insight",
        env: "prod",
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 10,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
      });
    }
  }, []);

  useEffect(() => {
    // Function to refresh the token
    const refreshToken = () => {
      if (accounts?.length > 0 && cookies.idToken && cookies.accessToken) {
        //kept below console for testing purpose need to remove while pushing to stage
        const request = {
          scopes: ["User.Read"],
          account: accounts[0],
          forceRefresh: true // This forces the library to ignore the token cache and retrieve a new token from the server
        };
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            setCookie("idToken", response.idToken, { secure: true, sameSite: "none" });
            setCookie("accessToken", response.accessToken, { secure: true, sameSite: "none" });
          })
          .catch((error) => {
            console.error(
              "Silent token acquisition failed, falling back to acquireTokenRedirect",
              error
            );
          });
      }
    };

    // Call refreshToken immediately if authenticated to ensure token is fresh on load
    if (isAuthenticated) {
      refreshToken();
    }

    // Set up the interval to refresh the token every hour
    const intervalId = setInterval(refreshToken, 3600000); // 3600000 ms = 1 hour

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    // }
  }, [instance, accounts, isAuthenticated]);

  return (
    <BrowserRouter basename="/app">
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <CookiesProvider defaultSetOptions={{ path: "/app" }}>
            {cookies?.accessToken && cookies?.idToken && isAuthenticated && <Pages />}
          </CookiesProvider>
        </CssBaseline>
      </ThemeProvider>
    </BrowserRouter>
  );
}
