/* eslint-disable no-unused-vars */
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef, createRef, useMemo } from "react";
import * as turf from "@turf/turf";
import {
  loginSyt,
  getAllTrialSummaryData,
  viewAPI,
  getRasterLayersAPICall,
  getMeasurmentSets,
  getAssessmentCategories,
  getTrialMeasurments,
  edcGetAccessToken,
  getRatingDetails,
  newBarChartApi,
  newFlightChartApi,
  getProtocolBytrial,
  viewCollectionDetailsAPI,
  saveRecentTrialsAPI,
  treatmentLabelAPI,
  treatmentUserLabel,
  checkTrialType,
  getPlotInfo,
  getTrialProtocol,
  saveSiteData,
  getDevices,
  getAllFilterData,
  recentlyActivityTracking,
  getPlotsForTrial,
  getTreatmentReplicates,
  checkSuplot,
  getMobileImagesDataByTrial,
  getMobileImageById
} from "../services/API";
import { useMsal } from "@azure/msal-react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navbar,
  Dropdown,
  Button,
  Row,
  Col,
  Modal,
  Tooltip,
  OverlayTrigger,
  Form,
  DropdownButton
} from "react-bootstrap";
import "../assests/Styles/newviewer.css";
import {
  ChevronDown,
  List,
  ChevronLeft,
  ChevronRight,
  ArrowsAngleExpand,
  ArrowsAngleContract,
  X,
  InfoCircleFill,
  Link,
  Sliders,
  ChevronUp,
  ThreeDots
} from "react-bootstrap-icons";
import * as L from "leaflet";
import DesktopTopBar from "./DesktopTopBar";
import {
  attachmentAPIIdToken,
  customTabletDimensions,
  formatFlightDate,
  formatFlightDateWithoutTimeStamp,
  Spinner,
  toFixedIfNecessary
} from "../services/Common";
import MobileTopBar from "./MobileTopBar";
import TabletTopBar from "./TabletTopBar";
import measurementIcon from "../assests/Styles/assets/measurementIcon.svg";
import heatMapColoredIcon from "../assests/Styles/assets/heatMapColoredIcon.svg";
import OverlayFlightIcon from "../assests/Styles/assets/overlayFlightIcon.svg";
import plotdownloadIcon from "../assests/Styles/assets/plotdownloadIcon.svg";
import copytoclipboardIcon from "../assests/Styles/assets/copytoclipboardIcon.svg";
import reportanissueIcon from "../assests/Styles/assets/reportanissueIcon.svg";
import draggableDots from "../assests/Styles/assets/draggableDots.svg";
import whitedraggableDots from "../assests/Styles/assets/whitedraggableDots.svg";
import assessmentIcon from "../assests/Styles/assets/assessmentIcon.svg";
import AssessmentDropDown from "./AssessmentDropDown";
import Assessmentdatecombinations from "./Assessmentdatecombinations";
import { LightenDarkenColor } from "lighten-darken-color";
import FeedbackForm from "./FeedbackForm";
import NewViewerTrialSummary from "./NewViewerTrialSummary";
import ThankYouModal from "./ThankYouModal";
import MobileTools from "./MobileTools";
import WebTools from "./WebTools";
import TabTools from "./TabTools";
import DifferentCharts from "./DifferentCharts";
import DesktopAndModalNav from "./DesktopAndModalNav";
import RatingDescription from "./RatingDescription";
import BaseOverlayFlight from "./BaseOverlayFlight";
import MobileBaseOverlayFlight from "./MobileBaseOverlayFlight";
import WeatherChart from "./WeatherChart";
import TabletButtons from "./TabletButtons";
import Clipboard from "../assests/Styles/assets/clipboard.svg";
import { isTablet, isDesktop, isMobile, isMobileOnly } from "react-device-detect";
import { useMobileOrientation } from "react-device-detect";
import HamburgerMenu from "./HamburgerMenu";
// eslint-disable-next-line no-unused-vars
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { saveAs } from "file-saver";
// import { saveAs } from "file-saver";
import ReportModal from "./ReportModal";
import JSZip from "jszip";
import downloadIcon from "../assests/Styles/assets/downloadIcon.svg";
import { useCookies } from "react-cookie";
import mdiCalendarIcon from "../assests/Styles/assets/mdiCalendarIcon.svg";
import { ImageSliderWithZoom } from "./ImageSliderWithZoom";

let mapLayout = null;
let vectorLayersLayout = [];
let colorMapConst = null;
const orderArray = ["A", "B", "C", "D", "E", "F"];
let tooltipsConst = null;
let oldZoom = null;
const xDistance = 0.5;
let drawnItemsConst = null;
let layoutDimensionsConst = null;
let plotDetailsFromAPIConst = [];
let firstPlotLatLng = {};
let selectedVectorsConst = [];
let currentZoom = 0;
let defaultZoom = 0;
let unsortedButtons = [];
let selectedOverlaysList = null;
let plotDetailsConst = [];
let zoomViewerInstance = null;
let generateRenderImageConst = null;
const measureTool = L.control.measure({
  position: "bottomright",
  primaryLengthUnit: "meters",
  secondaryLengthUnit: "feet",
  primaryAreaUnit: "sqmeters",
  secondaryAreaUnit: "acres",
  captureZIndex: 1111,
  popupOptions: {
    // standard leaflet popup options http://leafletjs.com/reference-1.3.0.html#popup-option
    className: "leaflet-measure-resultpopup",
    autoPanPadding: [10, 10]
  }
});
let selectedPlotsConst = [];
// let selectedMobileImagePlotsConst = [];
let currentRasterConst = null;
let imageExpandedConst = null;
let selectedOptionConst = null;
let downloadPlotImages = [];
let downloadComparePlotsRight = [];
let activeOverlaysConst = [];
let overlayVectorsConst = [];
let isBoundingBoxSelected = false;
let dronePlotDetailsConst = [];
let plotImagesExpandConst = false;
let imageDataForDownloadConst = {};

let activeOverlayComapareLeftConst = [];
let activeOverlayComapareRightConst = [];

let comparePlotRasterLeftConst = null;
let comparePlotRasterRightConst = null;

let isBoundingBoxComapareLeft = false;
let isBoundingBoxComapareRight = false;

//Used for tooltip position
window.assessmentsCount = 0;
window.plotTreatmentCount = 0;
window.eyeOpenClosed = false;
window.isHeatMapActive = false;

export default function ViewerComponent() {
  const [cookies, removeCookie] = useCookies(["idToken", "userEmail"]);
  const { isLandscape, isPortrait } = useMobileOrientation();
  const idToken = cookies?.idToken;
  let navigate = useNavigate();
  const [profileClick, setProfileClick] = useState(false);
  const [cropData, setCropData] = useState([]);
  const [pestData, setPestData] = useState([]);
  const [viewAPIData, setviewAPIData] = useState();
  const [loading, setLoading] = useState(true);
  const [mapInitiated, setMapInitiated] = useState(false);
  const [flightDates, setFlightDates] = useState();
  const [selectedFlightDate, setSelectedFlightDate] = useState();
  const [dateLoader, setDateLoader] = useState(false);
  const [trialOpen, setTrialOpen] = useState(false);
  const [assessmentContainerVisible, setAssessmentContainerVisible] = useState(false);
  const [assessmentOptionsVisible, setAssessmentOptionsVisible] = useState(true);
  const [ratingDates, setRatingDates] = useState([]);
  const [selectedRatingDate, setSelectedRatingDate] = useState();
  const [scrollableImagePlotId, setScrollableImagePlotId] = useState();
  const [imageDataForDownload, setImageDataForDownload] = useState([]);
  const [datesAndPlotIdsMapping, setDatesAndPlotIdsMapping] = useState({});
  const params = new URLSearchParams(window.location.search);
  const [searchParams] = useSearchParams();
  const trial = searchParams.get("trial")?.replace("+", " ");
  const collectionId = params.get("collection_id");
  const dropdownRef = useRef(null);
  const flightDateRef = useRef(null);
  const flightDatesContainerRef = useRef(null);
  const mobileDatesRef = useRef(null);
  const mobileDatesContainerRef = useRef(null);
  const spinnerRef = useRef(null);
  const customMobilePortrait = isMobile && isPortrait && !customTabletDimensions();
  const customMobileLandscape = isMobile && isLandscape && !customTabletDimensions();
  const urlParams = new URLSearchParams(window.location.search);
  const currentRasterFromUrl = urlParams.get("currentRaster");
  const activeOverlaysFromUrl = urlParams.get("activeOverlays");
  const setSelectedVectorsFromUrl = urlParams.get("selectedVectors");
  const assessmentDateTypeFromUrl = urlParams.get("assessmentDateType");
  const navbarButtonsUrl = urlParams.get("navbarButtons");
  const showTreatmentUrl = urlParams.get("showTreatment");
  const baseOverlayFlightUrl = urlParams.get("baseOverlayFlight");
  const flightDropdownUrl = urlParams.get("flightDropdown");
  const timeChartUrl = urlParams.get("showTime");
  const trailIdUrl = urlParams.get("showTrailid");
  const barBoxUrl = urlParams.get("barBox");
  const showCompareUrl = urlParams.get("showCompare");
  const compareCorrelationUrl = urlParams.get("compareCorrelation");
  const dateTypeUrl = urlParams.get("dateType");
  const selectedBoundingBoxesFromUrl = urlParams.get("selectedBoundingBoxes");
  const selectedCombinationUrl = urlParams.get("selectedCombinationForHeatMap");
  const selectedTrialInfoForEyeOpenUrl = urlParams.get("selectedTrialInfoForEyeOpen");
  const selectedCombinationForEyeOpenUrl = urlParams.get("selectedCombinationForEyeOpen");
  const selectedWeatherDateUrl = urlParams.get("selectedWeatherDate");
  const selectedWeatherDevicesUrl = urlParams.get("selectedWeatherDevices");
  const selectedWeatherForwardDayUrl = urlParams.get("weatherForwardDays");
  const selectedWeatherBackwardDayUrl = urlParams.get("weatherBackwardDays");
  const [selectedWeatherDate, setSelectedWeatherDate] = useState(selectedWeatherDateUrl || "");
  const [selectedWeatherDevices, setSelectedWeatherDevices] = useState(
    selectedWeatherDevicesUrl ? JSON.parse(selectedWeatherDevicesUrl.split(",")) : []
  );
  const weatherStarteDateUrl = urlParams.get("weatherStartDate");
  const weatherEndDateUrl = urlParams.get("weatherEndDate");
  const weatherSelectedCategoryUrl = urlParams.get("weatherSelectedCategory");
  const [weatherForwardDays, setWeatherForwardDays] = useState(selectedWeatherForwardDayUrl || "");
  const [weatherBackwardDays, setWeatherBackwardDays] = useState(
    selectedWeatherBackwardDayUrl || ""
  );
  const [weatherStartDate, setWeatherStartDate] = useState(weatherStarteDateUrl || "");
  const [weatherEndDate, setWeatherEndDate] = useState(weatherEndDateUrl || "");
  const [weatherSelectedCategory, setWeatherSelectedCategory] = useState(
    weatherSelectedCategoryUrl || ""
  );
  const selectedPlotsUrl = urlParams.get("selectedPlots");
  const comparePlotImages1Url = urlParams.get("comparePlotsImages1");
  const comparePlotImages2Url = urlParams.get("comparePlotsImages2");
  const comparePlotTreatment1Url = urlParams.get("comparePlotTreatment1");
  const comparePlotTreatment2Url = urlParams.get("comparePlotTreatment2");
  const comparePlotReps1Url = urlParams.get("comparePlotReps1");
  const comparePlotReps2Url = urlParams.get("comparePlotReps2");
  // const [showOnMobile, setShowOnMobile] = useState(true);
  // const [visibleButton, setVisibleButton] = useState(false);
  // const [flightMobileOpen, setFlightMobileOpen] = useState(false);
  const [assessmentDatesAndTypes, setAssessmentDatesAndTypes] = useState([]);
  const [assessmentsFilteredByType, setAssessmentsFilteredByType] = useState({});
  const [assessmentDateType, setAssessmentDateType] = useState(
    assessmentDateTypeFromUrl ? JSON.parse(assessmentDateTypeFromUrl) : []
  );
  const [isAssessmentDropdown, setIsAssessmentDropdown] = useState(false);
  const [expandContainerForTab, setExpandContainerForTab] = useState(true);
  const [measurementSets, setMeasurementSets] = useState([]);
  const latestSelectedMeasurements = useRef(new Set());
  const latestMeasurementSets = useRef([]);
  const [expandIcon, setExpandIcon] = useState(true);
  const [assessmentDrawerVisible, toggleAssessmentDrawerVisible] = useState(true);
  const [activerVectorLayers, setActiverVectorLayers] = useState([]);
  const [selectedVectors, setSelectedVectors] = useState(
    setSelectedVectorsFromUrl?.split(",") || []
  );
  const [selectedVectorsOnlyPlots, setSelectedVectorsOnlyPlots] = useState([]);
  const [inActiverVectorLayers, setInActiverVectorLayers] = useState([]);
  const [trialInfoVisible, setTrialInfoVisible] = useState(false);
  const [isShowFeedback, setIsShowFeedback] = useState(false);
  const [isShowThankYouModel, setIsShowThankYouModel] = useState(false);
  const [showTime, setShowTime] = useState(
    timeChartUrl && !showCompareUrl && !showTreatmentUrl ? true : false
  );
  const [isShowRatingdescription, setIsShowRatingdescription] = useState(false);
  const [ratingDescriptions, setRatingDescriptions] = useState();
  const [gsdnumber, setgsdnumber] = useState();
  //For Drone Image
  const mapRef = createRef();
  const [collectionData, setCollectionData] = useState({});
  const collectionDataRef = useRef([]);
  const [rasterLayers, setRasterLayers] = useState([]);
  // const [overlayLayers, setOverlayLayers] = useState([]);
  const [mapState, setMapState] = useState(null);
  const [rasterLayersList, setRasterLayersList] = useState({});
  const [overlayLayersList, setOverlayLayersList] = useState([]);
  const [activeOverlays, setActiveOverlays] = useState(activeOverlaysFromUrl?.split(",") || []);
  const [currentRaster, setCurrentRaster] = useState(currentRasterFromUrl || "Visible");
  const [assessmentBy, setAssessmentBy] = useState(dateTypeUrl || "date");
  const [overlayVectors, setOverlayVectors] = useState(null);
  const [geojson, setgeojson] = useState();
  const [selectedBoundingBoxes, setSelectedBoundingBoxes] = useState(
    selectedBoundingBoxesFromUrl || false
  );
  const [protocolName, setProtocolName] = useState("");
  const [baseVisible, setBaseVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isImgAvailable, setIsImgAvailable] = useState(false);
  const [measurementToolVisible, setMeasurementToolVisible] = useState(false);
  const [selectedCombinationForHeatMap, setSelectedCombinationForHeatMap] =
    useState(selectedCombinationUrl);
  const [selectedCombinationForEyeOpen, setSelectedCombinationForEyeOpen] = useState(
    selectedCombinationForEyeOpenUrl
  );
  const [selectedTrialInfoForEyeOpen, setSelectedTrialInfoForEyeOpen] = useState(
    selectedTrialInfoForEyeOpenUrl
  );
  const isImgAvailableLatest = useRef(false);
  const [trialSummary, setTrialSummary] = useState(false);
  const [chartsValue, setChartsValue] = useState(barBoxUrl || "Bar");
  const [barExpand, setBarExpand] = useState(false);
  const [droneExpand, setDroneExpand] = useState(false);
  const [showTreatment, setShowTreatment] = useState(timeChartUrl || showCompareUrl ? false : true);
  const [heatMapLegendColors, setHeatMapLegendColors] = useState([]);
  const [heatMapLegendRange, setHeatMapLegendRange] = useState([]);
  const [showHeatMapLegend, setShowHeatMapLegends] = useState(true);
  const [assementRes, setAssesmentRes] = useState([]);
  const [mapLoading, setMapLoading] = useState(true);
  // const [trialMeasurementsConst, setTrialMeasurements] = useState([]);
  // const [mobileTrailDropdown, setMobileTrailDropdown] = useState(false);
  const [showExtenedMenu, setShowExtendendMenu] = useState(false);
  // const [showExtentedVisible, setShowExtendedVisible] = useState(false);
  const [measurementSetLoaded, setMeasurementLoaded] = useState(true);
  const [showCompare, setShowCompare] = useState(showCompareUrl ? true : false);
  const navRef = useRef(null);
  const [barData, setBarData] = useState([]);
  const [noMean, setNoMean] = useState(false);
  const [noAssesment, setNoAssesment] = useState(false);
  const [error, setError] = useState(false);
  const [flightData, setFlightData] = useState([]);
  const [compareChartsValue, setCompareChartsValue] = useState(compareCorrelationUrl || "Compare");
  const [barData2, setBarData2] = useState([]);
  const [noAssesment2, setNoAssesment2] = useState(false);
  const [noMean2, setNoMean2] = useState(false);
  const [showTreatmentMT, setShowTreatmentMT] = useState(false);
  const [showTimeMT, setShowTimeMT] = useState(timeChartUrl || false);
  const [showCompareMT, setShowCompareMT] = useState(false);
  const [treatmentDataLoaded, setTreatmentDataLoaded] = useState(true);
  const [flightDataLoaded, setFightDataLoaded] = useState(true);
  const [compareData1Loaded, setCompareData1Loaded] = useState(true);
  const [compareData2Loaded, setCompareData2Loaded] = useState(true);
  const [ratingDescriptionsLoaded, setRatingDescriptionsLoaded] = useState(true);
  //const [treatmentLabel, setTreatmentLabel] = useState(false);
  const [treatmentLabelData, setTreatmentLabelData] = useState([]);
  const [treatmentExpansion, setTreatmentExpansion] = useState(false);
  const [isSelectPlotOpen, setIsSelectPlotOpen] = useState(false);
  const [isMobileImagesSelectPlotOpen, setIsMobileImagesSelectPlotOpen] = useState(false);
  // const windowHamburgerDevice = window.innerHeight < 375 && window.innerWidth > 740;
  const [isTreatmentUser, setIsTreatmentUser] = useState();
  const [trialType, setTrialType] = useState("manual");
  const [plotDetails, setPlotDetails] = useState([]);
  const [dronePlotDetails, setDronePlotDetails] = useState([]);
  const [protocolTrial, setProtocolTrial] = useState([]);
  const [navbarButtons, setNavbarButtons] = useState(
    navbarButtonsUrl ? JSON.parse(navbarButtonsUrl) : ["Drone"]
  );
  const [tabletNavbarButton, setTabletNavbarButton] = useState("Drone");
  const [deviceData, setDeviceData] = useState({});
  const [deviceId, setDeviceId] = useState("");
  const [soilDeviceId, setSoilDeviceId] = useState([]);
  const [deviceTrial, setDeviceTrial] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [geometry, setGeometry] = useState({});
  const [trialGeometry, setTrialGeometry] = useState({});
  const [envExpand, setEnvExpand] = useState(false);
  const [plotImagesExpand, setPlotImagesExpand] = useState(false);
  const [zeroDegreeMapRotation, setZeroDegreeMapRotation] = useState(false);
  const [baseOverlayFlightOpen, setBaseOverlayFlightOpen] = useState(baseOverlayFlightUrl || false);
  const [planDataLoaded, setPlanDataLoaded] = useState(true);
  const trialDropdownContainerRef = useRef(null);
  const baseOverlayRef = useRef(null);
  const trialButtonRef = useRef(null);
  const baseOverlayButtonRef = useRef(null);
  const dragPerson = useRef(0);
  const draggedOverPerson = useRef(0);
  const [showTabletTooltip, setShowTabletTooltip] = useState(false);
  // const targetTabletRef = useRef(null);
  const [selectedTabButton, setSelectedTabButton] = useState("");
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const hamburgerButtonRef = useRef(null);
  const tooltipRef = useRef(null);
  const tooltipRef1 = useRef(null);
  const [assessmentMobileOpen, setAssessmentMobileOpen] = useState(false);
  const mobileAssessmentdatecombinationRef = useRef(null);
  const [closeTooltip, setCloseTooltip] = useState(false);
  const [highlightedPlotId, setHighlightedPlotId] = useState();
  const [chartPointValue, setChartPointValue] = useState({});
  const [subPlots, setSubPlots] = useState([]);
  const [replicants, setReplicants] = useState([]);
  const [selectedSubplots, setSelectedSubplots] = useState([]);
  const [chartButtonSelected, SetChartButtonSelected] = useState(
    timeChartUrl || showCompareUrl || compareCorrelationUrl ? true : false
  );
  const [selectedSubPlotOptions, setSeletedSubPlotOptions] = useState([]);
  const selectedPlotsUrlFilter = selectedPlotsUrl === "" ? null : selectedPlotsUrl?.split(",");
  const [selectedPlots, setSelectedPlots] = useState(selectedPlotsUrlFilter || []);
  const [selectedMobileImagePlots, setSelectedMobileImagePlots] = useState([]);
  const [isMobileImageFlightContainerVisible, setIsMobileImageFlightContainerVisible] =
    useState(false);
  const [plotIdsForPlotImage, setPlotIdsForPlotImage] = useState([]);
  const [showPlotImageMessage, setShowPlotImageMessage] = useState(true);
  const [showMobileImageMessage, setShowMobileImageMessage] = useState(true);
  const [comparePlotMessage, setComparePlotMessage] = useState(true);
  const [showDroneSelectedMessage, setShowDroneSelectedMessage] = useState(false);
  const [comparePlotTreatment1, setComparePlotTreatment1] = useState(
    comparePlotTreatment1Url || ""
  );
  const [comparePlotReps1, setComparePlotReps1] = useState(comparePlotReps1Url || "");
  const [comparePlotTreatment2, setComparePlotTreatment2] = useState(
    comparePlotTreatment2Url || ""
  );
  const [comparePlotReps2, setComparePlotReps2] = useState(comparePlotReps2Url || "");
  // const [firstComparePlotOpen, setFirstComparePlotOpen] = useState(false);
  //const [secondComparePlotOpen, setSecondComparePlotOpen] = useState(false);
  const [treatmentsChevron, setTreatmentsChevron] = useState(false);
  const [firstRepsChevron, setFirstRepsChevron] = useState(false);
  const [secondTreatmentsChevron, setSecondTreatmentsChevron] = useState(false);
  const [secondRepsChevron, setSecondRepsChevron] = useState(false);
  const [plotImageExpandedPlotId, setPlotImageExpandedPlotId] = useState(null);
  const [mobileImageExpandedPlotId, setMobileImageExpandedPlotId] = useState(null);
  const [comparePlotsImages1, setComparePlotsImages1] = useState(
    comparePlotImages1Url?.split(",") || []
  );
  const [comparePlotsImages2, setComparePlotsImages2] = useState(
    comparePlotImages2Url?.split(",") || []
  );
  const [displayInfoIcon, setDisplayInfoIcon] = useState(true);
  const [comparePlotExpanded1, setComparePlotExpanded1] = useState();
  const [comparePlotExpanded2, setComparePlotExpanded2] = useState();
  const [comparePlotTreatmentData, setComparePlotTreatmentData] = useState([]);
  const [comparePlotRepsData, setComparePlotRepsData] = useState([]);
  const [comparePlotData, setComparePlotData] = useState([]);

  const firstComparePlotDropdownRef = useRef(null);
  const firstTreatmentDropdownRef = useRef(null);
  const firstRepDropdownRef = useRef(null);
  const secondTreatmentDropdownRef = useRef(null);
  const secondRepDropdownRef = useRef(null);
  const secondComparePlotDropdownRef = useRef(null);
  const selectPlotDropdownRef = useRef(null);
  const selectPlotFilterButtonRef = useRef(null);
  const mapPlotRefs = useRef(selectedPlots.map(() => createRef()));
  const overlayLayerRefs = useRef(selectedPlots.map(() => createRef()));
  const mapplotContainerRef = useRef(selectedPlots.map(() => createRef()));
  const droneMessageRef = useRef(null);
  const plotImageMessageRef = useRef(null);
  const mobileImageMessageRef = useRef(null);
  const comparePlotMessageRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const [trialSubPlot, setTrialSubPlot] = useState();
  const [comparePlotImagesDotsMenu1, setComparePlotImagesDotsMenu1] = useState(null);
  const [comparePlotImagesDotsMenu2, setComparePlotImagesDotsMenu2] = useState(null);
  const plotHamburgerMenuRef = useRef(null);
  const [plotImagesDotsMenu, setPlotImagesDotsMenu] = useState(null);
  const [showReportingModal, setShowReportingModal] = useState(false);
  const [reportingPlotId, setReportingPlotId] = useState("");
  const [reportingFlightDate, setReportingFlightDate] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const comparePlotLeftRef = useRef([]);
  const comparePlotRightRef = useRef([]);
  const comparePlotLeftOverlayRef = useRef([]);
  const comparePlotRightOverlayRef = useRef([]);
  const [openTrialReportModal, setOpenTrialReportModal] = useState(false);
  const [copyToClipBoardLoader, setCopyToClipBoardLoader] = useState(false);
  const [plotImageDownload, setPlotImageDownload] = useState(false);
  const [plotImageDownload2, setPlotImageDownload2] = useState(false);
  const [extraButtonsMenu, setExtraButtonsMenu] = useState(false);
  const [extraMenuItems, setExtraMenuItems] = useState([]);
  const [weatherDevices, setWeatherDevices] = useState([]);
  const extratButtonsMenuIconRef = useRef(null);
  const extraButtonsMenuIconContainerRef = useRef(null);
  const [compareFirstFlightContainerVisible, setCompareFirstFlightContainerVisible] =
    useState(false);
  const [compareSecondFlightContainerVisible, setCompareSecondFlightContainerVisible] =
    useState(false);
  const [firstImageSource, setFirstImageSource] = useState("Drone");
  const [secondImageSource, setSecondImageSource] = useState("Drone");
  const [compareFirstTreatmentReps, setCompareFirstTreatmentReps] = useState("Treatment");
  const [compareSecondTreatmentReps, setCompareSecondTreatmentReps] = useState("Treatment");
  const [secondCompareSelectedFlightDate, setSecondCompareSelectedFlightDate] = useState();
  const [firstCompareSelectedFlightDate, setFirstCompareSelectedFlightDate] = useState();
  const [baseOverlayComparePlot1, setBaseOverlayComparePlot1] = useState("Visible");
  const [baseOverlayComparePlot2, setBaseOverlayComparePlot2] = useState("Visible");
  const [activeOverlaysCompareLeft, setActiveOverlaysCompareLeft] = useState([]);
  const [activeOverlaysCompareRight, setActiveOverlaysCompareRight] = useState([]);
  const [comparePlotMobileDate1, setComparePlotMobileDate1] = useState();
  const [comparePlotMobileDate2, setComparePlotMobileDate2] = useState();

  const [checkedItems1, setCheckedItems1] = useState({
    crops: false,
    classification: false,
    weeds: false
  });
  const [checkedItems2, setCheckedItems2] = useState({
    crops: false,
    classification: false,
    weeds: false
  });
  const [showMobileImagesReportModal, setShowMobileImagesReportModal] = useState(false);
  const [currentMobileImageIndex, setCurrentMobileImageIndex] = useState(0);
  const baseOverlayCompare1 = useRef(null);
  const baseOverlayCompare2 = useRef(null);
  const collectionDataCompareLeftRef = useRef([]);
  const collectionDataCompareRightRef = useRef([]);
  const [collectionDataCompareLeft, setCollectionDataCompareLeft] = useState({});
  const [collectionDataCompareRight, setCollectionDataCompareRight] = useState({});
  const buttonsRef = useRef(null);
  const [boundingBoxCompareLeft, setBoundingBoxCompareLeft] = useState(false);
  const [boundingBoxCompareRight, setBoundingBoxCompareRight] = useState(false);
  const [downloadedAllPlotImagesIcon, setDownloadedAllPlotImagesIcon] = useState(false);

  const generateRefs = (el, index, ref) => {
    if (el && !ref.current.includes(el)) {
      ref.current[index] = el;
    }
  };

  useEffect(() => {
    if (ratingDates?.length > 0 && trialType === "manual") {
      setFirstImageSource("Mobile");
      setSecondImageSource("Mobile");
    }
    if (trialType !== "manual") {
      setFirstImageSource("Drone");
      setSecondImageSource("Drone");
    }
  }, [ratingDates, trialType]);

  useEffect(() => {
    comparePlotRasterLeftConst = baseOverlayComparePlot1;
  }, [baseOverlayComparePlot1]);

  useEffect(() => {
    comparePlotRasterRightConst = baseOverlayComparePlot2;
  }, [baseOverlayComparePlot2]);

  useEffect(() => {
    activeOverlayComapareLeftConst = activeOverlaysCompareLeft;
  }, [activeOverlaysCompareLeft]);

  useEffect(() => {
    activeOverlayComapareRightConst = activeOverlaysCompareRight;
  }, [activeOverlaysCompareRight]);

  useEffect(() => {
    plotDetailsConst = plotDetails;
  }, [plotDetails]);

  useEffect(() => {
    currentRasterConst = currentRaster;
  }, [currentRaster]);
  useEffect(() => {
    dronePlotDetailsConst = dronePlotDetails;
  }, [dronePlotDetails]);
  useEffect(() => {
    plotImagesExpandConst = plotImagesExpand;
  }, [plotImagesExpand]);

  const [availableTopButtons, setAvailableTopButtons] = useState([]);

  useEffect(() => {
    const handleClickMenuHamburgerMenuOutside = (event) => {
      if (plotHamburgerMenuRef.current && !plotHamburgerMenuRef.current.contains(event.target)) {
        setPlotImagesDotsMenu(null);
        setComparePlotImagesDotsMenu1(null);
        setComparePlotImagesDotsMenu2(null);
      }
    };
    window.addEventListener("mousedown", handleClickMenuHamburgerMenuOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickMenuHamburgerMenuOutside);
    };
  }, []);

  const downloadMobileImage = (plotId, selectedFlightDate, mobileTreatmentNumber) => {
    const url = imageDataForDownload?.[plotId];
    if (!url) return;
    const link = document.createElement("a");
    link.href = url[currentMobileImageIndex];
    link.download =
      [selectedOptionConst] +
      "_" +
      formatFlightDateWithoutTimeStamp(selectedFlightDate) +
      "_" +
      plotId +
      "_" +
      mobileTreatmentNumber +
      ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyToClipBoardMobileImage = async (plotId) => {
    setCopyToClipBoardLoader(true);

    const url = imageDataForDownload?.[plotId];
    if (!url) return;
    try {
      const response = await fetch(url[currentMobileImageIndex]);
      const blob = await response.blob();
      const data = new ClipboardItem({ "image/png": blob });
      await navigator.clipboard.write([data]);
    } catch (err) {
      console.log("failed to copy image to clipboard", err);
    }

    setCopyToClipBoardLoader(false);
  };

  const plotsHamburgerMenu = (
    plotId,
    index,
    selectedFlightDate,
    compareSide,
    isMobileImage = false,
    mobileTreatmentNumber = null
  ) => {
    return (
      <div
        style={{
          backgroundColor: "white",
          width: "150px",
          height: "100px",
          position: "absolute",
          right: "2px",
          zIndex: 999,
          top: "58px"
        }}
        id="downloadText"
        ref={plotHamburgerMenuRef}>
        <div
          className="plot-dots-menu-text cursor-pointer"
          onClick={() =>
            isMobileImage
              ? downloadMobileImage(plotId, selectedFlightDate, mobileTreatmentNumber)
              : downloadScreenShot(plotId, index, compareSide)
          }>
          <span>
            <img src={plotdownloadIcon} alt="" />
          </span>
          <span className="font-size-small font-weight-400 font-family-Helvetica pl-2">
            Download
          </span>
        </div>
        <div
          className="plot-dots-menu-text cursor-pointer"
          onClick={() =>
            isMobileImage
              ? copyToClipBoardMobileImage(plotId)
              : copyToClipBoardClick(plotId, index, compareSide)
          }>
          <span>
            <img src={copytoclipboardIcon} alt="" />
          </span>
          <span className="font-size-small font-weight-400 font-family-Helvetica pl-2">
            Copy to Clipboard
          </span>
        </div>
        <div className="plot-dots-menu-text">
          <span>
            <img src={reportanissueIcon} alt="" />
          </span>
          <span
            className="font-size-small font-weight-400 font-family-Helvetica pl-2 cursor-pointer"
            onClick={() => {
              setShowMobileImagesReportModal(isMobileImage);
              setShowReportingModal(true);
              setReportingPlotId(plotId);
              setReportingFlightDate(formatFlightDate(selectedFlightDate));
              setPlotImagesDotsMenu(null);
              setComparePlotImagesDotsMenu1(null);
              setComparePlotImagesDotsMenu2(null);
            }}>
            Report an issue
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleClickMenuOutside = (event) => {
      if (
        extratButtonsMenuIconRef.current &&
        !extratButtonsMenuIconRef.current.contains(event.target) &&
        !extraButtonsMenuIconContainerRef?.current?.contains(event.target)
      ) {
        setExtraButtonsMenu(false);
      }
    };
    window.addEventListener("mousedown", handleClickMenuOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickMenuOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickMessageOutside = (event) => {
      if (plotImageMessageRef.current && !plotImageMessageRef.current.contains(event.target)) {
        setShowPlotImageMessage(false);
      }
      if (mobileImageMessageRef.current && !mobileImageMessageRef.current.contains(event.target)) {
        setShowMobileImageMessage(false);
      }
      if (comparePlotMessageRef.current && !comparePlotMessageRef.current.contains(event.target)) {
        setComparePlotMessage(false);
      }
    };
    window.addEventListener("mousedown", handleClickMessageOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickMessageOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickPlotOutside = (event) => {
      if (
        selectPlotDropdownRef.current &&
        !selectPlotDropdownRef.current.contains(event.target) &&
        !selectPlotFilterButtonRef.current.contains(event.target)
      ) {
        setIsSelectPlotOpen(false);
        setIsMobileImagesSelectPlotOpen(false);
      }
    };
    window.addEventListener("mousedown", handleClickPlotOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickPlotOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickFirstCompareOutside = (event) => {
      if (
        firstComparePlotDropdownRef.current &&
        !firstComparePlotDropdownRef.current.contains(event.target) &&
        !firstComparePlotDropdownRef.current.contains(event.target)
      ) {
        //setFirstComparePlotOpen(false);
      }
      if (
        firstTreatmentDropdownRef.current &&
        !firstTreatmentDropdownRef.current.contains(event.target) &&
        !firstTreatmentDropdownRef.current.contains(event.target)
      ) {
        setTreatmentsChevron(false);
      }
      if (
        firstRepDropdownRef.current &&
        !firstRepDropdownRef.current.contains(event.target) &&
        !firstRepDropdownRef.current.contains(event.target)
      ) {
        setFirstRepsChevron(false);
      }
    };
    window.addEventListener("mousedown", handleClickFirstCompareOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickFirstCompareOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (droneMessageRef.current && !droneMessageRef.current.contains(event.target)) {
        setShowDroneSelectedMessage(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [droneMessageRef]);

  useEffect(() => {
    const handleClickSecondCompareOutside = (event) => {
      if (
        secondComparePlotDropdownRef.current &&
        !secondComparePlotDropdownRef.current.contains(event.target) &&
        !secondComparePlotDropdownRef.current.contains(event.target)
      ) {
        //setSecondComparePlotOpen(false);
      }
      if (
        secondTreatmentDropdownRef.current &&
        !secondTreatmentDropdownRef.current.contains(event.target) &&
        !secondTreatmentDropdownRef.current.contains(event.target)
      ) {
        setSecondTreatmentsChevron(false);
      }
      if (
        secondRepDropdownRef.current &&
        !secondRepDropdownRef.current.contains(event.target) &&
        !secondRepDropdownRef.current.contains(event.target)
      ) {
        setSecondRepsChevron(false);
      }
    };
    window.addEventListener("mousedown", handleClickSecondCompareOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickSecondCompareOutside);
    };
  }, []);

  const selectPlots = (e) => {
    if (e.target.checked) {
      setSelectedPlots([...selectedPlots, e.target.value]);
      selectedPlotsConst = [...selectedPlots, e.target.value];
    } else {
      setSelectedPlots(selectedPlots.filter((item) => item !== e.target.value));
      selectedPlotsConst = selectedPlots.filter((item) => item !== e.target.value);
    }
    setTimeout(() => {
      renderPlotImages();
    }, 500);
  };

  const selectMobileImagePlots = (e) => {
    if (e.target.checked) {
      setSelectedMobileImagePlots([...selectedMobileImagePlots, e.target.value]);
      getMobileImagesByPlotId(e.target.value);
    } else {
      setSelectedMobileImagePlots(
        selectedMobileImagePlots.filter((item) => item !== e.target.value)
      );
    }
    setTimeout(() => {
      renderPlotImages();
    }, 500);
  };

  const handleLinkClick = () => {
    navigator.clipboard.writeText(deepLinkUrl).then(() => {
      // Show the "Link copied to clipboard" text
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  const renderTooltip = (name, props) => {
    return (
      <Tooltip id="tooltip" {...props} className="newviewer-buttons-tooltip">
        <div className="d-flex align-items-center justify-content-between ">
          <span style={{ marginLeft: "8px", marginRight: "15px" }}>
            <InfoCircleFill height={20} width={20} />
          </span>
          <span className="mr-4 font-size-small font-weight-700">
            {name === "Environmental"
              ? "Please select a trial to enable Environmental"
              : name === "Charts"
              ? "Please select at least one assessment to enable Charts"
              : name === "Treatment Labels"
              ? "Please select a trial to enable Treatment Labels"
              : name === "Plot Images"
              ? "Please select a trial to enable Plot Images"
              : name === "Compare Plots"
              ? "Please select a trial to enable Compare Plots"
              : name === "Mobile Images"
              ? "Please select a trial to enable Mobile Images"
              : ""}
          </span>
        </div>
      </Tooltip>
    );
  };

  function handleSort() {
    const peopleClone = [...availableTopButtons];
    const temp = peopleClone[dragPerson.current];

    peopleClone[dragPerson.current] = peopleClone[draggedOverPerson.current];
    peopleClone[draggedOverPerson.current] = temp;

    setAvailableTopButtons(peopleClone);
    if (navbarButtons?.length === 2) {
      const firstValue = peopleClone?.findIndex((item) => item === navbarButtons[0]);
      const secondValue = peopleClone?.findIndex((item) => item === navbarButtons[1]);
      if (firstValue > secondValue) {
        setNavbarButtons([navbarButtons[1], navbarButtons[0]]);
      }
    }
  }

  const toggleBaseOverlayFlight = () => {
    setIsAssessmentDropdown(false);
    setBaseOverlayFlightOpen(!baseOverlayFlightOpen);
  };

  const handleTopButtonClick = (buttonName) => {
    if (buttonName === "Drone") {
      clearAllDrawnLayers();
      clearVectors();
      setMapInitiated(false);
      let _container = L.DomUtil.get("map");
      if (_container) {
        if (_container._mapInstance) {
          _container._mapInstance.remove();
          _container._mapInstance = null;
        }
        _container._leaflet_id = null;
      }
      if (trialType === "manual") {
        setTimeout(() => {
          initMap(true);
          bindCallbacks();
          generateManualPlots();
        }, 100);
      }
      if (selectedCombinationForHeatMap) {
        removeHeatMap(selectedOption);
        setTimeout(() => {
          window.isHeatMapActive = true;
          if (selectedCombinationForEyeOpen) {
            if (
              selectedCombinationForEyeOpen?.type === selectedCombinationForHeatMap?.type &&
              selectedCombinationForHeatMap?.date === selectedCombinationForEyeOpen?.date
            ) {
              switchToolTip(1);
              heatMapSoloMeasurement();
            } else {
              setSelectedCombinationForEyeOpen(undefined);
              setSelectedTrialInfoForEyeOpen(undefined);
              heatMapSoloMeasurement();
            }
          } else {
            heatMapSoloMeasurement();
            switchToolTip(0);
          }
          applyHeatMap(selectedCombinationForHeatMap, selectedOption);
        }, 500);
      }
      if (selectedCombinationForEyeOpen || selectedTrialInfoForEyeOpen) {
        setTimeout(() => {
          window.eyeOpenClosed = true;
          if (selectedCombinationForHeatMap) {
            if (
              selectedCombinationForEyeOpen?.type === selectedCombinationForHeatMap?.type &&
              selectedCombinationForHeatMap?.date === selectedCombinationForEyeOpen?.date
            ) {
              heatMapSoloMeasurement();
            } else {
              removeHeatMap(selectedOption);
              setSelectedCombinationForHeatMap(undefined);
            }
          } else {
            soloMeasurement();
          }
          switchToolTip(1);
        }, 100);
      }
      setTimeout(() => {
        RetainMapZoomOnAssessmentChange();
        if (mapLayout) {
          if (typeof mapLayout.invalidateSize === "function") {
            mapLayout.invalidateSize();
          }
        }
      }, 100); // Fetch drone data and other necessary operations here
    } else {
      if (
        (!selectedOption || selectedOption === "All trials" || selectedOption === "No trials") &&
        buttonName === "Environmental" &&
        isImgAvailableLatest.current
      ) {
        setGeometry(mapLayout?.getCenter());
      }
      setMapInitiated(true);
      setTimeout(() => {
        if (mapLayout) {
          if (typeof mapLayout.invalidateSize === "function") {
            mapLayout.invalidateSize();
          }
        }
      }, 100);
    }
    if (buttonName === "Charts") {
      SetChartButtonSelected(true);
    }
    if (!navbarButtons.includes(buttonName)) {
      let temp = [...navbarButtons];
      if (navbarButtons.length === 2) {
        temp = [
          unsortedButtons?.length > 0
            ? unsortedButtons[1]
            : navbarButtons?.length > 0 && navbarButtons[1],
          buttonName
        ];
        unsortedButtons = [...temp];
        setNavbarButtons(temp);
      } else {
        temp = [...navbarButtons.filter((item) => item !== buttonName), buttonName];
        setNavbarButtons(temp);
        unsortedButtons = [...temp];
      }
      if (temp?.length === 2) {
        const newArray = ["Drone", ...availableTopButtons];
        const firstValue = newArray?.findIndex((item) => item === temp[0]);
        const secondValue = newArray?.findIndex((item) => item === temp[1]);
        if (firstValue > secondValue) {
          temp.reverse();
        }
        setNavbarButtons(temp);
      }
    }

    if (buttonName === "Compare Plots" || buttonName === "Mobile Images") {
      setNavbarButtons([buttonName]);
    } else {
      setNavbarButtons((prevButtons) => {
        const updatedButtons = prevButtons.filter(
          (button) => button !== "Compare Plots" && button !== "Mobile Images"
        );
        return updatedButtons;
      });
    }

    if (buttonName === "Environmental") {
      setSelectedWeatherDate("");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (trialDropdownContainerRef.current &&
          !trialDropdownContainerRef.current.contains(event.target) &&
          !trialButtonRef.current.contains(event.target)) ||
        (baseOverlayRef.current &&
          !baseOverlayRef.current.contains(event.target) &&
          !baseOverlayButtonRef.current.contains(event.target)) ||
        (baseOverlayCompare1.current &&
          !baseOverlayCompare1.current.contains(event.target) &&
          !baseOverlayButtonRef.current.contains(event.target)) ||
        (baseOverlayCompare2.current &&
          !baseOverlayCompare2.current.contains(event.target) &&
          !baseOverlayButtonRef.current.contains(event.target))
      ) {
        if (baseOverlayRef.current) {
          setBaseOverlayFlightOpen(false);
          setFlightContainerVisible(false);
        }

        if (baseOverlayCompare1.current) {
          setCompareFirstFlightContainerVisible(false);
        }
        if (baseOverlayCompare2.current) {
          setCompareSecondFlightContainerVisible(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleHamburgerClickOutside = (event) => {
      if (
        hamburgerButtonRef.current &&
        !hamburgerButtonRef.current.contains(event.target) &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        tooltipRef1.current &&
        !tooltipRef1.current.contains(event.target)
      ) {
        setHamburgerOpen(false);
        setAssessmentContainerVisible(false);
      }
    };
    document.addEventListener("mousedown", handleHamburgerClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleHamburgerClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleTooltipClickOutside = (event) => {
      if (
        (tooltipRef.current && tooltipRef.current.contains(event.target)) ||
        (tooltipRef1.current && tooltipRef1.current.contains(event.target))
      ) {
        setCloseTooltip(false);
      }
    };
    document.addEventListener("mousedown", handleTooltipClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleTooltipClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleAssessmentdatecombinationClickOutside = (event) => {
      if (
        mobileAssessmentdatecombinationRef.current &&
        !mobileAssessmentdatecombinationRef.current.contains(event.target)
      ) {
        setAssessmentMobileOpen(false);
      }
    };
    document.addEventListener("mousedown", handleAssessmentdatecombinationClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleAssessmentdatecombinationClickOutside);
    };
  }, []);

  const handleCompareChartRadio = (e) => {
    setCompareChartsValue(e.target.value);
  };

  // const scrollLeft = () => {
  //   if (navRef.current) {
  //     const container = navRef.current;
  //     const scrollAmount = 100;
  //     container.scrollLeft -= scrollAmount;
  //   }
  // };

  useEffect(() => {
    selectedVectorsConst = selectedVectors;
  }, [selectedVectors]);

  useEffect(() => {
    if (navbarButtons.length === 1) {
      setEnvExpand(true);
    } else if (navbarButtons.length > 1) {
      setEnvExpand(false);
    }
    if (!navbarButtons.includes("Mobile Images")) {
      setSelectedMobileImagePlots([]);
      setMobileImageExpandedPlotId();
    }
  }, [navbarButtons]);
  // const scrollRight = () => {
  //   if (navRef.current) {
  //     const container = navRef.current;
  //     const scrollAmount = 100;
  //     container.scrollLeft += scrollAmount;
  //   }
  // };
  useEffect(() => {
    latestMeasurementSets.current = measurementSets;
  }, [measurementSets]);
  document.title = collectionData.name
    ? `Viewer : ${collectionData.name}`
    : trialType === "manual"
    ? "Viewer"
    : "Viewer | Loading...";
  let trailArray = new Set();
  const [selectedOption, setSelectedOption] = useState(
    trial === "null" || trial === "All+trials" || !trial ? "All trials" : trial
  );
  const trialNotSelected =
    !selectedOption || selectedOption === "All trials" || selectedOption === "No trials";
  useEffect(() => {
    selectedOptionConst = selectedOption;
    trialNotSelected && mapLayout && mapLayout.setBearing(0);
  }, [selectedOption]);
  const [flightContainerVisible, setFlightContainerVisible] = useState(flightDropdownUrl || false);
  const [flightContainerVisible2, setFlightContainerVisible2] = useState(false);

  // const handleFlightToggle = () => {
  //   setFlightMobileOpen(!flightMobileOpen);
  //   setBaseVisible(false);
  //   setOverlayVisible(false);
  //   setTrialInfoVisible(false);
  // };

  const handleAsessmentDropdown = () => {
    setIsAssessmentDropdown(!isAssessmentDropdown);
    // setTreatmentLabel(true);
    setBaseOverlayFlightOpen(false);
  };

  const handleSelect1 = (eventKey) => {
    let path = "/viewer";
    if (collectionId) {
      navigate({
        pathname: path,
        search: `?${createSearchParams({
          collection_id: collectionId,
          trial: eventKey
        })}`
      });
    } else {
      navigate({
        pathname: path,
        search: `?${createSearchParams({
          trial: eventKey
        })}`
      });
    }
    setNavbarButtons(["Drone"]);
    setChartPointValue({});
    setHighlightedPlotId(undefined);
    setNewTrial(eventKey);
    setMeasurementLoaded(true);
    setRatingDescriptionsLoaded(true);
    setSelectedOption(eventKey);
    setShowPlotImageMessage(true);
    setShowMobileImageMessage(true);
    setComparePlotMessage(true);
    if (eventKey === ("All trials" || "All+trials") || eventKey === "No trials") {
      setTabletNavbarButton("Drone");
    }
    setAssessmentDateType([]);
    setTimeout(() => {
      if (selectedBoundingBoxes) {
        mapLayout.removeLayer(geojson);
        setTimeout(() => {
          let overlayVectorLayers = [];
          let tempgeojson = null;
          for (let key in overlayVectors.features) {
            overlayVectorLayers.push(overlayVectors.features[key]);
          }
          tempgeojson = L.geoJSON(overlayVectorLayers, {
            color: "#FF0000",
            fill: true,
            fillOpacity: 0
          }).addTo(mapLayout);
          setgeojson(tempgeojson);
          // setSelectedBoundingBoxes(true);
        });
      }
    });
    setTimeout(() => {
      if (mapLayout) {
        if (typeof mapLayout.invalidateSize === "function") {
          mapLayout.invalidateSize();
        }
      }
    }, 100);
  };

  useEffect(() => {
    isImgAvailableLatest.current = isImgAvailable;
  }, [isImgAvailable]);

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          treatmentUserLabel(
            {
              email: cookies?.userEmail
            },
            (res) => {
              setIsTreatmentUser(res?.is_allowed);
            },
            (err) => {
              console.log("err in treatment user label", err);
            }
          );
        }
      },
      (err) => {
        setLoading(false);
        window.location.reload();
        console.log("err", err);
      }
    );
  }, [cookies?.userEmail]);

  useEffect(() => {
    if (selectedOption && (selectedOption !== "All trials" || selectedOption !== "No trials")) {
      getPlotsForTrial(
        selectedOption,
        (res) => {
          setPlotIdsForPlotImage(res?.result_dict?.sort((a, b) => a.plotid - b.plotid) || []);
        },
        (err) => {
          console.log("err", err);
        }
      );
      getTreatmentReplicates(
        selectedOption,
        (res) => {
          setComparePlotData(res?.result_dict);
          const treatmentData = [...new Set(res?.result_dict?.map((item) => item?.treatment))].sort(
            (a, b) => a - b
          );
          const repsData = [...new Set(res?.result_dict?.map((item) => item?.replicate_id))].sort(
            (a, b) => a - b
          );
          setComparePlotTreatmentData(treatmentData);
          setComparePlotRepsData(repsData);
          // setComparePlotTreatment1(res?.)
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isDesktop) {
      const availableButtons =
        isTreatmentUser && trialType !== "manual"
          ? ["Treatment Labels", "Charts", "Environmental", "Plot Images"]
          : !isTreatmentUser && trialType !== "manual"
          ? ["Charts", "Environmental", "Plot Images"]
          : isTreatmentUser && trialType === "manual"
          ? ["Treatment Labels", "Charts", "Environmental"]
          : ["Charts", "Environmental"];
      setAvailableTopButtons(availableButtons);
    } else {
      setAvailableTopButtons(
        isTreatmentUser
          ? ["Treatment Labels", "Charts", "Environmental"]
          : ["Charts", "Environmental"]
      );
    }
  }, [isTreatmentUser, trialType !== "manual", isDesktop, ratingDates]);

  useEffect(() => {
    setTimeout(() => {
      if (
        buttonsRef.current &&
        availableTopButtons?.length > 0 &&
        buttonsRef.current.offsetWidth !== 0
      ) {
        const dynamicBtnResult = Math.ceil(buttonsRef.current.offsetWidth / 134);
        setAvailableTopButtons(
          availableTopButtons.filter((_, index) => index < dynamicBtnResult - 2)
        );
        setExtraMenuItems(availableTopButtons.filter((_, index) => index >= dynamicBtnResult - 2));
      }
    }, 100);
  }, [loading]);

  useEffect(() => {
    if (selectedCombinationForHeatMap) {
      window.isHeatMapActive = true;
      if (selectedCombinationForEyeOpen) {
        if (
          selectedCombinationForEyeOpen?.type === selectedCombinationForHeatMap?.type &&
          selectedCombinationForHeatMap?.date === selectedCombinationForEyeOpen?.date
        ) {
          switchToolTip(1);
          heatMapSoloMeasurement();
        } else {
          setSelectedCombinationForEyeOpen(undefined);
          setSelectedTrialInfoForEyeOpen(undefined);
          heatMapSoloMeasurement();
        }
      } else {
        heatMapSoloMeasurement();
        switchToolTip(0);
      }
      applyHeatMap(selectedCombinationForHeatMap, selectedOption);
    } else {
      window.isHeatMapActive = false;
      unsoloMeasurement();
      removeHeatMap(selectedOption);
      switchToolTip(1);
      const temp = [];
      assessmentDateType.map((i, index) => {
        temp.push({ letter: orderArray[index], vector: i.type, date: i.date });
      });
      if (selectedCombinationForEyeOpen) {
        soloMeasurement();
        switchToolTip(1);
      }
      if (!selectedCombinationForEyeOpen) {
        updateMeasurements({
          vectors: [...temp, ...selectedVectorsOnlyPlots],
          trial: selectedOption,
          unsolo: true
        });
      }
    }
  }, [selectedCombinationForHeatMap]);

  useEffect(() => {
    if (selectedCombinationForEyeOpen || selectedTrialInfoForEyeOpen) {
      window.eyeOpenClosed = true;
      if (selectedCombinationForHeatMap) {
        if (
          selectedCombinationForEyeOpen?.type === selectedCombinationForHeatMap?.type &&
          selectedCombinationForHeatMap?.date === selectedCombinationForEyeOpen?.date
        ) {
          heatMapSoloMeasurement();
        } else {
          removeHeatMap(selectedOption);
          setSelectedCombinationForHeatMap(undefined);
        }
      } else {
        soloMeasurement();
      }
      switchToolTip(1);
    } else {
      window.eyeOpenClosed = false;
      unsoloMeasurement();
      switchToolTip(1);
      const temp = [];
      assessmentDateType.map((i, index) => {
        temp.push({ letter: orderArray[index], vector: i.type, date: i.date });
      });
      if (selectedCombinationForHeatMap) {
        heatMapSoloMeasurement();
        switchToolTip(0);
      }
      if (!selectedCombinationForHeatMap) {
        updateMeasurements({
          vectors: [...temp, ...selectedVectorsOnlyPlots],
          trial: selectedOption,
          unsolo: true
        });
      }
    }
  }, [
    selectedCombinationForEyeOpen,
    selectedTrialInfoForEyeOpen,
    mapLoading,
    measurementSetLoaded
  ]);

  const switchMeasurementTool = (isTrue) => {
    setMeasurementToolVisible(isTrue);
    if (isTrue) {
      measureTool.addTo(mapLayout);
    } else {
      measureTool.remove();
    }
  };
  const findRanges = (vectorVal, selectedTrail) => {
    const measArray = [];
    if (trialType === "manual") {
      drawnItemsConst &&
        drawnItemsConst?.eachLayer((layer) => {
          let filteredData = plotDetailsConst.filter((item) => {
            return item.leafletId.toString() == layer._leaflet_id.toString();
          });
          if (filteredData.length > 0) {
            const selectedPlotData = latestMeasurementSets?.current?.find((plot) => {
              return (
                plot.feature.properties.col == filteredData[0]?.Col &&
                plot.feature.properties.row == filteredData[0]?.Row
              );
            });
            const splitDate = vectorVal.date.split(/-(?=[^-]+$)/);
            const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : vectorVal.date;
            const selectedDateData = selectedPlotData?.means?.filter(
              ({ assessmentdate }) => dateForIndex === assessmentdate
            );
            const temp = selectedDateData?.reduce((acc, { means }) => {
              return { ...acc, ...means };
            }, {});
            let val = temp?.[vectorVal.type];
            if (val !== undefined && val !== null && val !== "nan" && val !== "n/a") {
              measArray.push(val);
            }
          }
          // });
        });
    } else {
      tooltipsConst &&
        Object.keys(tooltipsConst).forEach((trialName) => {
          if (trialName === selectedTrail) {
            Object.keys(tooltipsConst[trialName]).forEach((plotId) => {
              const selectedPlotData = latestMeasurementSets?.current?.find((plot) => {
                return plot.feature.properties.num == plotId;
              });
              const splitDate = vectorVal.date.split(/-(?=[^-]+$)/);
              const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : vectorVal.date;
              const selectedDateData = selectedPlotData?.means?.filter(
                ({ assessmentdate }) => dateForIndex === assessmentdate
              );
              const temp = selectedDateData?.reduce((acc, { means }) => {
                return { ...acc, ...means };
              }, {});
              let val = temp?.[vectorVal.type];
              if (val !== undefined && val !== null && val !== "nan" && val !== "n/a") {
                measArray.push(val);
              }
            });
          }
          // });
        });
    }
    return measArray;
  };
  const removeHeatMap = (selectedTrail) => {
    //remove the color
    if (trialType === "manual") {
      drawnItemsConst &&
        drawnItemsConst?.eachLayer((layer) => {
          layer.setStyle({
            fillColor: "transparent",
            fillOpacity: 1
          });
        });
    } else {
      Object.keys(vectorLayersLayout).forEach((trialName) => {
        Object.keys(vectorLayersLayout[trialName]).forEach((plotFeature) => {
          if (trialName !== selectedTrail && selectedTrail !== "All trials") {
            vectorLayersLayout[trialName][plotFeature].removeFrom(mapLayout);
          } else {
            vectorLayersLayout[trialName][plotFeature].setStyle({
              fillColor: "transparent",
              fillOpacity: 1
            });
            vectorLayersLayout[trialName][plotFeature].addTo(mapLayout);
          }
        });
      });
    }
  };
  const applyHeatMap = (measurementText, selectedTrail) => {
    const colorShades = [
      "#24050F",
      "#480A1D",
      "#7E1132",
      "#B41847",
      "#E2285F",
      "#E74B7A",
      "#EE81A2",
      "#F3A5BC",
      "#F8C9D7",
      "#FDEDF2"
    ].reverse();

    let measArray = [];
    measArray = findRanges(measurementText, selectedTrail);

    const measArrayAsc = [...measArray].sort((a, b) => a - b);

    const legendRange = measArrayAsc[measArrayAsc.length - 1] - measArrayAsc[0];
    const segmentSize = legendRange / 5;
    const ranges = [];
    let currentMin = measArrayAsc[0];
    for (let i = 0; i < 5; i++) {
      if (i === 4) {
        ranges.push(measArrayAsc[measArrayAsc.length - 1]);
      } else {
        ranges.push(currentMin);
        currentMin = currentMin + segmentSize;
      }
    }
    const res = [];
    let prevColorShade = "";
    let currentColorShade = "";
    const colorsForHeatMap = [];
    // Calculate the range and interval size
    const min = Math.min(...measArrayAsc);
    const max = Math.max(...measArrayAsc);
    const intervalSize = (max - min) / 10;

    // Initialize an array of empty chunks
    const chunks = Array.from({ length: 10 }, () => []);

    // Assign each measurement to a chunk
    measArrayAsc.forEach((measurement) => {
      let chunkIndex = Math.floor((measurement - min) / intervalSize) || 0;
      chunkIndex = chunkIndex >= 10 ? 9 : chunkIndex; // Ensure chunkIndex does not exceed the length of the chunks array
      chunks[chunkIndex].push(measurement);
    });
    let valuesFromEachChunk = [];
    // Process each chunk
    chunks.forEach((chunk, j) => {
      valuesFromEachChunk = [...valuesFromEachChunk, ...chunk];
      let colorShade = 0;
      chunk.forEach((element) => {
        currentColorShade = LightenDarkenColor(colorShades[j], colorShade * -1);
        const colorToApply =
          currentColorShade.toString().toLocaleLowerCase() === "#ffffff"
            ? prevColorShade
            : currentColorShade;
        res.push({
          value: element,
          color: colorToApply
        });
        prevColorShade = colorToApply;
        colorShade = colorShade + 1;
      });
    });
    if (valuesFromEachChunk.length !== 0) {
      let sortedValues = Array.from(new Set(valuesFromEachChunk)).sort((a, b) => a - b);
      let elementsPerRange = Math.floor(sortedValues.length / 4);
      let range = Array.from({ length: 4 }, (_, i) => sortedValues[i * elementsPerRange]).concat([
        Math.max(...sortedValues)
      ]);
      setHeatMapLegendRange(range);
    } else {
      setHeatMapLegendRange([]);
    }

    if (trialType === "manual") {
      drawnItemsConst.eachLayer((layer) => {
        let filteredData = plotDetailsConst.filter((item) => {
          return item.leafletId.toString() == layer._leaflet_id.toString();
        });
        if (filteredData.length > 0) {
          const selectedPlotData = latestMeasurementSets?.current?.find((plot) => {
            return (
              plot.feature.properties.col == filteredData[0]?.Col &&
              plot.feature.properties.row == filteredData[0]?.Row
            );
          });
          const splitDate = measurementText.date.split(/-(?=[^-]+$)/);
          const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : measurementText.date;
          const selectedDateData = selectedPlotData?.means?.filter(
            ({ assessmentdate }) => dateForIndex === assessmentdate
          );
          const temp = selectedDateData?.reduce((acc, { means }) => {
            return { ...acc, ...means };
          }, {});
          let itemValue = temp?.[measurementText.type];
          if (
            itemValue !== undefined &&
            itemValue !== null &&
            itemValue !== "nan" &&
            itemValue !== "n/a"
          ) {
            let resItem = res.filter((item) => {
              return item.value == itemValue;
            });
            if (!colorsForHeatMap.find(({ value }) => value === resItem[0]?.value)) {
              colorsForHeatMap.push(resItem[0]);
            }
            layer.setStyle({
              fillColor: resItem[0].color,
              fillOpacity: 1
            });
          } else {
            layer.setStyle({
              fillColor: "transparent",
              fillOpacity: 1
            });
          }
          // if (trialName !== selectedTrail && selectedTrail !== "All trials")
          //   vectorLayersLayout[trialName][plotId].removeFrom(mapLayout);
          // else vectorLayersLayout[trialName][plotId].addTo(mapLayout);
        }
      });
    } else {
      tooltipsConst &&
        Object.keys(tooltipsConst).forEach((trialName) => {
          Object.keys(tooltipsConst[trialName]).forEach((plotId) => {
            if (mapLayout.hasLayer(vectorLayersLayout[trialName][plotId])) {
              const selectedPlotData = latestMeasurementSets?.current?.find((plot) => {
                return plot.feature.properties.num == plotId;
              });
              const splitDate = measurementText.date.split(/-(?=[^-]+$)/);
              const dateForIndex = splitDate?.[1]?.includes(":")
                ? splitDate[0]
                : measurementText.date;
              const selectedDateData = selectedPlotData?.means?.filter(
                ({ assessmentdate }) => dateForIndex === assessmentdate
              );
              const temp = selectedDateData?.reduce((acc, { means }) => {
                return { ...acc, ...means };
              }, {});
              let itemValue = temp?.[measurementText.type];
              if (
                itemValue !== undefined &&
                itemValue !== null &&
                itemValue !== "nan" &&
                itemValue !== "n/a"
              ) {
                let resItem = res.filter((item) => {
                  return item.value == itemValue;
                });
                if (!colorsForHeatMap.find(({ value }) => value === resItem[0]?.value)) {
                  colorsForHeatMap.push(resItem[0]);
                }
                vectorLayersLayout[trialName][plotId].setStyle({
                  fillColor: resItem[0].color,
                  fillOpacity: 1
                });
              } else {
                vectorLayersLayout[trialName][plotId].setStyle({
                  fillColor: "transparent",
                  fillOpacity: 1
                });
              }
              if (trialName !== selectedTrail && selectedTrail !== "All trials")
                vectorLayersLayout[trialName][plotId].removeFrom(mapLayout);
              else vectorLayersLayout[trialName][plotId].addTo(mapLayout);
            }
          });
        });
    }
    colorsForHeatMap.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    setHeatMapLegendColors(colorsForHeatMap);
  };

  useEffect(() => {
    const savedRecentTrialData = {
      trial_name:
        selectedOption !== "All trials" && selectedOption !== "No trials" ? selectedOption : "",
      created_by: cookies?.userEmail
    };
    if (selectedOption !== "All trials" && selectedOption !== "No trials") {
      checkTrialType(
        { trial_name: selectedOption },
        (res) => {
          if (res?.has_drone_assessments === false) {
            mapLayout = new Map(null);
            setLoading(false);
            setTimeout(() => {
              initMap(true);
              getPlotInfo(
                selectedOption,
                (data) => {
                  plotDetailsFromAPIConst = data;
                  layoutDimensionsConst = {
                    height: data?.geometry?.plotLength,
                    width: data?.geometry?.plotWidth,
                    noOfColumns: data?.structure?.columnsNumber,
                    noOfRows: data?.structure?.blocksNumber
                  };
                  bindCallbacks();
                  generateManualPlots();
                },
                (err) => {
                  console.log("err", err);
                }
              );
            }, 1500);
            setActiverVectorLayers(["Plot #", "Treatment #"]);
          }
          setTrialType(res?.has_drone_assessments === false ? "manual" : "drone");
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      setTrialType("drone");
    }
    if (selectedOption !== "All trials" && selectedOption !== "No trials") {
      saveRecentTrialsAPI(
        savedRecentTrialData,
        () => {},
        () => {}
      );

      getDevices(
        selectedOption,
        (res) => {
          res?.devices?.forEach((item) => {
            const soilDeviceIds = res?.devices
              .filter((device) => device.deviceModel.name === "valenco_mm_soil")
              .map((device) => device?.name + "-" + device?.deviceId);
            setSoilDeviceId(soilDeviceIds);
            const weatherDeviceIds = res?.devices
              .filter((device) => device.deviceModel.name === "weather")
              .map((device) => device?.name + "-" + device?.deviceId);

            setWeatherDevices(weatherDeviceIds);

            if (item?.deviceId) {
              setDeviceId("Davis-" + item?.deviceId);
            }
            setDeviceTrial(item?.name);
            setGeometry(item?.geo?.features[0]?.geometry);
          });
          setDeviceData(deviceData);
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
    getAssessmentCategories(
      selectedOption,
      (res) => {
        res?.sort((a, b) => {
          if (a.assessmentdate < b.assessmentdate) {
            return -1;
          }
          if (a.assessmentdate > b.assessmentdate) {
            return 1;
          }
          return 0;
        });
        setAssesmentRes(res);
        const forType = res?.reduce((acc, data) => {
          data.categories &&
            Object.keys(data.categories).forEach((key) => {
              if (!(key in acc)) {
                acc[key] = {};
              }
              data.categories[key].map((type) => {
                const typeCheck = data?.assessmentType !== "manual" ? type : type?.fullName;
                if (!acc[key][typeCheck]) {
                  acc[key][typeCheck] = [];
                }
                const index = acc[key][typeCheck]?.findIndex(
                  ({ date }) => date === data.assessmentdate
                );
                if (index !== -1) {
                  acc[key][typeCheck][index] = {
                    ...acc[key][typeCheck][index],
                    isDrone: acc[key][typeCheck].isDrone || data.assessmentType === "drone",
                    isManual: acc[key][typeCheck].isManual || data.assessmentType === "manual"
                  };
                } else {
                  acc[key][typeCheck].push({
                    date: data.assessmentdate,
                    isDrone: data.assessmentType === "drone",
                    isManual: data.assessmentType === "manual",
                    assessmentID: data.assessmentID
                  });
                }
              });
            });
          return acc;
        }, {});
        setAssessmentsFilteredByType(
          Object.keys(forType)
            .sort((a, b) => a.localeCompare(b))
            .reduce((obj, key) => {
              obj[key] = forType[key];
              return obj;
            }, {})
        );
        const tempRes = res.reduce((acc, data) => {
          // const splitDate = data.assessmentdate.split(/-(?=[^-]+$)/);
          const dateForIndex = data.assessmentdate;
          const index = acc?.findIndex(({ dateForIndex: test }) => test === data.assessmentdate);
          if (index !== -1) {
            let group = {};
            Object.keys(data.categories).map((item) => {
              group[item] = data.assessmentType;
              return null;
            });
            acc[index] = {
              ...data,
              isDrone: acc[index].isDrone || data.assessmentType === "drone",
              isManual: acc[index].isManual || data.assessmentType === "manual",
              categories: { ...acc[index].categories, ...data.categories },
              group: { ...acc[index]?.group, ...group },
              dateForIndex
            };
          } else {
            let group = {};
            Object.keys(data.categories).map((item) => {
              group[item] = data.assessmentType;
              return null;
            });
            acc.push({
              ...data,
              isDrone: data.assessmentType === "drone",
              isManual: data.assessmentType === "manual",
              group,
              dateForIndex,
              assessemetID: data.assessmentID
            });
          }
          return acc;
        }, []);

        setAssessmentDatesAndTypes(tempRes);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [selectedOption, cookies?.userEmail]);

  useEffect(() => {
    window.assessmentsCount = assessmentDateType.length;
    if (assessmentDateType) {
      const temp = [];
      assessmentDateType.map((i, index) => {
        temp.push({ letter: orderArray[index], vector: i.type, date: i.date });
      });
      if (selectedCombinationForHeatMap) {
        heatMapSoloMeasurement();
        switchToolTip(0);
      } else {
        unsoloMeasurement();
        switchToolTip(1);
      }
      if (selectedCombinationForEyeOpen) {
        soloMeasurement();
        switchToolTip(1);
      } else {
        if (!selectedCombinationForHeatMap) {
          unsoloMeasurement();
          switchToolTip(1);
        }
      }
      updateMeasurements({
        vectors: [...temp, ...selectedVectorsOnlyPlots],
        trial: selectedOption,
        unsolo: true
      });
    }
    if (assessmentDateType.length === 0) {
      // setSelectedBoundingBoxes(undefined);
      removeHeatMap(selectedOption);
      setSelectedCombinationForEyeOpen(undefined);
      setSelectedCombinationForHeatMap(undefined);
      setShowTreatment(true);
      setAssessmentOptionsVisible(true);
      setShowTime(false);
      setShowCompare(false);
      setShowTreatmentMT(false);
      setShowTimeMT(false);
      setShowCompareMT(false);
    }
  }, [assessmentDateType]);

  useEffect(() => {
    if (mapLayout) {
      if (typeof mapLayout.invalidateSize === "function") {
        mapLayout.invalidateSize();
      }
    }
  }, [assessmentDateType, expandIcon]);

  const updateMeasurements = (measurements) => {
    let selectedMeasurements = new Set();

    tooltipsConst &&
      Object.keys(tooltipsConst).forEach((trialName) => {
        Object.keys(tooltipsConst[trialName]).forEach((measurementId) => {
          const tooltip = tooltipsConst[trialName][measurementId];
          measurements.vectors.forEach((measurement) => {
            selectedMeasurements.add(measurement);
            updateToolTipMeasurements(tooltip, measurement);
          });
        });
      });
    latestSelectedMeasurements.current = selectedMeasurements;
    updateTooltipViewOnZoom();
  };
  const updateToolTipMeasurements = (tooltip, meas) => {
    if (tooltip.measurements[meas.vector] !== undefined) {
      if (meas.active) {
        tooltip.content.add(meas.vector);
      } else {
        tooltip.content.delete(meas.vector);
      }
    }
  };
  const updateTooltipViewOnZoom = () => {
    if (trialType === "manual") {
      const chosenTooltipBounds = [];
      let exOffsetHeight, exOffsetWidth;
      drawnItemsConst?.eachLayer((layer) => {
        const feature = layer;
        if (feature.getTooltip() !== undefined && feature.getTooltip() !== null) {
          const tooltip = layer.getTooltip();
          const tooltipHTMLelement = feature.getTooltip()._container;
          exOffsetWidth = tooltipHTMLelement.offsetWidth;
          exOffsetHeight = tooltipHTMLelement.offsetHeight;
          // this culls out-of-view tooltips
          // now we get center of feature as latlng, get the tooltip size in pixels (approx?),
          // project tooltip corners to latlng, make polygon, check intersection with each (?), only display those that are non intersecting
          const values = tooltipHTMLelement.style.transform.split(/\w+\(|\);?/);
          const transform = values[1].split(/,\s?/g).map((value) => {
            return parseInt(value.slice(0, -2));
          });
          const padding = 5;

          // tooltip position in pixels (top-left corner)
          const tooltipTopLeft = {
            x: transform[0] - padding,
            y: transform[1] - padding
          };

          const tooltipBottomRight = {
            x: tooltipTopLeft.x + exOffsetWidth + padding,
            y: tooltipTopLeft.y + exOffsetHeight + padding
          };

          const tooltipBBox = {
            t: tooltipTopLeft.y,
            l: tooltipTopLeft.x,
            b: tooltipBottomRight.y,
            r: tooltipBottomRight.x
          };

          if (tooltipHTMLelement.classList.contains("soloed")) {
            if (feature.getBounds().intersects(mapLayout.getBounds())) {
              tooltipHTMLelement.style.visibility = "visible";
            } else {
              tooltipHTMLelement.style.visibility = "hidden";
            }
          } else {
            let nonintersects = true;
            for (let i = 0; i < chosenTooltipBounds.length; i++) {
              if (bbox_intersects(tooltipBBox, chosenTooltipBounds[i])) {
                nonintersects = false;
                tooltipHTMLelement.style.visibility = "hidden";
                tooltipHTMLelement.classList.remove("should-show");
                tooltipHTMLelement.classList.add("dont-show");
                break;
              }
            }

            if (nonintersects) {
              if (feature.getBounds().intersects(mapLayout.getBounds())) {
                tooltip.setContent(tooltip._content);
                tooltipHTMLelement.style.visibility = "visible";
              } else {
                tooltipHTMLelement.style.visibility = "hidden";
              }

              tooltipHTMLelement.classList.remove("dont-show");
              tooltipHTMLelement.classList.add("should-show");
              tooltipHTMLelement.style.zIndex = "100";
              chosenTooltipBounds.push(tooltipBBox);
            }
          }
        }
      });
    } else {
      const chosenTooltipBounds = [];
      Object.keys(vectorLayersLayout).forEach((trialName) => {
        let firstFeatureKey = Object.keys(vectorLayersLayout[trialName])[0];
        let exampleTooltip = tooltipsConst[trialName]?.[firstFeatureKey];
        let exOffsetHeight, exOffsetWidth;
        if (
          exampleTooltip != undefined &&
          mapLayout.hasLayer(vectorLayersLayout[trialName][firstFeatureKey])
        ) {
          let tooltipElement = exampleTooltip.tooltip._container;
          if (!tooltipElement?.classList.contains("soloed")) {
            exampleTooltip.tooltip.setContent(tooltipToString(exampleTooltip));
          }
          exOffsetWidth = tooltipElement?.offsetWidth;
          exOffsetHeight = tooltipElement?.offsetHeight;
        }

        Object.keys(vectorLayersLayout[trialName]).forEach((plotFeature) => {
          const feature = vectorLayersLayout[trialName][plotFeature];
          const tooltip = tooltipsConst?.[trialName]?.[plotFeature];
          const tooltipHTMLelement = feature.getTooltip()._container;

          // this culls out-of-view tooltips
          if (mapLayout.hasLayer(vectorLayersLayout[trialName][plotFeature])) {
            // now we get center of feature as latlng, get the tooltip size in pixels (approx?),
            // project tooltip corners to latlng, make polygon, check intersection with each (?), only display those that are non intersecting
            const values = tooltipHTMLelement.style.transform.split(/\w+\(|\);?/);
            const transform = values[1].split(/,\s?/g).map((value) => {
              return parseInt(value.slice(0, -2));
            });
            if (latestSelectedMeasurements?.current.size === 0) {
              tooltipHTMLelement.style.visibility = "hidden";
              return;
            }

            const padding = 5;

            // tooltip position in pixels (top-left corner)
            const tooltipTopLeft = {
              x: transform[0] - padding,
              y: transform[1] - padding
            };

            const tooltipBottomRight = {
              x: tooltipTopLeft.x + exOffsetWidth + padding,
              y: tooltipTopLeft.y + exOffsetHeight + padding
            };

            const tooltipBBox = {
              t: tooltipTopLeft.y,
              l: tooltipTopLeft.x,
              b: tooltipBottomRight.y,
              r: tooltipBottomRight.x
            };

            if (tooltipHTMLelement.classList.contains("soloed")) {
              if (feature.getBounds().intersects(mapLayout.getBounds())) {
                tooltipHTMLelement.style.visibility = "visible";
              } else {
                tooltipHTMLelement.style.visibility = "hidden";
              }
            } else {
              let nonintersects = true;

              for (let i = 0; i < chosenTooltipBounds.length; i++) {
                if (bbox_intersects(tooltipBBox, chosenTooltipBounds[i])) {
                  nonintersects = false;
                  tooltipHTMLelement.style.visibility = "hidden";
                  tooltipHTMLelement.classList.remove("should-show");
                  tooltipHTMLelement.classList.add("dont-show");
                  break;
                }
              }

              if (nonintersects) {
                if (feature.getBounds().intersects(mapLayout.getBounds())) {
                  tooltip.tooltip.setContent(tooltipToString(tooltip));
                  tooltipHTMLelement.style.visibility = "visible";
                } else {
                  tooltipHTMLelement.style.visibility = "hidden";
                }

                tooltipHTMLelement.classList.remove("dont-show");
                tooltipHTMLelement.classList.add("should-show");
                tooltipHTMLelement.style.zIndex = "100";
                chosenTooltipBounds.push(tooltipBBox);
              }
            }
          }
        });
      });
    }
  };
  const bbox_intersects = (b1, b2) => {
    let has_l = b2.l <= b1.r && b2.l >= b1.l;
    let has_r = b2.r <= b1.r && b2.r >= b1.l;
    let has_t = b2.t >= b1.t && b2.t <= b1.b;
    let has_b = b2.b >= b1.t && b2.b <= b1.b;

    return (has_l || has_r) && (has_t || has_b);
  };
  const updateTooltipViewOnPan = () => {
    if (trialType === "manual") {
      drawnItemsConst?.eachLayer((layer) => {
        const feature = layer;
        if (feature.getTooltip() !== undefined && feature.getTooltip() !== null) {
          const tooltipHTMLelement = feature.getTooltip()._container;
          const tooltip = layer.getTooltip();

          if (mapLayout.hasLayer(feature)) {
            if (
              feature.getBounds().intersects(mapLayout.getBounds()) &&
              (tooltipHTMLelement.classList.contains("should-show") ||
                tooltipHTMLelement.classList.contains("soloed"))
            ) {
              if (!tooltipHTMLelement.classList.contains("soloed")) {
                tooltip.setContent(tooltip._content);
              }
              tooltipHTMLelement.style.visibility = "visible";
            } else {
              tooltipHTMLelement.style.visibility = "hidden";
            }
          }
        }
      });
    } else {
      Object.keys(vectorLayersLayout).forEach((trialName) => {
        Object.keys(vectorLayersLayout[trialName]).forEach((plotFeature) => {
          const feature = vectorLayersLayout[trialName][plotFeature];
          const tooltipHTMLelement = feature.getTooltip()._container;
          const tooltip = tooltipsConst[trialName]?.[plotFeature];
          if (mapLayout.hasLayer(feature)) {
            if (
              feature.getBounds().intersects(mapLayout.getBounds()) &&
              latestSelectedMeasurements?.current.size !== 0 &&
              (tooltipHTMLelement.classList.contains("should-show") ||
                tooltipHTMLelement.classList.contains("soloed"))
            ) {
              if (!tooltipHTMLelement.classList.contains("soloed")) {
                tooltip.tooltip.setContent(tooltipToString(tooltip));
              }
              tooltipHTMLelement.style.visibility = "visible";
            } else {
              tooltipHTMLelement.style.visibility = "hidden";
            }
          }
        });
        // }
      });
    }
  };
  const generateTooltips = (content) => {
    const trialMeasurements = extractVectorLayerMeasurements();
    let tooltips = {};

    function contentFromOldContent(c, m) {
      let finalSet = new Set();

      if (!(c && m)) {
        return finalSet;
      }

      const possibleMeasurements = Object.keys(m);

      c.forEach((measurement) => {
        if (possibleMeasurements.includes(measurement)) {
          finalSet.add(measurement);
        }
      });

      return finalSet;
    }

    function addPlotTreatNumber(meas, plotNum, treatNum) {
      if (meas) {
        meas["Plot #"] = plotNum;
        meas["Treatment #"] = treatNum;
        return meas;
      } else {
        return {
          "Plot #": plotNum,
          "Treatment #": treatNum
        };
      }
    }
    var CustomTooltip = L.Tooltip.extend({
      _setPosition: function (pos) {
        var map = this._map,
          container = this._container,
          tooltipPoint = map.layerPointToContainerPoint(pos),
          centerPoint = map.latLngToContainerPoint(map.getCenter()),
          direction = this.options.direction,
          tooltipWidth = null,
          tooltipHeight = null,
          offset = L.point(this.options.offset),
          anchor = this._getAnchor();
        if (direction === "fast") {
          const totalCounts = window.assessmentsCount + (window.plotTreatmentCount > 0 ? 1 : 0);
          tooltipWidth = 44;
          if (window.eyeOpenClosed || window.isHeatMapActive) {
            tooltipHeight = 32;
          } else {
            if (totalCounts == 1 || totalCounts == 0) {
              tooltipHeight = 32;
            } else {
              tooltipHeight = totalCounts * 22;
            }
          }
          direction = "right";
        } else {
          tooltipWidth = container.offsetWidth;
          tooltipHeight = container.offsetHeight;
        }

        if (direction === "top") {
          pos = pos.add(
            L.point(-tooltipWidth / 2 + offset.x, -tooltipHeight + offset.y + anchor.y, true)
          );
        } else if (direction === "bottom") {
          pos = pos.subtract(L.point(tooltipWidth / 2 - offset.x, -offset.y, true));
        } else if (direction === "center") {
          pos = pos.subtract(
            L.point(tooltipWidth / 2 + offset.x, tooltipHeight / 2 - anchor.y + offset.y, true)
          );
        } else if (
          direction === "right" ||
          (direction === "auto" && tooltipPoint.x < centerPoint.x)
        ) {
          direction = "right";
          pos = pos.add(
            L.point(offset.x + anchor.x, anchor.y - tooltipHeight / 2 + offset.y, true)
          );
        } else {
          direction = "left";
          pos = pos.subtract(
            L.point(
              tooltipWidth + anchor.x - offset.x,
              tooltipHeight / 2 - anchor.y - offset.y,
              true
            )
          );
        }

        L.DomUtil.removeClass(container, "leaflet-tooltip-right");
        L.DomUtil.removeClass(container, "leaflet-tooltip-left");
        L.DomUtil.removeClass(container, "leaflet-tooltip-top");
        L.DomUtil.removeClass(container, "leaflet-tooltip-bottom");
        L.DomUtil.addClass(container, "leaflet-tooltip-" + direction);
        L.DomUtil.setPosition(container, pos);
      }
    });
    Object.keys(trialMeasurements)?.forEach((trialName) => {
      tooltips[trialName] = trialMeasurements[trialName].measurements.reduce(
        (finalObject, measurement) => {
          let properties = measurement.feature.properties;
          finalObject[properties.num] = {
            tooltip: new CustomTooltip({
              permanent: true,
              direction: "fast",
              className: "text",
              opacity: 1
            }),
            plotNumber: properties.num,
            trialName: properties.trial_name,
            treatment: properties.treatment,
            measurements: addPlotTreatNumber(
              measurement.meas,
              properties.num,
              properties.treatment
            ),
            content: contentFromOldContent(content, measurement.meas)
          };

          let tooltip = finalObject[properties.num];
          finalObject[properties.num].tooltip.setContent(tooltipToString(tooltip));

          vectorLayersLayout[trialName][properties.num].bindTooltip(
            finalObject[properties.num].tooltip
          );

          return finalObject;
        },
        {}
      );
    });
    tooltipsConst = tooltips;
  };
  const switchToolTip = (opacity) => {
    if (trialType === "manual") {
      drawnItemsConst?.eachLayer((layer) => {
        const feature = layer;
        if (opacity === 0) {
          feature.on("mouseover", function () {
            toolTip?.setOpacity(1);
          });
          feature.on("mouseout", function () {
            toolTip?.setOpacity(0);
          });
        } else {
          feature.on("mouseover", function () {
            toolTip?.setOpacity(1);
          });
          feature.on("mouseout", function () {
            toolTip?.setOpacity(1);
          });
        }
        let toolTip = feature.getTooltip();
        toolTip?.setOpacity(opacity);
      });
    } else {
      Object.keys(vectorLayersLayout).forEach((trialName) => {
        Object.keys(vectorLayersLayout[trialName]).forEach((plotFeature) => {
          const feature = vectorLayersLayout[trialName][plotFeature];
          if (opacity === 0) {
            feature.on("mouseover", function () {
              toolTip.setOpacity(1);
            });
            feature.on("mouseout", function () {
              toolTip.setOpacity(0);
            });
          } else {
            feature.on("mouseover", function () {
              toolTip.setOpacity(1);
            });
            feature.on("mouseout", function () {
              toolTip.setOpacity(1);
            });
          }
          let toolTip = feature.getTooltip();
          toolTip.setOpacity(opacity);
        });
      });
    }
  };
  const soloMeasurement = () => {
    generateManualSoloTooltip();
    tooltipsConst &&
      Object.keys(tooltipsConst).forEach((trialName) => {
        Object.keys(tooltipsConst[trialName]).forEach((measurementId) => {
          if (mapLayout.hasLayer(vectorLayersLayout[trialName][measurementId])) {
            const tooltip = tooltipsConst[trialName][measurementId];
            tooltip.tooltip._container.classList.add("soloed");
            tooltip.tooltip.setContent(tooltipToSoloString(tooltip, selectedCombinationForEyeOpen));
          }
        });
      });
  };
  const tooltipToSoloString = (tooltip, measurement) => {
    return () => {
      function notApplicableIfNotExist(correspondingData, measurementToGet, assessmentDate) {
        const selectedPlotData = latestMeasurementSets?.current.find(
          (plot) => plot.feature.properties.num === correspondingData?.plotNumber
        );
        const splitDate = assessmentDate?.split(/-(?=[^-]+$)/);
        const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : assessmentDate;
        const selectedDateData = selectedPlotData?.means?.filter(
          ({ assessmentdate }) => dateForIndex === assessmentdate
        );
        const temp = selectedDateData?.reduce((acc, { means }) => {
          return { ...acc, ...means };
        }, {});
        let val = temp?.[measurementToGet];
        if (val === undefined || (typeof val !== "string" && isNaN(val)) || val === "nan") {
          return isImgAvailableLatest.current
            ? "<span class='tooltip-shadow'>n/a</span>"
            : "<span>n/a</span>";
        }
        if (isImgAvailableLatest.current) {
          return `<span class='tooltip-shadow'>${toFixedIfNecessary(val, 3)}</span>`;
        } else {
          return `<span>${toFixedIfNecessary(val, 3)}</span>`;
        }
      }
      let finalString = "<table>";
      let hasPlot = false;
      let hasTreat = false;

      if (selectedTrialInfoForEyeOpen === "Plot #") {
        hasPlot = true;
        hasTreat = false;
      }
      if (selectedTrialInfoForEyeOpen === "Treatment #") {
        hasTreat = true;
        hasPlot = false;
      }

      if (hasPlot || hasTreat) {
        finalString +=
          "<tr>" + `<span style="color:${colorMapConst[tooltip.trialName]};">&#9632;</span>`;
        if (hasPlot) {
          finalString +=
            (isImgAvailableLatest.current ? "<span class='tooltip-shadow'>" : "<span>") +
            tooltip.plotNumber +
            "</span>";
        }
        if (hasTreat) {
          if (tooltip.treatment === undefined) {
            finalString += " []";
          } else {
            finalString +=
              (isImgAvailableLatest.current ? "<span class='tooltip-shadow'>" : "<span>") +
              ` [${tooltip.treatment}]` +
              "</span>";
          }
        }
        return (finalString += "</tr>" + "</table>");
      } else {
        finalString +=
          "<tr><td>" +
          (isImgAvailableLatest.current ? "<span class='tooltip-shadow'><b>" : "<span><b>") +
          notApplicableIfNotExist(tooltip, measurement.type, measurement.date) +
          "</b></span></td></tr>";

        return finalString + "</table>";
      }
    };
  };
  const heatMapTooltipToSoloString = (tooltip, measurement) => {
    return () => {
      function notApplicableIfNotExist(correspondingData, measurementToGet, assessmentDate) {
        const selectedPlotData = latestMeasurementSets?.current.find(
          (plot) => plot.feature.properties.num === correspondingData?.plotNumber
        );
        const splitDate = assessmentDate.split(/-(?=[^-]+$)/);
        const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : assessmentDate;
        const selectedDateData = selectedPlotData?.means?.filter(
          ({ assessmentdate }) => dateForIndex === assessmentdate
        );
        const temp = selectedDateData?.reduce((acc, { means }) => {
          return { ...acc, ...means };
        }, {});
        let val = temp?.[measurementToGet];
        if (val === undefined || (typeof val !== "string" && isNaN(val)) || val === "nan") {
          return isImgAvailableLatest.current
            ? "<span class='tooltip-shadow'>n/a</span>"
            : "<span>n/a</span>";
        }
        if (isImgAvailableLatest.current) {
          return `<span class='tooltip-shadow'>${toFixedIfNecessary(val, 3)}</span>`;
        } else {
          return `<span>${toFixedIfNecessary(val, 3)}</span>`;
        }
      }

      let finalString = "<table>";
      let hasPlot = false;
      let hasTreat = false;

      for (const item of latestSelectedMeasurements.current.values()) {
        if (item.vector === "Plot #") {
          hasPlot = true;
        }
        if (item.vector === "Treatment #") {
          hasTreat = true;
        }
      }

      if (hasPlot || hasTreat) {
        finalString +=
          "<tr>" + `<span style="color:${colorMapConst[tooltip.trialName]};">&#9632;</span>`;
        if (hasPlot) {
          finalString +=
            (isImgAvailableLatest.current ? "<span class='tooltip-shadow'>" : "<span>") +
            tooltip.plotNumber +
            "</span>";
        }
        if (hasTreat) {
          if (tooltip.treatment === undefined) {
            finalString += " []";
          } else {
            finalString +=
              (isImgAvailableLatest.current ? "<span class='tooltip-shadow'>" : "<span>") +
              ` [${tooltip.treatment}]` +
              "</span>";
          }
        }
        finalString += "</tr>";
      }
      finalString +=
        "<tr><td>" +
        (isImgAvailableLatest.current ? "<span class='tooltip-shadow'><b>" : "<span><b>") +
        notApplicableIfNotExist(tooltip, measurement.type, measurement.date) +
        "</b></span></td></tr>";

      return finalString + "</table>";
    };
  };
  const heatMapSoloMeasurement = () => {
    generateManualTooltipHeatMap();
    tooltipsConst &&
      Object.keys(tooltipsConst).forEach((trialName) => {
        Object.keys(tooltipsConst[trialName]).forEach((measurementId) => {
          if (mapLayout.hasLayer(vectorLayersLayout[trialName][measurementId])) {
            const tooltip = tooltipsConst[trialName][measurementId];
            tooltip.tooltip._container.classList.add("soloed");
            tooltip.tooltip.setContent(
              heatMapTooltipToSoloString(tooltip, selectedCombinationForHeatMap)
            );
          }
        });
      });
  };
  const unsoloMeasurement = () => {
    generateManualTooltip();
    tooltipsConst &&
      Object.keys(tooltipsConst).forEach((trialName) => {
        Object.keys(tooltipsConst[trialName]).forEach((measurementId) => {
          if (mapLayout.hasLayer(vectorLayersLayout[trialName][measurementId])) {
            const tooltip = tooltipsConst[trialName][measurementId];
            tooltip.tooltip._container.classList.remove("soloed");
            tooltip.tooltip.setContent(tooltipToString(tooltip));
          }
        });
      });
  };
  const tooltipToString = (tooltip) => {
    return () => {
      function notApplicableIfNotExist(correspondingData, measurementToGet, assessmentDate) {
        const selectedPlotData = latestMeasurementSets?.current?.find(
          (plot) => plot.feature.properties.num === correspondingData?.plotNumber
        );
        const splitDate = assessmentDate.split(/-(?=[^-]+$)/);
        const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : assessmentDate;
        const selectedDateData = selectedPlotData?.means?.filter(
          ({ assessmentdate }) => dateForIndex === assessmentdate
        );
        const temp = selectedDateData?.reduce((acc, { means }) => {
          return { ...acc, ...means };
        }, {});
        let val = temp?.[measurementToGet];
        if (val === undefined || (typeof val !== "string" && isNaN(val)) || val === "nan") {
          return isImgAvailableLatest.current
            ? "<span class='tooltip-shadow'>n/a</span>"
            : "<span>n/a</span>";
        }
        if (isImgAvailableLatest.current) {
          return `<span class='tooltip-shadow'>${toFixedIfNecessary(val, 3)}</span>`;
        } else {
          return `<span>${toFixedIfNecessary(val, 3)}</span>`;
        }
      }
      let finalString = "<table>";
      let hasPlot = false;
      let hasTreat = false;
      for (const item of latestSelectedMeasurements.current.values()) {
        if (item.vector === "Plot #") {
          hasPlot = true;
        }
        if (item.vector === "Treatment #") {
          hasTreat = true;
        }
      }

      if (hasPlot || hasTreat) {
        finalString +=
          "<tr>" + `<span style="color:${colorMapConst[tooltip.trialName]};">&#9632;</span>`;
        if (hasPlot) {
          finalString +=
            (isImgAvailableLatest.current ? "<span class='tooltip-shadow'>" : "<span>") +
            tooltip.plotNumber +
            "</span>";
        }
        if (hasTreat) {
          if (tooltip.treatment === undefined) {
            finalString += " []";
          } else {
            finalString +=
              (isImgAvailableLatest.current ? "<span class='tooltip-shadow'>" : "<span>") +
              ` [${tooltip.treatment}]` +
              "</span>";
          }
        }
        finalString += "</tr>";
      }
      latestSelectedMeasurements.current.forEach((measurement) => {
        if (
          measurement.vector &&
          measurement.vector !== "Plot #" &&
          measurement.vector !== "Treatment #"
        ) {
          finalString +=
            "<tr><td>" +
            (isImgAvailableLatest.current ? "<span class='tooltip-shadow'>" : "<span>") +
            (measurement.letter +
              ":&nbsp</span></td><td>" +
              (isImgAvailableLatest.current ? "<span class='tooltip-shadow'> <b>" : "<span><b>") +
              notApplicableIfNotExist(tooltip, measurement.vector, measurement.date) +
              "</b></span></td></tr>");
        }
      });
      return finalString + "</table>";
    };
  };
  const bindCallbacks = () => {
    mapLayout.on("moveend", () => {
      if (mapLayout.getZoom() === oldZoom) {
        updateTooltipViewOnPan();
      }
      oldZoom = mapLayout.getZoom();
    });

    mapLayout.on("movestart", () => {
      oldZoom = mapLayout.getZoom();
    });

    mapLayout.on("zoomend", () => {
      updateTooltipViewOnZoom();
    });
    detectZoomLevelApplySettings();
  };

  const handleAssessmentSelect = (e, date, type, collId, assessmentType) => {
    e.stopPropagation();
    const payload = {
      activity_type: "Feature_assessment",
      description: "user selected an assessment",
      email_id: `${cookies?.userEmail}`,
      activity_details: {
        page: "/app/viewer",
        trial_name: trial,
        collection_id: collId,
        feature_name: "",
        feature_data: [],
        assessment_type: assessmentType,
        assessment_data: { date: date, type: type }
      }
    };

    activityTrackingApi(payload);

    if (e.target.checked) {
      const oldCombinations = [...assessmentDateType];
      setAssessmentDateType([
        ...assessmentDateType,
        { date: date, type: type, assessmentID: collId, assessmentType }
      ]);
      if (oldCombinations.length === 0) {
        setTimeout(() => {
          RetainMapZoomOnAssessmentChange();
        }, 100);
      }

      const assessmentsCount = parseInt(window.assessmentsCount);
      if (assessmentsCount === 0) {
        if (showTreatment) {
          setTreatmentDataLoaded(true);
        } else {
          setTreatmentDataLoaded(false);
        }
        if (showTime) {
          setFightDataLoaded(true);
        }
      }
      if (assessmentsCount === 1) {
        if (showCompare) {
          setCompareData1Loaded(true);
          setCompareData2Loaded(true);
        }
      }

      window.assessmentsCount = assessmentsCount + 1;
    } else {
      const index = assessmentDateType.findIndex((i) => i?.date == date && i?.type == type);
      const temp = [...assessmentDateType];
      if (index !== -1) {
        temp.splice(index, 1);
        setAssessmentDateType(temp);
      }
      const assessmentsCount = parseInt(window.assessmentsCount);
      window.assessmentsCount = assessmentsCount - 1;

      // if (
      //   selectedCombinationForHeatMap?.date === date &&
      //   selectedCombinationForHeatMap?.type === type
      // ) {
      //   setSelectedCombinationForHeatMap(undefined);
      // }
      // if (
      //   selectedCombinationForEyeOpen?.date === date &&
      //   selectedCombinationForEyeOpen?.type === type
      // ) {
      //   setSelectedCombinationForEyeOpen(undefined);
      // }
    }
  };
  const handleFlightDateChange = (value) => {
    setMeasurementLoaded(true);
    setDateLoader(true);
    setSelectedFlightDate(value);
    fetchRasterLayers(value);
    setFlightContainerVisible(false);
  };

  const handleFirstCompareFlightDateChange = (value) => {
    setFirstCompareSelectedFlightDate(value);
    fetchRasterLayersNew(value, "left");
    setFlightContainerVisible2(false);
  };

  const handleSecondCompareFlightDateChange = (value) => {
    setSecondCompareSelectedFlightDate(value);
    fetchRasterLayersNew(value, "right");
    setFlightContainerVisible2(false);
  };

  useEffect(() => {
    collectionDataRef.current = collectionData;
  }, [collectionData]);

  const fetchRasterLayers = async (newDate) => {
    const id = flightDates[newDate];
    const queryParams = new URLSearchParams(window.location.search);
    const tri = queryParams.get("trial");
    if (id) {
      let path = `/viewer?collection_id=${id}&trial=${tri}`;
      navigate(path, {
        search: "?collection_id=${id}"
      });
    } else {
      navigate(`/viewer?trial=${tri}`, { search: "?trial=${tri}" });
    }

    return new Promise(() => {
      getRasterLayersAPICall(
        id,
        (res) => {
          setCollectionData({ ...collectionDataRef?.current, ...res });
          setCollectionDataCompareLeft({ ...collectionDataRef?.current, ...res });
          setCollectionDataCompareRight({ ...collectionDataRef?.current, ...res });
          fetchVectorLayers(newDate);
        },
        (err) => {
          console.log("err", err);
        }
      );
    });
  };

  const fetchRasterLayersNew = async (newDate, compareSide) => {
    const id = flightDates[newDate];

    return new Promise(() => {
      getRasterLayersAPICall(
        id,
        (res) => {
          compareSide === "left"
            ? setCollectionDataCompareLeft({ ...collectionDataRef?.current, ...res })
            : setCollectionDataCompareRight({ ...collectionDataRef?.current, ...res });
        },
        (err) => {
          console.log("err", err);
        }
      );
    });
  };

  useEffect(() => {
    collectionDataCompareLeftRef.current = collectionDataCompareLeft;
  }, [collectionDataCompareLeft]);
  useEffect(() => {
    collectionDataCompareRightRef.current = collectionDataCompareRight;
  }, [collectionDataCompareRight]);

  const fetchVectorLayers = async (newDate) => {
    const id = collectionDataRef?.current?.collection_ids[newDate];
    return new Promise(() => {
      getMeasurmentSets(
        id,
        (overlays) => {
          setOverlayVectors(null);
          //To reload the measurement_sets - end
          updateView();
          setDateLoader(false);
          overlayChanged(activeOverlays);
          setNewTrial(selectedOption);
          setSelectedCombinationForHeatMap(undefined);
          setSelectedCombinationForEyeOpen(undefined);
          if (overlays.overlay_vectors !== undefined && overlays.overlay_vectors.length > 0) {
            const tempOverlayVectors = overlays.overlay_vectors[0];
            setOverlayVectors(tempOverlayVectors);
            overlayVectorsConst = tempOverlayVectors;
            if (selectedBoundingBoxes && tempOverlayVectors.features !== null) {
              let overlayVectorLayers = [];
              let tempgeojson = null;
              for (let key in tempOverlayVectors.features) {
                overlayVectorLayers.push(tempOverlayVectors.features[key]);
              }
              tempgeojson = L.geoJSON(overlayVectorLayers, {
                color: "#FF0000",
                fill: true,
                fillOpacity: 0
              }).addTo(mapLayout);
              setgeojson(tempgeojson);
            }
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    });
  };
  const clearLayers = () => {
    mapLayout.eachLayer((layer) => {
      mapLayout.removeLayer(layer);
    });
  };
  const updateView = () => {
    clearLayers();
    generateRasterLayers();
    generateTooltips();
  };

  const extractZoomsFromLayer = (layer) => {
    return {
      min: Math.min.apply(null, layer.zooms),
      max: Math.max.apply(null, layer.zooms)
    };
  };
  const handlePlotClick = (plotNumber) => {
    if (unsortedButtons?.includes("Plot Images")) {
      setSelectedPlots((prevSelectedPlots) => {
        if (prevSelectedPlots.includes(plotNumber.toString())) {
          selectedPlotsConst = prevSelectedPlots.filter((item) => item !== plotNumber.toString());
          return prevSelectedPlots.filter((item) => item !== plotNumber.toString());
        } else if (
          prevSelectedPlots.length < 8 &&
          !prevSelectedPlots.includes(plotNumber.toString())
        ) {
          selectedPlotsConst = [...prevSelectedPlots, plotNumber.toString()];
          return [...prevSelectedPlots, plotNumber.toString()];
        } else {
          setShowDroneSelectedMessage(true);
          return prevSelectedPlots;
        }
      });
      setTimeout(() => {
        if (selectedPlotsConst.length <= 8) {
          renderPlotImages();
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (mapInitiated && latestMeasurementSets?.current?.length > 0) {
      setTimeout(() => {
        renderPlotImages();
      }, 500);
    }
  }, [
    currentRaster,
    selectedFlightDate,
    activeOverlays,
    mapInitiated,
    selectedBoundingBoxes,
    latestMeasurementSets?.current
  ]);
  useEffect(() => {
    selectedPlotsConst = selectedPlots;
  }, [selectedPlots]);

  const extractBounds = () => {
    return L.latLngBounds(
      L.latLng(collectionDataRef?.current?.nswe[1], collectionDataRef?.current?.nswe[2]),
      L.latLng(collectionDataRef?.current?.nswe[0], collectionDataRef?.current?.nswe[3])
    );
  };
  const extractExtraLayer = (name) => {
    return collectionDataRef?.current?.extra_layers[name];
  };
  const extractZooms = () => {
    return collectionDataRef?.current?.base_layer?.zooms;
  };
  const getZooms = () => {
    const zooms = extractZooms();
    return {
      min: Math.min.apply(null, zooms),
      max: Math.max.apply(null, zooms)
    };
  };
  const generateColorMap = () => {
    const boundColors = [
      "#1D7CF2",
      "#E32E30",
      "#FF8C00",
      "#FFD700",
      "#0000EE",
      "#DE85B1",
      "#B0E0E6",
      "#0198E1",
      "#EEEE00",
      "#FF34B3",
      "#D0A9AA",
      "#00CED1",
      "#E47833",
      "#E6E8FA",
      "#551A8B",
      "#8F8FBC"
    ];

    let colorMap = {};
    extractTrialNames().forEach((trialName, index) => {
      let j = index % boundColors.length;
      colorMap[trialName] = boundColors[j];
    });
    colorMapConst = colorMap;
  };
  const extractTrialNames = () => {
    let trialNames = new Set([]);

    collectionDataRef?.current?.vectors.forEach((vector) => {
      vector.features.forEach((feature) => {
        trialNames.add(feature.properties.trial_name);
      });
    });
    return Array.from(trialNames).sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }));
  };
  const generateRasterLayers = (isPlotImage = false, returnOverlays = false, compareSide = "") => {
    const temp = isPlotImage
      ? compareSide === "right"
        ? collectionDataCompareRightRef
        : collectionDataCompareLeftRef
      : collectionDataRef;

    const makeLayer = (rasterlayer) => {
      if (rasterlayer === undefined || rasterlayer === null) {
        return undefined;
      }

      setIsImgAvailable(rasterlayer.meta_data.imgtype !== undefined);
      let layer_template;
      let zIndex = 1;
      if (rasterlayer.meta_data.imgtype !== undefined && rasterlayer.meta_data.imgtype === "png") {
        layer_template = "/tiles/{baseName}/{z}/{x}/{y}.png";
        zIndex = 2;
      } else {
        layer_template = "/tiles/{baseName}/{z}/{x}/{y}.jpeg";
      }

      const tl = L.tileLayer(layer_template, {
        baseName: rasterlayer.s3_id,
        minZoom: temp?.current?.base_layer?.zooms?.min,
        maxZoom: temp?.current?.base_layer?.zooms?.max + 3,
        minNativeZoom: extractZoomsFromLayer(rasterlayer).min,
        maxNativeZoom: extractZoomsFromLayer(rasterlayer).max,
        bounds: extractBounds(),
        noWrap: true,
        zIndex: zIndex
      });

      tl.on("tileerror", async () => {});

      return tl;
    };

    const baseLayer = makeLayer(temp?.current?.base_layer);
    const demLayer = makeLayer(temp?.current?.dem_layer);
    const ndviLayer = makeLayer(temp?.current?.ndvi_layer);
    let innerRasterLayers = rasterLayers;
    innerRasterLayers = {
      Visible: baseLayer
    };
    if (demLayer) {
      innerRasterLayers.Elevation = demLayer;
    } else if (currentRaster === "Elevation") {
      handleRasterChange("Visible");
    }
    if (ndviLayer) {
      innerRasterLayers.NDVI = ndviLayer;
    } else if (currentRaster === "NDVI") {
      handleRasterChange("Visible");
    }

    const extraLayerNames = ["VARI", "MCARI", "thermal"];
    extraLayerNames.forEach((layerName) => {
      const lyr = makeLayer(extractExtraLayer(layerName));
      if (lyr) {
        let upLayerName = layerName.charAt(0).toUpperCase() + layerName.slice(1);
        innerRasterLayers[upLayerName] = lyr;
      }
    });
    const altRgbLayer = makeLayer(extractExtraLayer("alt-rgb"));
    if (altRgbLayer) {
      innerRasterLayers["Alternate RGB"] = altRgbLayer;
    }
    if (isPlotImage) {
      return innerRasterLayers;
    } else if (returnOverlays) {
      let innerOverlayLayers = [];
      const vegLayer = makeLayer(extractExtraLayer("veg"));
      if (vegLayer) {
        innerOverlayLayers.Crop = vegLayer;
      }
      const phytoLayer = makeLayer(extractExtraLayer("phyto"));
      if (phytoLayer) {
        innerOverlayLayers.Phyto = phytoLayer;
      }
      const noncropLayer = makeLayer(extractExtraLayer("noncrop"));
      if (noncropLayer) {
        innerOverlayLayers.Weeds = noncropLayer;
      }
      const clsLayer = makeLayer(extractExtraLayer("cls"));
      if (clsLayer) {
        innerOverlayLayers.Classification = clsLayer;
      }
      return innerOverlayLayers;
    } else {
      setRasterLayersList(Object.keys(innerRasterLayers));
      setRasterLayers(innerRasterLayers);
      let innerOverlayLayers = [];
      const vegLayer = makeLayer(extractExtraLayer("veg"));
      if (vegLayer) {
        innerOverlayLayers.Crop = vegLayer;
      }
      const phytoLayer = makeLayer(extractExtraLayer("phyto"));
      if (phytoLayer) {
        innerOverlayLayers.Phyto = phytoLayer;
      }
      const noncropLayer = makeLayer(extractExtraLayer("noncrop"));
      if (noncropLayer) {
        innerOverlayLayers.Weeds = noncropLayer;
      }
      const clsLayer = makeLayer(extractExtraLayer("cls"));
      if (clsLayer) {
        innerOverlayLayers.Classification = clsLayer;
      }
      setOverlayLayersList(Object.keys(innerOverlayLayers));
      // setOverlayLayers(innerOverlayLayers);
      selectedOverlaysList = innerOverlayLayers;
    }
  };
  const buildMeasurementSetsFromVectors = (vectors) => {
    let measurementsSets = {};

    if (vectors?.length > 0) {
      vectors[0].features?.forEach((vector) => {
        if (measurementsSets[vector.properties.trial_name] === undefined) {
          measurementsSets[vector.properties.trial_name] = {
            measurements: [{ feature: vector, id: vector.id }]
          };
        } else {
          measurementsSets[vector.properties.trial_name].measurements.push({
            feature: vector,
            id: vector.id
          });
        }
      });

      return measurementsSets;
    }
  };

  const extractVectorLayerMeasurements = () => {
    let vectors = {};

    collectionDataRef?.current?.measurement_sets?.forEach((measurementSet) => {
      vectors[measurementSet.name] = {
        // id: measurementSet.id,
        keys: measurementSet.keys,
        measurements: measurementSet.measurements
      };
    });

    return { ...buildMeasurementSetsFromVectors(collectionDataRef?.current.vectors), ...vectors };
  };

  function detectZoomLevelApplySettings() {
    const zoomLevel = Math.round(window.devicePixelRatio * 100);
    const images = document.querySelectorAll(".leaflet-tile.leaflet-tile-loaded");
    let mixBlendMode = "plus-lighter";
    images.forEach((img) => {
      if (zoomLevel == 67 || zoomLevel == 110) {
        mixBlendMode = "normal";
      }
      img.style.setProperty("mix-blend-mode", mixBlendMode, "important");
    });
  }
  // Call detectZoomLevel on page load and resize
  window.addEventListener("resize", detectZoomLevelApplySettings);
  window.addEventListener("load", detectZoomLevelApplySettings);

  const generateVectorLayers = () => {
    const trialMeasurements = extractVectorLayerMeasurements();
    generateRenderImageConst = trialMeasurements;
    let vectorLayers = {};
    const tempPlotDetails = {};
    Object.keys(trialMeasurements).forEach((trialName) => {
      tempPlotDetails[trialName] = [];
      vectorLayers[trialName] = trialMeasurements[trialName].measurements.reduce(
        (finalObject, measurement) => {
          finalObject[measurement.feature.properties.num] = L.geoJson(measurement.feature, {
            color: colorMapConst?.[trialName] || "#000",
            fill: true,
            fillOpacity: 0
          });
          // if(trialName === selectedOption) {
          tempPlotDetails[trialName].push({
            plotId: measurement.feature.properties.num,
            plotCoordinates: measurement.feature.geometry.coordinates?.[0],
            leafletId: finalObject[measurement.feature.properties.num]._leaflet_id,
            rotation: 0,
            Row: measurement.feature.properties.row,
            Col: measurement.feature.properties.col,
            plotNumber: measurement.feature.properties.num,
            treatmentNumber: measurement.feature.properties?.treatment
          });
          // }
          finalObject[measurement.feature.properties.num].on("mouseover", function () {
            const tooltipHTMLelement = this.getTooltip()._container;
            if (
              !tooltipHTMLelement.classList.contains("should-show") &&
              latestSelectedMeasurements?.current.size !== 0 &&
              !tooltipHTMLelement.classList.contains("soloed")
            ) {
              this.getTooltip().update();
              tooltipHTMLelement.style.zIndex = "1000000";
              tooltipHTMLelement.style.visibility = "visible";
            } else if (!tooltipHTMLelement.classList.contains("soloed")) {
              tooltipHTMLelement.style.zIndex = "100";
            }
          });
          finalObject[measurement.feature.properties.num].on("click", function () {
            handlePlotClick(measurement?.feature?.properties?.num);
          });
          finalObject[measurement.feature.properties.num].on("mouseout", function () {
            const tooltipHTMLelement = this.getTooltip()._container;

            if (
              !tooltipHTMLelement.classList.contains("should-show") &&
              !tooltipHTMLelement.classList.contains("soloed")
            ) {
              tooltipHTMLelement.style.visibility = "hidden";
            } else if (!tooltipHTMLelement.classList.contains("soloed")) {
              tooltipHTMLelement.style.zIndex = "100";
            }
          });

          return finalObject;
        },
        {}
      );
    });
    setDronePlotDetails(tempPlotDetails);
    vectorLayersLayout = vectorLayers;
  };

  const rotateMapZeroDegree = () => {
    let angleToRotate = getShortestRotation(
      latestMeasurementSets.current[0].feature.properties.angle,
      0
    );
    if (angleToRotate) {
      mapLayout.setBearing(angleToRotate);
      setZeroDegreeMapRotation(true);
    }
  };

  const initMap = (isManual = false) => {
    let zooms = isManual ? { max: 27, min: 5 } : getZooms();
    let container = L.DomUtil.get("map");

    if (container != null) {
      container._leaflet_id = null;
    }
    let innerMap = mapState;
    innerMap = L.map("map", {
      mapMaxZoom: zooms.max + 3,
      mapMinZoom: zooms.min,
      crs: L.CRS.EPSG3857,
      zoomControl: false,
      rotate: true,
      rotateControl: {
        closeOnZeroBearing: false
      },
      scrollWheelZoom: true,
      // touchRotate: true
      zoomSnap: 1, // partial zooms have bad gaps on safari/chrome
      zoomDelta: 1
    }).setView([0, 0], isManual ? 19 : zooms.max);
    innerMap.setMinZoom(zooms.min);
    innerMap.setMaxZoom(zooms.max + 3);
    !isManual && innerMap.fitBounds(extractBounds());
    currentZoom = isManual ? 19 : innerMap.getZoom();
    defaultZoom = isManual ? 19 : innerMap.getZoom();
    innerMap.setView(innerMap.getCenter(), innerMap.getZoom());
    innerMap.touchRotate.disable();
    innerMap.touchZoom.disable();
    innerMap.compassBearing.disable();
    innerMap.touchGestures.enable();
    innerMap.rotateControl.setPosition("bottomright");
    !isManual &&
      innerMap.setMaxBounds([
        [-90, -180],
        [90, 180]
      ]);
    !isManual && rasterLayers["Visible"]?.addTo(innerMap);
    container._mapInstance = innerMap;
    let ZoomViewer = L.Control.extend({
      onAdd: function () {
        let container = L.DomUtil.create("div");
        let gauge = L.DomUtil.create("div");
        let gsd = L.DomUtil.create("div");
        container.className = "zoomviewcontainer";
        // container.style.width = "5em";
        container.style.background = "rgba(255,255,255,0.5)";
        container.style.textAlign = "center";
        container.style.position = "fixed"; //new
        container.style.bottom = "5px"; //new
        container.style.right = "18px"; //new
        container.style.color = "#0D1A40";
        container.style.padding = "0 2px 0 2px";
        innerMap.on("zoomstart zoom zoomend", function () {
          currentZoom = innerMap.getZoom();
          gauge.innerHTML = "zoom: " + innerMap.getZoom();
        });
        gauge.innerHTML = "zoom: " + innerMap.getZoom();
        container.appendChild(gauge);
        let gsdRestrictDecimal =
          gsdnumber && !isNaN(gsdnumber) ? (gsdnumber * 1000).toFixed(1) : "";
        gsd.innerHTML = gsdRestrictDecimal ? "GSD: " + gsdRestrictDecimal + " mm" : "";
        container.appendChild(gsd);
        this.gsdElement = gsd;
        return container;
      }
    });

    let drawnItems = new L.FeatureGroup();
    innerMap.addLayer(drawnItems);
    drawnItemsConst = drawnItems;

    zoomViewerInstance = new ZoomViewer({ position: "bottomright" }).addTo(innerMap);
    innerMap.addControl(zoomViewerInstance);
    L.control
      .zoom({
        position: "bottomright"
      })
      .addTo(innerMap);
    mapLayout = innerMap;
    setMapState(innerMap);
  };
  const clearVectors = () => {
    Object.keys(vectorLayersLayout).forEach((trialName) => {
      Object.keys(vectorLayersLayout[trialName]).forEach((feature) => {
        vectorLayersLayout[trialName][feature].removeFrom(mapLayout);
      });
    });
  };

  // clear all plots
  const clearAllDrawnLayers = () => {
    drawnItemsConst.eachLayer((layer) => {
      drawnItemsConst.removeLayer(layer); // Remove the layer from the feature group
    });
  };
  const getNextPolygon = (latLngs, rectangle, row) => {
    let bearing = 90;

    const points1 = [];
    for (let i = 0; i < latLngs.length; i++) {
      points1.push(
        L.GeometryUtil.destination(
          { lat: latLngs[i].lat, lng: latLngs[i].lng },
          bearing,
          Number(
            rectangle.getBounds().getNorthWest().distanceTo(rectangle.getBounds().getNorthEast())
          ) + Number(xDistance)
        )
      );
    }
    let polygon1 = L.polygon(points1, { fillColor: "transparent", color: "#3388ff" });

    polygon1.addTo(drawnItemsConst);

    let latlngs11 = polygon1.getLatLngs()[0];

    // Get plot details from API payload
    const plotDetailsFromAPIPayload = plotDetailsFromAPIConst?.payload || [];
    // Find data based on col and blk values
    const findData = plotDetailsFromAPIPayload.find((item) => {
      return item.col === 2 && item.blk === row;
    });
    setPlotDetails((prevArray) => [
      ...prevArray,
      {
        plotId: prevArray.length + 1,
        plotCoordinates: polygon1._latlngs[0],
        leafletId: polygon1._leaflet_id,
        rotation: 0,
        Row: row,
        Col: 2,
        plotNumber: findData?.planPlotId,
        treatmentNumber: findData?.trt
      }
    ]);

    let col = 2;

    for (let j = 1; j < layoutDimensionsConst.noOfColumns - 1; j++) {
      col = col + 1;
      const points2 = [];
      for (let k = 0; k < latlngs11.length; k++) {
        points2.push(
          L.GeometryUtil.destination(
            { lat: latlngs11[k].lat, lng: latlngs11[k].lng },
            bearing,
            Number(
              rectangle.getBounds().getNorthWest().distanceTo(rectangle.getBounds().getNorthEast())
            ) + Number(xDistance)
          )
        );
      }
      let polygon2 = L.polygon(points2, { fillColor: "transparent", color: "#3388ff" });

      polygon2.addTo(drawnItemsConst);

      latlngs11 = polygon2.getLatLngs()[0];
      const col1 = col;
      // Get plot details from API payload
      const plotDetailsFromAPIPayload = plotDetailsFromAPIConst?.payload || [];
      // Find data based on col and blk values
      const findData = plotDetailsFromAPIPayload.find((item) => {
        return item.col === col1 && item.blk === row;
      });
      setPlotDetails((prevArray) => [
        ...prevArray,
        {
          plotId: prevArray.length + 1,
          plotCoordinates: latlngs11,
          leafletId: polygon2._leaflet_id,
          Row: row,
          Col: col1,
          plotNumber: findData?.planPlotId,
          treatmentNumber: findData?.trt
        }
      ]);
    }
    return latlngs11;
  };
  const generateManualPlots = () => {
    clearAllDrawnLayers();
    let center =
      firstPlotLatLng?.lat && firstPlotLatLng?.lng ? firstPlotLatLng : mapLayout.getCenter();
    mapLayout.setView([center.lat, center?.lng]);
    // Calculate the north, south, east, and west bounds
    let north = L.GeometryUtil.destination(center, 0, layoutDimensionsConst?.height / 2).lat;
    let south = L.GeometryUtil.destination(center, 180, layoutDimensionsConst?.height / 2).lat;
    let east = L.GeometryUtil.destination(center, 90, layoutDimensionsConst?.width / 2).lng;
    let west = L.GeometryUtil.destination(center, -90, layoutDimensionsConst?.width / 2).lng;

    // Calculate the points of the rectangle
    let point1 = [south, west];
    let point2 = [north, west];
    let point3 = [north, east];
    let point4 = [south, east];

    // Create an array of the points
    let latlngs = [[point1, point2, point3, point4]];

    // Create the rectangle and add it to the map
    let rectangle = L.polygon(latlngs, { fillColor: "transparent" });
    drawnItemsConst.addLayer(rectangle);

    let latLngs = rectangle.getLatLngs()[0];

    const plotDetailsFromAPIPayload = plotDetailsFromAPIConst?.payload || [];

    // Find the data for the plot with col = 1 and blk = 1
    const findData = plotDetailsFromAPIPayload.find((item) => {
      return item.col === 1 && item.blk === 1;
    });
    const plots = [
      {
        plotId: 1,
        plotCoordinates: latLngs,
        rotation: 0,
        leafletId: rectangle._leaflet_id,
        Row: 1,
        Col: 1,
        plotNumber: findData?.planPlotId,
        treatmentNumber: findData?.trt
      }
    ];
    setPlotDetails(plots);
    for (let i = 1; i <= layoutDimensionsConst.noOfRows; i++) {
      const row = i + 1;
      if (layoutDimensionsConst.noOfColumns > 1) {
        getNextPolygon(latLngs, rectangle, i);
      }
      if (i < layoutDimensionsConst.noOfRows) {
        const points = [];
        for (let j = 0; j < latLngs.length; j++) {
          points.push(
            L.GeometryUtil.destination(
              { lat: latLngs[j].lat, lng: latLngs[j].lng },
              0, //repBearingConst,
              Number(
                rectangle
                  .getBounds()
                  .getNorthWest()
                  .distanceTo(rectangle.getBounds().getSouthWest())
              ) + Number(xDistance)
            )
          );
        }
        let polygon3 = L.polygon(points, { fillColor: "transparent", color: "#3388ff" });
        polygon3.addTo(drawnItemsConst);
        latLngs = polygon3.getLatLngs()[0];
        // Get plot details from API payload
        const plotDetailsFromAPIPayload = plotDetailsFromAPIConst?.payload || [];

        // Find data for the specific row and column
        const findData = plotDetailsFromAPIPayload.find((item) => {
          return item.col === 1 && item.blk === row;
        });
        setPlotDetails((prevArray) => {
          const updatePlotDetails = [
            ...prevArray,
            {
              plotId: prevArray.length + 1,
              plotCoordinates: latLngs,
              leafletId: polygon3?._leaflet_id,
              Row: row,
              Col: 1,
              plotNumber: findData?.planPlotId,
              treatmentNumber: findData?.trt
            }
          ];
          return updatePlotDetails;
        });
      }
    }
  };
  var CustomTooltip = L.Tooltip.extend({
    _setPosition: function (pos) {
      var map = this._map,
        container = this._container,
        tooltipPoint = map.layerPointToContainerPoint(pos),
        centerPoint = map.latLngToContainerPoint(map.getCenter()),
        direction = this.options.direction,
        tooltipWidth = null,
        tooltipHeight = null,
        offset = L.point(this.options.offset),
        anchor = this._getAnchor();
      if (direction === "fast") {
        const totalCounts = window.assessmentsCount + (window.plotTreatmentCount > 0 ? 1 : 0);
        tooltipWidth = 44;
        if (window.eyeOpenClosed || window.isHeatMapActive) {
          tooltipHeight = 32;
        } else {
          if (totalCounts == 1 || totalCounts == 0) {
            tooltipHeight = 32;
          } else {
            tooltipHeight = totalCounts * 22;
          }
        }
        direction = "right";
      } else {
        tooltipWidth = container.offsetWidth;
        tooltipHeight = container.offsetHeight;
      }

      if (direction === "top") {
        pos = pos.add(
          L.point(-tooltipWidth / 2 + offset.x, -tooltipHeight + offset.y + anchor.y, true)
        );
      } else if (direction === "bottom") {
        pos = pos.subtract(L.point(tooltipWidth / 2 - offset.x, -offset.y, true));
      } else if (direction === "center") {
        pos = pos.subtract(
          L.point(tooltipWidth / 2 + offset.x, tooltipHeight / 2 - anchor.y + offset.y, true)
        );
      } else if (
        direction === "right" ||
        (direction === "auto" && tooltipPoint.x < centerPoint.x)
      ) {
        direction = "right";
        pos = pos.add(L.point(offset.x + anchor.x, anchor.y - tooltipHeight / 2 + offset.y, true));
      } else {
        direction = "left";
        pos = pos.subtract(
          L.point(tooltipWidth + anchor.x - offset.x, tooltipHeight / 2 - anchor.y - offset.y, true)
        );
      }

      L.DomUtil.removeClass(container, "leaflet-tooltip-right");
      L.DomUtil.removeClass(container, "leaflet-tooltip-left");
      L.DomUtil.removeClass(container, "leaflet-tooltip-top");
      L.DomUtil.removeClass(container, "leaflet-tooltip-bottom");
      L.DomUtil.addClass(container, "leaflet-tooltip-" + direction);
      L.DomUtil.setPosition(container, pos);
    }
  });
  const generateManualTooltip = () => {
    function notApplicableIfNotExist(currentPlot, measurementToGet, assessmentDate) {
      const selectedPlotData = latestMeasurementSets?.current?.find(
        (plot) =>
          plot.feature.properties.col == currentPlot?.Col &&
          plot.feature.properties.row == currentPlot?.Row
      );
      const splitDate = assessmentDate.split(/-(?=[^-]+$)/);
      const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : assessmentDate;
      const selectedDateData = selectedPlotData?.means?.filter(
        ({ assessmentdate }) => dateForIndex === assessmentdate
      );
      const temp = selectedDateData?.reduce((acc, { means }) => {
        return { ...acc, ...means };
      }, {});
      let val = temp?.[measurementToGet];
      if (val === undefined || (typeof val !== "string" && isNaN(val)) || val === "nan") {
        return "<span class='tooltip-shadow'>n/a</span>";
      }
      return `<span class='tooltip-shadow'>${toFixedIfNecessary(val, 3)}</span>`;
    }
    if (drawnItemsConst !== null) {
      const treatmentAvailable = plotDetails.some((item) => item.treatmentNumber);
      const plotNumberAvailable = plotDetails.some((item) => item.plotNumber);
      drawnItemsConst.eachLayer((layer) => {
        layer.unbindTooltip();
        layer.closeTooltip();
        let filteredData = plotDetails.filter((item) => {
          return item.leafletId.toString() == layer._leaflet_id.toString();
        });
        if (filteredData.length > 0) {
          let finalString = "";
          if (
            selectedVectorsConst.includes("Plot #") ||
            selectedVectorsConst.includes("Treatment #")
          ) {
            finalString += "<tr><td colspan='2'>";
          }
          if (
            (selectedVectorsConst.includes("Plot #") &&
              filteredData[0]?.plotNumber != undefined &&
              plotNumberAvailable) ||
            (selectedVectorsConst.includes("Treatment #") &&
              filteredData[0]?.treatmentNumber != undefined &&
              treatmentAvailable)
          ) {
            finalString += "<span style='color:#1b74df;'>&#9632;</span>&nbsp;";
          }
          if (
            selectedVectorsConst.includes("Plot #") &&
            filteredData[0]?.plotNumber != undefined &&
            plotNumberAvailable
          ) {
            finalString +=
              "<span class='tooltip-shadow'>" + filteredData[0]?.plotNumber?.toString() + "</span>";
          }
          if (
            selectedVectorsConst.includes("Treatment #") &&
            filteredData[0]?.treatmentNumber != undefined &&
            treatmentAvailable
          ) {
            finalString +=
              "<span class='tooltip-shadow'>" +
              ` [${filteredData[0].treatmentNumber?.toString()}]` +
              "</span>";
          } else if (
            selectedVectorsConst.includes("Treatment #") &&
            filteredData[0]?.treatmentNumber === undefined &&
            treatmentAvailable
          ) {
            finalString += "<span class='tooltip-shadow'>[]</span>";
          }
          if (
            selectedVectorsConst.includes("Plot #") ||
            selectedVectorsConst.includes("Treatment #")
          ) {
            finalString += "</td></tr>";
          }

          if (selectedVectorsConst.includes("Plot ID")) {
            finalString +=
              "<tr><td>" +
              "<span class='tooltip-shadow'>Plot ID  " +
              `${filteredData[0].plotId?.toString()}` +
              "</span></td></tr>";
          }
          assessmentDateType.forEach((measurement, index) => {
            if (
              measurement.type &&
              measurement.type !== "Plot #" &&
              measurement.type !== "Treatment #"
            ) {
              finalString +=
                "<tr><td style='width:15px'>" +
                "<span class='tooltip-shadow'>" +
                (orderArray[index] +
                  ":&nbsp</span></td><td>" +
                  "<span class='tooltip-shadow'> <b>" +
                  notApplicableIfNotExist(filteredData[0], measurement.type, measurement.date) +
                  "</b></span></td></tr>");
            }
          });
          if (finalString !== "") {
            finalString = "<table class='tooltip-table-manual'>" + finalString + "</table>";

            let tooltip = new CustomTooltip({
              permanent: true,
              direction: "fast",
              className: "text",
              opacity: 1
            });
            tooltip.setContent(finalString);
            tooltip.setLatLng(layer.getCenter());
            layer.bindTooltip(tooltip).openTooltip();
            layer.getTooltip()._container.classList.remove("soloed");
          }
        }
      });
      updateTooltipViewOnZoom();
    }
  };
  const generateManualTooltipHeatMap = () => {
    function notApplicableIfNotExist(currentPlot, measurementToGet, assessmentDate) {
      const selectedPlotData = latestMeasurementSets?.current?.find(
        (plot) =>
          plot.feature.properties.col == currentPlot?.Col &&
          plot.feature.properties.row == currentPlot?.Row
      );
      const splitDate = assessmentDate.split(/-(?=[^-]+$)/);
      const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : assessmentDate;
      const selectedDateData = selectedPlotData?.means?.filter(
        ({ assessmentdate }) => dateForIndex === assessmentdate
      );
      const temp = selectedDateData?.reduce((acc, { means }) => {
        return { ...acc, ...means };
      }, {});
      let val = temp?.[measurementToGet];
      if (val === undefined || (typeof val !== "string" && isNaN(val)) || val === "nan") {
        return "<span class='tooltip-shadow'>n/a</span>";
      }
      return `<span class='tooltip-shadow'>${toFixedIfNecessary(val, 3)}</span>`;
    }
    if (drawnItemsConst !== null) {
      const treatmentAvailable = plotDetailsConst.some((item) => item.treatmentNumber);
      const plotNumberAvailable = plotDetailsConst.some((item) => item.plotNumber);
      drawnItemsConst.eachLayer((layer) => {
        layer.unbindTooltip();
        layer.closeTooltip();
        let filteredData = plotDetailsConst.filter((item) => {
          return item.leafletId.toString() == layer._leaflet_id.toString();
        });
        if (filteredData.length > 0) {
          let finalString = "";
          if (
            selectedVectorsConst.includes("Plot #") ||
            selectedVectorsConst.includes("Treatment #")
          ) {
            finalString += "<tr><td>";
          }
          if (
            (selectedVectorsConst.includes("Plot #") &&
              filteredData[0]?.plotNumber != undefined &&
              plotNumberAvailable) ||
            (selectedVectorsConst.includes("Treatment #") &&
              filteredData[0]?.treatmentNumber != undefined &&
              treatmentAvailable)
          ) {
            finalString += "<span style='color:#1b74df;'>&#9632;</span>&nbsp;";
          }
          if (
            selectedVectorsConst.includes("Plot #") &&
            filteredData[0]?.plotNumber != undefined &&
            plotNumberAvailable
          ) {
            finalString +=
              "<span class='tooltip-shadow'>" + filteredData[0]?.plotNumber?.toString() + "</span>";
          }
          if (
            selectedVectorsConst.includes("Treatment #") &&
            filteredData[0]?.treatmentNumber != undefined &&
            treatmentAvailable
          ) {
            finalString +=
              "<span class='tooltip-shadow'>" +
              ` [${filteredData[0].treatmentNumber?.toString()}]` +
              "</span>";
          } else if (
            selectedVectorsConst.includes("Treatment #") &&
            filteredData[0]?.treatmentNumber === undefined &&
            treatmentAvailable
          ) {
            finalString += "<span class='tooltip-shadow'>[]</span>";
          }
          if (
            selectedVectorsConst.includes("Plot #") ||
            selectedVectorsConst.includes("Treatment #")
          ) {
            finalString += "</td></tr>";
          }

          if (selectedVectorsConst.includes("Plot ID")) {
            finalString +=
              "<tr><td>" +
              "<span class='tooltip-shadow'>Plot ID  " +
              `${filteredData[0].plotId?.toString()}` +
              "</span></td></tr>";
          }
          if (selectedCombinationForHeatMap) {
            finalString +=
              "<tr><td>" +
              "<span class='tooltip-shadow'><b>" +
              notApplicableIfNotExist(
                filteredData[0],
                selectedCombinationForHeatMap.type,
                selectedCombinationForHeatMap.date
              ) +
              "</b></span></td></tr>";
          }
          if (finalString !== "") {
            finalString = "<table class='tooltip-table-manual'>" + finalString + "</table>";

            let tooltip = new CustomTooltip({
              permanent: true,
              direction: "fast",
              className: "text",
              opacity: 1
            });
            tooltip.setContent(finalString);
            tooltip.setLatLng(layer.getCenter());
            layer.bindTooltip(tooltip).openTooltip();
            layer.getTooltip()._container.classList.add("soloed");
          }
        }
      });
      updateTooltipViewOnZoom();
    }
  };
  const generateManualSoloTooltip = () => {
    function notApplicableIfNotExist(currentPlot, measurementToGet, assessmentDate) {
      const selectedPlotData = latestMeasurementSets?.current?.find(
        (plot) =>
          plot.feature.properties.col == currentPlot?.Col &&
          plot.feature.properties.row == currentPlot?.Row
      );
      const splitDate = assessmentDate?.split(/-(?=[^-]+$)/);
      const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : assessmentDate;
      const selectedDateData = selectedPlotData?.means?.filter(
        ({ assessmentdate }) => dateForIndex === assessmentdate
      );
      const temp = selectedDateData?.reduce((acc, { means }) => {
        return { ...acc, ...means };
      }, {});
      let val = temp?.[measurementToGet];
      if (val === undefined || (typeof val !== "string" && isNaN(val)) || val === "nan") {
        return "<span class='tooltip-shadow'>n/a</span>";
      }
      return `<span class='tooltip-shadow'>${toFixedIfNecessary(val, 3)}</span>`;
    }
    if (drawnItemsConst !== null) {
      drawnItemsConst.eachLayer((layer) => {
        layer.unbindTooltip();
        layer.closeTooltip();
        let filteredData = plotDetails.filter((item) => {
          return item.leafletId.toString() == layer._leaflet_id.toString();
        });
        if (filteredData.length > 0) {
          let finalString = "";
          let hasPlot = false;
          let hasTreat = false;

          if (selectedTrialInfoForEyeOpen === "Plot #") {
            hasPlot = true;
            hasTreat = false;
          }
          if (selectedTrialInfoForEyeOpen === "Treatment #") {
            hasTreat = true;
          }
          if (selectedCombinationForEyeOpen || selectedTrialInfoForEyeOpen) {
            if (selectedTrialInfoForEyeOpen) {
              if (hasPlot) {
                finalString = `<span class='tooltip-shadow'>${filteredData[0]?.plotNumber}</span>`;
              }
              if (hasTreat) {
                finalString = `<span class='tooltip-shadow'>${filteredData[0]?.treatmentNumber.toString()}</span>`;
              }
            } else {
              finalString = notApplicableIfNotExist(
                filteredData[0],
                selectedCombinationForEyeOpen.type,
                selectedCombinationForEyeOpen.date
              );
            }
          }
          if (finalString !== "") {
            // finalString = "<table>" + finalString + "</table>";

            let tooltip = new CustomTooltip({
              permanent: true,
              direction: "fast",
              className: "text",
              opacity: 1
            });
            tooltip.setContent(finalString);
            tooltip.setLatLng(layer.getCenter());
            layer.bindTooltip(tooltip).openTooltip();
            layer.getTooltip()._container.classList.add("soloed");
          }
        }
      });
      updateTooltipViewOnZoom();
    }
  };
  const setNewTrial = (newTrial) => {
    const innerMap = mapLayout;
    if (newTrial === "All trials") {
      Object.keys(vectorLayersLayout).forEach((trialName) => {
        Object.keys(vectorLayersLayout[trialName]).forEach((feature) => {
          if (!innerMap.hasLayer(vectorLayersLayout[trialName][feature])) {
            vectorLayersLayout[trialName][feature].addTo(innerMap);
          }
        });
      });
    } else if (newTrial == "No trials") {
      clearVectors();
    } else if (newTrial) {
      Object.keys(vectorLayersLayout).forEach((trialName) => {
        if (trialName === newTrial) {
          Object.keys(vectorLayersLayout[trialName]).forEach((feature) => {
            if (!innerMap.hasLayer(vectorLayersLayout[trialName][feature])) {
              vectorLayersLayout[trialName][feature].addTo(innerMap);
            }
          });
        } else {
          Object.keys(vectorLayersLayout[trialName]).forEach((feature) => {
            vectorLayersLayout[trialName][feature].removeFrom(innerMap);
          });
        }
      });
    }
    setMapState(innerMap);
    updateTooltipViewOnZoom();
    updateTooltipViewOnPan();
  };

  useEffect(() => {
    setMapState(new Map(null));
    mapLayout = new Map(null);
  }, []);

  const viewCollectionData = (data) => {
    const displayName = data?.name?.split(" ");
    displayName?.pop();
    getTrialMeasurments(
      selectedOption,
      (res) => {
        setMeasurementSets(res?.measurements);
        let plotList = new Set();
        let repList = new Set();
        checkSuplot(
          selectedOption,
          (resp) => {
            setTrialSubPlot(resp);
            res?.measurements.forEach((obj) => {
              const plotNumber = obj?.feature?.properties?.num;
              if (resp?.is_subplot_exist === true) {
                if (plotNumber.toString().length >= 5) {
                  const plotLastTwoDigits = plotNumber.toString().slice(-2);

                  if (
                    plotLastTwoDigits[1] >= "1" &&
                    plotLastTwoDigits[1] <= "9" &&
                    plotLastTwoDigits[0] === "0"
                  ) {
                    plotList.add(parseInt(plotLastTwoDigits[1].toString()));
                  }
                }
              } else {
                if (plotNumber.toString().length === 4) {
                  repList.add(parseInt(plotNumber.toString()[0] + plotNumber.toString()[1]));
                } else {
                  repList.add(parseInt(plotNumber.toString()[0]));
                }
              }
            });
            setSubPlots(Array.from(plotList));
            setReplicants(Array.from(repList));
          },
          (err) => {
            console.log("err", err);
          }
        );

        setMeasurementLoaded(false);
      },
      (err) => {
        setMeasurementLoaded(false);
        console.log("err", err);
      }
    );
    data?.collection_ids;

    setgsdnumber(data?.base_layer?.meta_data?.gsd);
    setviewAPIData(data);
    setCollectionData(data);
    setCollectionDataCompareLeft(data);
    setCollectionDataCompareRight(data);
    setLoading(false);
    if (data.overlay_vectors !== undefined && data.overlay_vectors.length > 0) {
      setOverlayVectors(data.overlay_vectors[0]);
      overlayVectorsConst = data.overlay_vectors[0];
    }
    setFlightDates(data?.collection_ids);
    setMapLoading(false);
    getRatingDetails(
      selectedOption,
      (data) => {
        setRatingDescriptions(data);
        setRatingDescriptionsLoaded(false);
      },
      (err) => {
        console.log("err in getRatingDetails", err);
      }
    );
  };

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          getProtocolBytrial(
            selectedOption,
            (data) => {
              setProtocolName(data?.protocols[0]?.name);
              getTrialProtocol(data?.protocols[0]?.name, (data) => {
                setProtocolTrial(data?.trials);
              });
            },
            (err) => {
              console.log("err", err);
            }
          );
          if (collectionId != null && collectionId != "") {
            viewAPI(
              collectionId,
              (data) => {
                const sitesYear =
                  selectedOption !== "All trials" &&
                  selectedOption !== "No trials" &&
                  trial.slice(-4);

                const datesLength = Object.keys(data?.collection_ids)?.length;
                const savedSiteData = {
                  site_id: data?.site_id,
                  site_year:
                    Object.keys(data?.collection_ids).length > 0
                      ? Object.keys(data?.collection_ids)?.[datesLength - 1]?.split("-")?.[0]
                      : sitesYear,
                  user_email: cookies?.userEmail
                };
                viewCollectionData(data);

                if (params.size === 1) {
                  saveSiteData(savedSiteData, (err) => {
                    console.log("saveRecentSiteAPI err", err);
                  });
                }
              },
              (err) => {
                setLoading(false);
                setMapLoading(false);
                setMeasurementLoaded(false);
                setDateLoader(false);
                console.log("err", err);
              }
            );
          } else {
            const selectedOptionHaveValue =
              selectedOption != null &&
              selectedOption !== "All trials" &&
              selectedOption !== "No trials"
                ? selectedOption
                : null;
            if (selectedOption !== "All trials" && selectedOption !== "No trials") {
              viewCollectionDetailsAPI(
                selectedOptionHaveValue,
                (data) => {
                  viewCollectionData(data);
                },
                (err) => {
                  setLoading(false);
                  setMapLoading(false);
                  setMeasurementLoaded(false);
                  setDateLoader(false);
                  console.log("err", err);
                }
              );
            } else {
              setMeasurementLoaded(false);
              setRatingDescriptionsLoaded(false);
            }
          }
          edcGetAccessToken((res) => {
            if ((trial || selectedOption) && res.id_token !== "") {
              setCropData([]);
              setPestData([]);
              setTrialGeometry({});
              getAllTrialSummaryData(
                selectedOption ? selectedOption : trial,
                res.id_token,
                (data) => {
                  const cropName = new Set(
                    data?.trialSummary?.crop?.map((item) => item?.commonName)
                  );
                  const pestName = new Set(
                    data?.trialSummary?.pest?.map((item) => item?.scientificName)
                  );
                  setCropData(Array.from(cropName));
                  setPestData(Array.from(pestName));
                  setLoading(false);
                  setMapLoading(false);
                  firstPlotLatLng = {
                    lat: data?.trialSummary?.generalTrial?.latitude,
                    lng: data?.trialSummary?.generalTrial?.longitude
                  };
                  setPlanDataLoaded(false);
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                  setMapLoading(false);
                  setPlanDataLoaded(false);
                }
              );
              getAllFilterData(
                {
                  filters: [
                    {
                      comparator: "LIKE",
                      field: "id",
                      value: selectedOption
                    }
                  ]
                },
                res?.id_token,
                (data) => {
                  let geo = data?.contents[0]?.geo;
                  setTrialGeometry({
                    lat: geo?.features[0]?.geometry?.coordinates[1],
                    lng: geo?.features[0]?.geometry?.coordinates[0]
                  });
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                }
              );
            }
          });
        }
      },
      (err) => {
        setLoading(false);
        setDateLoader(false);
        setMapLoading(false);
        console.log("err", err);
      }
    );
    // }, []);
  }, [collectionId, profileClick, selectedOption, idToken]);

  useEffect(() => {
    treatmentLabelAPI(
      selectedOption,
      (data) => {
        setTreatmentLabelData(data);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [selectedOption]);

  useEffect(() => {
    if (Object.keys(collectionData).length > 0 && !mapInitiated) {
      vectorLayersLayout = [];
      generateColorMap();
      generateRasterLayers();
      generateVectorLayers();
      generateTooltips();
      initMap();
      bindCallbacks();
      const initial_trial = "All trials";
      setNewTrial(trial && trial !== "null" ? trial : initial_trial);
      setMapInitiated(true);
      const date = collectionData?.name.split(" ");
      setSelectedFlightDate(date[date.length - 1]);
      setSecondCompareSelectedFlightDate(date[date.length - 1]);
      setFirstCompareSelectedFlightDate(date[date.length - 1]);
      const activerVectorLayers = extractVectorLayerNames().filter((item) => {
        return (
          item.toLowerCase() == "Plot #".toLowerCase() ||
          item.toLowerCase() == "Treatment #".toLowerCase()
        );
      });
      RetainMapZoomOnAssessmentChange();
      setActiverVectorLayers(activerVectorLayers);
    }
    handleRasterChange(currentRaster);
    setTimeout(() => {
      detectZoomLevelApplySettings();
    }, 6000);
  }, [JSON.stringify(collectionData), rasterLayers, mapInitiated]);

  if (viewAPIData && Object.keys(viewAPIData).length > 0) {
    viewAPIData &&
      viewAPIData?.vectors?.forEach((vector) => {
        vector.features?.forEach((feature) => {
          trailArray.add(feature.properties?.trial_name);
        });
      });
  } else {
    if (selectedOption !== "All trials" && selectedOption !== "No trials") trailArray.add(trial);
  }

  let modifiedTrialArray = Array.from(trailArray).sort();
  if (viewAPIData && Object.keys(viewAPIData).length > 0) {
    modifiedTrialArray.splice(0, 0, "All trials");
    modifiedTrialArray.splice(1, 0, "No trials");
  }

  useEffect(() => {
    function handlePopstate() {
      window.location.reload();
    }
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    const handleClickOutsideFlightDates = (event) => {
      if (
        flightDatesContainerRef.current &&
        !flightDatesContainerRef.current.contains(event.target) &&
        !flightDateRef.current?.contains(event.target)
      ) {
        setFlightContainerVisible(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideFlightDates);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("mousedown", handleClickOutsideFlightDates);
    };
  }, []);
  useEffect(() => {
    const handleClickOutsideFlightDates = (event) => {
      if (
        mobileDatesContainerRef.current &&
        !mobileDatesContainerRef.current.contains(event.target) &&
        !mobileDatesRef.current?.contains(event.target)
      ) {
        setIsMobileImageFlightContainerVisible(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideFlightDates);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideFlightDates);
    };
  }, []);

  const extractVectorLayerNames = () => {
    let finalSet = new Set();

    collectionData.measurement_sets.forEach((measurements) => {
      measurements.keys.forEach((key) => {
        finalSet.add(key);
      });
    });

    let trialMeasurements = extractVectorLayerMeasurements();
    if (Object.keys(trialMeasurements).length > 0) {
      finalSet.add("Plot #");
    }

    let has_treatments = false;
    Object.keys(trialMeasurements).every((trialName) => {
      trialMeasurements[trialName].measurements.every((measurement) => {
        let properties = measurement.feature.properties;
        if (properties.treatment !== undefined) {
          has_treatments = true;
        }
        return !has_treatments;
      });
      return !has_treatments;
    });
    if (has_treatments) {
      finalSet.add("Treatment #");
    }
    return Array.from(finalSet);
  };

  const drawRasterLayer = (newLayer) => {
    // remove all other layers and add new one
    Object.keys(rasterLayers).forEach((layerKey) => {
      if (newLayer === layerKey) {
        rasterLayers[layerKey].addTo(mapLayout);
      } else {
        rasterLayers[layerKey].removeFrom(mapLayout);
      }
    });
  };

  const handleRasterChange = (raster, side = "normal") => {
    if (mapInitiated) {
      if (raster === "Visible") {
        const gsdValue = viewAPIData?.base_layer?.meta_data?.gsd;
        const gsdRestrictDecimalValue = (gsdValue * 1000).toFixed(1);
        setgsdnumber(gsdRestrictDecimalValue);
        if (zoomViewerInstance && zoomViewerInstance?.gsdElement) {
          zoomViewerInstance.gsdElement.innerHTML =
            gsdRestrictDecimalValue !== undefined && !isNaN(gsdRestrictDecimalValue)
              ? "GSD: " + gsdRestrictDecimalValue + " mm"
              : "";
        }
      } else if (raster === "Elevation") {
        const gsdValue = viewAPIData?.dem_layer?.meta_data?.gsd;
        const gsdRestrictDecimalValue = (gsdValue * 1000).toFixed(1);
        setgsdnumber(gsdRestrictDecimalValue);
        if (zoomViewerInstance && zoomViewerInstance?.gsdElement) {
          zoomViewerInstance.gsdElement.innerHTML =
            gsdRestrictDecimalValue !== undefined && !isNaN(gsdRestrictDecimalValue)
              ? "GSD: " + gsdRestrictDecimalValue + " mm"
              : "";
        }
      } else {
        const gsdValue = viewAPIData?.ndvi_layer?.meta_data?.gsd;
        const gsdRestrictDecimalValue = (gsdValue * 1000).toFixed(1);
        setgsdnumber(gsdRestrictDecimalValue);
        if (zoomViewerInstance && zoomViewerInstance?.gsdElement) {
          zoomViewerInstance.gsdElement.innerHTML =
            gsdRestrictDecimalValue !== undefined && !isNaN(gsdRestrictDecimalValue)
              ? "GSD: " + gsdRestrictDecimalValue + " mm"
              : "";
        }
      }
    }
    if (side === "right") {
      setBaseOverlayComparePlot2(raster);
      drawRasterLayer(raster);
    } else if (side === "left") {
      setBaseOverlayComparePlot1(raster);
      drawRasterLayer(raster);
    } else {
      setCurrentRaster(raster);
      drawRasterLayer(raster);
    }
  };

  useEffect(() => {
    if (activeOverlaysFromUrl && !mapLoading && !measurementSetLoaded) {
      overlayChanged(activeOverlays);
    }
  }, [activeOverlaysFromUrl, mapLoading, measurementSetLoaded]);

  useEffect(() => {
    activeOverlaysConst = activeOverlays;
  }, [activeOverlays]);

  const overlayChecklist = (event, side = "") => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (side === "left") {
        setActiveOverlaysCompareLeft([...activeOverlaysCompareLeft, event.target.value]);
        overlayChanged([...activeOverlaysCompareLeft, event.target.value]);
      } else if (side === "right") {
        setActiveOverlaysCompareRight([...activeOverlaysCompareRight, event.target.value]);
        overlayChanged([...activeOverlaysCompareRight, event.target.value]);
      } else {
        setActiveOverlays([...activeOverlays, event.target.value]);
        overlayChanged([...activeOverlays, event.target.value]);
      }
    } else {
      if (side === "left") {
        const updatedActiveOverlays = activeOverlaysCompareLeft?.filter(
          (name) => name !== event.target.value
        );
        setActiveOverlaysCompareLeft(updatedActiveOverlays);
        overlayChanged(updatedActiveOverlays);
      } else if (side === "right") {
        const updatedActiveOverlays = activeOverlaysCompareRight?.filter(
          (name) => name !== event.target.value
        );
        setActiveOverlaysCompareRight(updatedActiveOverlays);
        overlayChanged(updatedActiveOverlays);
      } else {
        const updatedActiveOverlays = activeOverlays?.filter((name) => name !== event.target.value);
        setActiveOverlays(updatedActiveOverlays);
        overlayChanged(updatedActiveOverlays);
      }
    }
  };
  const overlayChanged = (overlays) => {
    Object.keys(selectedOverlaysList).forEach((layerKey) => {
      if (overlays.includes(layerKey)) {
        selectedOverlaysList[layerKey].addTo(mapLayout);
      } else {
        selectedOverlaysList[layerKey].removeFrom(mapLayout);
      }
    });
  };
  const ShowFullMap = () => {
    mapState.setBearing(0);

    if (trialType === "manual") {
      let center =
        firstPlotLatLng?.lat && firstPlotLatLng?.lng ? firstPlotLatLng : { lat: 0, lng: 0 };
      mapLayout.setView([center.lat, center?.lng], 19);
    } else {
      mapState.fitBounds(extractBounds());
    }
  };
  const RetainMapZoomOnAssessmentChange = () => {
    if (!zeroDegreeMapRotation) {
      mapLayout.setBearing(0);
    }
    if (trialType === "manual") {
      let center =
        firstPlotLatLng?.lat && firstPlotLatLng?.lng ? firstPlotLatLng : { lat: 0, lng: 0 };
      mapLayout.setView([center.lat, center?.lng], currentZoom);
    } else {
      mapLayout.setZoom(currentZoom);
    }
  };

  useEffect(() => {
    if (selectedBoundingBoxesFromUrl && !mapLoading && !measurementSetLoaded) {
      let overlayVectorLayers = [];
      let tempgeojson = null;
      for (let key in overlayVectors?.features) {
        overlayVectorLayers.push(overlayVectors?.features[key]);
      }
      tempgeojson = L.geoJSON(overlayVectorLayers, {
        color: "#FF0000",
        fill: true,
        fillOpacity: 0
      }).addTo(mapLayout);
      setgeojson(tempgeojson);
    }
  }, [selectedBoundingBoxesFromUrl, mapLoading, measurementSetLoaded]);

  const boundingBoxes = (event, side = "") => {
    let overlayVectorLayers = [];
    let tempgeojson = null;
    if (event.target.checked) {
      for (let key in overlayVectors.features) {
        overlayVectorLayers.push(overlayVectors.features[key]);
      }
      tempgeojson = L.geoJSON(overlayVectorLayers, {
        color: "#FF0000",
        fill: true,
        fillOpacity: 0
      }).addTo(mapLayout);
      setgeojson(tempgeojson);
      if (side === "left") {
        setBoundingBoxCompareLeft(true);
        isBoundingBoxComapareLeft = true;
      } else if (side === "right") {
        setBoundingBoxCompareRight(true);
        isBoundingBoxComapareRight = true;
      } else {
        setSelectedBoundingBoxes(true);
        isBoundingBoxSelected = true;
      }
    } else {
      if (side === "left") {
        mapLayout.removeLayer(geojson);
        setBoundingBoxCompareLeft(false);
        isBoundingBoxComapareLeft = false;
      } else if (side === "right") {
        mapLayout.removeLayer(geojson);
        setBoundingBoxCompareRight(false);
        isBoundingBoxComapareRight = false;
      } else {
        mapLayout.removeLayer(geojson);
        setSelectedBoundingBoxes(true);
        isBoundingBoxSelected = true;
      }
    }
  };
  const handleToggle = () => {
    setTrialOpen(!trialOpen);
    setAssessmentContainerVisible(false);
    // setTreatmentLabel(true);
    setBaseOverlayFlightOpen(false);
  };

  const flightDropdown = () => {
    setFlightContainerVisible(!flightContainerVisible);
  };

  const handleExpand = () => {
    setExpandIcon(!expandIcon);
  };

  useEffect(() => {
    if (!mapLoading && !measurementSetLoaded && !dateLoader) {
      setSelectedVectorsFromUrl?.split(",").map((e) => {
        plotVectorChanged({ target: { checked: true, value: e } });
      });
    }
  }, [setSelectedVectorsFromUrl, mapLoading, measurementSetLoaded, dateLoader]);
  const plotVectorChanged = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const plotTreatmentCount = parseInt(window.plotTreatmentCount);
      window.plotTreatmentCount = plotTreatmentCount + 1;
      // }
      const updatedSelectedVectors = [...selectedVectors, event.target.value];
      setSelectedVectors(updatedSelectedVectors);
      selectedVectorsConst = updatedSelectedVectors;
      generateManualTooltip();
      //Only Plot # and Treatment #
      let selectedVectorsOnlyPlotsInner = updatedSelectedVectors.filter((item) => {
        return item === "Plot #" || item === "Treatment #";
      });
      if (selectedVectorsOnlyPlotsInner.length > 0) {
        selectedVectorsOnlyPlotsInner = selectedVectorsOnlyPlotsInner.map((item) =>
          Object.assign({}, { vector: item }, { letter: "" })
        );
      }
      setSelectedVectorsOnlyPlots(selectedVectorsOnlyPlotsInner);
      //Without Plot # and Treatment #

      const inActiverVectorLayersInner = inActiverVectorLayers.filter((item) => {
        return item.toLowerCase() != event.target.value.toLowerCase();
      });
      setInActiverVectorLayers(inActiverVectorLayersInner);
      const temp = [];
      assessmentDateType.map((i, index) => {
        temp.push({ letter: orderArray[index], vector: i.type, date: i.date });
      });
      updateMeasurements({
        vectors: [...selectedVectorsOnlyPlotsInner, ...temp],
        trial: selectedOption,
        unsolo: true
      });
      if (selectedCombinationForHeatMap) {
        heatMapSoloMeasurement();
      }
    } else {
      const tempSelectedVectors = selectedVectors?.filter(
        (vector) => vector !== event.target.value
      );
      const plotTreatmentCount = parseInt(window.plotTreatmentCount);
      setSelectedVectors(tempSelectedVectors);
      selectedVectorsConst = tempSelectedVectors;
      generateManualTooltip();
      window.plotTreatmentCount = plotTreatmentCount - 1;
      let selectedVectorsOnlyPlotsInner = tempSelectedVectors.filter((item) => {
        return item === "Plot #" || item === "Treatment #";
      });
      if (selectedVectorsOnlyPlotsInner.length > 0) {
        selectedVectorsOnlyPlotsInner = selectedVectorsOnlyPlotsInner.map((item) =>
          Object.assign({}, { vector: item }, { letter: "" })
        );
      }
      setSelectedVectorsOnlyPlots(selectedVectorsOnlyPlotsInner);
      const temp = [];
      assessmentDateType.map((i, index) => {
        temp.push({ letter: orderArray[index], vector: i.type, date: i.date });
      });
      updateMeasurements({
        vectors: [...selectedVectorsOnlyPlotsInner, ...temp],
        trial: selectedOption,
        unsolo: true
      });
      if (selectedCombinationForHeatMap) {
        heatMapSoloMeasurement();
      }
    }
  };

  const handleFeedback = () => {
    setIsShowFeedback(!isShowFeedback);
  };
  const handleFeedbackClose = () => {
    setIsShowThankYouModel(true);
    setIsShowFeedback(false);
  };
  const handleCloseFeedback = () => {
    setIsShowFeedback(false);
  };
  const handleClose = () => {
    setIsShowThankYouModel(false);
  };

  const handleRatingDescription = () => {
    setIsAssessmentDropdown(!isAssessmentDropdown);
    setIsShowRatingdescription(!isShowRatingdescription);
  };

  const handleRatingDesktopDescription = () => {
    setIsAssessmentDropdown(false);
    setIsShowRatingdescription(!isShowRatingdescription);
  };

  const handleCloseRatingDescription = () => {
    setIsAssessmentDropdown(false);
    setIsShowRatingdescription(false);
  };

  const getDates = flightDates && Object.entries(flightDates);
  let droneFlightDates = [];
  getDates &&
    getDates?.map((text) => {
      droneFlightDates.push({ x: text[0], y: "Drone Flight" });
    });

  const properData = assementRes.find((item) => {
    const splitDate = item?.assessmentdate.split(/-(?=[^-]+$)/);
    const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : item?.assessmentdate;
    const splitDate1 = assessmentDateType[0]?.date.split(/-(?=[^-]+$)/);
    const dateForIndex1 = splitDate1?.[1]?.includes(":")
      ? splitDate1[0]
      : assessmentDateType[0]?.date;
    return (
      dateForIndex === dateForIndex1 &&
      item.assessmentType === assessmentDateType[0]?.assessmentType
    );
  });

  const properData1 = assementRes.find((item) => {
    const splitDate = item?.assessmentdate.split(/-(?=[^-]+$)/);
    const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : item?.assessmentdate;
    const splitDate1 = assessmentDateType[1]?.date.split(/-(?=[^-]+$)/);
    const dateForIndex1 = splitDate1?.[1]?.includes(":")
      ? splitDate1[0]
      : assessmentDateType[1]?.date;
    return (
      dateForIndex === dateForIndex1 &&
      item.assessmentType === assessmentDateType[1]?.assessmentType
    );
  });

  const findDataWithAsseesmentType1 = (name) => {
    for (const item in properData?.categories) {
      const result = properData?.categories[item].find((obj) => obj?.fullName === name);
      if (result) {
        return result;
      }
    }
    return null;
  };

  const findDataWithAsseesmentType2 = (name) => {
    for (const item in properData1?.categories) {
      const result = properData1?.categories[item].find((obj) => obj?.fullName === name);
      if (result) {
        return result;
      }
    }
    return null;
  };

  const trailId = viewAPIData?.measurement_sets?.filter((item) => item?.name === selectedOption);

  const particularCropObject =
    properData?.assessmentType === "manual" &&
    findDataWithAsseesmentType1(assessmentDateType[0]?.type);

  const particularCropObject2 =
    properData1?.assessmentType === "manual" &&
    findDataWithAsseesmentType2(assessmentDateType[1]?.type);

  const splitDate = properData?.assessmentdate?.split(/-(?=[^-]+$)/);
  const dateForIndex = splitDate?.[1]?.includes(":") ? splitDate[0] : properData?.assessmentdate;

  const splitDate2 = properData1?.assessmentdate?.split(/-(?=[^-]+$)/);
  const dateForIndex2 = splitDate2?.[1]?.includes(":")
    ? splitDate2[0]
    : properData1?.assessmentdate;

  const handleChartRadio = (e) => {
    setChartsValue(e.target.value);
  };

  let subPlotRepList =
    subPlots?.length > 0 && trialSubPlot?.is_subplot_exist === true ? subPlots : replicants;
  let plotOrRep =
    subPlots?.length > 0 && trialSubPlot?.is_subplot_exist === true ? "Sub Plots" : "Reps";

  const barChartPayloadForManual = {
    assessmentType: "Manual",
    collection_id: properData?.assessmentID,
    trial_id: trailId ? trailId[0]?.trial_id : "",
    subplot_id:
      subPlots?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    replicate_id:
      replicants?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    trial_name: selectedOption,
    collection_date: dateForIndex,
    ratingCode: particularCropObject?.ratingCode ? particularCropObject?.ratingCode : "",
    pestScientificName: particularCropObject?.pestScientificName
      ? particularCropObject?.pestScientificName
      : "",
    assessment: assessmentDateType[0]?.type,
    cropScientificName: particularCropObject?.cropScientificName
      ? particularCropObject?.cropScientificName
      : "",
    cropVariety: particularCropObject?.cropVariety ? particularCropObject?.cropVariety : null
  };

  const barChartPayloadForManual2 = {
    assessmentType: "Manual",
    collection_id: properData1?.assessmentID,
    trial_id: trailId ? trailId[0]?.trial_id : "",
    subplot_id:
      subPlots?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    replicate_id:
      replicants?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    trial_name: selectedOption,
    collection_date: dateForIndex2,
    ratingCode: particularCropObject2?.ratingCode ? particularCropObject2?.ratingCode : "",
    pestScientificName: particularCropObject2?.pestScientificName
      ? particularCropObject2?.pestScientificName
      : "",
    assessment: assessmentDateType[1]?.type,
    cropScientificName: particularCropObject2?.cropScientificName
      ? particularCropObject2?.cropScientificName
      : "",
    cropVariety: particularCropObject2?.cropVariety ? particularCropObject2?.cropVariety : null
  };
  const treatmentbarChartNumber = trailId?.map((item) => item?.trial_id);

  const barChartPayloadForDrone = {
    assessmentType: "Drone",

    collection_id: properData?.assessmentID,
    trial_id: trailId ? trailId[0]?.trial_id : "",
    subplot_id:
      subPlots?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    replicate_id:
      replicants?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    trial_name: selectedOption,
    collection_date: properData?.assessmentdate,
    ratingCode: "",
    pestScientificName: "",
    assessment: assessmentDateType[0]?.type,
    cropScientificName: "",
    cropVariety: null
  };

  const barChartPayloadForDrone2 = {
    assessmentType: "Drone",

    collection_id: properData1?.assessmentID,
    trial_id: trailId ? trailId[0]?.trial_id : "",
    subplot_id:
      subPlots?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    replicate_id:
      replicants?.length > 0
        ? selectedSubPlotOptions?.length > 0
          ? selectedSubPlotOptions?.filter((item) => item != "All")
          : []
        : [],
    collection_date: properData1?.assessmentdate,
    ratingCode: "",
    pestScientificName: "",
    assessment: assessmentDateType[1]?.type,
    cropScientificName: "",
    cropVariety: null
  };
  const payload =
    properData?.assessmentType === "manual" ? barChartPayloadForManual : barChartPayloadForDrone;

  const payload2 =
    properData1?.assessmentType === "manual" ? barChartPayloadForManual2 : barChartPayloadForDrone2;

  useEffect(() => {
    if (navbarButtons.length === 1) {
      setEnvExpand(true);
    } else if (navbarButtons.length > 1) {
      setEnvExpand(false);
    } else {
      setNavbarButtons(["Drone"]);
      handleTopButtonClick("Drone");
    }
  }, [navbarButtons]);

  useEffect(() => {
    if (assessmentDateType?.length === 0) {
      setTabletNavbarButton("Drone");
      setNavbarButtons(
        navbarButtonsUrl
          ? JSON.parse(navbarButtonsUrl)
          : navbarButtons?.filter((item) => item !== "Charts")
      );
      unsortedButtons = navbarButtonsUrl
        ? JSON.parse(navbarButtonsUrl)
        : navbarButtons?.filter((item) => item !== "Charts");
    }
  }, [assessmentDateType]);

  useEffect(() => {
    setNoAssesment(false);
    setNoMean(false);

    if (trial?.length === 0) {
      setError(true);
      setNoAssesment(true);
    }
    setError(false);
    if (
      (showTreatment || showTreatmentMT) &&
      assessmentDateType[0] !== undefined &&
      !mapLoading &&
      !measurementSetLoaded &&
      !dateLoader &&
      showTreatmentUrl !== undefined &&
      loading === false &&
      selectedSubPlotOptions &&
      selectedSubPlotOptions.length !== 0
      // ||
      // ((showTreatment === true || showTreatmentMT === true) &&
      //   selectedSubPlotOptions &&
      //   (subPlots?.length > 0 || replicants?.length > 0) &&
      //   properData1?.assessmentID)
    ) {
      setTreatmentDataLoaded(true);
      loginSyt(idToken, (res) => {
        if (res === 200) {
          newBarChartApi(
            payload,
            (res) => {
              if (
                res.length <= 0 ||
                res?.meanValues?.length === 0 ||
                res?.treatmentSummary?.length === 0
              ) {
                setNoAssesment(true);
                setNoMean(true);
                setTreatmentDataLoaded(false);
              } else {
                setNoAssesment(false);
                setBarData(res);
                setTreatmentDataLoaded(false);
              }
            },
            (err) => {
              setTreatmentDataLoaded(false);
              if (err === "Request failed with status code 403") {
                setError(true);
                setTreatmentDataLoaded(false);
              }
            }
          );
        }
      });
    } else {
      setTreatmentDataLoaded(false);
    }
  }, [
    assessmentDateType[0]?.type,
    properData?.assessmentType,
    selectedOption,
    properData?.assessmentID,
    idToken,
    properData?.assessmentdate,
    dateForIndex,
    showTreatmentMT,
    showTreatment,
    selectedSubPlotOptions,
    // replicants,
    // subPlots,
    mapLoading,
    measurementSetLoaded,
    dateLoader
    // trailIdUrl,
    // treatmentDataLoaded
  ]);

  useEffect(() => {
    setNoAssesment(false);

    setNoMean(false);

    if (trial?.length === 0) {
      setError(true);
      setNoAssesment(true);
    }
    setError(false);
    if (
      (showTime === true || showTimeMT === true || timeChartUrl !== undefined) &&
      assessmentDateType[0] !== undefined &&
      !mapLoading &&
      !measurementSetLoaded &&
      !dateLoader &&
      selectedSubPlotOptions &&
      selectedSubPlotOptions.length !== 0

      // ||
      // ((showTime === true || showTimeMT === true) &&
      //   selectedSubPlotOptions && (subPlots?.length > 0 || replicants?.length > 0))
    ) {
      setFightDataLoaded(true);
      loginSyt(idToken, (res) => {
        if (res === 200) {
          newFlightChartApi(
            payload,
            (res) => {
              if (res.length <= 0) {
                setNoAssesment(true);
                setFightDataLoaded(false);
              } else {
                setNoAssesment(false);
                setFlightData(res);
                setFightDataLoaded(false);
              }
            },
            (err) => {
              if (err === "Request failed with status code 403") {
                setError(true);
                setFightDataLoaded(false);
              }
            }
          );
        } else {
          // setFightDataLoaded(false);
          const { instance } = useMsal();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/app",
            mainWindowRedirectUri: "/app" // redirects the top level app after logout
          });
          removeCookie("msal.interaction.status");
        }
      });
    } else {
      setFightDataLoaded(false);
    }
  }, [
    assessmentDateType[0]?.type,
    properData?.assessmentType,
    selectedOption,
    properData?.assessmentID,
    idToken,
    properData?.assessmentdate,
    dateForIndex,
    showTime,
    showTimeMT,
    selectedSubPlotOptions,
    // replicants,
    // subPlots,
    mapLoading,
    measurementSetLoaded,
    dateLoader,
    timeChartUrl
  ]);

  useEffect(() => {
    if (!trialNotSelected) {
      const filter = {
        filters: [
          {
            field: "trialId",
            value: selectedOption,
            comparator: "EQ",
            type: "STRING"
          }
        ]
      };
      getMobileImagesDataByTrial(
        attachmentAPIIdToken,
        JSON.stringify(filter),
        (res) => {
          const datesAndPlotsMapping = res?.reduce((plotIdsByDate, item) => {
            if (plotIdsByDate[item.meta?.ratingdate]) {
              if (plotIdsByDate[item.meta?.ratingdate][item.meta?.plotid]) {
                plotIdsByDate[item.meta?.ratingdate][item.meta?.plotid].push(item.id);
              } else {
                plotIdsByDate[item.meta?.ratingdate][item.meta?.plotid] = [item.id];
              }
            } else {
              plotIdsByDate[item.meta?.ratingdate] = {};
              plotIdsByDate[item.meta?.ratingdate][item.meta?.plotid] = [item.id];
            }
            return plotIdsByDate;
          }, {});
          setDatesAndPlotIdsMapping(datesAndPlotsMapping);
          const ratingDates = Object.keys(datesAndPlotsMapping);
          setRatingDates(ratingDates);
          if (ratingDates?.length > 0) {
            setSelectedRatingDate(ratingDates[ratingDates.length - 1]);
            setComparePlotMobileDate1(ratingDates[ratingDates.length - 1]);
            setComparePlotMobileDate2(ratingDates[ratingDates.length - 1]);
          }
        },
        (err) => {
          console.log("Attachment data error", err);
        }
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedRatingDate && datesAndPlotIdsMapping) {
      const plotIdsForSelectedDate = Object.keys(datesAndPlotIdsMapping[selectedRatingDate]);
      const filteredPlots = selectedMobileImagePlots?.filter((item) =>
        plotIdsForSelectedDate?.includes(item)
      );
      setSelectedMobileImagePlots(filteredPlots);
      filteredPlots.forEach((plotId) => getMobileImagesByPlotId(plotId));
    }
  }, [selectedRatingDate]);

  useEffect(() => {
    setNoAssesment(false);
    setNoAssesment2(false);
    setNoMean(false);
    setNoMean2(false);
    if (trial?.length === 0) {
      setError(true);
      setNoAssesment(true);
      setNoAssesment2(true);
    }
    setError(false);
    if (
      (showCompare === true || showCompareMT === true || showCompareUrl) &&
      assessmentDateType[0] !== undefined &&
      assessmentDateType[1] !== undefined &&
      !mapLoading &&
      !measurementSetLoaded &&
      !dateLoader &&
      selectedSubPlotOptions &&
      selectedSubPlotOptions.length !== 0
      // showCompareUrl !== undefined &&
      // loading === false
      // ||
      // ((showCompare === true || showCompareMT === true) &&
      //   selectedSubPlotOptions &&
      //   (subPlots?.length > 0 || replicants?.length > 0))
    ) {
      setCompareData1Loaded(true);
      setCompareData2Loaded(true);
      loginSyt(idToken, (res) => {
        if (res === 200) {
          newBarChartApi(
            payload,
            (res) => {
              if (
                res.length <= 0 ||
                res?.meanValues?.length === 0 ||
                res?.treatmentSummary?.length === 0
              ) {
                setNoAssesment(true);
                setNoMean(true);
                setCompareData1Loaded(false);
              } else {
                setNoAssesment(false);
                setBarData(res);
                setCompareData1Loaded(false);
              }
            },
            (err) => {
              if (err === "Request failed with status code 403") {
                setError(true);
                setCompareData1Loaded(false);
              }
            }
          );

          newBarChartApi(
            payload2,
            (res) => {
              if (
                res.length <= 0 ||
                res?.meanValues.length === 0 ||
                res?.treatmentSummary?.length === 0
              ) {
                setNoAssesment2(true);
                setNoMean2(true);
                setCompareData2Loaded(false);
              } else {
                setNoAssesment2(false);
                setBarData2(res);
                setCompareData2Loaded(true);
              }
            },

            (err) => {
              if (err === "Request failed with status code 403") {
                setError(true);
                setCompareData2Loaded(false);
              }
            }
          );
        } else {
          // setCompareData1Loaded(false);
          // setCompareData2Loaded(false);
          const { instance } = useMsal();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/app",
            mainWindowRedirectUri: "/app" // redirects the top level app after logout
          });
          removeCookie("msal.interaction.status");
        }
      });
    } else {
      setCompareData1Loaded(false);
      setCompareData2Loaded(false);
    }
  }, [
    assessmentDateType[0]?.type,
    assessmentDateType[1]?.type,
    properData?.assessmentType,
    properData1?.assessmentType,
    selectedOption,
    properData?.assessmentID,
    properData1?.assessmentID,
    idToken,
    properData?.assessmentdate,
    properData1?.assessmentdate,
    dateForIndex,
    dateForIndex2,
    showCompare,
    showCompareMT,
    selectedSubPlotOptions,
    // replicants,
    // subPlots,
    trailIdUrl,
    showCompareUrl,
    loading,
    compareCorrelationUrl,
    compareChartsValue,
    mapLoading,
    measurementSetLoaded,
    dateLoader
    // compareData1Loaded,
    // compareData2Loaded
  ]);
  const dropdownMobile = isMobile ? "trialDropdownMobile" : isTablet ? "trialDropdownTablet" : " ";
  const commonDropdown = (trialOpen, selectedOption, trailArray, onSelect, onToggle) => {
    return (
      <Dropdown
        show={trialOpen}
        onToggle={onToggle}
        style={{ backgroundColor: "transparent", display: !isMobile ? "grid" : "flex" }}>
        <Dropdown.Toggle
          data-testid="trial-dropdown"
          className={
            (isMobile || isTablet) && trialOpen
              ? "mobileTrial"
              : "removeBackTrial" + " dropdown-toggle d-flex"
          }
          variant="light">
          <div style={{ paddingTop: "8px" }}>
            <span className="trial-dropdown-label">
              Trial
              <ChevronDown
                style={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "80px"
                }}
              />
            </span>
            <div style={{ textAlign: "left", lineHeight: "0.1px" }}>
              <span className="font-size-extra-small deep-cove-color" data-testid="trial-value">
                {selectedOption}
              </span>
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu col-xs-12 className={dropdownMobile} data-testid="trial-dropdown-menu">
          {trailArray.map((option) =>
            trialType == "manual" && (option === "All trials" || option === "No trials") ? null : (
              <Dropdown.Item
                key={option}
                eventKey={option}
                onClick={() => trailArray?.length > 1 && onSelect(option)}
                className="d-flex align-items-center"
                active={selectedOption === option}>
                <input
                  type="radio"
                  // eslint-disable-next-line quotes
                  className={`custom-radio ${selectedOption == option ? "selected1 checked" : ""}`}
                  name="options1"
                  checked={selectedOption === option}
                  // readOnly
                />
                <span className="font-size-small deep-cove-color" style={{ marginLeft: "10px" }}>
                  {option}
                </span>
                {option !== "All trials" && option !== "No trials" && (
                  <span
                    className="color-square"
                    style={{
                      background:
                        trialType === "manual" ? "#1b74df" : colorMapConst?.[option] || "#fff"
                    }}></span>
                )}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleBaseLayer = () => {
    setBaseVisible(!baseVisible);
    setOverlayVisible(false);
    setTrialInfoVisible(false);
    // setFlightMobileOpen(false);
  };

  const handleOverLayLayer = () => {
    setOverlayVisible(!overlayVisible);
    setBaseVisible(false);
    setTrialInfoVisible(false);
    // setFlightMobileOpen(false);
  };

  const handleTrialInfoLayer = () => {
    setOverlayVisible(false);
    setBaseVisible(false);
    setTrialInfoVisible(!trialInfoVisible);
    // setFlightMobileOpen(false);
  };

  const handleTrialSummary = () => {
    setTrialSummary(!trialSummary);
  };

  useEffect(() => {
    const temp = document.querySelector(".measurement-icon");
    const measIcon = document.querySelector(".leaflet-control-measure.leaflet-bar.leaflet-control");
    const position = temp?.getBoundingClientRect();
    if (temp && measIcon) {
      measIcon.style.left = "45px";
      measIcon.style.top = "unset";
      measIcon.style.bottom = `${window.innerHeight - position.bottom - 10}px`;
      measIcon.style.width = "fit-content";
    }
  });

  useEffect(() => {
    const temp =
      customMobilePortrait || customMobileLandscape
        ? document.querySelector(".mobile-tools-container")
        : document.querySelector(".web-tools-container");
    const rotateIcon = document.querySelector(
      ".new-viewer-page .leaflet-control-rotate.leaflet-bar.leaflet-control"
    );
    const position = temp?.getBoundingClientRect();
    if (temp && rotateIcon) {
      rotateIcon.style.top =
        customMobilePortrait || customMobileLandscape
          ? `${position.top - 41}px`
          : `${position.top - 49}px`;
      rotateIcon.style.bottom = "unset";
    }
  });
  useEffect(() => {
    const handleScrolling = () => {
      const temp =
        customMobilePortrait || customMobileLandscape
          ? document.querySelector(".mobile-tools-container")
          : document.querySelector(".web-tools-container");
      const rotateIcon = document.querySelector(
        ".new-viewer-page .leaflet-control-rotate.leaflet-bar.leaflet-control"
      );
      const position = temp?.getBoundingClientRect();
      if (temp && rotateIcon) {
        rotateIcon.style.top =
          customMobilePortrait || customMobileLandscape
            ? `${position.top - 41}px`
            : `${position.top - 49}px`;
        rotateIcon.style.bottom = "unset";
      }
    };
    window.addEventListener("scroll", handleScrolling);
    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, []);
  useEffect(() => {
    const temp =
      customMobilePortrait || customMobileLandscape
        ? document.querySelector(".mobile-tools-container")
        : document.querySelector(".web-tools-container");
    const rotateIcon = document.querySelector(
      ".new-viewer-page .leaflet-control-rotate.leaflet-bar.leaflet-control"
    );
    const position = temp?.getBoundingClientRect();
    if (temp && rotateIcon) {
      rotateIcon.style.top =
        customMobilePortrait || customMobileLandscape
          ? `${position.top - 41}px`
          : `${position.top - 49}px`;
      rotateIcon.style.bottom = "unset";
    }
  });

  let closeModal = false;
  // let openModal = true;
  useEffect(() => {
    setTimeout(() => {
      const temp = document.querySelector(".modal-dialog .scrollbox");
      if (temp) {
        temp.addEventListener("wheel", (e) => {
          e.preventDefault();
        });
      }
    }, 500);
  }, [barExpand, showTreatment, showTime, showCompare, barData, barData2, flightData]);

  function getCentroid(coords) {
    let latSum = 0,
      lonSum = 0;
    coords.forEach(function (coord) {
      latSum += coord[0];
      lonSum += coord[1];
    });

    let latCenter = latSum / coords.length;
    let lonCenter = lonSum / coords.length;

    return [latCenter, lonCenter];
  }

  useEffect(() => {
    if (vectorLayersLayout[selectedOption] && trialType !== "manual")
      Object.keys(vectorLayersLayout[selectedOption]).forEach((plotId) => {
        if (selectedPlots.includes(plotId.toString())) {
          vectorLayersLayout[selectedOption][plotId]
            .setStyle({
              color: "#FF00FF"
            })
            .bringToFront();
        } else {
          vectorLayersLayout[selectedOption][plotId].setStyle({
            color: colorMapConst[selectedOption]
          });
        }
      });
  }, [selectedPlots, measurementSetLoaded, navbarButtons]);
  useEffect(() => {
    if (!navbarButtons.includes("Plot Images")) {
      setSelectedPlots([]);
      selectedPlotsConst = [];
    }
    if (!navbarButtons.includes("Compare Plots")) {
      setComparePlotExpanded1();
      setComparePlotExpanded2();
      setComparePlotReps1();
      setComparePlotReps2();
      setComparePlotTreatment1();
      setComparePlotTreatment2();
      setComparePlotsImages1([]);
      setComparePlotsImages2([]);
    }
  }, [navbarButtons]);
  const highlightPlots = (type, value) => {
    if (
      !highlightedPlotId ||
      highlightedPlotId?.value != value ||
      highlightedPlotId?.type != type
    ) {
      if (trialType === "manual") {
        drawnItemsConst.eachLayer((layer) => {
          layer.setStyle({
            color: "#3388ff"
          });
        });
      } else {
        Object.keys(vectorLayersLayout[selectedOption]).forEach((plotId) => {
          vectorLayersLayout[selectedOption][plotId].setStyle({
            color: colorMapConst[selectedOption]
          });
        });
      }
      if (type === "scatter") {
        let layer;
        if (trialType === "manual") {
          let filteredData = plotDetailsConst.find((item) => {
            return item.plotNumber == value;
          });
          if (filteredData) {
            layer = drawnItemsConst.getLayer(filteredData?.leafletId);
            mapLayout.panTo(layer.getCenter());
          }
        } else {
          layer = vectorLayersLayout[selectedOption][value];
          const plotCoordinates = dronePlotDetails[selectedOption].find(
            (plot) => plot.plotNumber == value
          ).plotCoordinates;
          const cords = [
            [plotCoordinates[0][1], plotCoordinates[0][0]],
            [plotCoordinates[1][1], plotCoordinates[1][0]],
            [plotCoordinates[2][1], plotCoordinates[2][0]],
            [plotCoordinates[3][1], plotCoordinates[3][0]]
          ];
          const center = getCentroid(cords);
          mapLayout.panTo(center);
        }
        layer.setStyle({
          color: "#FF00FF"
        });
        layer.bringToFront();
      } else if (type === "bar") {
        (trialType === "manual" ? plotDetailsConst : dronePlotDetails[selectedOption])
          .filter((plot) => plot.treatmentNumber == value)
          .map((plot, index) => {
            let layer;
            if (trialType === "manual") {
              layer = drawnItemsConst.getLayer(plot?.leafletId);
              if (index === 0) {
                mapLayout.setView(layer.getCenter(), defaultZoom);
              }
            } else {
              layer = vectorLayersLayout[selectedOption][plot?.plotId];
              if (index === 0) {
                const plotCoordinates = plot.plotCoordinates;
                const cords = [
                  [plotCoordinates[0][1], plotCoordinates[0][0]],
                  [plotCoordinates[1][1], plotCoordinates[1][0]],
                  [plotCoordinates[2][1], plotCoordinates[2][0]],
                  [plotCoordinates[3][1], plotCoordinates[3][0]]
                ];
                const center = getCentroid(cords);
                mapLayout.setView(center, defaultZoom);
              }
            }
            layer.setStyle({
              color: "#FF00FF"
            });
            layer.bringToFront();
          });
      }
      setHighlightedPlotId({ type, value });
    } else if (highlightedPlotId?.value == value && highlightedPlotId?.type == type) {
      if (trialType === "manual") {
        drawnItemsConst.eachLayer((layer) => {
          layer.setStyle({
            color: "#3388ff"
          });
        });
      } else {
        Object.keys(vectorLayersLayout[selectedOption]).forEach((plotId) => {
          vectorLayersLayout[selectedOption][plotId].setStyle({
            color: colorMapConst[selectedOption]
          });
        });
      }
      setHighlightedPlotId(undefined);
    }
  };
  const treatmentLabelTable = () => {
    const headings = {
      number: "Treatment Number",
      type: "Type",
      name: "Product Name",
      formType: "Form Type",
      ratingUnit: "Rating Unit",
      applicationMethod: "Application Method",
      applicationTiming: "Application Timing",
      applicationPlacement: "Application Placement",
      activeRates: "Active Rates"
    };
    const treatmentInfo = treatmentLabelData?.treatment_info?.filter((item) => item !== null);
    const columns = Object.keys(headings)?.filter(
      (key) =>
        (key !== "number" &&
          key !== "type" &&
          key !== "name" &&
          key !== "formType" &&
          key !== "ratingUnit" &&
          key !== "applicationMethod" &&
          key !== "applicationTiming" &&
          key !== "applicationPlacement" &&
          key !== "activeRates") ||
        treatmentInfo?.some((item) => item[key] !== null)
    );
    const sortedData = treatmentInfo?.sort((a, b) => a.number - b.number);

    return (
      <>
        {isMobileOnly ? (
          <div
            className="w-100 d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <div>
              <ChevronLeft
                onClick={() => {
                  setTabletNavbarButton("Drone");
                  setHamburgerOpen(true);
                }}
                className="ml-2"
                width={16}
                height={16}
              />{" "}
              <span className="pl-2 font-size-large font-weight-700">Treatment Labels</span>
            </div>
            <div>
              <X
                onClick={() => {
                  setTabletNavbarButton("Drone");
                }}
                width={16}
                height={16}
                style={{ height: "24px", width: "24px" }}
                className="mr-2"
              />
            </div>
          </div>
        ) : (
          <div
            className="panel-title-bar d-flex align-items-center font-size-small font-weight-700 deep-cove-color"
            role="button"
            style={{
              padding: customTabletDimensions()
                ? "20px 0px 19px 14px"
                : navbarButtons.length > 1 && navbarButtons.includes("Treatment Labels")
                ? "10px"
                : "10px 30px"
            }}>
            <span className="text-left mr-auto font-size-medium">Treatment Labels</span>

            <div className="d-flex align-items-center">
              {navbarButtons?.length > 1 && (
                <>
                  <span>
                    <Button
                      className="d-flex expand-button"
                      onClick={() => setTreatmentExpansion(!treatmentExpansion)}>
                      {treatmentExpansion === true ? (
                        <ArrowsAngleContract
                          color="black"
                          alignItems="center"
                          width={16}
                          height={16}
                        />
                      ) : (
                        <ArrowsAngleExpand
                          color="black"
                          alignItems="center"
                          width={16}
                          height={16}
                        />
                      )}
                    </Button>
                  </span>
                  {!treatmentExpansion && (
                    <span>
                      <X
                        onClick={() => {
                          // setTreatmentLabel(false);
                          setTreatmentExpansion(false);
                          const updatedNavbarButtons = navbarButtons.filter(
                            (name) => name !== "Treatment Labels"
                          );
                          setTimeout(() => {
                            if (mapLayout) {
                              if (typeof mapLayout.invalidateSize === "function") {
                                mapLayout.invalidateSize();
                              }
                            }
                          }, 100);
                          setNavbarButtons(updatedNavbarButtons);
                        }}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        color="#0D1A40"
                        width={24}
                        height={24}
                      />
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div
          className={
            treatmentExpansion && isDesktop
              ? "treatment-label-model-table"
              : `treatment-label-table ${
                  !(customMobileLandscape || customMobilePortrait || isDesktop) &&
                  expandContainerForTab &&
                  assessmentDateType.length > 0
                    ? "treatment-label-table-tab-with-assessments"
                    : ""
                }`
          }>
          <table>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column} className="table-headings">
                    {headings[column]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((row) => (
                <tr key={row?.number}>
                  {columns.map((column) => {
                    const rowData = row[column];
                    const cellData =
                      rowData && typeof rowData === "object" ? rowData.name : rowData || "null";
                    return (
                      <td key={column?.number} className="table-data">
                        {cellData}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const assessmentMobileIconClick = () => {
    setAssessmentMobileOpen(true);
  };

  const activityTrackingApi = (payload) => {
    recentlyActivityTracking(
      payload,
      (data) => {
        console.log("data", data);
      },
      (err) => {
        // setLoading(false);
        console.log("err", err);
      }
    );
  };

  const envTrack = (data) => {
    let envData;
    if (selectedTabButton === "Environmental") {
      envData = data;
    }
    const payload = {
      activity_type: "Feature_Click",
      description: "user clicked a tab",
      email_id: `${cookies?.userEmail}`,
      activity_details: {
        page: "/app/viewer",
        trial_name: trial,
        collection_id: collectionId,
        feature_name: selectedTabButton,
        feature_data: [
          {
            Environmental: envData
          }
        ],
        assessment_type: "",
        assessment_data: ""
      }
    };
    activityTrackingApi(payload);
  };

  const tabsChange = (data) => {
    if (data === "Drone") {
      const payload = {
        activity_type: "Feature_Click",
        description: "user clicked a tab",
        email_id: `${cookies?.userEmail}`,
        activity_details: {
          page: "/app/viewer",
          trial_name: trial,
          collection_id: collectionId,
          feature_name: "Drone",
          feature_data: [],
          assessment_type: "",
          assessment_data: ""
        }
      };
      activityTrackingApi(payload);
    }
    if (data === "Treatment Labels") {
      const payload = {
        activity_type: "Feature_Click",
        description: "user clicked a tab",
        email_id: `${cookies?.userEmail}`,
        activity_details: {
          page: "/app/viewer",
          trial_name: trial,
          collection_id: collectionId,
          feature_name: "Treatment Labels",
          feature_data: [],
          assessment_type: "",
          assessment_data: ""
        }
      };
      activityTrackingApi(payload);
    }
    if (data === "Charts") {
      setSelectedTabButton("Charts");
    } else if (data === "Environmental") {
      setSelectedTabButton("Environmental");
    }
  };
  useEffect(() => {
    let chartData;
    if (
      (selectedTabButton === "Charts" || tabletNavbarButton === "Charts") &&
      assessmentDateType?.length > 0
    ) {
      if (showTreatment || showTreatmentMT) {
        chartData = {
          chart_type: `treatment summary ${chartsValue}`,
          chart_assessment: assessmentDateType?.[0]?.type
        };
      }
      if (showTime || showTimeMT) {
        chartData = {
          chart_type: "time",
          chart_assessment: assessmentDateType?.[0]?.type
        };
      }
      if (showCompare || showCompareMT) {
        chartData = {
          chart_type: `compare ${compareChartsValue}`,
          chart_assessment: assessmentDateType?.[0]?.type
        };
      }
      const payload = {
        activity_type: "Feature_Click",
        description: "user clicked a tab",
        email_id: `${cookies?.userEmail}`,
        activity_details: {
          page: "/app/viewer",
          trial_name: trial,
          collection_id: collectionId,
          feature_name: "Charts",
          feature_data: [
            {
              Charts: chartData
            }
          ],
          assessment_type: "",
          assessment_data: ""
        }
      };
      activityTrackingApi(payload);
    }
  }, [
    selectedTabButton,
    showTreatment,
    showTime,
    showCompare,
    showTreatmentMT,
    showTimeMT,
    showCompareMT,
    chartsValue,
    compareChartsValue
  ]);

  useEffect(() => {
    if (!loading) {
      const payload = {
        activity_type: "Page_viewer",
        description: "user viewed a trial",
        email_id: `${cookies?.userEmail}`,
        activity_details: {
          page: "/app/viewer",
          trial_name: trial,
          collection_id: collectionId,
          feature_name: "Drone",
          feature_data: [],
          assessment_type: "",
          assessment_data: ""
        }
      };
      activityTrackingApi(payload);
    }
  }, [loading, trial]);

  const deepLinkUrl = useMemo(() => {
    const currentUrl = new URL(window.location.href);
    if (currentRaster) {
      currentUrl.searchParams.set("currentRaster", currentRaster);
    }
    if (activeOverlays?.length > 0) {
      currentUrl.searchParams.set("activeOverlays", activeOverlays);
    }
    if (selectedBoundingBoxes) {
      currentUrl.searchParams.set("selectedBoundingBoxes", selectedBoundingBoxes);
    }
    if (selectedVectors?.length > 0) {
      currentUrl.searchParams.set("selectedVectors", selectedVectors);
    }
    if (assessmentDateType?.length > 0) {
      currentUrl.searchParams.set("assessmentDateType", JSON.stringify(assessmentDateType));
    } else {
      currentUrl.searchParams.delete("assessmentDateType");
    }
    if (assessmentBy) {
      currentUrl.searchParams.set("dateType", assessmentBy);
    }

    if (navbarButtons?.length > 0) {
      currentUrl.searchParams.set("navbarButtons", JSON.stringify(navbarButtons));
    }
    if (chartsValue) {
      currentUrl.searchParams.set("barBox", chartsValue);
    }
    if (compareChartsValue) {
      currentUrl.searchParams.set("compareCorrelation", compareChartsValue);
    }
    if (showTime || showTreatment || showCompare) {
      currentUrl.searchParams.set("showTrailid", treatmentbarChartNumber?.[0]);
    }
    if (showTreatment) {
      currentUrl.searchParams.set(
        "showTreatment",
        treatmentbarChartNumber && treatmentbarChartNumber[0]
      );
      currentUrl.searchParams.delete("showTime");
      currentUrl.searchParams.delete("showCompare");
    }
    if (showTime) {
      currentUrl.searchParams.delete("showTreatment");
      currentUrl.searchParams.delete("showCompare");
      currentUrl.searchParams.set("showTime", showTime);
    }
    if (showCompare) {
      currentUrl.searchParams.delete("showTreatment");
      currentUrl.searchParams.delete("showTime", showTime);
      currentUrl.searchParams.set("showCompare", treatmentbarChartNumber?.[0]);
    }
    if (baseOverlayFlightOpen) {
      currentUrl.searchParams.set("baseOverlayFlight", baseOverlayFlightOpen);
    }
    if (flightContainerVisible) {
      currentUrl.searchParams.set("flightDropdown", flightContainerVisible);
    }
    if (selectedCombinationForHeatMap) {
      currentUrl.searchParams.set("selectedCombinationForHeatMap", selectedCombinationForHeatMap);
    }
    if (selectedWeatherDate) {
      currentUrl.searchParams.set("selectedWeatherDate", selectedWeatherDate);
    }
    if (selectedWeatherDevices) {
      currentUrl.searchParams.set("selectedWeatherDevices", JSON.stringify(selectedWeatherDevices));
    }
    if (selectedTrialInfoForEyeOpen) {
      currentUrl.searchParams.set("selectedTrialInfoForEyeOpen", selectedTrialInfoForEyeOpen);
    }
    if (selectedCombinationForEyeOpen) {
      currentUrl.searchParams.set("selectedCombinationForEyeOpen", selectedCombinationForEyeOpen);
    }
    if (weatherStartDate) {
      currentUrl.searchParams.set("weatherStartDate", weatherStartDate);
    }
    if (weatherEndDate) {
      currentUrl.searchParams.set("weatherEndDate", weatherEndDate);
    }
    if (weatherForwardDays) {
      currentUrl.searchParams.set("weatherForwardDays", weatherForwardDays);
    }
    if (weatherBackwardDays) {
      currentUrl.searchParams.set("weatherBackwardDays", weatherBackwardDays);
    }
    if (weatherSelectedCategory) {
      currentUrl.searchParams.set("weatherSelectedCategory", weatherSelectedCategory);
    }
    if (selectedPlots) {
      currentUrl.searchParams.set("selectedPlots", selectedPlots);
    }
    if (comparePlotsImages1) {
      currentUrl.searchParams.set("comparePlotsImages1", comparePlotsImages1);
    }
    if (comparePlotsImages2) {
      currentUrl.searchParams.set("comparePlotsImages2", comparePlotsImages2);
    }
    if (comparePlotTreatment1) {
      currentUrl.searchParams.set("comparePlotTreatment1", comparePlotTreatment1);
    }
    if (comparePlotReps1) {
      currentUrl.searchParams.set("comparePlotReps1", comparePlotReps1);
    }
    if (comparePlotTreatment2) {
      currentUrl.searchParams.set("comparePlotTreatment2", comparePlotTreatment2);
    }
    if (comparePlotReps2) {
      currentUrl.searchParams.set("comparePlotReps2", comparePlotReps2);
    }
    return currentUrl?.toString();
  }, [
    currentRaster,
    activeOverlays,
    activerVectorLayers,
    assessmentDateType,
    availableTopButtons,
    navbarButtons,
    showTreatment,
    baseOverlayFlightOpen,
    flightContainerVisible,
    selectedCombinationForHeatMap,
    isAssessmentDropdown,
    showTime,
    chartsValue,
    showCompare,
    compareChartsValue,
    assessmentBy,
    selectedWeatherDate,
    selectedWeatherDevices,
    weatherForwardDays,
    weatherBackwardDays,
    weatherSelectedCategory,
    selectedPlots,
    comparePlotsImages1,
    comparePlotsImages2,
    comparePlotTreatment1,
    comparePlotTreatment2,
    comparePlotReps1,
    comparePlotReps2,
    latestMeasurementSets?.current
  ]);
  const comparePlotsImages = (plotId) => {
    if (treatmentsChevron) {
      const result = comparePlotData
        .filter((item) => item?.treatment === plotId)
        .map((item) => item.plotid);
      setComparePlotsImages1(result);
    }
    if (firstRepsChevron) {
      const result = comparePlotData
        .filter((item) => item?.replicate_id === plotId)
        .map((item) => item.plotid);
      setComparePlotsImages1(result);
    }
  };

  const secondComparePlotsImages = (plotId) => {
    if (secondTreatmentsChevron) {
      const result = comparePlotData
        .filter((item) => item?.treatment === plotId)
        .map((item) => item.plotid);
      setComparePlotsImages2(result);
    }
    if (secondRepsChevron) {
      const result = comparePlotData
        .filter((item) => item?.replicate_id === plotId)
        .map((item) => item.plotid);
      setComparePlotsImages2(result);
    }
  };

  useEffect(() => {
    if (
      comparePlotsImages1.length > 0 &&
      mapInitiated &&
      latestMeasurementSets?.current?.length > 0
    ) {
      setTimeout(() => {
        renderComparePlotImages("left");
      }, 1000);
    }
    if (
      comparePlotsImages2.length > 0 &&
      mapInitiated &&
      latestMeasurementSets?.current?.length > 0
    ) {
      setTimeout(() => {
        renderComparePlotImages("right");
      }, 500);
    }
  }, [
    comparePlotsImages1,
    comparePlotsImages2,
    comparePlotExpanded1,
    comparePlotExpanded2,
    baseOverlayComparePlot1,
    baseOverlayComparePlot2,
    firstCompareSelectedFlightDate,
    secondCompareSelectedFlightDate,
    mapInitiated,
    activeOverlaysCompareLeft,
    activeOverlaysCompareRight,
    boundingBoxCompareLeft,
    boundingBoxCompareRight,
    latestMeasurementSets?.current,
    measurementSets?.length > 0
  ]);
  const renderComparePlotImages = (compareIsLeftOrRight = "left") => {
    let _map = null;
    let _zooms = getZooms();
    let plotsToRender = comparePlotExpanded1
      ? [comparePlotExpanded1]
      : comparePlotExpanded2
      ? [comparePlotExpanded2]
      : compareIsLeftOrRight === "left"
      ? comparePlotsImages1
      : comparePlotsImages2;
    if (compareIsLeftOrRight === "left") {
      downloadPlotImages.length = 0;
    } else {
      downloadComparePlotsRight.length = 0;
    }
    for (let i = 0; i < plotsToRender?.length; i++) {
      if (plotsToRender[i]) {
        let _container = L.DomUtil.get(
          (compareIsLeftOrRight === "right" || comparePlotExpanded1 || comparePlotExpanded2
            ? "comparePlotRight"
            : "comparePlotLeft") + i
        );
        if (_container != null) {
          if (_container._mapInstance) {
            _container._mapInstance.remove();
            _container._mapInstance = null;
          }
          _container._leaflet_id = null;
        }
        _map = L.map(
          (compareIsLeftOrRight === "right" || comparePlotExpanded1 || comparePlotExpanded2
            ? "comparePlotRight"
            : "comparePlotLeft") + i,
          {
            mapMaxZoom: _zooms.max + 3,
            mapMinZoom: _zooms.min,
            crs: L.CRS.EPSG3857,
            zoomControl: false,
            rotate: true,
            rotateControl: false,
            zoomSnap: 1,
            zoomDelta: 0.25,
            scrollWheelZoom: false
          }
        ).setView([0, 0], 23);
        _map.setMinZoom(_zooms.min);
        _map.setMaxZoom(_zooms.max + 3);
        _map.touchRotate.disable();
        _map.touchZoom.disable();
        _map.compassBearing.disable();
        _map.touchGestures.disable();
        _map.setMinZoom(_map.getZoom());
        // Associate the map instance with the container
        _container._mapInstance = _map;
        _map.invalidateSize();

        let _trialMeasurements = generateRenderImageConst;
        let _vectorLayers = {};
        Object.keys(_trialMeasurements).forEach((trialName) => {
          _vectorLayers[trialName] = _trialMeasurements[trialName].measurements.reduce(
            (finalObject, measurement) => {
              finalObject[measurement.feature.properties.num] = L.geoJson(measurement.feature, {
                color: colorMapConst?.[trialName] || "#000",
                fill: true,
                fillOpacity: 0
              });
              return finalObject;
            },
            {}
          );
        });

        if (
          (isBoundingBoxComapareLeft && compareIsLeftOrRight === "left") ||
          (isBoundingBoxComapareRight && compareIsLeftOrRight === "right")
        ) {
          let overlayVectorLayers = [];
          for (let key in overlayVectorsConst?.features) {
            overlayVectorLayers.push(overlayVectorsConst?.features[key]);
          }
          L.geoJSON(overlayVectorLayers, {
            color: "#FF0000",
            fill: true,
            fillOpacity: 0
          }).addTo(_map);
        }

        let bearing = latestMeasurementSets.current
          ?.filter((x) => x.feature.properties.num == plotsToRender[i])
          .map((y) => y.feature.properties.angle);
        // Below line helps to reset bearing when no bearing received or map is already straight
        if (!bearing[0] || [0, 180].includes(Math.trunc(bearing[0]) * 1)) bearing = [0];
        let defaultDegree = 360;
        let plotInformation;
        let lengthDiff = 0;

        if (dronePlotDetailsConst) {
          if (
            dronePlotDetailsConst[selectedOptionConst].find((x) => x.plotId == plotsToRender[i])
              .plotCoordinates
          ) {
            plotInformation = getShapeMeasurementOfPlot(
              dronePlotDetailsConst[selectedOptionConst]
                .find((x) => x.plotId == plotsToRender[i])
                .plotCoordinates.map((x) => [x[1], x[0]])
            );
            lengthDiff = Math.abs(plotInformation.length - plotInformation.width);
            if (!plotImagesExpandConst) {
              defaultDegree = 90;
            } else if (plotImagesExpandConst) {
              defaultDegree = 90;
            } else {
              defaultDegree = 360;
            }
          }
        }
        if (bearing?.length > 0) {
          let angleToRotate = getShortestRotation(bearing[0], defaultDegree);
          if (angleToRotate) {
            _map.setBearing(angleToRotate);
          }
        }

        let maxBounds = _vectorLayers[selectedOptionConst][plotsToRender[i]].getBounds();
        if (!_map.hasLayer(_vectorLayers[selectedOptionConst][plotsToRender[i]])) {
          const plotCoordinates = latestMeasurementSets?.current
            ?.filter((x) => x.feature.properties.num == plotsToRender[i])[0]
            .feature.geometry.coordinates[0];
          const tempPolygon = turf.polygon([plotCoordinates]);
          const expandedPolygonArea = turf.buffer(tempPolygon, 50, { units: "meters" });
          const expandedCoordinates = expandedPolygonArea.geometry.coordinates;
          let polygon = L.polygon([plotCoordinates.map(x => [x[1], x[0]]), expandedCoordinates[0].map(x => [x[1], x[0]])], {
            color: colorMapConst?.[selectedOptionConst] || "#3388ff",
            fillOpacity: 1,
            fillColor: "#f9f9f9",
            fill: true,
            interactive: false
          }).addTo(_map);
          let bounds = getBoundingBoxOfRotatedPolygon(bearing[0], polygon);
          _map.fitBounds(bounds);
          _map.setMaxBounds(bounds);
        }

        // let newBounds = getExtraBounds(maxBounds);
        // let padding;
        // if (plotInformation.nonSquare) {
        //   if (lengthDiff <= 1) {
        //     padding = [-100, -100];
        //   } else {
        //     if (defaultDegree === 360) {
        //       padding = [-90, 0, -90, 0];
        //     } else if (defaultDegree === 90) {
        //       padding = [0, -90, 0, -90];
        //     }
        //   }
        // } else {
        //   padding = [0, -100, 0, -100];
        // }

        // _map.fitBounds(newBounds, { padding });
        // _map.setMinZoom(_map.getZoom());
        // _map.setMaxBounds(newBounds);

        // const zoomLevel = _map.getBoundsZoom(newBounds);
        // _map.setView(newBounds.getCenter(), zoomLevel + 0.5);
        // let zoomLevel = _map.getBoundsZoom(newBounds);
        // if (window.innerHeight > 450 && window.innerHeight < 700) {
        //   zoomLevel += 0.5;
        // }
        // _map.setView(newBounds.getCenter(), (zoomLevel));

        let _rasterLayers = generateRasterLayers(true, false, compareIsLeftOrRight || "left");
        if (compareIsLeftOrRight === "left") {
          Object.keys(_rasterLayers).forEach((layerKey) => {
            if (comparePlotRasterLeftConst === layerKey) {
              _rasterLayers[layerKey].options.bounds = maxBounds;

              _rasterLayers[layerKey].addTo(_map);
            } else {
              _rasterLayers[layerKey].removeFrom(_map);
            }
          });
        } else {
          Object.keys(_rasterLayers).forEach((layerKey) => {
            if (comparePlotRasterRightConst === layerKey) {
              _rasterLayers[layerKey].options.bounds = maxBounds;
              _rasterLayers[layerKey].addTo(_map);
            } else {
              _rasterLayers[layerKey].removeFrom(_map);
            }
          });
        }

        let _overlayLayers = generateRasterLayers(false, true, compareIsLeftOrRight);
        if (compareIsLeftOrRight === "left") {
          Object.keys(_overlayLayers).map((layerKey) => {
            if (activeOverlayComapareLeftConst.includes(layerKey)) {
              _overlayLayers[layerKey].addTo(_map);
            } else {
              _overlayLayers[layerKey].removeFrom(_map);
            }
          });
        } else {
          Object.keys(_overlayLayers).map((layerKey) => {
            if (activeOverlayComapareRightConst.includes(layerKey)) {
              _overlayLayers[layerKey].addTo(_map);
            } else {
              _overlayLayers[layerKey].removeFrom(_map);
            }
          });
        }

        if (compareIsLeftOrRight === "left") {
          downloadPlotImages[plotsToRender[i]] = _map;
        } else {
          downloadComparePlotsRight[plotsToRender[i]] = _map;
        }

        const _mapRef = compareIsLeftOrRight === "left" ? comparePlotLeftRef : comparePlotRightRef;
        const _overlayRef =
          compareIsLeftOrRight === "left" ? comparePlotLeftOverlayRef : comparePlotRightOverlayRef;
        _mapRef.current[i].style.pointerEvents = "none";
        _overlayRef.current[i].addEventListener("click", () => {
          _mapRef.current.forEach((mapEl, idx) => {
            mapEl.style.pointerEvents = "none";
            if (_overlayRef.current[idx]) {
              _overlayRef.current[idx].style.pointerEvents = "block";
            }
          });
          _mapRef.current[i].style.pointerEvents = "auto";
          if (_overlayRef.current[i]) {
            _overlayRef.current[i].style.pointerEvents = "none";
            _overlayRef.current[i].style.border = "2px solid rgb(255,0,255)";
          }
        });
        _overlayRef.current[i].addEventListener("mouseleave", () => {
          _mapRef.current.forEach((mapEl, idx) => {
            mapEl.style.pointerEvents = "none";
            if (_overlayRef.current[idx]) {
              _overlayRef.current[idx].style.pointerEvents = "auto";
              _overlayRef.current[idx].style.border = "2px solid transparent";
            }
          });
        });
      }
    }
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const copyToClipBoardClick = async (plotId, index, compareSide) => {
    try {
      setCopyToClipBoardLoader(true);
      let _map =
        compareSide === "right" ? downloadComparePlotsRight[plotId] : downloadPlotImages[plotId];
      const simpleMapScreenshoter = L.simpleMapScreenshoter({
        hidden: true // hide screen btn on map
      }).addTo(_map);

      _map.simpleMapScreenshoter = simpleMapScreenshoter;
      _map.on("zoomstart", () => {
        // Re-focus on the document to prevent losing focus
        if (!document.hasFocus()) {
          window.focus();
        }
        detectZoomLevelApplySettings();
      });
      const downloadElement = document.getElementById("downloadText");
      const hideIconElements = document.getElementById(`closeIcon${index}`);
      const threeDotsElements = document.getElementById(`threeDots${index}`);
      const flightDateElements = document.getElementById(`flightDate${index}`);
      const hidePlotsElements = document.getElementById(`hidePlots${index}`);
      const hideIconElementsRight = document.getElementById(`closeIconRight${index}`);
      const threeDotsElementsRight = document.getElementById(`threeDotsRight${index}`);
      const flightDateElementsRight = document.getElementById(`flightDateRight${index}`);
      const hidePlotsElementsRight = document.getElementById(`hidePlotsRight${index}`);
      const spinnerPlotImages = document.getElementById("spinnerPlotImages");

      if (downloadElement) downloadElement.style.display = "none";

      if (compareSide === "left") {
        if (hideIconElements) hideIconElements.style.display = "none";
        if (threeDotsElements) threeDotsElements.style.display = "none";
        if (flightDateElements) flightDateElements.style.display = "none";
        if (hidePlotsElements) hidePlotsElements.style.display = "none";
      }
      if (compareSide === "right") {
        if (hideIconElementsRight) hideIconElementsRight.style.display = "none";
        if (threeDotsElementsRight) threeDotsElementsRight.style.display = "none";
        if (flightDateElementsRight) flightDateElementsRight.style.display = "none";
        if (hidePlotsElementsRight) hidePlotsElementsRight.style.display = "none";
      }
      if (spinnerRef.current) {
        spinnerRef.current.style.display = "none"; // Hide the spinner during the screenshot
      }

      if (document.hasFocus()) {
        if (spinnerPlotImages) spinnerPlotImages.style.display = "none";
        const blob = await simpleMapScreenshoter.takeScreen("blob", {});
        const clipboardItem = new ClipboardItem({
          "image/png": blob
        });
        await navigator.clipboard.write([clipboardItem]);
        if (spinnerPlotImages) spinnerPlotImages.style.display = "flex";
      }
      setCopyToClipBoardLoader(false);
      await delay(1000);
      setTimeout(() => {
        // if (downloadElement) downloadElement.style.display = "block";
        if (compareSide === "left") {
          if (hideIconElements) hideIconElements.style.display = "flex";
          if (threeDotsElements) threeDotsElements.style.display = "flex";
          if (flightDateElements) flightDateElements.style.display = "flex";
          if (hidePlotsElements) hidePlotsElements.style.display = "flex";
        }
        if (compareSide === "right") {
          if (hideIconElementsRight) hideIconElementsRight.style.display = "flex";
          if (threeDotsElementsRight) threeDotsElementsRight.style.display = "flex";
          if (flightDateElementsRight) flightDateElementsRight.style.display = "flex";
          if (hidePlotsElementsRight) hidePlotsElementsRight.style.display = "flex";
        }
        if (spinnerRef.current) {
          spinnerRef.current.style.display = "flex"; // Restore the spinner after the screenshot
        }
      }, 100);
      //await new Promise(resolve => setTimeout(resolve, 1000));
      //alert("Map screenshot copied to clipboard!");
    } catch (err) {
      setCopyToClipBoardLoader(false);
      alert("Failed to copy image: " + err);
    }
  };
  const getMobileImagesByPlotId = (plotId) => {
    const imageIds = datesAndPlotIdsMapping[selectedRatingDate][plotId];
    if (imageDataForDownloadConst[plotId]) {
      imageDataForDownloadConst[plotId] = [];
      setImageDataForDownload({ ...imageDataForDownloadConst });
    }
    imageIds?.forEach((imageId) => {
      getMobileImageById(
        attachmentAPIIdToken,
        imageId,
        (res) => {
          if (imageDataForDownloadConst[plotId]) {
            imageDataForDownloadConst[plotId].push(URL.createObjectURL(res));
          } else {
            imageDataForDownloadConst[plotId] = [URL.createObjectURL(res)];
          }
          setImageDataForDownload({ ...imageDataForDownloadConst });
        },
        (err) => {
          console.log("Get image error", err);
        }
      );
    });
  };

  const downloadAllImages = (compareSide) => {
    const zip = new JSZip();
    let promises = [];
    let trialFlightDate =
      [selectedOptionConst] + "_" + formatFlightDateWithoutTimeStamp(selectedFlightDate);
    let ImageArray = compareSide === "right" ? downloadComparePlotsRight : downloadPlotImages;
    // Iterate through each div, capture screenshot and add to zip
    let imageIndex = 0;
    ImageArray?.forEach((div, index) => {
      let extractTreatmentNumber = dronePlotDetails[selectedOption]?.find(
        (plot) => plot.plotId == index
      )?.treatmentNumber;
      let treatmentNumber = extractTreatmentNumber ? extractTreatmentNumber : "NA";
      const simpleMapScreenshoter = L.simpleMapScreenshoter({
        hidden: true,
        hideElementsWithSelectors: [
          ".plot-image-summary",
          ".plot-image-expand-icon",
          ".plot-image-three-dots",
          ".plot-image-cancel-icon"
        ]
      }).addTo(div);
      const promise = new Promise((resolve, reject) => {
        simpleMapScreenshoter
          .takeScreen(div)
          .then((screenshot) => {
            let file = trialFlightDate + "_" + index + "_" + `${treatmentNumber}` + ".png";
            zip.file(file, screenshot);
            resolve();
          })
          .catch(reject);
      });
      promises.push(promise);
      imageIndex++;
    });
    // Once all screenshots are captured, generate the zip and prompt download
    Promise.all(promises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, trialFlightDate + ".zip"); // This will prompt the user to download the zip file
      });
    });
  };

  const getShapeMeasurementOfPlot = (latLng) => {
    let plotWidth = 0;
    let plotHeight = 0;
    let nonSquare = true;

    if (latLng && latLng?.length == 5) {
      plotHeight = L.latLng(latLng[0]).distanceTo(L.latLng(latLng[1])).toFixed(2);
      plotWidth = L.latLng(latLng[1]).distanceTo(L.latLng(latLng[2])).toFixed(2);
    } else {
      plotHeight = L.latLng(latLng[2]).distanceTo(L.latLng(latLng[3])).toFixed(2);
      plotWidth = L.latLng(latLng[1]).distanceTo(L.latLng(latLng[2])).toFixed(2);
    }

    if (plotWidth == plotHeight) {
      nonSquare = false;
    } else {
      nonSquare = true;
    }
    return { nonSquare: nonSquare, length: plotHeight * 1, width: plotWidth * 1 };
  };

  const downloadScreenShot = async (plotId, index, compareSide) => {
    let _map =
      compareSide === "right" ? downloadComparePlotsRight[plotId] : downloadPlotImages[plotId];
    let plotsToRender = imageExpandedConst ? [imageExpandedConst] : selectedPlotsConst;
    const simpleMapScreenshoter1 = L.simpleMapScreenshoter({
      hidden: true // hide screen btn on map
    }).addTo(_map);
    const downloadElement = document.getElementById("downloadText");
    const hideIconElements = document.getElementById(`closeIcon${index}`);
    const threeDotsElements = document.getElementById(`threeDots${index}`);
    const flightDateElements = document.getElementById(`flightDate${index}`);
    const hidePlotsElements = document.getElementById(`hidePlots${index}`);
    const hideIconElementsRight = document.getElementById(`closeIconRight${index}`);
    const threeDotsElementsRight = document.getElementById(`threeDotsRight${index}`);
    const flightDateElementsRight = document.getElementById(`flightDateRight${index}`);
    const hidePlotsElementsRight = document.getElementById(`hidePlotsRight${index}`);

    if (downloadElement) downloadElement.style.display = "none";
    if (compareSide === "left") {
      if (hideIconElements) hideIconElements.style.display = "none";
      if (threeDotsElements) threeDotsElements.style.display = "none";
      if (flightDateElements) flightDateElements.style.display = "none";
      if (hidePlotsElements) hidePlotsElements.style.display = "none";
    }
    if (compareSide === "right") {
      if (hideIconElementsRight) hideIconElementsRight.style.display = "none";
      if (threeDotsElementsRight) threeDotsElementsRight.style.display = "none";
      if (flightDateElementsRight) flightDateElementsRight.style.display = "none";
      if (hidePlotsElementsRight) hidePlotsElementsRight.style.display = "none";
    }
    const renderId = plotsToRender[index] === undefined ? plotId : [plotsToRender[index]];
    let extractTreatmentNumber = dronePlotDetails[selectedOption]?.find(
      (plot) => plot.plotId == plotId
    )?.treatmentNumber;
    let treatmentNumber = extractTreatmentNumber ? extractTreatmentNumber : "NA";

    simpleMapScreenshoter1
      .takeScreen("blob", {})
      .then((blob) => {
        saveAs(
          blob,
          [selectedOptionConst] +
            "_" +
            formatFlightDateWithoutTimeStamp(selectedFlightDate) +
            "_" +
            renderId +
            "_" +
            `${treatmentNumber}` +
            ".png"
        );
      })
      .catch((e) => {
        console.log("Not able to download due to error: ", e.toString());
      });
    setTimeout(() => {
      // if (downloadElement) downloadElement.style.display = "block";
      if (compareSide === "left") {
        if (hideIconElements) hideIconElements.style.display = "flex";
        if (threeDotsElements) threeDotsElements.style.display = "flex";
        if (flightDateElements) flightDateElements.style.display = "flex";
        if (hidePlotsElements) hidePlotsElements.style.display = "flex";
      }
      if (compareSide === "right") {
        if (hideIconElementsRight) hideIconElementsRight.style.display = "flex";
        if (threeDotsElementsRight) threeDotsElementsRight.style.display = "flex";
        if (flightDateElementsRight) flightDateElementsRight.style.display = "flex";
        if (hidePlotsElementsRight) hidePlotsElementsRight.style.display = "flex";
      }
    }, 100);
  };

  // Get rotate angle against 0/360 degree
  const getShortestRotation = (currentAngle, targetAngle) => {
    let angleDifference = targetAngle - currentAngle;
    if (angleDifference > 180) angleDifference -= 360;
    if (angleDifference < -180) angleDifference += 360;
    return angleDifference;
  };

  const renderPlotImages = () => {
    let _map = null;
    let _zooms = getZooms();
    setDownloadedAllPlotImagesIcon(false);
    let plotsToRender = imageExpandedConst ? [imageExpandedConst] : selectedPlotsConst;
    downloadPlotImages.length = 0;
    for (let i = 0; i < plotsToRender?.length; i++) {
      if (plotsToRender[i]) {
        let _container = L.DomUtil.get("mapplot" + i);
        if (_container != null) {
          if (_container._mapInstance) {
            _container._mapInstance.remove();
            _container._mapInstance = null;
          }
          _container._leaflet_id = null;
        }
        _map = L.map("mapplot" + i, {
          mapMaxZoom: _zooms.max + 3,
          mapMinZoom: _zooms.min,
          crs: L.CRS.EPSG3857,
          zoomControl: false,
          rotate: true,
          rotateControl: false,
          zoomSnap: 1,
          zoomDelta: 0.25,
          scrollWheelZoom: false
        }).setView([0, 0], 23);
        _map.scrollWheelZoom.disable();
        //kept for reference
        // _map.getContainer().addEventListener("wheel", (e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   mapplotContainerRef.current.scrollTop += e.deltaY;
        // });
        _map.setMinZoom(_zooms.min);
        _map.setMaxZoom(_zooms.max + 3);
        _map.touchRotate.disable();
        _map.touchZoom.disable();
        _map.compassBearing.disable();
        _map.touchGestures.disable();
        _map.scrollWheelZoom.disable();
        _map.setMinZoom(_map.getZoom());
        // Associate the map instance with the container
        _container._mapInstance = _map;
        _map.invalidateSize();

        let _trialMeasurements = generateRenderImageConst;
        let _vectorLayers = {};
        Object.keys(_trialMeasurements).forEach((trialName) => {
          _vectorLayers[trialName] = _trialMeasurements[trialName].measurements.reduce(
            (finalObject, measurement) => {
              finalObject[measurement.feature.properties.num] = L.geoJson(measurement.feature, {
                color: colorMapConst?.[trialName] || "#000",
                fill: true,
                fillOpacity: 0
              });
              return finalObject;
            },
            {}
          );
        });

        if (isBoundingBoxSelected) {
          let overlayVectorLayers = [];
          for (let key in overlayVectorsConst?.features) {
            overlayVectorLayers.push(overlayVectorsConst?.features[key]);
          }
          L.geoJSON(overlayVectorLayers, {
            color: "#FF0000",
            fill: true,
            fillOpacity: 0
          }).addTo(_map);
        }

        let bearing = latestMeasurementSets?.current
          ?.filter((x) => x.feature.properties.num == plotsToRender[i])
          .map((y) => y.feature.properties.angle);
        // Below line helps to reset bearing when no bearing received or map is already straight
        if (!bearing[0] || [0, 180].includes(Math.trunc(bearing[0]) * 1)) bearing = [0];
        let defaultDegree = 360;
        let plotInformation;
        let lengthDiff = 0;
        if (dronePlotDetailsConst) {
          if (
            dronePlotDetailsConst[selectedOptionConst].find((x) => x.plotId == plotsToRender[i])
              .plotCoordinates
          ) {
            plotInformation = getShapeMeasurementOfPlot(
              dronePlotDetailsConst[selectedOptionConst]
                .find((x) => x.plotId == plotsToRender[i])
                .plotCoordinates.map((x) => [x[1], x[0]])
            );
            lengthDiff = Math.abs(plotInformation.length - plotInformation.width);
            if (!plotImagesExpandConst) {
              defaultDegree = 90;
            } else if (plotImagesExpandConst) {
              defaultDegree = 90;
            } else {
              defaultDegree = 360;
            }
          }
        }
        if (bearing?.length > 0) {
          let angleToRotate = getShortestRotation(bearing[0], defaultDegree);
          if (angleToRotate) {
            _map.setBearing(angleToRotate);
          }
        }

        let maxBounds = _vectorLayers[selectedOptionConst][plotsToRender[i]]?.getBounds();
        if (!_map?.hasLayer(_vectorLayers[selectedOptionConst][plotsToRender[i]])) {
          const plotCoordinates = latestMeasurementSets?.current
            ?.filter((x) => x.feature.properties.num == plotsToRender[i])[0]
            .feature.geometry.coordinates[0];
          const tempPolygon = turf.polygon([plotCoordinates]);
          const expandedPolygonArea = turf.buffer(tempPolygon, 50, { units: "meters" });
          const expandedCoordinates = expandedPolygonArea.geometry.coordinates;
          let polygon = L.polygon([plotCoordinates.map(x => [x[1], x[0]]), expandedCoordinates[0].map(x => [x[1], x[0]])], {
            color: colorMapConst?.[selectedOptionConst] || "#3388ff",
            fillOpacity: 1,
            fillColor: "#f9f9f9",
            fill: true,
            interactive: false
          }).addTo(_map);
          let bounds = getBoundingBoxOfRotatedPolygon(bearing[0], polygon);
          _map.fitBounds(bounds);
          _map.setMaxBounds(bounds);
        }

        let _rasterLayers = generateRasterLayers(true);
        Object.keys(_rasterLayers).forEach((layerKey) => {
          if (currentRasterConst === layerKey) {
            _rasterLayers[layerKey].options.bounds = maxBounds;
            _rasterLayers[layerKey].addTo(_map);
          } else {
            _rasterLayers[layerKey].removeFrom(_map);
          }
        });

        let _overlayLayers = generateRasterLayers(false, true);
        Object.keys(_overlayLayers).forEach((layerKey) => {
          if (activeOverlaysConst.includes(layerKey)) {
            _overlayLayers[layerKey].addTo(_map);
          } else {
            _overlayLayers[layerKey].removeFrom(_map);
          }
        });
        downloadPlotImages[plotsToRender[i]] = _map;

        mapPlotRefs.current[i].style.pointerEvents = "none";
        overlayLayerRefs.current[i].addEventListener("click", () => {
          mapPlotRefs.current.forEach((mapEl, idx) => {
            mapEl.style.pointerEvents = "none";
            if (overlayLayerRefs.current[idx]) {
              overlayLayerRefs.current[idx].style.pointerEvents = "block";
            }
          });
          mapPlotRefs.current[i].style.pointerEvents = "auto";
          if (overlayLayerRefs.current[i]) {
            overlayLayerRefs.current[i].style.pointerEvents = "none";
            overlayLayerRefs.current[i].style.border = "2px solid rgb(255,0,255)";
          }
        });
        overlayLayerRefs.current[i].addEventListener("mouseleave", () => {
          mapPlotRefs.current.forEach((mapEl, idx) => {
            mapEl.style.pointerEvents = "none";
            if (overlayLayerRefs.current[idx]) {
              overlayLayerRefs.current[idx].style.pointerEvents = "auto";
              overlayLayerRefs.current[idx].style.border = "2px solid transparent";
            }
          });
          // downloadPlotImages[plotsToRender[i]].eachLayer((layer) => {
          //   if(layer instanceof L.Polygon) {
          //     layer.getElement().style.pointerEvents = "none";
          //   }
          // })
          // polygon.getElement().style.pointerEvents = "none";
        });
      }
      if (plotsToRender?.length === i + 1) {
        setTimeout(() => {
          setDownloadedAllPlotImagesIcon(true);
        }, 6000);
      }
    }
  };

  function rotateCoordinates(latLngs, center, angle) {
    const radians = angle * (Math.PI / 180); // Convert angle to radians
    return latLngs.map((latLng) => {
      const x = latLng.lng - center.lng;
      const y = latLng.lat - center.lat;
      // Apply rotation matrix
      const rotatedX = x * Math.cos(radians) - y * Math.sin(radians);
      const rotatedY = x * Math.sin(radians) + y * Math.cos(radians);
      // Convert back to lat, lng
      return [center.lat + rotatedY, center.lng + rotatedX];
    });
  }

  // Function to calculate the bounding box of the rotated polygon (Minimum Bounding Rectangle)
  function getBoundingBoxOfRotatedPolygon(angle, polygon) {
    const center = polygon.getBounds().getCenter(); // Get the center of the polygon
    const rotatedLatLngs = rotateCoordinates(polygon.getLatLngs()[0], center, angle);
    const rotatedPolygon = L.polygon(rotatedLatLngs);
    return rotatedPolygon.getBounds();
  }

  const extraButtonsMenuPosition = extratButtonsMenuIconRef?.current?.getBoundingClientRect()?.left;
  // function getExtraBounds(bounds) {
  //   var bufferPercentage = 0.1; // 10% buffer
  //   var latDiff = bounds.getNorth() - bounds.getSouth();
  //   var lngDiff = bounds.getEast() - bounds.getWest();
  //   var latBuffer = latDiff * bufferPercentage;
  //   var lngBuffer = lngDiff * bufferPercentage;
  //   var newBounds = L.latLngBounds(
  //     [bounds.getSouth() - latBuffer, bounds.getWest() - lngBuffer], // Southwest corner
  //     [bounds.getNorth() + latBuffer, bounds.getEast() + lngBuffer] // Northeast corner
  //   );
  //   return newBounds;
  // }

  useEffect(() => {
    if (navbarButtons.length === 1 && navbarButtons[0] === "Plot Images") {
      setPlotImagesExpand(true);
    }
    if (
      navbarButtons.includes("Plot Images") &&
      mapInitiated &&
      latestMeasurementSets?.current?.length > 0
    ) {
      setTimeout(() => {
        renderPlotImages();
      }, 500);
    }
  }, [navbarButtons, mapInitiated, latestMeasurementSets?.current, measurementSets?.length > 0]);

  const selectExpansion = () => {
    setPlotImagesExpand(true);
    setTimeout(() => {
      renderPlotImages();
    }, 500);
  };

  useEffect(() => {
    imageExpandedConst = plotImageExpandedPlotId;
  }, [plotImageExpandedPlotId]);

  let plotImageWrapperClass = "plot-image-wrapper ";
  if (plotImagesExpand || (navbarButtons.length === 1 && navbarButtons[0] === "Plot Images")) {
    if (selectedPlots.length === 1 || plotImageExpandedPlotId) {
      plotImageWrapperClass += "expanded one-image";
    } else if (selectedPlots.length === 2) {
      plotImageWrapperClass += "expanded two-images";
    } else if (selectedPlots.length === 3) {
      plotImageWrapperClass += "expanded three-col-images";
    } else if (selectedPlots.length >= 4) {
      plotImageWrapperClass += "expanded more-than-four-images";
    }
  }

  const handleCheckboxChangeComparePlot1 = (event) => {
    const { name, checked } = event.target;
    setCheckedItems1((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleCheckboxChangeComparePlot2 = (event) => {
    const { name, checked } = event.target;
    setCheckedItems2((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  const secondFlightDropdown = () => {
    setFlightContainerVisible2(!flightContainerVisible2);
  };

  return (
    <>
      {loading ? (
        <div className="center-spinner-full-height">
          <Spinner animation="border" className="custom-spinner" />
        </div>
      ) : (
        <div
          className="root new-viewer-page"
          style={{
            height: "100vh",
            backgroundColor: "#ddd"
          }}>
          {(dateLoader || mapLoading || measurementSetLoaded) && (
            <div className="center-spinner map-loader">
              <Spinner animation="border" className="date-spinner" />
            </div>
          )}
          {isMobile && !customTabletDimensions() ? (
            <MobileTopBar
              protocolName={protocolName}
              viewAPIData={viewAPIData}
              cropData={cropData}
              pestData={pestData}
              selectedOption={selectedOption}
              trial={trial}
              selectedFlightDate={selectedFlightDate}
              protocolTrials={protocolTrial}
              collectionId={collectionId}
              trialType={trialType}
              handleTrialSummary={handleTrialSummary}
              customMobilePortrait={customMobilePortrait}
              customMobileLandscape={customMobileLandscape}
              assessmentDateType={assessmentDatesAndTypes}
              flightCollectionId={selectedFlightDate && flightDates[selectedFlightDate]}
              setOpenTrialReportModal={setOpenTrialReportModal}
              deepLinkUrl={deepLinkUrl}
              openTrialReportModal={openTrialReportModal}
            />
          ) : customTabletDimensions() ? (
            <TabletTopBar
              protocolName={protocolName}
              viewAPIData={viewAPIData}
              cropData={cropData}
              pestData={pestData}
              selectedOption={selectedOption}
              trial={trial}
              selectedFlightDate={selectedFlightDate}
              protocolTrials={protocolTrial}
              collectionId={collectionId}
              trialType={trialType}
              handleTrialSummary={handleTrialSummary}
              customMobileLandscape={customMobileLandscape}
              customMobilePortrait={customMobilePortrait}
              assessmentDateType={assessmentDatesAndTypes}
              flightCollectionId={selectedFlightDate && flightDates[selectedFlightDate]}
              setOpenTrialReportModal={setOpenTrialReportModal}
              deepLinkUrl={deepLinkUrl}
              openTrialReportModal={openTrialReportModal}
            />
          ) : (
            <DesktopTopBar
              protocolName={protocolName}
              viewAPIData={viewAPIData}
              cropData={cropData}
              pestData={pestData}
              selectedOption={selectedOption}
              trial={trial}
              selectedFlightDate={selectedFlightDate}
              protocolTrials={protocolTrial}
              collectionId={collectionId}
              flightDates={flightDates ? Object.keys(flightDates) : []}
              setSelectedFlightDate={setSelectedFlightDate}
              trialType={trialType}
              handleTrialSummary={handleTrialSummary}
              isDesktop={isDesktop}
              mapRef={mapRef}
              assessmentDateType={assessmentDatesAndTypes}
              flightCollectionId={selectedFlightDate && flightDates[selectedFlightDate]}
              setOpenTrialReportModal={setOpenTrialReportModal}
              openTrialReportModal={openTrialReportModal}
              deepLinkUrl={deepLinkUrl}
            />
          )}
          <div style={{ position: "fixed", top: "60px", left: 0, right: 0 }} className={"parent"}>
            {(customMobilePortrait || customMobileLandscape) && (
              <div>
                <Button
                  className="latest-hamburger-icon"
                  onClick={() => {
                    setHamburgerOpen(!hamburgerOpen);
                  }}>
                  <List width={32} height={32} color="#0D1A40" />
                </Button>
              </div>
            )}
            {(customMobilePortrait || customMobileLandscape) && hamburgerOpen && (
              <div>
                <HamburgerMenu
                  setHamburgerOpen={setHamburgerOpen}
                  hamburgerOpen={hamburgerOpen}
                  trialType={trialType}
                  hamburgerButtonRef={hamburgerButtonRef}
                  trailArray={modifiedTrialArray}
                  onSelect={handleSelect1}
                  selectedOption={selectedOption}
                  colorMapConst={colorMapConst}
                  setTabletNavbarButton={setTabletNavbarButton}
                  tabletNavbarButton={tabletNavbarButton}
                  isTreatmentUser={isTreatmentUser}
                  setAssessmentContainerVisible={setAssessmentContainerVisible}
                  assessmentContainerVisible={assessmentContainerVisible}
                  assessmentBy={assessmentBy}
                  setAssessmentBy={setAssessmentBy}
                  isTablet={customTabletDimensions()}
                  customMobilePortrait={customMobilePortrait}
                  customMobileLandscape={customMobileLandscape}
                  assessmentDatesAndTypes={assessmentDatesAndTypes}
                  assessmentsFilteredByType={assessmentsFilteredByType}
                  assessmentDateType={assessmentDateType}
                  handleAssessmentSelect={handleAssessmentSelect}
                  setAssessmentDateType={setAssessmentDateType}
                  setShowTreatment={setShowTreatment}
                  setShowTime={setShowTime}
                  setShowCompare={setShowCompare}
                  setShowTreatmentMT={setShowTreatmentMT}
                  setShowTimeMT={setShowTimeMT}
                  setShowCompareMT={setShowCompareMT}
                  setAssessmentOptionsVisible={setAssessmentOptionsVisible}
                  setShowTabletTooltip={setShowTabletTooltip}
                  items={availableTopButtons}
                  showTabletTooltip={showTabletTooltip}
                  tooltipRef={tooltipRef}
                  tooltipRef1={tooltipRef1}
                  setCloseTooltip={setCloseTooltip}
                  closeTooltip={closeTooltip}
                  isImgAvailable={isImgAvailable}
                  mapLayout={mapLayout}
                  setGeometry={setGeometry}
                />
              </div>
            )}
            {(customMobilePortrait || customMobileLandscape) && assessmentDateType?.length > 0 && (
              <div>
                <Button className="assessmemt-icon-mobile" onClick={assessmentMobileIconClick}>
                  <img src={assessmentIcon} width={24} height={24} alt="" color="#0D1A40" />
                  <span className="d-flex justify-content-center align-items-center deep-cove-color font-size-extra-small font-weight-400">
                    Assessments
                  </span>
                </Button>
              </div>
            )}
            {assessmentMobileOpen && assessmentDateType?.length > 0 ? (
              <div
                ref={mobileAssessmentdatecombinationRef}
                style={{
                  width: "100%",
                  padding:
                    customMobileLandscape && assessmentOptionsVisible
                      ? "10px 100px"
                      : showCompareMT || showTimeMT
                      ? "0px 0px 0px 20px"
                      : "10px 30px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                  backgroundColor: "white",
                  boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
                  maxHeight: "100vh"
                }}>
                <div>
                  <>
                    <div className="d-flex justify-content-between mb-3">
                      <span className="deep-cove-color font-size-small font-weight-600 pt-1">
                        Assessments
                      </span>
                      <span>
                        <span>
                          <img
                            src={Clipboard}
                            height={24}
                            width={24}
                            onClick={handleRatingDescription}
                          />
                        </span>
                        <span
                          onClick={() => {
                            setAssessmentDateType([]);
                            setShowTreatmentMT(false);
                            setShowTimeMT(false);
                            setShowCompareMT(false);
                            setAssessmentMobileOpen(false);
                          }}
                          className="font-size-medium font-weight-700"
                          style={{
                            margin: "0px 10px"
                          }}>
                          <span style={{ borderBottom: "1px solid" }}>CLEAR ALL</span>
                        </span>
                        <span
                          className={
                            customMobileLandscape ? "mobile-landscape-date-combination-close" : ""
                          }>
                          <X
                            height={24}
                            width={24}
                            onClick={() => setAssessmentMobileOpen(false)}
                          />
                        </span>
                      </span>
                    </div>
                    <Assessmentdatecombinations
                      assessmentDateType={assessmentDateType}
                      orderArray={orderArray}
                      setAssessmentDateType={setAssessmentDateType}
                      setSelectedCombinationForHeatMap={setSelectedCombinationForHeatMap}
                      selectedCombinationForHeatMap={selectedCombinationForHeatMap}
                      customMobilePortrait={customMobilePortrait}
                      customMobileLandscape={customMobileLandscape}
                      selectedCombinationForEyeOpen={selectedCombinationForEyeOpen}
                      setSelectedCombinationForEyeOpen={setSelectedCombinationForEyeOpen}
                      showTreatment={showTreatmentMT}
                      showTime={showTimeMT}
                      showCompare={showCompareMT}
                      setTreatmentDataLoaded={setTreatmentDataLoaded}
                      setFightDataLoaded={setFightDataLoaded}
                      setCompareData1Loaded={setCompareData1Loaded}
                      setCompareData2Loaded={setCompareData2Loaded}
                      setSelectedTrialInfoForEyeOpen={setSelectedTrialInfoForEyeOpen}
                    />
                  </>
                </div>
              </div>
            ) : null}
            {!(customMobilePortrait || customMobileLandscape) && (
              <div className="trial-bar">
                <div
                  className="col-md-3 col-lg-2 col-xl-2 col-xxl-2 col-sm-2 trial-dropdown"
                  style={{ height: "60px", borderRight: "1px solid #EDEDED", padding: "0" }}>
                  {commonDropdown(
                    trialOpen,
                    selectedOption,
                    modifiedTrialArray,
                    handleSelect1,
                    handleToggle
                  )}
                </div>
                <AssessmentDropDown
                  assessmentContainerVisible={assessmentContainerVisible}
                  setAssessmentContainerVisible={setAssessmentContainerVisible}
                  handleSelect1={handleSelect1}
                  selectedOption={selectedOption}
                  assessmentBy={assessmentBy}
                  setAssessmentBy={setAssessmentBy}
                  isTablet={customTabletDimensions()}
                  isMobile={isMobile}
                  assessmentDatesAndTypes={assessmentDatesAndTypes}
                  assessmentsFilteredByType={assessmentsFilteredByType}
                  assessmentDateType={assessmentDateType}
                  handleAssessmentSelect={handleAssessmentSelect}
                  setAssessmentDateType={setAssessmentDateType}
                  isAssessmentDropdown={isAssessmentDropdown}
                  handleAsessmentDropdown={handleAsessmentDropdown}
                  // setMobileTrailDropdown={setMobileTrailDropdown}
                  setShowExtendendMenu={setShowExtendendMenu}
                  setShowTreatment={setShowTreatment}
                  setShowTime={setShowTime}
                  setShowCompare={setShowCompare}
                  setShowTreatmentMT={setShowTreatmentMT}
                  setShowTimeMT={setShowTimeMT}
                  setShowCompareMT={setShowCompareMT}
                  setAssessmentOptionsVisible={setAssessmentOptionsVisible}
                  isDesktop={isDesktop}
                  selectedCombinationForEyeOpen={selectedCombinationForEyeOpen}
                  setSelectedCombinationForEyeOpen={setSelectedCombinationForEyeOpen}
                  handleRatingDescription={handleRatingDescription}
                  setSelectedCombinationForHeatMap={setSelectedCombinationForHeatMap}
                  selectedCombinationForHeatMap={selectedCombinationForHeatMap}
                  selectedTrialInfoForEyeOpen={selectedTrialInfoForEyeOpen}
                  setSelectedTrialInfoForEyeOpen={setSelectedTrialInfoForEyeOpen}
                  setNavbarButtons={setNavbarButtons}
                  navbarButtons={navbarButtons}
                  showTabletTooltip={showTabletTooltip}
                  setShowTabletTooltip={setShowTabletTooltip}
                  // targetTabletRef={targetTabletRef}
                  customMobileLandscape={customMobileLandscape}
                  customMobilePortrait={customMobilePortrait}
                  dateTypeUrl={dateTypeUrl}
                />
                {customTabletDimensions() && (
                  <TabletButtons
                    items={availableTopButtons}
                    navbarButtons={navbarButtons}
                    selectedOption={selectedOption}
                    isTreatmentUser={isTreatmentUser}
                    handleTopButtonClick={handleTopButtonClick}
                    isImgAvailable={isImgAvailable}
                    dragPerson={dragPerson}
                    draggedOverPerson={draggedOverPerson}
                    handleSort={handleSort}
                    renderTooltip={renderTooltip}
                    assessmentDateType={assessmentDateType}
                    setBarExpand={setBarExpand}
                    setEnvExpand={setEnvExpand}
                    setTreatmentExpansion={setTreatmentExpansion}
                    setTabletNavbarButton={setTabletNavbarButton}
                    tabletNavbarButton={tabletNavbarButton}
                    showTabletTooltip={showTabletTooltip}
                    setShowTabletTooltip={setShowTabletTooltip}
                    mapLayout={mapLayout}
                    setGeometry={setGeometry}
                    setChartButtonSelected={SetChartButtonSelected}
                  />
                )}

                {isDesktop && !customTabletDimensions() && (
                  <>
                    <div
                      // className={`${
                      //   isTreatmentUser ? "col-6" : "col-5"
                      // } pl-1 d-flex align-items-center`}
                      ref={buttonsRef}
                      // className="d-flex align-items-center"
                      className={`${
                        isTreatmentUser
                          ? "col-md-5 col-lg-7 col-xl-7 col-xxl-7"
                          : "col-md-5 col-lg-7 col-xl-7 col-xxl-7"
                      } pl-1 d-flex align-items-center`}>
                      <span>
                        <Button
                          style={{
                            backgroundColor: navbarButtons.includes("Drone")
                              ? "#E08330"
                              : "#F1F2F3",
                            color: navbarButtons.includes("Drone") ? "#FFFFFF" : "#0D1A40",
                            marginRight: "10px"
                          }}
                          className="viewer-bar-buttons ml-1"
                          onClick={() => {
                            handleTopButtonClick("Drone");
                            tabsChange("Drone");
                            if (navbarButtons.includes("Plot Images")) {
                              setPlotImagesExpand(false);
                              renderPlotImages();
                            }
                            // setBarExpand(false),
                            // setEnvExpand(false),
                            // setTreatmentExpansion(false);
                          }}>
                          {isImgAvailable ? "Drone / Layout" : "Layout"}
                        </Button>
                      </span>
                      {availableTopButtons?.map((item, index) => (
                        <span
                          key=""
                          style={{ marginRight: "10px" }}
                          draggable
                          onDragStart={() => (dragPerson.current = index)}
                          onDragEnter={() => (draggedOverPerson.current = index)}
                          onDragEnd={handleSort}
                          onDragOver={(e) => e.preventDefault()}>
                          <OverlayTrigger
                            placement="bottom"
                            trigger={["hover"]}
                            overlay={
                              trialNotSelected && item === "Treatment Labels" ? (
                                renderTooltip("Treatment Labels")
                              ) : trialNotSelected &&
                                !isImgAvailableLatest.current &&
                                item === "Environmental" ? (
                                renderTooltip("Environmental")
                              ) : (trialNotSelected && item === "Charts") ||
                                (selectedOption &&
                                  assessmentDateType.length === 0 &&
                                  item === "Charts") ? (
                                renderTooltip("Charts")
                              ) : (trialNotSelected || trialType === "manual") &&
                                item === "Plot Images" ? (
                                renderTooltip("Plot Images")
                              ) : trialNotSelected && item === "Compare Plots" ? (
                                renderTooltip("Compare Plots")
                              ) : trialNotSelected && item === "Mobile Images" ? (
                                renderTooltip("Mobile Images")
                              ) : (
                                <></>
                              )
                            }>
                            <Button
                              disabled={
                                (item === "Charts" && assessmentDateType.length === 0) ||
                                (item === "Treatment Labels" && trialNotSelected) ||
                                (item === "Environmental" &&
                                  trialNotSelected &&
                                  !isImgAvailableLatest.current) ||
                                (item === "Plot Images" && trialNotSelected) ||
                                (item === "Mobile Images" && trialNotSelected) ||
                                (item === "Compare Plots" && trialNotSelected)
                              }
                              data-testid={item}
                              style={{
                                backgroundColor: navbarButtons.includes(item)
                                  ? "#E08330"
                                  : "#F1F2F3",
                                color: navbarButtons.includes(item) ? "#FFFFFF" : "#0D1A40",
                                cursor:
                                  (item === "Treatment Labels" && trialNotSelected) ||
                                  (item === "Environmental" &&
                                    trialNotSelected &&
                                    !isImgAvailableLatest.current) ||
                                  (item === "Charts" && assessmentDateType.length === 0) ||
                                  (item === "Plot Images" && trialNotSelected) ||
                                  (item === "Compare Plots" && trialNotSelected) ||
                                  (item === "Mobile Images" && trialNotSelected) ||
                                  trialType === "manual"
                                    ? "default"
                                    : "pointer"
                              }}
                              className="viewer-bar-buttons ml-1"
                              onClick={() => {
                                handleTopButtonClick(item);
                                setBarExpand(false);
                                setEnvExpand(false);
                                setPlotImagesExpand(false);
                                setTreatmentExpansion(false);
                                setDroneExpand(false);
                                tabsChange(item);
                                if (item === "Plot Images") {
                                  setShowPlotImageMessage(true);
                                }
                                if (item === "Mobile Images") {
                                  setShowMobileImageMessage(true);
                                }
                                if (item === "Compare Plots") {
                                  setComparePlotMessage(true);
                                }
                              }}>
                              <span className="d-flex justify-content-between">
                                <span style={{ marginRight: "6px" }}>
                                  {navbarButtons.includes(item) ? (
                                    <img draggable="false" src={whitedraggableDots} />
                                  ) : (
                                    <img draggable="false" src={draggableDots} />
                                  )}
                                </span>
                                <span
                                  style={{
                                    marginRight:
                                      item === "Charts"
                                        ? "32px"
                                        : item === "Environmental"
                                        ? "8px"
                                        : item === "Plot Images"
                                        ? "18px"
                                        : ""
                                  }}>
                                  {item}
                                </span>
                              </span>
                            </Button>
                          </OverlayTrigger>
                        </span>
                      ))}
                      {extraMenuItems?.length > 0 && isDesktop && (
                        <span>
                          <Button
                            data-testid="menu-toggle-button"
                            style={{
                              backgroundColor: "#ffffff",
                              border: "none",
                              color: "#0D1A40",
                              boxShadow: "none",
                              marginRight: "10px"
                            }}
                            ref={extratButtonsMenuIconRef}
                            onClick={() => setExtraButtonsMenu(!extraButtonsMenu)}>
                            <ThreeDots width={24} height={24} />
                          </Button>
                        </span>
                      )}

                      {extraButtonsMenu && (
                        <div
                          data-testid="menu-container"
                          style={{
                            position: "fixed",
                            top: "120px",
                            left:
                              (extraButtonsMenuPosition ? extraButtonsMenuPosition - 80 : 1264) +
                              "px",
                            backgroundColor: "#ffffff",
                            width: "172px"
                            // height: isTreatmentUser ? "100px" : "50px"
                          }}
                          ref={extraButtonsMenuIconContainerRef}>
                          {extraMenuItems?.map((item) => (
                            <div
                              key=""
                              className="d-flex align-items-center justify-content-center"
                              style={{ marginTop: "10px", marginBottom: "10px" }}>
                              <OverlayTrigger
                                placement="left"
                                overlay={
                                  (trialNotSelected && item === "Charts") ||
                                  (selectedOption &&
                                    assessmentDateType.length === 0 &&
                                    item === "Charts") ? (
                                    renderTooltip("Charts")
                                  ) : item === "Plot Images" && trialNotSelected ? (
                                    renderTooltip("Plot Images")
                                  ) : item === "Compare Plots" && trialNotSelected ? (
                                    renderTooltip("Compare Plots")
                                  ) : item === "Mobile Images" && trialNotSelected ? (
                                    renderTooltip("Mobile Images")
                                  ) : (
                                    <></>
                                  )
                                }>
                                <Button
                                  disabled={
                                    (item === "Plot Images" && trialNotSelected) ||
                                    (item === "Compare Plots" && trialNotSelected) ||
                                    (item === "Mobile Images" && trialNotSelected) ||
                                    (item === "Charts" && assessmentDateType.length === 0)
                                      ? true
                                      : false
                                  }
                                  style={{
                                    backgroundColor: navbarButtons.includes(item)
                                      ? "#E08330"
                                      : "#F1F2F3",
                                    color: navbarButtons.includes(item) ? "#E08330" : "#0D1A40",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: item === "Charts" ? "start" : "center",
                                    fontWeight: 400,
                                    fontFamily: "Helvetica"
                                  }}
                                  className="viewer-bar-buttons"
                                  onClick={() => {
                                    const lastElement =
                                      availableTopButtons[availableTopButtons.length - 1];
                                    const updatedExtraMenuItems = extraMenuItems.map((menuItem) =>
                                      menuItem === item ? lastElement : menuItem
                                    );
                                    setExtraMenuItems(updatedExtraMenuItems);
                                    const updatedAvailableTopButtons = [
                                      ...availableTopButtons.slice(0, -1),
                                      item
                                    ];
                                    setAvailableTopButtons(updatedAvailableTopButtons);
                                    setExtraButtonsMenu(false);
                                    handleTopButtonClick(item);
                                  }}>
                                  <span className="d-flex justify-content-between">
                                    <span style={{ marginRight: "6px" }}>
                                      {navbarButtons.includes(item) ? (
                                        <img draggable="false" src={whitedraggableDots} />
                                      ) : (
                                        <img draggable="false" src={draggableDots} />
                                      )}
                                    </span>
                                    <span
                                      style={{
                                        marginRight: item === "Plot Images" ? "18px" : ""
                                      }}>
                                      {item}
                                    </span>
                                  </span>
                                </Button>
                              </OverlayTrigger>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      data-testid="share-link-button"
                      className="col-1 d-flex justify-content-center align-items-center share-link-button">
                      {isCopied && (
                        <span className="link-copied-text">Link copied to clipboard </span>
                      )}
                      <span style={{ position: "absolute" }}>
                        <Link
                          height={24}
                          width={24}
                          onClick={handleLinkClick}
                          className="cursor-pointer"
                        />
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
            {/* )} */}

            <div
              style={{
                height:
                  customMobileLandscape || customMobilePortrait
                    ? "calc(100vh - 60px)"
                    : "calc(100vh - 60px)",
                width: "100%",
                maxWidth: "100%",
                flexShrink: { sm: 0 },
                padding: 0
              }}>
              {navbarButtons.includes("Drone") && (
                <span
                  style={{
                    position: "absolute",
                    top:
                      customMobileLandscape || customMobilePortrait
                        ? "0px"
                        : navbarButtons.length > 1 || navbarButtons.includes("Compare Plots")
                        ? "90px"
                        : "60px",
                    zIndex:
                      droneExpand === true || customMobileLandscape || customMobilePortrait
                        ? 999
                        : 998
                  }}>
                  <button
                    ref={baseOverlayButtonRef}
                    className="overlay-flight-icon"
                    onClick={toggleBaseOverlayFlight}
                    style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div
                      className="overlay-flight-dropdown-toggle"
                      style={
                        {
                          // boxShadow: baseOverlayFlightOpen
                          //   ? "none"
                          //   : "rgba(0, 0, 0, 0.3) 0px 2px 5px 2px"
                        }
                      }>
                      <div
                        className={
                          selectedFlightDate
                            ? "flight-overlay-button"
                            : "flight-overlay-button-withoutdate"
                        }>
                        <img
                          src={OverlayFlightIcon}
                          alt=""
                          height={16}
                          style={{ marginBottom: "5px", color: "#0D1A40" }}
                        />
                        <div
                          className="font-size-extra-small deep-cove-color"
                          style={{
                            marginLeft: "2px"
                          }}>
                          {selectedFlightDate
                            ? formatFlightDateWithoutTimeStamp(selectedFlightDate)
                            : " "}
                        </div>
                      </div>
                    </div>
                  </button>
                  {isDesktop && !customTabletDimensions() ? (
                    <BaseOverlayFlight
                      toggleBaseOverlayFlight={toggleBaseOverlayFlight}
                      baseOverlayFlightOpen={baseOverlayFlightOpen}
                      flightDropdown={flightDropdown}
                      flightContainerVisible={flightContainerVisible}
                      flightDatesContainerRef={flightDatesContainerRef}
                      selectedFlightDate={selectedFlightDate}
                      flightDates={flightDates}
                      flightDateRef={flightDateRef}
                      handleFlightOption={handleFlightDateChange}
                      trialType={trialType}
                      rasterLayersList={rasterLayersList}
                      overlayLayersList={overlayLayersList}
                      overlayVectors={overlayVectors}
                      overlayChecklist={overlayChecklist}
                      currentRaster={currentRaster}
                      activeOverlays={activeOverlays}
                      boundingBoxes={boundingBoxes}
                      selectedBoundingBoxes={selectedBoundingBoxes}
                      // showOnMobile={showOnMobile}
                      handleRaster={handleRasterChange}
                      handleBaseLayer={handleBaseLayer}
                      handleOverLayLayer={handleOverLayLayer}
                      baseVisible={baseVisible}
                      overlayVisible={overlayVisible}
                      assessmentDrawerVisible={assessmentDrawerVisible}
                      toggleAssessmentDrawerVisible={toggleAssessmentDrawerVisible}
                      activerVectorLayers={activerVectorLayers}
                      plotVectorChanged={plotVectorChanged}
                      handleTrialInfoLayer={handleTrialInfoLayer}
                      trialInfoVisible={trialInfoVisible}
                      selectedVectors={selectedVectors}
                      showExtenedMenu={showExtenedMenu}
                      selectedTrialInfoForEyeOpen={selectedTrialInfoForEyeOpen}
                      setSelectedTrialInfoForEyeOpen={setSelectedTrialInfoForEyeOpen}
                      selectedCombinationForEyeOpen={selectedCombinationForEyeOpen}
                      setSelectedCombinationForEyeOpen={setSelectedCombinationForEyeOpen}
                      baseOverlayRef={baseOverlayRef}
                    />
                  ) : (
                    <MobileBaseOverlayFlight
                      toggleBaseOverlayFlight={toggleBaseOverlayFlight}
                      baseOverlayFlightOpen={baseOverlayFlightOpen}
                      flightDropdown={flightDropdown}
                      flightContainerVisible={flightContainerVisible}
                      flightDatesContainerRef={flightDatesContainerRef}
                      selectedFlightDate={selectedFlightDate}
                      flightDates={flightDates}
                      flightDateRef={flightDateRef}
                      handleFlightOption={handleFlightDateChange}
                      trialType={trialType}
                      rasterLayersList={rasterLayersList}
                      overlayLayersList={overlayLayersList}
                      overlayVectors={overlayVectors}
                      overlayChecklist={overlayChecklist}
                      currentRaster={currentRaster}
                      activeOverlays={activeOverlays}
                      boundingBoxes={boundingBoxes}
                      selectedBoundingBoxes={selectedBoundingBoxes}
                      // showOnMobile={showOnMobile}
                      handleRaster={handleRasterChange}
                      handleBaseLayer={handleBaseLayer}
                      handleOverLayLayer={handleOverLayLayer}
                      baseVisible={baseVisible}
                      overlayVisible={overlayVisible}
                      assessmentDrawerVisible={assessmentDrawerVisible}
                      toggleAssessmentDrawerVisible={toggleAssessmentDrawerVisible}
                      activerVectorLayers={activerVectorLayers}
                      plotVectorChanged={plotVectorChanged}
                      handleTrialInfoLayer={handleTrialInfoLayer}
                      trialInfoVisible={trialInfoVisible}
                      selectedVectors={selectedVectors}
                      showExtenedMenu={showExtenedMenu}
                      selectedTrialInfoForEyeOpen={selectedTrialInfoForEyeOpen}
                      setSelectedTrialInfoForEyeOpen={setSelectedTrialInfoForEyeOpen}
                      selectedCombinationForEyeOpen={selectedCombinationForEyeOpen}
                      setSelectedCombinationForEyeOpen={setSelectedCombinationForEyeOpen}
                      baseOverlayRef={baseOverlayRef}
                      setBaseOverlayFlightOpen={setBaseOverlayFlightOpen}
                    />
                  )}
                </span>
              )}
              <Row style={{ position: "unset", height: "100%" }}>
                <Col xs={12} style={{ width: "100%", position: "none", height: "100%" }}>
                  {/* <MobileBaseOverlayBar
                    rasterLayersList={rasterLayersList}
                    overlayLayersList={overlayLayersList}
                    overlayVectors={overlayVectors}
                    overlayChecklist={overlayChecklist}
                    currentRaster={currentRaster}
                    activeOverlays={activeOverlays}
                    boundingBoxes={boundingBoxes}
                    selectedBoundingBoxes={selectedBoundingBoxes}
                    // showOnMobile={showOnMobile}
                    handleRaster={handleRasterChange}
                    baseVisible={baseVisible}
                    overlayVisible={overlayVisible}
                    plotVectorChanged={plotVectorChanged}
                    handleTrialInfoLayer={handleTrialInfoLayer}
                    trialInfoVisible={trialInfoVisible}
                    toggleAssessmentDrawerVisible={toggleAssessmentDrawerVisible}
                    activerVectorLayers={activerVectorLayers}
                    selectedVectors={selectedVectors}
                    showExtenedMenu={showExtenedMenu}
                    trialType={trialType}
                  /> */}
                  {customTabletDimensions() && (
                    <TabTools
                      selectedOption={selectedOption}
                      handleTrialSummary={handleTrialSummary}
                      ShowFullMap={ShowFullMap}
                      handleFeedback={handleFeedback}
                      measurementToolVisible={measurementToolVisible}
                      switchMeasurementTool={switchMeasurementTool}
                      measurementIcon={measurementIcon}
                      isShowFeedback={isShowFeedback}
                      assessmentDateType={assessmentDateType}
                      setExpandContainerForTab={setExpandContainerForTab}
                      expandContainerForTab={expandContainerForTab}
                      handleRatingDescription={handleRatingDescription}
                      isShowRatingdescription={isShowRatingdescription}
                      rotateMapZeroDegreeClick={rotateMapZeroDegree}
                      rotateMapZeroDegreeDisable={trialNotSelected}
                      rotateMapZeroDegreeHide={trialType === "manual"}
                    />
                  )}

                  {(customMobileLandscape || customMobilePortrait) &&
                    // showOnMobile &&
                    selectedOption !== "All trials" &&
                    selectedOption !== "No trials" &&
                    assessmentDateType.length > 0 &&
                    tabletNavbarButton === "Charts" && (
                      <div className="mobile-charts-menu">
                        {isMobileOnly && (
                          <div
                            className="w-100 d-flex align-items-center justify-content-between"
                            style={{ height: "50px" }}>
                            <div style={{ marginLeft: "10px" }}>
                              <span>
                                <ChevronLeft
                                  onClick={() => {
                                    setTabletNavbarButton("Drone");
                                    setHamburgerOpen(true);
                                  }}
                                  className="ml-2"
                                  width={16}
                                  height={16}
                                />{" "}
                              </span>
                              <span className="pl-2 font-weight-700 font-size-large">Charts</span>
                            </div>
                            <div style={{ marginRight: "10px" }}>
                              <X
                                onClick={() => {
                                  setTabletNavbarButton("Drone");
                                }}
                                width={16}
                                height={16}
                                style={{ height: "24px", width: "24px" }}
                                className="mr-2"
                              />
                            </div>
                          </div>
                        )}

                        <div>
                          <Navbar
                            bg="white"
                            variant="white"
                            className="d-flex"
                            style={{
                              width: "100%",
                              padding: 0,
                              height: "60px",
                              position: "absolute",
                              // top:
                              //   showExtenedMenu === false ||
                              //   !(baseVisible || overlayVisible || trialInfoVisible)
                              //     ? 0
                              //     : 60,
                              // left: 0,
                              zIndex: 99999
                            }}>
                            {/* {assessmentDateType.length > 0 && (
                            <Button
                              className="scroll-arrow"
                              style={{
                                zIndex: 99999999,
                                border: "none",
                                backgroundColor: "white",
                                color: "#0D1A40"
                              }}
                              onClick={scrollLeft}>
                              <ChevronLeft width={22} height={22} />
                            </Button>
                          )} */}
                            <div
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                position: "relative",
                                height: "100%"
                              }}>
                              <div
                                className="chart-scroll-wrapper overflow-auto"
                                ref={navRef}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  height: "100%"
                                }}>
                                {/* {isTreatmentUser && !customMobileLandscape && !customMobilePortrait && (
                                <div
                                  className={`${
                                    isLandscape ? "col-3" : "col-2"
                                  } d-flex flex-column justify-content-center`}
                                  id="tab1"
                                  style={{
                                    backgroundColor: treatmentLabel ? "#FFE8BC" : "white",
                                    marginLeft: assessmentDateType.length === 0 ? "15px" : null
                                  }}>
                                  <Button
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      border: "none",
                                      height: "100%",
                                      backgroundColor: treatmentLabel ? "#FFE8BC" : "white",
                                      boxShadow: "none"
                                    }}
                                    onClick={() => {
                                      setTreatmentLabel(!treatmentLabel);
                                      setAssessmentOptionsVisible(false);
                                      setShowTreatmentMT(false);
                                      setShowTimeMT(false);
                                      setShowCompareMT(false);
                                      scrollToItem("tab1");
                                    }}>
                                    <img src={label} alt="Treatment labels" />
                                    {isLandscape && (
                                      <span
                                        style={{
                                          paddingLeft: "10px",
                                          fontSize: "12px",
                                          fontWeight: "400",
                                          fontFamily: "Helvetica",
                                          color: "#0D1A40"
                                        }}>
                                        Treatment Labels
                                      </span>
                                    )}
                                  </Button>
                                </div>
                              )} */}
                                {assessmentDateType.length > 0 && (
                                  <>
                                    <div
                                      className="col-3 d-flex flex-column justify-content-center"
                                      id="tab2"
                                      style={{
                                        backgroundColor: assessmentOptionsVisible
                                          ? "#FFE8BC"
                                          : "white"
                                      }}>
                                      <Button
                                        className="d-flex flex-column align-items-center "
                                        style={{
                                          border: "none",
                                          height: "60px",
                                          backgroundColor: assessmentOptionsVisible
                                            ? "#FFE8BC"
                                            : "white",
                                          boxShadow: "none"
                                        }}
                                        onClick={() => {
                                          setAssessmentOptionsVisible(!assessmentOptionsVisible);
                                          setShowTreatmentMT(false);
                                          setShowTimeMT(false);
                                          setShowCompareMT(false);
                                          // setTreatmentLabel(false);
                                          // scrollToItem("tab2");
                                        }}>
                                        <span
                                          style={{
                                            color: "#0D1A40",
                                            fontSize: "12px",
                                            marginTop: "12px",
                                            fontFamily: "Helvetica",
                                            paddingLeft: "10px"
                                          }}>
                                          Assessments
                                        </span>
                                      </Button>
                                    </div>
                                    <div
                                      className={`${
                                        customMobileLandscape ? "col-3" : "col-4"
                                      } d-flex flex-column justify-content-center`}
                                      id="tab3"
                                      style={{
                                        backgroundColor: showTreatmentMT ? "#FFE8BC" : "white"
                                      }}>
                                      <Button
                                        className="d-flex flex-column align-items-center"
                                        style={{
                                          border: "none",
                                          height: "60px",
                                          backgroundColor: showTreatmentMT ? "#FFE8BC" : "white",
                                          boxShadow: "none"
                                        }}
                                        onClick={() => {
                                          setShowTreatmentMT(!showTreatmentMT);
                                          SetChartButtonSelected(true);
                                          setAssessmentOptionsVisible(false);
                                          setShowTimeMT(false);
                                          setShowCompareMT(false);
                                          setTreatmentDataLoaded(true);
                                          // setTreatmentLabel(false);
                                          // scrollToItem("tab3");
                                        }}>
                                        <span
                                          style={{
                                            color: "#0D1A40",
                                            fontSize: "12px",
                                            marginTop: "12px",
                                            fontFamily: "Helvetica"
                                          }}>
                                          Treatment Summary
                                        </span>
                                      </Button>
                                    </div>
                                    <div
                                      className={`${
                                        customMobileLandscape ? "col-3" : "col-2"
                                      } d-flex flex-column justify-content-center`}
                                      id="tab4"
                                      style={{
                                        backgroundColor: showTimeMT ? "#FFE8BC" : "white"
                                      }}>
                                      <Button
                                        className="d-flex flex-column align-items-center"
                                        style={{
                                          border: "none",
                                          height: "60px",
                                          backgroundColor: showTimeMT ? "#FFE8BC" : "white",
                                          boxShadow: "none"
                                        }}
                                        onClick={() => {
                                          setShowTimeMT(!showTimeMT);
                                          setShowTreatmentMT(false);
                                          setAssessmentOptionsVisible(false);
                                          setShowCompareMT(false);
                                          setFightDataLoaded(true);
                                          // setTreatmentLabel(false);
                                          // scrollToItem("tab4");
                                        }}>
                                        <span
                                          style={{
                                            color: "#0D1A40",
                                            fontSize: "12px",
                                            marginTop: "12px",
                                            fontFamily: "Helvetica"
                                          }}>
                                          Time
                                        </span>
                                      </Button>
                                    </div>

                                    <div
                                      className={`${
                                        customMobileLandscape ? "col-3" : "col-3"
                                      } d-flex flex-column justify-content-center`}
                                      id="tab5"
                                      style={{
                                        backgroundColor: showCompareMT ? "#FFE8BC" : "white"
                                      }}>
                                      <Button
                                        className="d-flex flex-column align-items-center"
                                        style={{
                                          border: "none",
                                          height: "60px",
                                          backgroundColor: showCompareMT ? "#FFE8BC" : "white",
                                          boxShadow: "none"
                                        }}
                                        onClick={() => {
                                          setShowCompareMT(!showCompareMT);
                                          setShowTimeMT(false);
                                          setShowTreatmentMT(false);
                                          setAssessmentOptionsVisible(false);
                                          setCompareData1Loaded(true);
                                          setCompareData2Loaded(true);
                                          // setTreatmentLabel(false);
                                          // scrollToItem("tab5");
                                        }}>
                                        <span
                                          style={{
                                            color: "#0D1A40",
                                            fontSize: "12px",
                                            marginTop: "12px",
                                            fontFamily: "Helvetica"
                                          }}>
                                          Compare
                                        </span>
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {/* {assessmentDateType.length > 0 && (
                            <Button
                              className="scroll-arrow"
                              style={{
                                zIndex: 99999,
                                border: "none",
                                backgroundColor: "white",
                                color: "#0D1A40"
                              }}
                              onClick={scrollRight}>
                              <ChevronRight width={22} height={22} />
                            </Button>
                          )} */}
                          </Navbar>
                        </div>
                        {(showTimeMT ||
                          showTreatmentMT ||
                          assessmentOptionsVisible ||
                          showCompareMT) &&
                          assessmentDateType.length > 0 && (
                            <div
                              style={{
                                width: "100%",
                                padding:
                                  customMobileLandscape && assessmentOptionsVisible
                                    ? "10px 100px"
                                    : showCompareMT || showTimeMT || showTreatmentMT
                                    ? "0px 0px 0px 20px"
                                    : "10px 30px",
                                position: "absolute",
                                top: "110px",
                                left: 0,
                                zIndex: 99,
                                backgroundColor: "white",
                                boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
                                height: customMobileLandscape ? "calc(100vh - 160px)" : "100%",
                                overflow: "auto"
                              }}>
                              <div>
                                {assessmentOptionsVisible ? (
                                  <>
                                    <div
                                      className="text-right py-2 cursor-pointer"
                                      onClick={() => {
                                        setAssessmentDateType([]);
                                        setShowTreatmentMT(false);
                                        setShowTimeMT(false);
                                        setShowCompareMT(false);
                                      }}
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "700"
                                      }}>
                                      <span style={{ marginRight: "10px" }}>
                                        <img
                                          src={Clipboard}
                                          height={24}
                                          width={24}
                                          onClick={handleRatingDescription}
                                        />
                                      </span>
                                      <span style={{ borderBottom: "1px solid" }}>CLEAR ALL</span>
                                    </div>
                                    <Assessmentdatecombinations
                                      customMobilePortrait={customMobilePortrait}
                                      assessmentDateType={assessmentDateType}
                                      orderArray={orderArray}
                                      setAssessmentDateType={setAssessmentDateType}
                                      setSelectedCombinationForHeatMap={
                                        setSelectedCombinationForHeatMap
                                      }
                                      selectedCombinationForHeatMap={selectedCombinationForHeatMap}
                                      isMobile={isMobile}
                                      selectedCombinationForEyeOpen={selectedCombinationForEyeOpen}
                                      setSelectedCombinationForEyeOpen={
                                        setSelectedCombinationForEyeOpen
                                      }
                                      showTreatment={showTreatmentMT}
                                      showTime={showTimeMT}
                                      showCompare={showCompareMT}
                                      setTreatmentDataLoaded={setTreatmentDataLoaded}
                                      setFightDataLoaded={setFightDataLoaded}
                                      setCompareData1Loaded={setCompareData1Loaded}
                                      setCompareData2Loaded={setCompareData2Loaded}
                                      setSelectedTrialInfoForEyeOpen={
                                        setSelectedTrialInfoForEyeOpen
                                      }
                                    />
                                  </>
                                ) : (
                                  <DifferentCharts
                                    modelBool={closeModal}
                                    isMobile={customMobilePortrait}
                                    chartsValue={chartsValue}
                                    compareChartsValue={compareChartsValue}
                                    showTreatment={showTreatmentMT}
                                    showTime={showTimeMT}
                                    isModel={showTimeMT}
                                    barData={barData}
                                    barData2={barData2}
                                    flightData={flightData}
                                    setBarData={setBarData}
                                    setBarData2={setBarData2}
                                    setFlightData={setFlightData}
                                    noAssesment={noAssesment}
                                    noAssesment2={noAssesment2}
                                    noMean={noMean}
                                    noMean2={noMean2}
                                    error={error}
                                    isMobileLandscape={customMobileLandscape}
                                    isTablet={customTabletDimensions()}
                                    showCompare={showCompareMT}
                                    handleChartRadio={handleChartRadio}
                                    handleCompareChartRadio={handleCompareChartRadio}
                                    assessmentDateType={assessmentDateType}
                                    treatmentDataLoaded={treatmentDataLoaded}
                                    flightDataLoaded={flightDataLoaded}
                                    compareData1Loaded={compareData1Loaded}
                                    compareData2Loaded={compareData2Loaded}
                                    collectionDate1={assessmentDateType[0]?.date}
                                    collectionDate2={assessmentDateType[1]?.date}
                                    navbarButtons={navbarButtons}
                                    highlightPlots={highlightPlots}
                                    chartPointValue={chartPointValue}
                                    setChartPointValue={setChartPointValue}
                                    subPlots={subPlotRepList}
                                    type={plotOrRep}
                                    selectedSubplots={selectedSubplots}
                                    setSelectedSubplots={setSelectedSubplots}
                                    setChartButtonSelected={SetChartButtonSelected}
                                    chartButtonSelected={chartButtonSelected}
                                    setSeletedSubPlotOptions={setSeletedSubPlotOptions}
                                    timeChartUrl={timeChartUrl}
                                    showTreatmentUrl={showTreatmentUrl}
                                    showCompareUrl={showCompareUrl}
                                    compareCorrelationUrl={compareCorrelationUrl}
                                    trialSubplot={trialSubPlot}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  <Row style={{ position: "unset", height: "100%" }}>
                    {navbarButtons.includes("Drone") || navbarButtonsUrl ? (
                      <Col
                        className="pr-0"
                        xs={
                          !(
                            customMobileLandscape ||
                            customMobilePortrait ||
                            customTabletDimensions()
                          ) &&
                          // (treatmentLabel || chartState) &&
                          navbarButtons?.length > 1 &&
                          navbarButtons?.includes("Drone") &&
                          droneExpand === false
                            ? 6
                            : 12
                        }
                        style={{
                          visibility:
                            navbarButtonsUrl !== null && !navbarButtons.includes("Drone")
                              ? "hidden"
                              : "visible",
                          width: "100%",
                          position: "none",
                          order: 1,
                          height:
                            expandContainerForTab &&
                            !(customMobilePortrait || customMobileLandscape || isDesktop) &&
                            navbarButtons?.length > 1 &&
                            navbarButtons?.includes("Drone")
                              ? "55%"
                              : "100%"
                        }}>
                        <div
                          ref={mapRef}
                          id="map"
                          data-testid="map"
                          className={`leaflet-container leaflet-retina ${
                            expandContainerForTab &&
                            customTabletDimensions() &&
                            !customMobileLandscape &&
                            navbarButtons?.length > 1
                              ? "zoom-icon-at-fifty"
                              : ""
                          } ${
                            !customMobileLandscape &&
                            expandIcon &&
                            isDesktop &&
                            navbarButtons?.length > 1 &&
                            navbarButtons?.includes("Drone") &&
                            droneExpand === false &&
                            "zoom-move-to-left leaflet-container"
                          }
                         ${customMobileLandscape || customMobilePortrait ? "map-on-mobile" : ""}`}
                          style={{
                            zIndex: droneExpand === true ? 998 : 997,
                            height: "100%"
                          }}>
                          {showDroneSelectedMessage ? (
                            <div
                              className="drone-images-message"
                              ref={droneMessageRef}
                              onClick={(e) => e.stopPropagation()}>
                              <div className="d-flex align-items-center justify-content-between ">
                                <span style={{ marginLeft: "8px", marginRight: "10px" }}>
                                  <InfoCircleFill height={20} width={20} />
                                </span>
                                <span className="mr-4 font-size-small font-weight-700">
                                  Only 8 Plots can be selected one time
                                </span>
                                <span>
                                  <X
                                    onClick={() => setShowDroneSelectedMessage(false)}
                                    width={24}
                                    height={24}
                                    style={{
                                      height: "24px",
                                      width: "24px",
                                      marginLeft: "20px",
                                      cursor: "pointer"
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {navbarButtons.length > 1 && (
                          <div
                            className="panel-title-bar d-flex align-items-center"
                            role="button"
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              padding: "10px 30px",
                              color: "#0D1A40",
                              position: "absolute",
                              top: 0,
                              zIndex: 998,
                              width: "100%",
                              maxWidth: "100%",
                              marginLeft: "-15px"
                            }}>
                            <span className="text-left mr-auto font-size-medium">
                              Drone / Layout
                            </span>
                            {navbarButtons?.length > 1 && (
                              <>
                                <span>
                                  <Button
                                    className="d-flex expand-button"
                                    onClick={() => {
                                      setDroneExpand(!droneExpand);
                                      setTimeout(() => {
                                        if (mapLayout) {
                                          if (typeof mapLayout.invalidateSize === "function") {
                                            mapLayout.invalidateSize();
                                          }
                                        }
                                      }, 100);
                                    }}>
                                    {droneExpand === true ? (
                                      <ArrowsAngleContract
                                        color="black"
                                        alignItems="center"
                                        width={16}
                                        height={16}
                                      />
                                    ) : (
                                      <ArrowsAngleExpand
                                        color="black"
                                        alignItems="center"
                                        width={16}
                                        height={16}
                                      />
                                    )}
                                  </Button>
                                </span>
                                {!droneExpand && (
                                  <span>
                                    <X
                                      onClick={() => {
                                        setTreatmentExpansion(false);
                                        const updatedNavbarButtons = navbarButtons.filter(
                                          (name) => name !== "Drone"
                                        );
                                        setTimeout(() => {
                                          if (mapLayout) {
                                            if (typeof mapLayout.invalidateSize === "function") {
                                              mapLayout.invalidateSize();
                                            }
                                          }
                                        }, 100);
                                        setBarExpand(false);
                                        setDroneExpand(false);
                                        setNavbarButtons(updatedNavbarButtons);
                                      }}
                                      style={{ marginLeft: "10px", cursor: "pointer" }}
                                      color="#0D1A40"
                                      width={24}
                                      height={24}
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        {selectedCombinationForHeatMap && (
                          <div
                            className={`heat-map-legends-container ${
                              customMobileLandscape || customMobilePortrait ? "mobile-legends" : ""
                            }`}
                            style={{
                              top:
                                !(customMobilePortrait || customMobileLandscape) &&
                                assessmentDrawerVisible
                                  ? "84px"
                                  : customMobilePortrait || customMobileLandscape
                                  ? "56px"
                                  : "24px",
                              zIndex: droneExpand === true ? 998 : 997
                            }}>
                            <button
                              className="btn bg-white d-flex justify-content-center align-items-center w-100 legend-button"
                              onClick={() => setShowHeatMapLegends(!showHeatMapLegend)}
                              style={{
                                boxShadow:
                                  customMobilePortrait || customMobileLandscape
                                    ? "-4px 0px 4px -2px rgba(0, 0, 0, 0.3)"
                                    : "0px 4px 4px -2px rgba(0, 0, 0, 0.3)"
                              }}>
                              <img src={heatMapColoredIcon} alt="" />
                              <span className="legend-label">Legend</span>
                            </button>
                            {showHeatMapLegend && (
                              <div
                                className={`bg-white p-1 ${
                                  customMobilePortrait || customMobileLandscape
                                    ? "mr-2"
                                    : "mt-2 d-flex align-items-center p-2"
                                }`}>
                                {customMobilePortrait || customMobileLandscape ? (
                                  <div
                                    className="legend-box"
                                    style={{
                                      background:
                                        heatMapLegendColors.length === 1
                                          ? `linear-gradient(${
                                              customMobilePortrait || customMobileLandscape
                                                ? "to left"
                                                : "to bottom"
                                            }, ${heatMapLegendColors[0]?.color}, ${
                                              heatMapLegendColors[0]?.color
                                            }`
                                          : `linear-gradient(${
                                              customMobilePortrait || customMobileLandscape
                                                ? "to left"
                                                : "to bottom"
                                            }, ${heatMapLegendColors
                                              .map(({ color }) => color)
                                              .filter(
                                                (color) =>
                                                  color?.length === 7 || color?.length === 4
                                              )
                                              .join(", ")})`,
                                      width:
                                        heatMapLegendRange &&
                                        heatMapLegendRange.every(
                                          (num) => num === undefined || num === null || isNaN(num)
                                        )
                                          ? "0px"
                                          : heatMapLegendRange.every((num) => num === 0)
                                          ? "12px"
                                          : "200px"
                                    }}></div>
                                ) : (
                                  <div
                                    className="legend-box"
                                    style={{
                                      background:
                                        heatMapLegendColors.length === 1
                                          ? `linear-gradient(${
                                              customMobilePortrait || customMobileLandscape
                                                ? "to left"
                                                : "to bottom"
                                            }, ${heatMapLegendColors[0]?.color}, ${
                                              heatMapLegendColors[0]?.color
                                            }`
                                          : `linear-gradient(${
                                              customMobilePortrait || customMobileLandscape
                                                ? "to left"
                                                : "to bottom"
                                            }, ${heatMapLegendColors
                                              .map(({ color }) => color)
                                              .filter(
                                                (color) =>
                                                  color?.length === 7 || color?.length === 4
                                              )
                                              .join(", ")})`,
                                      height:
                                        heatMapLegendRange &&
                                        heatMapLegendRange.every(
                                          (num) => num === undefined || num === null || isNaN(num)
                                        )
                                          ? "0px"
                                          : heatMapLegendRange.every((num) => num === 0)
                                          ? "12px"
                                          : "128px"
                                    }}></div>
                                )}
                                {customMobilePortrait || customMobileLandscape ? (
                                  <ul className="no-bullets pl-2">
                                    {heatMapLegendRange &&
                                    heatMapLegendRange.every(
                                      (num) =>
                                        num === undefined || num === null || isNaN(num) || num === 0
                                    ) ? (
                                      <li>{heatMapLegendRange?.[0] === 0 ? "0" : "n/a"}</li>
                                    ) : (
                                      heatMapLegendRange.map((num, index) => (
                                        <li key={index}>
                                          {num || num === 0 ? toFixedIfNecessary(num, 3) : "n/a"}
                                        </li>
                                      ))
                                    )}
                                  </ul>
                                ) : (
                                  <ul
                                    className="no-bullets pl-2"
                                    style={{
                                      height:
                                        heatMapLegendRange &&
                                        heatMapLegendRange.every(
                                          (num) =>
                                            num === undefined ||
                                            num === null ||
                                            isNaN(num) ||
                                            num === 0
                                        )
                                          ? "10px"
                                          : "128px"
                                    }}>
                                    {heatMapLegendRange &&
                                    heatMapLegendRange.every(
                                      (num) =>
                                        num === undefined || num === null || isNaN(num) || num === 0
                                    ) ? (
                                      <li key={heatMapLegendRange?.[0] === 0 ? "0" : "n/a"}>
                                        {heatMapLegendRange?.[0] === 0 ? "0" : "n/a"}
                                      </li>
                                    ) : (
                                      heatMapLegendRange.map((num, index) => (
                                        <li key={index}>
                                          {num || num === 0 ? toFixedIfNecessary(num, 3) : "n/a"}
                                        </li>
                                      ))
                                    )}
                                  </ul>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {(customMobilePortrait || customMobileLandscape) && (
                          <MobileTools
                            isMobileLandscape={customMobileLandscape}
                            ShowFullMap={ShowFullMap}
                            rotateMapZeroDegreeClick={rotateMapZeroDegree}
                            rotateMapZeroDegreeDisable={trialNotSelected}
                            rotateMapZeroDegreeHide={trialType === "manual"}
                          />
                        )}
                      </Col>
                    ) : null}
                    {navbarButtons.includes("Treatment Labels") ||
                    ((customTabletDimensions() || isMobileOnly) &&
                      tabletNavbarButton === "Treatment Labels") ? (
                      <Col
                        className={
                          treatmentExpansion
                            ? "pl-2"
                            : navbarButtons.length > 1 &&
                              navbarButtons.includes("Treatment Labels") &&
                              navbarButtons.findIndex((i) => i === "Treatment Labels") + 1 == 2
                            ? "pr-0 pl-0"
                            : navbarButtons.length > 1 && navbarButtons.includes("Treatment Labels")
                            ? "pr-1"
                            : ""
                        }
                        xs={
                          navbarButtons.length > 1 &&
                          navbarButtons.includes("Treatment Labels") &&
                          treatmentExpansion === false
                            ? 6
                            : 12
                        }
                        style={{
                          order: navbarButtons.includes("Drone")
                            ? 2
                            : navbarButtons.findIndex((i) => i === "Treatment Labels") + 1
                        }}>
                        {(navbarButtons.includes("Treatment Labels") ||
                          tabletNavbarButton === "Treatment Labels") &&
                          selectedOption !== "All trials" &&
                          selectedOption !== "No trials" && (
                            <div
                              style={{
                                position: "fixed",
                                zIndex: customTabletDimensions()
                                  ? 998
                                  : treatmentExpansion === true
                                  ? 998
                                  : navbarButtons?.length > 1 && flightContainerVisible
                                  ? 9
                                  : isMobileOnly
                                  ? 9999
                                  : 997,
                                top:
                                  (customTabletDimensions() && !customMobileLandscape) ||
                                  navbarButtons.includes("Treatment Labels") ||
                                  !navbarButtons.includes("Drone")
                                    ? isMobileOnly
                                      ? "60px"
                                      : "120px"
                                    : isMobileOnly
                                    ? "60px"
                                    : "120px",
                                width:
                                  !(
                                    customMobilePortrait ||
                                    customMobileLandscape ||
                                    customTabletDimensions()
                                  ) &&
                                  expandIcon &&
                                  navbarButtons.length > 1 &&
                                  treatmentExpansion === false
                                    ? "50%"
                                    : "100%",
                                backgroundColor: "#F9F9F9",
                                height:
                                  (isTablet && !customMobileLandscape) ||
                                  navbarButtons.includes("Treatment Labels") ||
                                  !navbarButtons.includes("Drone")
                                    ? isMobileOnly
                                      ? "calc(100% - 60px)"
                                      : "calc(100% - 120px)"
                                    : isMobileOnly
                                    ? "calc(100% - 60px)"
                                    : "calc(100% - 120px)"
                              }}>
                              {treatmentLabelTable()}
                            </div>
                          )}
                      </Col>
                    ) : null}
                    {!(customMobilePortrait || customMobileLandscape) &&
                      (navbarButtons.includes("Charts") ||
                        ((customTabletDimensions() || isMobileOnly) &&
                          tabletNavbarButton === "Charts")) &&
                      assessmentDateType.length !== 0 && (
                        <Col
                          className={
                            navbarButtons.length > 1 &&
                            navbarButtons.includes("Charts") &&
                            !barExpand
                              ? "pr-0 pl-0"
                              : ""
                          }
                          xs={
                            navbarButtons.length > 1 &&
                            navbarButtons.includes("Charts") &&
                            barExpand === false
                              ? 6
                              : 12
                          }
                          style={{
                            width: "100%",
                            order: navbarButtons.includes("Drone")
                              ? 2
                              : navbarButtons.findIndex((i) => i === "Charts") + 1
                          }}>
                          <div
                            style={{
                              top: navbarButtons.length > 1 && barExpand ? "120px" : "",
                              width: "100%",
                              position: barExpand ? "fixed" : "absolute",
                              left: 0,
                              bottom: 0,
                              zIndex: customTabletDimensions()
                                ? 998
                                : barExpand
                                ? 998
                                : navbarButtons?.length > 1 && flightContainerVisible
                                ? 9
                                : 997,
                              backgroundColor: "white",
                              boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
                              height: "calc(100vh - 60px)",
                              overflowY: "auto"
                            }}>
                            <div
                              className="split-scrollbar"
                              style={{
                                width: "99%",
                                height: "100vh",
                                paddingLeft:
                                  navbarButtons.length > 1 &&
                                  navbarButtons.includes("Charts") &&
                                  navbarButtons.findIndex((i) => i === "Charts") === 0
                                    ? "10px"
                                    : ""
                              }}>
                              <div
                                className="panel-title-bar d-flex align-items-center"
                                role="button"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  padding:
                                    navbarButtons.length > 1 && navbarButtons.includes("Charts")
                                      ? "10px"
                                      : "10px 30px",
                                  color: "#0D1A40"
                                }}>
                                <span className="text-left mr-auto font-size-medium">Charts</span>

                                <div className="d-flex align-items-center">
                                  {navbarButtons?.length > 1 && (
                                    <>
                                      <span>
                                        <Button
                                          className="d-flex expand-button"
                                          onClick={() => setBarExpand(!barExpand)}>
                                          {barExpand === true ? (
                                            <ArrowsAngleContract
                                              color="black"
                                              alignItems="center"
                                              width={16}
                                              height={16}
                                            />
                                          ) : (
                                            <ArrowsAngleExpand
                                              color="black"
                                              alignItems="center"
                                              width={16}
                                              height={16}
                                            />
                                          )}
                                        </Button>
                                      </span>
                                      {!barExpand && (
                                        <span>
                                          <X
                                            onClick={() => {
                                              setTreatmentExpansion(false);
                                              const updatedNavbarButtons = navbarButtons.filter(
                                                (name) => name !== "Charts"
                                              );
                                              setTimeout(() => {
                                                if (mapLayout) {
                                                  if (
                                                    typeof mapLayout.invalidateSize === "function"
                                                  ) {
                                                    mapLayout.invalidateSize();
                                                  }
                                                }
                                              }, 100);
                                              setBarExpand(false);
                                              setNavbarButtons(updatedNavbarButtons);
                                            }}
                                            style={{ marginLeft: "10px", cursor: "pointer" }}
                                            color="#0D1A40"
                                            width={24}
                                            height={24}
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div
                                className=" d-flex align-items-center font-size-small font-weight-700"
                                role="button"
                                style={{
                                  padding:
                                    navbarButtons.length > 1 && navbarButtons.includes("Charts")
                                      ? "10px"
                                      : "10px 30px",
                                  color: "#0D1A40",
                                  float: "right"
                                }}>
                                <div className="d-flex align-items-center">
                                  <Button
                                    title="Rating Description"
                                    disabled={
                                      !selectedOption ||
                                      ["All trials", "No trials"].includes(selectedOption)
                                    }
                                    className="d-flex align-items-center"
                                    style={{
                                      zIndex: isShowRatingdescription ? 9999 : 99,
                                      border: "none",
                                      padding: customTabletDimensions() ? "0px 25px" : "0px 10px",
                                      boxShadow: "none",
                                      backgroundColor: "transparent"
                                    }}
                                    onClick={handleRatingDesktopDescription}>
                                    <img src={Clipboard} height={24} width={24} alt="" />
                                  </Button>
                                  <span
                                    className="clear-all-span"
                                    onClick={() => {
                                      setAssessmentDateType([]);
                                      const updatedNavbarButtons = navbarButtons.filter(
                                        (name) => name !== "Charts"
                                      );
                                      setTimeout(() => {
                                        setNavbarButtons(updatedNavbarButtons);
                                      }, 100);
                                      setTabletNavbarButton("Drone");
                                      setShowTreatment(true);
                                      setShowTime(false);
                                      setShowCompare(false);
                                    }}>
                                    CLEAR ALL
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  padding: customTabletDimensions()
                                    ? "0px 8px 30px 15px"
                                    : "0px 8px 8px 8px"
                                }}>
                                <Assessmentdatecombinations
                                  assessmentDateType={assessmentDateType}
                                  orderArray={orderArray}
                                  setAssessmentDateType={setAssessmentDateType}
                                  setSelectedCombinationForHeatMap={
                                    setSelectedCombinationForHeatMap
                                  }
                                  selectedCombinationForHeatMap={selectedCombinationForHeatMap}
                                  selectedCombinationForEyeOpen={selectedCombinationForEyeOpen}
                                  setSelectedCombinationForEyeOpen={
                                    setSelectedCombinationForEyeOpen
                                  }
                                  showTreatment={showTreatment}
                                  showTime={showTime}
                                  showCompare={showCompare}
                                  setTreatmentDataLoaded={setTreatmentDataLoaded}
                                  setFightDataLoaded={setFightDataLoaded}
                                  setCompareData1Loaded={setCompareData1Loaded}
                                  setCompareData2Loaded={setCompareData2Loaded}
                                  selectedTrialInfoForEyeOpen={selectedTrialInfoForEyeOpen}
                                  setSelectedTrialInfoForEyeOpen={setSelectedTrialInfoForEyeOpen}
                                />
                              </div>
                              <DesktopAndModalNav
                                modelBool={closeModal}
                                showTreatment={showTreatment}
                                setShowTreatment={setShowTreatment}
                                barExpand={barExpand}
                                setBarExpand={setBarExpand}
                                showTime={showTime}
                                setShowTime={setShowTime}
                                setShowCompare={setShowCompare}
                                showCompare={showCompare}
                                flightDataLoaded={flightDataLoaded}
                                setFightDataLoaded={setFightDataLoaded}
                                setTreatmentDataLoaded={setTreatmentDataLoaded}
                                treatmentDataLoaded={treatmentDataLoaded}
                                setCompareData1Loaded={setCompareData1Loaded}
                                setCompareData2Loaded={setCompareData2Loaded}
                                compareData1Loaded={compareData1Loaded}
                                compareData2Loaded={compareData2Loaded}
                                timeChartUrl={timeChartUrl}
                                showTreatmentUrl={showTreatmentUrl}
                                showCompareUrl={showCompareUrl}
                              />
                              <DifferentCharts
                                modelBool={closeModal}
                                isMobile={customMobilePortrait}
                                chartsValue={chartsValue}
                                compareChartsValue={compareChartsValue}
                                showTreatment={showTreatment}
                                showTime={showTime}
                                isModel={showTime}
                                barData={barData}
                                barData2={barData2}
                                flightData={flightData}
                                setBarData={setBarData}
                                setBarData2={setBarData2}
                                setFlightData={setFlightData}
                                noAssesment={noAssesment}
                                noAssesment2={noAssesment2}
                                noMean={noMean}
                                noMean2={noMean2}
                                error={error}
                                isMobileLandscape={customMobileLandscape}
                                isTablet={customTabletDimensions()}
                                showCompare={showCompare}
                                handleChartRadio={handleChartRadio}
                                handleCompareChartRadio={handleCompareChartRadio}
                                assessmentDateType={assessmentDateType}
                                treatmentDataLoaded={treatmentDataLoaded}
                                flightDataLoaded={flightDataLoaded}
                                compareData1Loaded={compareData1Loaded}
                                compareData2Loaded={compareData2Loaded}
                                collectionDate1={assessmentDateType[0]?.date}
                                collectionDate2={assessmentDateType[1]?.date}
                                navbarButtons={navbarButtons}
                                barExpand={barExpand}
                                highlightPlots={highlightPlots}
                                chartPointValue={chartPointValue}
                                setChartPointValue={setChartPointValue}
                                subPlots={subPlotRepList}
                                type={plotOrRep}
                                selectedSubplots={selectedSubplots}
                                setSelectedSubplots={setSelectedSubplots}
                                setChartButtonSelected={SetChartButtonSelected}
                                chartButtonSelected={chartButtonSelected}
                                setSeletedSubPlotOptions={setSeletedSubPlotOptions}
                                timeChartUrl={timeChartUrl}
                                showTreatmentUrl={showTreatmentUrl}
                                showCompareUrl={showCompareUrl}
                                compareCorrelationUrl={compareCorrelationUrl}
                                trialSubplot={trialSubPlot}
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                    {navbarButtons.includes("Environmental") ||
                    ((customTabletDimensions() || isMobileOnly) &&
                      tabletNavbarButton === "Environmental") ? (
                      <Col
                        className={
                          navbarButtons.length > 1 &&
                          navbarButtons.includes("Environmental") &&
                          !envExpand
                            ? "pl-0"
                            : ""
                        }
                        xs={
                          !isTablet &&
                          !customTabletDimensions() &&
                          navbarButtons.length > 1 &&
                          navbarButtons.includes("Environmental") &&
                          !envExpand
                            ? 6
                            : 12
                        }
                        style={{
                          borderLeft: "1px solid rgb(249, 249, 249)",
                          order: navbarButtons.includes("Drone")
                            ? 2
                            : navbarButtons.findIndex((i) => i === "Environmental") + 1
                        }}
                        id={navbarButtons.findIndex((i) => i === "Environmental")}>
                        {
                          // openEnv &&
                          (navbarButtons.includes("Environmental") ||
                            tabletNavbarButton === "Environmental") &&
                            ((selectedOption !== "All trials" && selectedOption !== "No trials") ||
                              isImgAvailableLatest.current) && (
                              <div
                                style={{
                                  position: "fixed",
                                  backgroundColor: "#FFF",
                                  zIndex: isMobileOnly
                                    ? 999
                                    : customTabletDimensions()
                                    ? 998
                                    : envExpand
                                    ? 998
                                    : navbarButtons?.length > 1 && flightContainerVisible
                                    ? 9
                                    : isTablet || customTabletDimensions()
                                    ? 999
                                    : 997,
                                  top: isMobileOnly
                                    ? "60px"
                                    : (isTablet && !customMobileLandscape) ||
                                      navbarButtons.includes("Environmental") ||
                                      !navbarButtons.includes("Drone")
                                    ? "120px"
                                    : "120px",
                                  width:
                                    !(
                                      customMobilePortrait ||
                                      customMobileLandscape ||
                                      customTabletDimensions()
                                    ) &&
                                    expandIcon &&
                                    navbarButtons.length > 1 &&
                                    envExpand === false &&
                                    navbarButtons.findIndex((i) => i === "Environmental") === 1
                                      ? "49.8%"
                                      : !isTablet &&
                                        !customTabletDimensions() &&
                                        expandIcon &&
                                        navbarButtons.length > 1 &&
                                        envExpand === false
                                      ? "50.7%"
                                      : "100%",
                                  height: isMobileOnly
                                    ? "calc(100% - 60px)"
                                    : (isTablet && !customMobileLandscape) ||
                                      (navbarButtons.includes("Environmental") &&
                                        !assessmentDrawerVisible) ||
                                      !navbarButtons.includes("Drone")
                                    ? "calc(100% - 120px)"
                                    : (navbarButtons.includes("Environmental") &&
                                        !assessmentDrawerVisible) ||
                                      navbarButtons.includes("Drone")
                                    ? "calc(100% - 120px)"
                                    : "calc(100% - 180px)"
                                }}>
                                {isMobileOnly ? (
                                  <div className="w-100 d-flex align-items-center justify-content-between general-border-bottom height-50px">
                                    <div>
                                      <span>
                                        <ChevronLeft
                                          onClick={() => {
                                            setTabletNavbarButton("Drone");
                                            setHamburgerOpen(true);
                                          }}
                                          className="ml-2"
                                          width={16}
                                          height={16}
                                        />{" "}
                                      </span>
                                      <span className="pl-2 font-weight-700 font-size-large">
                                        Environmental
                                      </span>

                                      <div className="d-flex align-items-center">
                                        {navbarButtons?.length > 1 && (
                                          <>
                                            <span>
                                              <Button
                                                className="d-flex expand-button"
                                                onClick={() => setEnvExpand(!envExpand)}>
                                                {envExpand === true ? (
                                                  <ArrowsAngleContract
                                                    color="black"
                                                    alignItems="center"
                                                    width={16}
                                                    height={16}
                                                  />
                                                ) : (
                                                  <ArrowsAngleExpand
                                                    color="black"
                                                    alignItems="center"
                                                    width={16}
                                                    height={16}
                                                  />
                                                )}
                                              </Button>
                                            </span>
                                            {!envExpand && (
                                              <span>
                                                <X
                                                  onClick={() => {
                                                    // setOpenEnv(false);
                                                    unsortedButtons = unsortedButtons.filter(
                                                      (item) => item != "Environmental"
                                                    );
                                                    setNavbarButtons(
                                                      navbarButtons.filter(
                                                        (item) => item != "Environmental"
                                                      )
                                                    );
                                                    setTimeout(() => {
                                                      if (mapLayout) {
                                                        if (
                                                          typeof mapLayout.invalidateSize ===
                                                          "function"
                                                        ) {
                                                          mapLayout.invalidateSize();
                                                        }
                                                      }
                                                    }, 100);
                                                  }}
                                                  style={{ marginLeft: "10px", cursor: "pointer" }}
                                                  color="#0D1A40"
                                                  width={24}
                                                  height={24}
                                                />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <X
                                        onClick={() => {
                                          setTabletNavbarButton("Drone");
                                        }}
                                        width={16}
                                        height={16}
                                        style={{ height: "24px", width: "24px" }}
                                        className="mr-2"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="panel-title-bar d-flex align-items-center font-size-small font-weight-700"
                                    role="button"
                                    style={{
                                      padding:
                                        navbarButtons.length > 1 &&
                                        navbarButtons.includes("Environmental")
                                          ? "10px"
                                          : isTablet || customTabletDimensions()
                                          ? "30px 10px"
                                          : "10px 30px",
                                      marginLeft:
                                        navbarButtons.findIndex((i) => i === "Environmental") === 0
                                          ? "10px"
                                          : "",
                                      color: "#0D1A40",
                                      backgroundColor:
                                        isTablet || customTabletDimensions() ? "#FFF" : "#F9F9F9"
                                    }}>
                                    <span className="text-left mr-auto font-size-medium">
                                      Environmental
                                    </span>

                                    <div className="d-flex align-items-center">
                                      {navbarButtons?.length > 1 && (
                                        <>
                                          <span>
                                            <Button
                                              className="d-flex expand-button"
                                              onClick={() => setEnvExpand(!envExpand)}>
                                              {envExpand === true ? (
                                                <ArrowsAngleContract
                                                  color="black"
                                                  alignItems="center"
                                                  width={16}
                                                  height={16}
                                                />
                                              ) : (
                                                <ArrowsAngleExpand
                                                  color="black"
                                                  alignItems="center"
                                                  width={16}
                                                  height={16}
                                                />
                                              )}
                                            </Button>
                                          </span>
                                          {!envExpand && (
                                            <span>
                                              <X
                                                onClick={() => {
                                                  // setOpenEnv(false);
                                                  unsortedButtons = unsortedButtons.filter(
                                                    (item) => item != "Environmental"
                                                  );
                                                  setNavbarButtons(
                                                    navbarButtons.filter(
                                                      (item) => item != "Environmental"
                                                    )
                                                  );
                                                  setTimeout(() => {
                                                    if (mapLayout) {
                                                      mapLayout.invalidateSize();
                                                    }
                                                  }, 100);
                                                  setSelectedWeatherDate("");
                                                }}
                                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                                color="#0D1A40"
                                                width={24}
                                                height={24}
                                              />
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <WeatherChart
                                  deviceId={deviceId}
                                  weatherDevices={weatherDevices}
                                  soilDeviceId={soilDeviceId}
                                  envExpand={envExpand}
                                  deviceTrial={deviceTrial}
                                  selectedTrial={selectedOption}
                                  geometry={
                                    Object.keys(firstPlotLatLng)?.length > 0 &&
                                    firstPlotLatLng?.lat !== null &&
                                    firstPlotLatLng?.lng !== null
                                      ? firstPlotLatLng
                                      : Object.keys(geometry)?.length > 0
                                      ? geometry
                                      : trialGeometry
                                  }
                                  customMobilePortrait={customMobilePortrait}
                                  customMobileLandscape={customMobileLandscape}
                                  envTrack={envTrack}
                                  isMobileOnly={isMobileOnly}
                                  planDataLoaded={planDataLoaded}
                                  selectedWeatherDate={selectedWeatherDate}
                                  setSelectedWeatherDate={setSelectedWeatherDate}
                                  selectedWeatherDevices={selectedWeatherDevices}
                                  setSelectedWeatherDevices={setSelectedWeatherDevices}
                                  selectedWeatherDateUrl={selectedWeatherDateUrl}
                                  selectedWeatherDevicesUrl={selectedWeatherDevicesUrl}
                                  weatherEndDate={weatherEndDate}
                                  setWeatherEndDate={setWeatherEndDate}
                                  weatherStartDate={weatherStartDate}
                                  setWeatherStartDate={setWeatherStartDate}
                                  weatherForwardDays={weatherForwardDays}
                                  setWeatherForwardDays={setWeatherForwardDays}
                                  setWeatherBackwardDays={setWeatherBackwardDays}
                                  weatherBackwardDays={weatherBackwardDays}
                                  weatherSelectedCategory={weatherSelectedCategory}
                                  setWeatherSelectedCategory={setWeatherSelectedCategory}
                                  displayInfoIcon={displayInfoIcon}
                                  setDisplayInfoIcon={setDisplayInfoIcon}
                                />
                              </div>
                            )
                        }
                      </Col>
                    ) : null}
                    {navbarButtons.includes("Plot Images") ||
                    ((customTabletDimensions() || isMobileOnly) &&
                      tabletNavbarButton === "Plot Images") ? (
                      <Col
                        data-testid="plot-images-container"
                        className={
                          navbarButtons.length > 1 &&
                          navbarButtons.includes("Plot Images") &&
                          !plotImagesExpand
                            ? "pl-0"
                            : ""
                        }
                        xs={
                          !isTablet &&
                          !customTabletDimensions() &&
                          navbarButtons.length > 1 &&
                          navbarButtons.includes("Plot Images") &&
                          !plotImagesExpand
                            ? 6
                            : 12
                        }
                        style={{
                          borderLeft: "1px solid rgb(249, 249, 249)",
                          order: navbarButtons.includes("Drone")
                            ? 2
                            : navbarButtons.findIndex((i) => i === "Plot Images") + 1
                        }}
                        id={navbarButtons.findIndex((i) => i === "Plot Images")}>
                        {
                          // openEnv &&
                          (navbarButtons.includes("Plot Images") ||
                            tabletNavbarButton === "Plot Images") && (
                            <div
                              style={{
                                position: "fixed",
                                backgroundColor: "#FFF",
                                zIndex: isMobileOnly
                                  ? 999
                                  : customTabletDimensions()
                                  ? 998
                                  : plotImagesExpand
                                  ? 998
                                  : navbarButtons?.length > 1 && flightContainerVisible
                                  ? 9
                                  : isTablet || customTabletDimensions()
                                  ? 999
                                  : 997,
                                top: isMobileOnly
                                  ? "60px"
                                  : (isTablet && !customMobileLandscape) ||
                                    navbarButtons.includes("Plot Images") ||
                                    !navbarButtons.includes("Drone")
                                  ? "120px"
                                  : "120px",
                                width:
                                  !(
                                    customMobilePortrait ||
                                    customMobileLandscape ||
                                    customTabletDimensions()
                                  ) &&
                                  expandIcon &&
                                  navbarButtons.length > 1 &&
                                  plotImagesExpand === false &&
                                  navbarButtons.findIndex((i) => i === "Plot Images") === 1
                                    ? "49.8%"
                                    : !isTablet &&
                                      !customTabletDimensions() &&
                                      expandIcon &&
                                      navbarButtons.length > 1 &&
                                      plotImagesExpand === false
                                    ? "50.7%"
                                    : "100%",
                                height: isMobileOnly
                                  ? "calc(100% - 60px)"
                                  : (isTablet && !customMobileLandscape) ||
                                    (navbarButtons.includes("Plot Images") &&
                                      !assessmentDrawerVisible) ||
                                    !navbarButtons.includes("Drone")
                                  ? "calc(100% - 120px)"
                                  : (navbarButtons.includes("Plot Images") &&
                                      !assessmentDrawerVisible) ||
                                    navbarButtons.includes("Drone")
                                  ? "calc(100% - 120px)"
                                  : "calc(100% - 180px)"
                              }}>
                              {isMobileOnly ? (
                                <div
                                  className="w-100 d-flex align-items-center justify-content-between"
                                  style={{ height: "50px", borderBottom: "1px solid #ededed" }}>
                                  <div>
                                    <span>
                                      <ChevronLeft
                                        onClick={() => {
                                          setTabletNavbarButton("Drone");
                                          setHamburgerOpen(true);
                                        }}
                                        className="ml-2"
                                        width={16}
                                        height={16}
                                      />{" "}
                                    </span>
                                    <span className="pl-2 font-size-large font-weight-700">
                                      Plot Images
                                    </span>
                                  </div>
                                  <div>
                                    <X
                                      onClick={() => {
                                        setTabletNavbarButton("Drone");
                                        setSelectedPlots([]);
                                      }}
                                      width={16}
                                      height={16}
                                      style={{ height: "24px", width: "24px" }}
                                      className="mr-2"
                                      data-testid="plot-images-close-button"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="panel-title-bar d-flex align-items-center font-size-small font-weight-700"
                                  role="button"
                                  style={{
                                    padding:
                                      navbarButtons.length > 1 &&
                                      navbarButtons.includes("Plot Images")
                                        ? "10px"
                                        : isTablet || customTabletDimensions()
                                        ? "30px 10px"
                                        : "10px 30px",
                                    marginLeft:
                                      navbarButtons.findIndex((i) => i === "Plot Images") === 0
                                        ? "10px"
                                        : "",
                                    color: "#0D1A40",
                                    backgroundColor:
                                      isTablet || customTabletDimensions() ? "#FFF" : "#F9F9F9"
                                  }}>
                                  <span className="text-left mr-auto font-size-medium">
                                    Plot Images{" "}
                                    <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                      {formatFlightDateWithoutTimeStamp(selectedFlightDate)}
                                    </span>
                                  </span>

                                  <div className="d-flex align-items-center">
                                    {selectedPlots?.length > 0 && (
                                      <div
                                        className="plot-download-icon-container"
                                        onMouseEnter={() => setPlotImageDownload(true)}
                                        onMouseLeave={() => setPlotImageDownload(false)}
                                        style={{
                                          opacity: !downloadedAllPlotImagesIcon ? 0.5 : 1,
                                          pointerEvents: !downloadedAllPlotImagesIcon && "none"
                                        }}
                                        onClick={() => downloadAllImages("left")}>
                                        <img src={downloadIcon} height={24} width={24} />
                                        {plotImageDownload && (
                                          <div className="plot-download-custom-tooltip">
                                            <span className="plot-download-tooltip-text">
                                              Download all plot images
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                    <div
                                      ref={selectPlotFilterButtonRef}
                                      style={{
                                        cursor: "pointer",
                                        paddingRight: "12px",
                                        paddingBottom: "2px"
                                      }}>
                                      <Sliders
                                        onClick={() => setIsSelectPlotOpen(!isSelectPlotOpen)}
                                        height={16}
                                        width={16}
                                      />
                                    </div>
                                    {navbarButtons?.length > 1 && (
                                      <>
                                        <span>
                                          <Button
                                            className="d-flex expand-button"
                                            onClick={() => {
                                              setPlotImagesExpand(!plotImagesExpand);
                                              setTimeout(() => {
                                                renderPlotImages();
                                              }, 200);
                                              if (
                                                plotImagesExpand === true &&
                                                plotImageExpandedPlotId
                                              ) {
                                                setPlotImageExpandedPlotId();
                                                setTimeout(() => {
                                                  renderPlotImages();
                                                }, 500);
                                              }
                                            }}>
                                            {plotImagesExpand === true ? (
                                              <ArrowsAngleContract
                                                color="black"
                                                alignItems="center"
                                                width={16}
                                                height={16}
                                              />
                                            ) : (
                                              <ArrowsAngleExpand
                                                color="black"
                                                alignItems="center"
                                                width={16}
                                                height={16}
                                              />
                                            )}
                                          </Button>
                                        </span>
                                        {!plotImagesExpand && (
                                          <span>
                                            <X
                                              data-testid="plot-images-close-button"
                                              onClick={() => {
                                                // setOpenEnv(false);
                                                unsortedButtons = unsortedButtons.filter(
                                                  (item) => item != "Plot Images"
                                                );
                                                setNavbarButtons(
                                                  navbarButtons.filter(
                                                    (item) => item != "Plot Images"
                                                  )
                                                );
                                                setSelectedPlots([]);
                                                setTimeout(() => {
                                                  if (mapLayout) {
                                                    if (
                                                      typeof mapLayout.invalidateSize === "function"
                                                    ) {
                                                      mapLayout.invalidateSize();
                                                    }
                                                  }
                                                }, 100);
                                              }}
                                              style={{ marginLeft: "10px", cursor: "pointer" }}
                                              color="#0D1A40"
                                              width={24}
                                              height={24}
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div ref={mapplotContainerRef} className={plotImageWrapperClass}>
                                {isSelectPlotOpen ? (
                                  <div
                                    ref={selectPlotDropdownRef}
                                    className="select-plots-dropdown"
                                    aria-labelledby="multiSelectDropdown">
                                    <div className="select-plot-info">
                                      <InfoCircleFill fill="#FFB71E" height={14} width={14} />
                                      <div className="select-plot-text">Select upto 8 plots</div>
                                    </div>
                                    <div
                                      className="font-size-small font-weight-700"
                                      style={{
                                        paddingTop: "4px"
                                      }}>
                                      Plots
                                    </div>
                                    <div>
                                      {plotIdsForPlotImage?.map(({ plotid }) => (
                                        <Form.Check
                                          className="custom-checkbox custom-checkbox-viewer"
                                          style={{ color: "#0D1A40" }}
                                          key={0}
                                          type="checkbox">
                                          <Form.Check.Input
                                            type="checkbox"
                                            onChange={selectPlots}
                                            checked={selectedPlots.includes(plotid.toString())}
                                            label={plotid}
                                            value={plotid}
                                            disabled={
                                              selectedPlots.length === 8 &&
                                              !selectedPlots.includes(plotid.toString())
                                            }
                                            id={plotid.toString()}
                                          />
                                          <Form.Check.Label
                                            htmlFor={plotid.toString()}
                                            style={{ cursor: "pointer" }}>
                                            {plotid}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))}
                                    </div>
                                  </div>
                                ) : null}
                                {selectedPlots.length === 0 && showPlotImageMessage ? (
                                  <div ref={plotImageMessageRef} className="plot-images-message">
                                    <div className="d-flex align-items-center justify-content-between ">
                                      <span style={{ marginLeft: "8px", marginRight: "10px" }}>
                                        <InfoCircleFill height={20} width={20} />
                                      </span>
                                      <span className="mr-4 font-size-small font-weight-700">
                                        Please select at least one plot from the layout to see the
                                        plot images
                                      </span>
                                      <span>
                                        <X
                                          onClick={() => setShowPlotImageMessage(false)}
                                          width={24}
                                          height={24}
                                          style={{
                                            height: "24px",
                                            width: "24px",
                                            marginLeft: "20px",
                                            cursor: "pointer"
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {(plotImageExpandedPlotId
                                  ? [plotImageExpandedPlotId]
                                  : selectedPlots
                                ).map((plotId, index) => (
                                  <div
                                    key={plotId}
                                    className={
                                      index === 0 ? "image-wrapper first-image" : "image-wrapper"
                                    }
                                    style={{
                                      position: "relative",
                                      margin:
                                        plotImagesExpand &&
                                        (index % 2 != 0 ||
                                          (selectedPlots.length === 3 && index === 2))
                                          ? "0 4px 4px 0"
                                          : selectedPlots.length === 1
                                          ? "0 4px"
                                          : "0 4px 4px",
                                      overflow: "hidden",
                                      width: !plotImagesExpand ? "100%" : null,
                                      height: !plotImagesExpand ? "100%" : null
                                    }}>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "transparent"
                                      }}
                                      ref={(el) => generateRefs(el, index, overlayLayerRefs)}>
                                      {copyToClipBoardLoader && plotImagesDotsMenu === plotId && (
                                        <div className="d-flex justify-content-center align-items-center p-3 copytoclipboard-spinner">
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"
                                            ref={spinnerRef}></div>
                                        </div>
                                      )}
                                      <div
                                        // ref={mapplotRef}
                                        ref={(el) => generateRefs(el, index, mapPlotRefs)}
                                        className="no-gridlines"
                                        id={`mapplot${index}`}
                                        style={{
                                          height: "100%",
                                          width: "100%"
                                        }}>
                                        <div
                                          id={`hidePlots${index}`}
                                          className="plot-image-summary">{`${plotId} (${
                                          dronePlotDetails[selectedOption]?.find(
                                            (plot) => plot.plotId == plotId
                                          )?.treatmentNumber || "NA"
                                        })`}</div>
                                        <div
                                          className="plot-image-expand-icon"
                                          id={`flightDate${index}`}
                                          onClick={() => {
                                            if (plotImageExpandedPlotId === plotId) {
                                              setPlotImageExpandedPlotId();
                                              setTimeout(() => {
                                                renderPlotImages();
                                              }, 1000);
                                            } else {
                                              setPlotImageExpandedPlotId(plotId);
                                              selectExpansion(plotId);
                                            }
                                          }}>
                                          {plotImageExpandedPlotId === plotId ? (
                                            <ArrowsAngleContract height={16} width={16} />
                                          ) : (
                                            <ArrowsAngleExpand height={16} width={16} />
                                          )}
                                        </div>
                                        {selectedPlots?.length > 0 && (
                                          <div
                                            id={`threeDots${index}`}
                                            className="plot-image-three-dots"
                                            style={{
                                              opacity: !downloadedAllPlotImagesIcon ? 0.5 : 1,
                                              pointerEvents: !downloadedAllPlotImagesIcon && "none"
                                            }}
                                            onClick={() => setPlotImagesDotsMenu(plotId)}>
                                            <ThreeDots width={16} height={16} />
                                          </div>
                                        )}
                                        {plotImagesDotsMenu === plotId && (
                                          <div>
                                            {plotsHamburgerMenu(
                                              plotId,
                                              index,
                                              selectedFlightDate,
                                              "left"
                                            )}
                                          </div>
                                        )}

                                        <div
                                          id={`closeIcon${index}`}
                                          className="plot-image-cancel-icon">
                                          <X
                                            height={24}
                                            width={24}
                                            onClick={() => {
                                              setSelectedPlots((prevSelectedPlots) =>
                                                prevSelectedPlots.filter((item) => item !== plotId)
                                              );
                                              setPlotImageExpandedPlotId();
                                              setTimeout(() => {
                                                renderPlotImages();
                                              }, 500);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        }
                      </Col>
                    ) : null}

                    {navbarButtons.includes("Compare Plots") &&
                    isDesktop &&
                    !comparePlotExpanded1 &&
                    !comparePlotExpanded2 ? (
                      <Col
                        xs={
                          !isTablet &&
                          !customTabletDimensions() &&
                          !isMobileOnly &&
                          navbarButtons.includes("Compare Plots") &&
                          isDesktop &&
                          6
                        }
                        style={{
                          borderLeft: "1px solid rgb(249, 249, 249)",
                          order: navbarButtons.includes("Compare Plots") && isDesktop && 1
                        }}
                        id={navbarButtons.findIndex((i) => i === "Compare Plots")}>
                        {navbarButtons.includes("Compare Plots") && (
                          <div
                            style={{
                              position: "fixed",
                              backgroundColor: "#FFF",
                              zIndex: 997,
                              top: "120px",
                              width: "50%",
                              height: "calc(100% - 120px)"
                            }}>
                            <div
                              className="panel-title-bar d-flex align-items-center"
                              role="button"
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                                padding: "0px 10px",
                                color: "#0D1A40",
                                backgroundColor:
                                  isTablet || customTabletDimensions() ? "#FFF" : "#F9F9F9"
                              }}>
                              <span className="text-left mr-auto font-size-medium">
                                Compare Plots
                                <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                  {firstImageSource}
                                </span>
                                <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                  {firstImageSource === "Drone"
                                    ? formatFlightDateWithoutTimeStamp(
                                        firstCompareSelectedFlightDate
                                      )
                                    : formatFlightDateWithoutTimeStamp(comparePlotMobileDate1)}
                                </span>
                                {(comparePlotTreatment1 || comparePlotReps1) && (
                                  <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                    {comparePlotTreatment1
                                      ? `Treatment ${comparePlotTreatment1}`
                                      : `REP ${comparePlotReps1}`}
                                  </span>
                                )}
                              </span>

                              <div className="d-flex align-items-center">
                                {(comparePlotTreatment1 || comparePlotReps1) && (
                                  <div
                                    className="plot-download-icon-container"
                                    onMouseEnter={() => setPlotImageDownload(true)}
                                    onMouseLeave={() => setPlotImageDownload(false)}
                                    onClick={() => downloadAllImages("left")}>
                                    <img src={downloadIcon} height={24} width={24} />
                                    {plotImageDownload && (
                                      <div className="plot-download-custom-tooltip">
                                        <span className="plot-download-tooltip-text">
                                          Download all plot images
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="compare-plot-flight-button">
                              <button
                                ref={baseOverlayButtonRef}
                                className="overlay-flight-icon"
                                onClick={() => {
                                  setFirstRepsChevron(false);
                                  setTreatmentsChevron(false);
                                  setCompareFirstFlightContainerVisible(
                                    !compareFirstFlightContainerVisible
                                  );
                                  setCompareSecondFlightContainerVisible(false);
                                }}
                                style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <div
                                  className="overlay-flight-dropdown-toggle"
                                  style={
                                    {
                                      // boxShadow: baseOverlayFlightOpen
                                      //   ? "none"
                                      //   : "rgba(0, 0, 0, 0.3) 0px 2px 5px 2px"
                                    }
                                  }>
                                  <div
                                    className={
                                      selectedFlightDate
                                        ? "flight-overlay-button"
                                        : "flight-overlay-button-withoutdate"
                                    }>
                                    <img
                                      src={OverlayFlightIcon}
                                      alt=""
                                      height={16}
                                      style={{ marginBottom: "5px", color: "#0D1A40" }}
                                    />
                                    <div
                                      className="font-size-extra-small deep-cove-color"
                                      style={{
                                        marginLeft: "2px"
                                      }}>
                                      {firstCompareSelectedFlightDate &&
                                      firstImageSource === "Drone"
                                        ? formatFlightDateWithoutTimeStamp(
                                            firstCompareSelectedFlightDate
                                          )
                                        : formatFlightDateWithoutTimeStamp(comparePlotMobileDate1)}
                                    </div>
                                  </div>
                                </div>
                              </button>
                              {compareFirstFlightContainerVisible && (
                                <div ref={baseOverlayCompare1}>
                                  <Dropdown
                                    show={compareFirstFlightContainerVisible}
                                    //toggleBaseOverlayFlight={toggleBaseOverlayFlight}
                                    className="base-flight-dropdown-show">
                                    <Dropdown.Menu className="flight-overlay-toggle-compare">
                                      <div className="comapre-image-source">
                                        <div className="font-weight-600 deep-cove-color font-size-extra-small font-family-Helvetica">
                                          IMAGE SOURCE
                                        </div>
                                        <div>
                                          {trialType !== "manual" && (
                                            <>
                                              <span className="mr-3">
                                                <span className="mr-1">
                                                  <input
                                                    type="radio"
                                                    height={12}
                                                    width={12}
                                                    onClick={() => {
                                                      setFirstImageSource("Drone");
                                                    }}
                                                    checked={firstImageSource === "Drone"}
                                                  />
                                                </span>
                                                <span className="font-weight-400 deep-cove-color font-size-small font-family-Helvetica">
                                                  Drone
                                                </span>
                                              </span>
                                            </>
                                          )}
                                          {ratingDates?.length > 0 && (
                                            <>
                                              <span className="mr-1">
                                                <input
                                                  type="radio"
                                                  height={12}
                                                  width={12}
                                                  onClick={() => {
                                                    setFirstImageSource("Mobile");
                                                  }}
                                                  checked={firstImageSource === "Mobile"}
                                                />
                                              </span>
                                              <span className="font-weight-400 deep-cove-color font-size-small font-family-Helvetica">
                                                Mobile
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      {
                                        <DropdownButton
                                          drop="end"
                                          variant="Light"
                                          className="first-compare-base-dropdown"
                                          onClick={flightDropdown}
                                          ref={flightDateRef}
                                          // disabled={isDisabled}
                                          title={
                                            <div
                                              className="d-flex justify-content-between align-items-center"
                                              style={{ height: "49px" }}>
                                              <div>
                                                <span>
                                                  <img
                                                    src={mdiCalendarIcon}
                                                    className="compare-calender-icon"
                                                    alt=""
                                                    height={22}
                                                  />
                                                </span>
                                                <span
                                                  className="compare-flight-text"
                                                  style={{
                                                    marginLeft: "20px"
                                                  }}>
                                                  {firstImageSource === "Drone"
                                                    ? "Flight Date"
                                                    : "Mobile Date"}{" "}
                                                  &nbsp;
                                                  {firstCompareSelectedFlightDate &&
                                                  firstImageSource === "Drone"
                                                    ? formatFlightDateWithoutTimeStamp(
                                                        firstCompareSelectedFlightDate
                                                      )
                                                    : formatFlightDateWithoutTimeStamp(
                                                        comparePlotMobileDate1
                                                      )}
                                                </span>
                                                <span
                                                  style={{
                                                    marginLeft: "45px"
                                                  }}>
                                                  <ChevronRight color="#0D1A40" height={8} />
                                                </span>
                                              </div>
                                            </div>
                                          }>
                                          <div
                                            className="scrollbar-assessment p-2"
                                            style={{ background: "white" }}>
                                            <div
                                              ref={flightDatesContainerRef}
                                              className="baseOF-dropdown d-flex flex-column justify-content-between">
                                              <div className="dd-flight-title">
                                                <InfoCircleFill
                                                  data-testid="info-circle-fill"
                                                  fill="#FFB71E"
                                                  height={20}
                                                  width={20}
                                                />{" "}
                                                {firstImageSource === "Drone" ? (
                                                  "Select a flight date to show image"
                                                ) : (
                                                  <div className="compare-plot-dates-container">
                                                    <span className="compare-plot-dates-text1">
                                                      Select a date to show Mobile
                                                    </span>
                                                    <span className="compare-plot-dates-text2">
                                                      images(s)
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                              <div
                                                style={{ paddingTop: "10px", paddingLeft: "20px" }}>
                                                {firstImageSource === "Drone" &&
                                                Object.keys(flightDates)?.length > 0
                                                  ? flightDates &&
                                                    Object.keys(flightDates)?.map((option) => (
                                                      <div className="p-1" key={option}>
                                                        <div className="d-flex align-items-center">
                                                          <input
                                                            type="radio"
                                                            name="options"
                                                            checked={
                                                              firstCompareSelectedFlightDate ===
                                                              option
                                                            }
                                                            onChange={() =>
                                                              handleFirstCompareFlightDateChange(
                                                                option
                                                              )
                                                            }
                                                            id={`radio-${option}`}
                                                            value={option}
                                                          />
                                                          <label
                                                            htmlFor={`radio-${option}`}
                                                            className="comapare-flight-options">
                                                            {formatFlightDate(option)}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    ))
                                                  : ratingDates?.map((option) => (
                                                      <div className="p-1" key={option}>
                                                        <div className="d-flex align-items-center">
                                                          <input
                                                            type="radio"
                                                            name="options"
                                                            checked={
                                                              comparePlotMobileDate1 === option
                                                            }
                                                            onChange={() =>
                                                              setComparePlotMobileDate1(option)
                                                            }
                                                            id={`radio-${option}`}
                                                            value={option}
                                                          />
                                                          <label
                                                            htmlFor={`radio-${option}`}
                                                            className="font-size-small deep-cove-color cursor-pointer">
                                                            {formatFlightDate(option)}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    ))}
                                              </div>
                                            </div>
                                          </div>
                                        </DropdownButton>
                                      }
                                      <div>
                                        {trialType !== "manual" &&
                                          secondImageSource === "Drone" &&
                                          Object.values(rasterLayersList) !== null && (
                                            <div className="overlay-menu">
                                              <p className="my-0 overlay-label">BASE</p>
                                              <div className="d-flex align-items-center overlay-item ">
                                                {Object.values(rasterLayersList).map((text) => (
                                                  <div className="d-flex pr-2" key={text}>
                                                    <input
                                                      type="radio"
                                                      className="assessment-input"
                                                      name="visible"
                                                      onChange={(e) =>
                                                        handleRasterChange(e.target.value, "left")
                                                      }
                                                      checked={baseOverlayComparePlot1 === text}
                                                      value={text}
                                                    />
                                                    <span className="ml-1 input-label">{text}</span>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div>
                                        {trialType !== "manual" && firstImageSource === "Drone" && (
                                          <div
                                            className={"overlay-menu"}
                                            style={
                                              {
                                                // height:
                                                //   overlayVectors !== null ? "94px !important" : ""
                                              }
                                            }>
                                            <p className="my-0 overlay-label">OVERLAY</p>
                                            <div className="d-flex align-items-center overlay-item">
                                              {Object.values(overlayLayersList)?.map((text) => (
                                                <div className="d-flex pr-2" key={text}>
                                                  <input
                                                    type="checkbox"
                                                    className="assessment-input"
                                                    name="overlay"
                                                    value={text}
                                                    onChange={(e) => overlayChecklist(e, "left")}
                                                    checked={activeOverlaysCompareLeft.includes(
                                                      text
                                                    )}
                                                  />
                                                  <span className="ml-1 input-label input-label-checkbox">
                                                    {text}
                                                  </span>
                                                </div>
                                              ))}
                                              {overlayVectors !== null && (
                                                <div
                                                  className="d-flex pr-2"
                                                  key="chkoverlayVectors">
                                                  <input
                                                    type="checkbox"
                                                    className="assessment-input"
                                                    value="Bounding Box"
                                                    onChange={(e) => boundingBoxes(e, "left")}
                                                    checked={boundingBoxCompareLeft}
                                                    id="chkoverlayVectors"
                                                  />
                                                  <span className="ml-1 input-label input-label-checkbox">
                                                    Bounding Box
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="comapare-treatment-reps">
                                        <span className="mr-3">
                                          <span className="mr-1">
                                            <input
                                              type="radio"
                                              height={12}
                                              width={12}
                                              onClick={() =>
                                                setCompareFirstTreatmentReps("Treatment")
                                              }
                                              checked={compareFirstTreatmentReps === "Treatment"}
                                            />
                                          </span>
                                          <span className="font-size-small deep-cove-color font-family-Helvetica font-weight-400">
                                            Treatment
                                          </span>
                                        </span>
                                        <span className="mr-1">
                                          <input
                                            type="radio"
                                            height={12}
                                            width={12}
                                            onClick={() => setCompareFirstTreatmentReps("Reps")}
                                            checked={compareFirstTreatmentReps === "Reps"}
                                          />
                                        </span>
                                        <span className="font-size-small deep-cove-color font-family-Helvetica font-weight-400">
                                          Reps
                                        </span>
                                      </div>
                                      <div>
                                        {compareFirstTreatmentReps === "Treatment" ? (
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ marginTop: "7px", marginLeft: "18px" }}>
                                            <span className="font-size-small font-weight-400 deep-cove-color mr-2">
                                              Treatment
                                            </span>
                                            <span
                                              className="compare-chevron-down"
                                              onClick={() => {
                                                setTreatmentsChevron(!treatmentsChevron),
                                                  setFirstRepsChevron(false);
                                              }}>
                                              <span className="pr-4 light-orange-color font-weight-700 font-size-small">
                                                {comparePlotTreatment1}
                                              </span>
                                              <span className="pr-2">
                                                {treatmentsChevron ? (
                                                  <ChevronUp height={16} width={16} />
                                                ) : (
                                                  <ChevronDown height={16} width={16} />
                                                )}
                                              </span>
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ marginTop: "7px", marginLeft: "18px" }}>
                                            <span
                                              className="font-size-small font-weight-400 deep-cove-color"
                                              style={{ marginRight: "38px" }}>
                                              Reps
                                            </span>
                                            <span
                                              className="compare-chevron-down"
                                              onClick={() => {
                                                setFirstRepsChevron(!firstRepsChevron),
                                                  setTreatmentsChevron(false);
                                              }}>
                                              <span className="pr-4 light-orange-color font-weight-700 font-size-small">
                                                {comparePlotReps1}
                                              </span>
                                              <span className="pr-2">
                                                {firstRepsChevron ? (
                                                  <ChevronUp height={16} width={16} />
                                                ) : (
                                                  <ChevronDown height={16} width={16} />
                                                )}
                                              </span>
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        {treatmentsChevron && (
                                          <div
                                            className="compare-treatments-menu"
                                            ref={firstTreatmentDropdownRef}>
                                            {comparePlotTreatmentData.map((plotId) => (
                                              <div
                                                onClick={() => {
                                                  setComparePlotTreatment1(plotId),
                                                    setComparePlotReps1(),
                                                    comparePlotsImages(plotId);
                                                  setTreatmentsChevron(false);
                                                }}
                                                style={{
                                                  borderBottom: "1px solid #ededed",
                                                  backgroundColor:
                                                    comparePlotTreatment1 === plotId
                                                      ? "#FFE8BC"
                                                      : "white",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  cursor: "pointer",
                                                  fontSize: "14px",
                                                  color: "#0D1A40"
                                                }}
                                                key="">
                                                {plotId}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        {firstRepsChevron && (
                                          <div
                                            className="compare-reps-menu"
                                            ref={firstRepDropdownRef}>
                                            {comparePlotRepsData.map((plotId) => (
                                              <div
                                                onClick={() => {
                                                  setComparePlotReps1(plotId),
                                                    setComparePlotTreatment1(),
                                                    comparePlotsImages(plotId);
                                                  setFirstRepsChevron(false);
                                                }}
                                                style={{
                                                  borderBottom: "1px solid #ededed",
                                                  backgroundColor:
                                                    comparePlotReps1 === plotId
                                                      ? "#FFE8BC"
                                                      : "white",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  cursor: "pointer",
                                                  fontSize: "14px",
                                                  color: "#0D1A40"
                                                }}
                                                key="">
                                                {plotId}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              )}
                            </div>
                            {selectedMobileImagePlots?.length === 0 && comparePlotMessage ? (
                              <div
                                ref={comparePlotMessageRef}
                                className="plot-images-message mobile-image-message">
                                <div className="d-flex align-items-center justify-content-between ">
                                  <span style={{ marginLeft: "8px", marginRight: "10px" }}>
                                    <InfoCircleFill height={20} width={20} />
                                  </span>
                                  <span className="mr-4 font-size-small font-weight-700">
                                    Please select treatment / rep to see the plot images
                                  </span>
                                  <span>
                                    <X
                                      onClick={() => setComparePlotMessage(false)}
                                      width={24}
                                      height={24}
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        marginLeft: "20px",
                                        cursor: "pointer"
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            ) : null}
                            <div
                              style={{
                                height: "calc(100vh - 150px)",
                                maxHeight: "calc(100vh - 150px)",
                                overflowY: "auto",
                                cursor: "pointer",
                                overflowX: "hidden"
                              }}>
                              {(comparePlotTreatment1 || comparePlotReps1) &&
                                (comparePlotExpanded1
                                  ? [comparePlotExpanded1]
                                  : comparePlotsImages1
                                )?.map((plotId, index) => (
                                  <div
                                    key={plotId}
                                    className={
                                      index === 0 ? "image-wrapper first-image" : "image-wrapper"
                                    }
                                    style={{
                                      position: "relative",
                                      margin: "0 4px 4px",
                                      height: "100%",
                                      width: "99%",
                                      cursor: "default"
                                    }}>
                                    {copyToClipBoardLoader &&
                                      comparePlotImagesDotsMenu1 === plotId && (
                                        <div className="d-flex justify-content-center align-items-center p-3 copytoclipboard-spinner">
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"></div>
                                        </div>
                                      )}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "transparent"
                                      }}
                                      ref={(el) =>
                                        generateRefs(el, index, comparePlotLeftOverlayRef)
                                      }>
                                      <div
                                        id={`comparePlotLeft${index}`}
                                        className="no-gridlines"
                                        ref={(el) => generateRefs(el, index, comparePlotLeftRef)}
                                        style={{
                                          position: "relative",
                                          height: "100%",
                                          width: "100%"
                                        }}>
                                        <div
                                          className="plot-image-summary"
                                          id={`hidePlots${index}`}
                                          style={{ left: "70px" }}>{`${plotId} (${
                                          dronePlotDetails[selectedOption]?.find(
                                            (plot) => plot.plotId == plotId
                                          )?.treatmentNumber || "NA"
                                        })`}</div>
                                        <div
                                          onClick={() =>
                                            comparePlotExpanded1 == plotId
                                              ? setComparePlotExpanded1()
                                              : setComparePlotExpanded1(plotId)
                                          }
                                          id={`flightDate${index}`}
                                          className="plot-image-expand-icon"
                                          style={{ top: "24px" }}>
                                          {comparePlotExpanded1 == plotId ? (
                                            <ArrowsAngleContract height={16} width={16} />
                                          ) : (
                                            <ArrowsAngleExpand height={16} width={16} />
                                          )}
                                        </div>
                                        <div
                                          className="plot-image-three-dots"
                                          id={`threeDots${index}`}
                                          onClick={() => {
                                            setComparePlotImagesDotsMenu1(plotId);
                                            // setComparePlotId1(plotId);
                                          }}>
                                          <ThreeDots width={16} height={16} />
                                        </div>
                                        {comparePlotImagesDotsMenu1 === plotId && (
                                          <div>
                                            {plotsHamburgerMenu(
                                              plotId,
                                              index,
                                              selectedFlightDate,
                                              "left"
                                            )}
                                          </div>
                                        )}
                                        <div
                                          id={`closeIcon${index}`}
                                          onClick={() => {
                                            if (
                                              comparePlotsImages1.filter((id) => id != plotId)
                                                ?.length === 0
                                            ) {
                                              setComparePlotReps1(), setComparePlotTreatment1();
                                            }
                                            setComparePlotsImages1(
                                              comparePlotsImages1.filter((id) => id != plotId)
                                            );
                                            setComparePlotExpanded1();
                                          }}
                                          className="plot-image-cancel-icon"
                                          style={{ top: "24px" }}>
                                          <X height={24} width={24} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </Col>
                    ) : null}

                    {navbarButtons.includes("Compare Plots") && isDesktop ? (
                      <Col
                        xs={
                          !isTablet &&
                          !customTabletDimensions() &&
                          !isMobileOnly &&
                          navbarButtons.includes("Compare Plots") &&
                          isDesktop &&
                          (comparePlotExpanded1 || comparePlotExpanded2)
                            ? 12
                            : 6
                        }
                        style={{
                          borderLeft: "1px solid rgb(249, 249, 249)",
                          order: navbarButtons.includes("Compare Plots") && isDesktop && 2,
                          paddingLeft: comparePlotExpanded1 || comparePlotExpanded2 ? "15px" : "2px"
                        }}
                        className="pr-0"
                        id={navbarButtons.findIndex((i) => i === "Compare Plots")}>
                        {navbarButtons.includes("Compare Plots") && isDesktop && (
                          <div
                            style={{
                              position: "fixed",
                              backgroundColor: "#FFF",
                              zIndex: 997,
                              top: "120px",
                              width: comparePlotExpanded1 || comparePlotExpanded2 ? "100%" : "50%",
                              height: "calc(100% - 120px)"
                            }}>
                            <div
                              className="panel-title-bar d-flex align-items-center"
                              role="button"
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                                padding:
                                  navbarButtons.length > 1 &&
                                  navbarButtons.includes("Compare Plots")
                                    ? "10px"
                                    : isTablet || customTabletDimensions()
                                    ? "30px 10px"
                                    : "10px 30px",
                                color: "#0D1A40",
                                backgroundColor:
                                  isTablet || customTabletDimensions() ? "#FFF" : "#F9F9F9"
                              }}>
                              <span className="text-left mr-auto font-size-medium">
                                Compare Plots
                                <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                  {secondImageSource}
                                </span>
                                <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                  {secondImageSource === "Drone"
                                    ? formatFlightDateWithoutTimeStamp(
                                        secondCompareSelectedFlightDate
                                      )
                                    : formatFlightDateWithoutTimeStamp(comparePlotMobileDate2)}
                                </span>
                                {comparePlotExpanded1 &&
                                  (comparePlotTreatment1 || comparePlotReps1) && (
                                    <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                      {comparePlotTreatment1
                                        ? `Treatment ${comparePlotTreatment1}`
                                        : `REP ${comparePlotReps1}`}
                                    </span>
                                  )}
                                {!comparePlotExpanded1 &&
                                  (comparePlotTreatment2 || comparePlotReps2) && (
                                    <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                      {comparePlotTreatment2
                                        ? `Treatment ${comparePlotTreatment2}`
                                        : `REP ${comparePlotReps2}`}
                                    </span>
                                  )}
                              </span>

                              <div className="d-flex align-items-center">
                                {(comparePlotTreatment2 || comparePlotReps2) && (
                                  <div
                                    className="plot-download-icon-container"
                                    onMouseEnter={() => setPlotImageDownload2(true)}
                                    onMouseLeave={() => setPlotImageDownload2(false)}
                                    onClick={() => downloadAllImages("right")}>
                                    <img src={downloadIcon} height={24} width={24} />
                                    {plotImageDownload2 && (
                                      <div className="plot-download-custom-tooltip">
                                        <span className="plot-download-tooltip-text">
                                          Download all plot images
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}

                                <>
                                  <span>
                                    <X
                                      data-testid="plot-images-close-button"
                                      onClick={() => {
                                        setNavbarButtons(
                                          navbarButtons.filter((item) => item != "Compare Plots")
                                        );
                                        setComparePlotTreatment1();
                                        setComparePlotTreatment2();
                                        setComparePlotReps1();
                                        setComparePlotReps2();
                                        setComparePlotExpanded1();
                                        setComparePlotExpanded2();
                                        setBaseOverlayComparePlot1("Visible");
                                        setBaseOverlayComparePlot2("Visible");
                                        setCompareFirstTreatmentReps("Treatment");
                                        setCompareSecondTreatmentReps("Treatment");
                                        setActiveOverlaysCompareLeft([]);
                                        setActiveOverlaysCompareRight([]);
                                        setTimeout(() => {
                                          if (mapLayout) {
                                            if (typeof mapLayout.invalidateSize === "function") {
                                              mapLayout.invalidateSize();
                                            }
                                          }
                                        }, 100);
                                      }}
                                      style={{ marginLeft: "10px", cursor: "pointer" }}
                                      color="#0D1A40"
                                      width={24}
                                      height={24}
                                    />
                                  </span>
                                </>
                              </div>
                            </div>

                            <div className="compare-plot-flight-button">
                              <button
                                ref={baseOverlayButtonRef}
                                className="overlay-flight-icon"
                                onClick={() => {
                                  setSecondTreatmentsChevron(false);
                                  setSecondRepsChevron(false);
                                  setCompareSecondFlightContainerVisible(
                                    !compareSecondFlightContainerVisible
                                  );
                                  setCompareFirstFlightContainerVisible(false);
                                }}
                                style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <div
                                  className="overlay-flight-dropdown-toggle"
                                  style={
                                    {
                                      // boxShadow: baseOverlayFlightOpen
                                      //   ? "none"
                                      //   : "rgba(0, 0, 0, 0.3) 0px 2px 5px 2px"
                                    }
                                  }>
                                  <div
                                    className={
                                      secondCompareSelectedFlightDate
                                        ? "flight-overlay-button"
                                        : "flight-overlay-button-withoutdate"
                                    }>
                                    <img
                                      src={OverlayFlightIcon}
                                      alt=""
                                      height={16}
                                      style={{ marginBottom: "5px", color: "#0D1A40" }}
                                    />
                                    <div
                                      className="font-size-extra-small deep-cove-color"
                                      style={{
                                        marginLeft: "2px"
                                      }}>
                                      {secondCompareSelectedFlightDate &&
                                      secondImageSource === "Drone"
                                        ? formatFlightDateWithoutTimeStamp(
                                            secondCompareSelectedFlightDate
                                          )
                                        : formatFlightDateWithoutTimeStamp(comparePlotMobileDate2)}
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </div>

                            {compareSecondFlightContainerVisible && (
                              <div ref={baseOverlayCompare2}>
                                <Dropdown
                                  show={compareSecondFlightContainerVisible}
                                  //toggleBaseOverlayFlight={toggleBaseOverlayFlight}
                                  className="base-flight-dropdown-show">
                                  <Dropdown.Menu className="flight-overlay-toggle-compare-two">
                                    <div className="comapre-image-source">
                                      <div className="font-weight-600 deep-cove-color font-size-extra-small font-family-Helvetica">
                                        IMAGE SOURCE
                                      </div>
                                      <div>
                                        {trialType !== "manual" && (
                                          <>
                                            <span className="mr-3">
                                              <span className="mr-1">
                                                <input
                                                  type="radio"
                                                  height={12}
                                                  width={12}
                                                  onClick={() => {
                                                    setSecondImageSource("Drone");
                                                  }}
                                                  checked={secondImageSource === "Drone"}
                                                />
                                              </span>
                                              <span className="font-weight-400 deep-cove-color font-size-small font-family-Helvetica">
                                                Drone
                                              </span>
                                            </span>
                                          </>
                                        )}
                                        {ratingDates?.length > 0 && (
                                          <>
                                            <span className="mr-1">
                                              <input
                                                type="radio"
                                                height={12}
                                                width={12}
                                                onClick={() => {
                                                  setSecondImageSource("Mobile");
                                                }}
                                                checked={secondImageSource === "Mobile"}
                                              />
                                            </span>
                                            <span className="font-weight-400 deep-cove-color font-size-small font-family-Helvetica">
                                              Mobile
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {
                                      <DropdownButton
                                        drop="end"
                                        variant="Light"
                                        className="second-compare-base-dropdown"
                                        onClick={secondFlightDropdown}
                                        ref={flightDateRef}
                                        // disabled={isDisabled}
                                        title={
                                          <div
                                            className="d-flex justify-content-between align-items-center"
                                            style={{ height: "49px" }}>
                                            <div>
                                              <span>
                                                <img
                                                  src={mdiCalendarIcon}
                                                  className="compare-calender-icon"
                                                  alt=""
                                                  height={22}
                                                />
                                              </span>
                                              <span
                                                className="compare-flight-text"
                                                style={{
                                                  marginLeft: "20px"
                                                }}>
                                                {secondImageSource === "Drone"
                                                  ? "Flight Date"
                                                  : "Mobile Date"}
                                                &nbsp;
                                                {secondCompareSelectedFlightDate &&
                                                secondImageSource === "Drone"
                                                  ? formatFlightDateWithoutTimeStamp(
                                                      secondCompareSelectedFlightDate
                                                    )
                                                  : formatFlightDateWithoutTimeStamp(
                                                      comparePlotMobileDate2
                                                    )}
                                              </span>
                                              <span
                                                style={{
                                                  marginLeft: "45px"
                                                }}>
                                                <ChevronRight color="#0D1A40" height={8} />
                                              </span>
                                            </div>
                                          </div>
                                        }>
                                        <div
                                          className="scrollbar-assessment p-2"
                                          style={{ background: "white" }}>
                                          <div
                                            ref={flightDatesContainerRef}
                                            className="baseOF-dropdown d-flex flex-column justify-content-between">
                                            <div className="dd-flight-title">
                                              <InfoCircleFill
                                                data-testid="info-circle-fill"
                                                fill="#FFB71E"
                                                height={20}
                                                width={20}
                                              />{" "}
                                              {secondImageSource === "Drone" ? (
                                                "Select a flight date to show image"
                                              ) : (
                                                <div className="compare-plot-dates-container">
                                                  <span className="compare-plot-dates-text1">
                                                    Select a date to show Mobile
                                                  </span>
                                                  <span className="compare-plot-dates-text2">
                                                    images(s)
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div
                                              style={{ paddingTop: "10px", paddingLeft: "20px" }}>
                                              {secondImageSource === "Drone" &&
                                              Object.keys(flightDates)?.length > 0
                                                ? flightDates &&
                                                  Object.keys(flightDates)?.map((option) => (
                                                    <div className="p-1" key={option}>
                                                      <div className="d-flex align-items-center">
                                                        <input
                                                          type="radio"
                                                          name="options"
                                                          checked={
                                                            secondCompareSelectedFlightDate ===
                                                            option
                                                          }
                                                          onChange={() =>
                                                            handleSecondCompareFlightDateChange(
                                                              option
                                                            )
                                                          }
                                                          id={`radio-${option}`}
                                                          value={option}
                                                        />
                                                        <label
                                                          htmlFor={`radio-${option}`}
                                                          className="comapare-flight-options">
                                                          {formatFlightDate(option)}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  ))
                                                : ratingDates?.map((option) => (
                                                    <div className="p-1" key={option}>
                                                      <div className="d-flex align-items-center">
                                                        <input
                                                          type="radio"
                                                          name="options"
                                                          checked={
                                                            comparePlotMobileDate2 === option
                                                          }
                                                          onChange={() =>
                                                            setComparePlotMobileDate2(option)
                                                          }
                                                          id={`radio-${option}`}
                                                          value={option}
                                                        />
                                                        <label
                                                          htmlFor={`radio-${option}`}
                                                          className="font-size-small deep-cove-color cursor-pointer">
                                                          {formatFlightDate(option)}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  ))}
                                            </div>
                                          </div>
                                        </div>
                                      </DropdownButton>
                                    }
                                    <div>
                                      {trialType !== "manual" &&
                                        secondImageSource === "Drone" &&
                                        Object.values(rasterLayersList) !== null && (
                                          <div className="overlay-menu">
                                            <p className="my-0 overlay-label">BASE</p>
                                            <div className="d-flex align-items-center overlay-item ">
                                              {Object.values(rasterLayersList).map((text) => (
                                                <div className="d-flex pr-2" key={text}>
                                                  <input
                                                    type="radio"
                                                    className="assessment-input"
                                                    name="visible"
                                                    onChange={(e) =>
                                                      handleRasterChange(e.target.value, "right")
                                                    }
                                                    checked={baseOverlayComparePlot2 === text}
                                                    value={text}
                                                  />
                                                  <span className="ml-1 input-label">{text}</span>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div>
                                      {trialType !== "manual" && secondImageSource === "Drone" && (
                                        <div
                                          className={"overlay-menu"}
                                          style={
                                            {
                                              // height:
                                              //   overlayVectors !== null ? "94px !important" : ""
                                            }
                                          }>
                                          <p className="my-0 overlay-label">OVERLAY</p>
                                          <div className="d-flex align-items-center overlay-item">
                                            {Object.values(overlayLayersList)?.map((text) => (
                                              <div className="d-flex pr-2" key={text}>
                                                <input
                                                  type="checkbox"
                                                  className="assessment-input"
                                                  name="overlay"
                                                  value={text}
                                                  onChange={(e) => overlayChecklist(e, "right")}
                                                  checked={activeOverlaysCompareRight.includes(
                                                    text
                                                  )}
                                                />
                                                <span className="ml-1 input-label input-label-checkbox">
                                                  {text}
                                                </span>
                                              </div>
                                            ))}
                                            {overlayVectors !== null && (
                                              <div className="d-flex pr-2" key="chkoverlayVectors">
                                                <input
                                                  type="checkbox"
                                                  className="assessment-input"
                                                  value="Bounding Box"
                                                  onChange={(e) => boundingBoxes(e, "right")}
                                                  checked={boundingBoxCompareRight}
                                                  id="chkoverlayVectors"
                                                />
                                                <span className="ml-1 input-label input-label-checkbox">
                                                  Bounding Box
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div style={{ paddingTop: "10px", paddingLeft: "20px" }}>
                                      <span className="mr-3">
                                        <span className="mr-1">
                                          <input
                                            type="radio"
                                            height={12}
                                            width={12}
                                            onClick={() =>
                                              setCompareSecondTreatmentReps("Treatment")
                                            }
                                            checked={compareSecondTreatmentReps === "Treatment"}
                                          />
                                        </span>
                                        <span className="font-size-small deep-cove-color font-family-Helvetica font-weight-400">
                                          Treatment
                                        </span>
                                      </span>
                                      <span className="mr-1">
                                        <input
                                          type="radio"
                                          height={12}
                                          width={12}
                                          onClick={() => setCompareSecondTreatmentReps("Reps")}
                                          checked={compareSecondTreatmentReps === "Reps"}
                                        />
                                      </span>
                                      <span className="font-size-small deep-cove-color font-family-Helvetica font-weight-400">
                                        Reps
                                      </span>
                                    </div>
                                    <div>
                                      {compareSecondTreatmentReps === "Treatment" ? (
                                        <div
                                          className="d-flex align-items-center"
                                          style={{ marginTop: "7px", marginLeft: "18px" }}>
                                          <span className="font-size-small font-weight-400 deep-cove-color mr-2">
                                            Treatment
                                          </span>
                                          <span
                                            className="compare-chevron-down"
                                            onClick={() => {
                                              setSecondTreatmentsChevron(!secondTreatmentsChevron);
                                            }}>
                                            <span className="pr-4 light-orange-color font-weight-700 font-size-small">
                                              {comparePlotTreatment2}
                                            </span>
                                            <span className="pr-2">
                                              {secondTreatmentsChevron ? (
                                                <ChevronUp height={16} width={16} />
                                              ) : (
                                                <ChevronDown height={16} width={16} />
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          className="d-flex align-items-center"
                                          style={{ marginTop: "7px", marginLeft: "18px" }}>
                                          <span
                                            className="font-size-small font-weight-400 deep-cove-color"
                                            style={{ marginRight: "38px" }}>
                                            Reps
                                          </span>
                                          <span
                                            className="compare-chevron-down"
                                            onClick={() => {
                                              setSecondRepsChevron(!secondRepsChevron);
                                            }}>
                                            <span className="pr-4 light-orange-color font-weight-700 font-size-small">
                                              {comparePlotReps2}
                                            </span>
                                            <span className="pr-2">
                                              {secondRepsChevron ? (
                                                <ChevronUp height={16} width={16} />
                                              ) : (
                                                <ChevronDown height={16} width={16} />
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      {secondTreatmentsChevron && (
                                        <div
                                          className="compare-treatments-menu"
                                          ref={firstTreatmentDropdownRef}>
                                          {comparePlotTreatmentData.map((plotId) => (
                                            <div
                                              onClick={() => {
                                                setComparePlotTreatment2(plotId),
                                                  setComparePlotReps2(),
                                                  secondComparePlotsImages(plotId);
                                                setSecondTreatmentsChevron(false);
                                              }}
                                              style={{
                                                borderBottom: "1px solid #ededed",
                                                backgroundColor:
                                                  comparePlotTreatment2 === plotId
                                                    ? "#FFE8BC"
                                                    : "white",
                                                display: "flex",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                fontSize: "14px",
                                                color: "#0D1A40"
                                              }}
                                              key="">
                                              {plotId}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      {secondRepsChevron && (
                                        <div
                                          className="compare-reps-menu"
                                          ref={firstRepDropdownRef}>
                                          {comparePlotRepsData.map((plotId) => (
                                            <div
                                              onClick={() => {
                                                setComparePlotReps2(plotId),
                                                  setComparePlotTreatment2(),
                                                  secondComparePlotsImages(plotId);
                                                setSecondRepsChevron(false);
                                              }}
                                              style={{
                                                borderBottom: "1px solid #ededed",
                                                backgroundColor:
                                                  comparePlotReps2 === plotId ? "#FFE8BC" : "white",
                                                display: "flex",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                fontSize: "14px",
                                                color: "#0D1A40"
                                              }}
                                              key="">
                                              {plotId}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}
                            {selectedMobileImagePlots?.length === 0 && comparePlotMessage ? (
                              <div
                                ref={comparePlotMessageRef}
                                className="plot-images-message mobile-image-message">
                                <div className="d-flex align-items-center justify-content-between ">
                                  <span style={{ marginLeft: "8px", marginRight: "10px" }}>
                                    <InfoCircleFill height={20} width={20} />
                                  </span>
                                  <span className="mr-4 font-size-small font-weight-700">
                                    Please select treatment / rep to see the plot images
                                  </span>
                                  <span>
                                    <X
                                      onClick={() => setComparePlotMessage(false)}
                                      width={24}
                                      height={24}
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        marginLeft: "20px",
                                        cursor: "pointer"
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            ) : null}
                            <div
                              style={{
                                height: "calc(100vh - 150px)",
                                maxHeight: "calc(100vh - 150px)",
                                overflowY: "scroll",
                                marginRight: "4px",
                                cursor: "pointer",
                                overflowX: "hidden"
                              }}>
                              {/* {secondComparePlotOpen ? (
                                <div
                                  ref={secondComparePlotDropdownRef}
                                  style={{
                                    height:
                                      secondTreatmentsChevron || secondRepsChevron
                                        ? "237px"
                                        : "122px"
                                  }}
                                  className="select-second-compare-plot-dropdown">
                                  <div>
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ marginTop: "20px" }}>
                                      <span className="mr-2 font-size-small deep-cove-color font-weight-400">
                                        Treatments
                                      </span>
                                      <span
                                        className="compare-chevron-down"
                                        onClick={() => {
                                          setSecondTreatmentsChevron(!treatmentsChevron),
                                            setSecondRepsChevron(false);
                                        }}>
                                        <span className="pr-4 light-orange-color font-weight-700 font-size-small">
                                          {comparePlotTreatment2}
                                        </span>

                                        <span className="pr-2">
                                          {secondTreatmentsChevron ? (
                                            <ChevronUp height={16} width={16} />
                                          ) : (
                                            <ChevronDown height={16} width={16} />
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ marginTop: "20px" }}>
                                      <span
                                        className="font-size-small font-weight-400 deep-cove-color"
                                        style={{ marginRight: "38px" }}>
                                        Reps
                                      </span>
                                      <span
                                        className="compare-chevron-down"
                                        onClick={() => {
                                          setSecondRepsChevron(!firstRepsChevron),
                                            setSecondTreatmentsChevron(false);
                                        }}>
                                        <span className="pr-4 light-orange-color font-weight-700 font-size-small">
                                          {comparePlotReps2}
                                        </span>

                                        <span className="pr-2">
                                          {secondRepsChevron ? (
                                            <ChevronUp height={16} width={16} />
                                          ) : (
                                            <ChevronDown height={16} width={16} />
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  {secondTreatmentsChevron && (
                                    <div
                                      className="compare-treatments-menu"
                                      ref={secondTreatmentDropdownRef}>
                                      {comparePlotTreatmentData.map((plotId) => (
                                        <div
                                          onClick={() => {
                                            setComparePlotTreatment2(plotId),
                                              setComparePlotReps2(),
                                              secondComparePlotsImages(plotId);
                                          }}
                                          style={{
                                            borderBottom: "1px solid #ededed",
                                            backgroundColor:
                                              comparePlotTreatment2 === plotId
                                                ? "#FFE8BC"
                                                : "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            color: "#0D1A40"
                                          }}
                                          key="">
                                          {plotId}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  {secondRepsChevron && (
                                    <div className="compare-reps-menu" ref={secondRepDropdownRef}>
                                      {comparePlotRepsData.map((plotId) => (
                                        <div
                                          onClick={() => {
                                            setComparePlotReps2(plotId),
                                              setComparePlotTreatment2(),
                                              secondComparePlotsImages(plotId);
                                          }}
                                          style={{
                                            borderBottom: "1px solid #ededed",
                                            backgroundColor:
                                              comparePlotReps2 === plotId ? "#FFE8BC" : "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            color: "#0D1A40"
                                          }}
                                          key="">
                                          {plotId}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ) : null} */}

                              {
                                (comparePlotTreatment2 ||
                                  comparePlotReps2 ||
                                  comparePlotExpanded1) &&
                                  // <div style={{ cursor: "default" }}>
                                  (comparePlotExpanded1
                                    ? [comparePlotExpanded1]
                                    : comparePlotExpanded2
                                    ? [comparePlotExpanded2]
                                    : comparePlotsImages2
                                  )?.map((plotId, index) => (
                                    <div
                                      key={plotId}
                                      className={
                                        index === 0 ? "image-wrapper first-image" : "image-wrapper"
                                      }
                                      style={{
                                        margin: "0 4px 4px",
                                        height: "100%",
                                        width: "99%",
                                        position: "relative",
                                        cursor: "default"
                                      }}>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          backgroundColor: "transparent"
                                        }}
                                        ref={(el) =>
                                          generateRefs(el, index, comparePlotRightOverlayRef)
                                        }>
                                        {copyToClipBoardLoader &&
                                          comparePlotImagesDotsMenu2 === plotId && (
                                            <div className="d-flex justify-content-center align-items-center p-3 copytoclipboard-spinner">
                                              <div
                                                className="spinner-border text-warning"
                                                role="status"></div>
                                            </div>
                                          )}
                                        <div
                                          key={plotId}
                                          id={`comparePlotRight${index}`}
                                          className="no-gridlines"
                                          ref={(el) => generateRefs(el, index, comparePlotRightRef)}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            position: "relative"
                                          }}>
                                          <div
                                            className="plot-image-summary"
                                            id={`hidePlotsRight${index}`}
                                            style={{ left: "70px" }}>{`${plotId} (${
                                            dronePlotDetails[selectedOption]?.find(
                                              (plot) => plot.plotId == plotId
                                            )?.treatmentNumber || "NA"
                                          })`}</div>
                                          <div
                                            onClick={() => {
                                              if (
                                                comparePlotExpanded2 == plotId ||
                                                comparePlotExpanded1 == plotId
                                              ) {
                                                setComparePlotExpanded2();
                                                setComparePlotExpanded1();
                                              } else {
                                                setComparePlotExpanded2(plotId);
                                              }
                                            }}
                                            className="plot-image-expand-icon"
                                            id={`flightDateRight${index}`}
                                            style={{ top: "24px" }}>
                                            {comparePlotExpanded2 == plotId ||
                                            comparePlotExpanded1 == plotId ? (
                                              <ArrowsAngleContract height={16} width={16} />
                                            ) : (
                                              <ArrowsAngleExpand height={16} width={16} />
                                            )}
                                          </div>
                                          <div
                                            className="plot-image-three-dots"
                                            id={`threeDotsRight${index}`}
                                            onClick={() => {
                                              setComparePlotImagesDotsMenu2(plotId);
                                            }}>
                                            <ThreeDots width={16} height={16} />
                                          </div>
                                          {comparePlotImagesDotsMenu2 === plotId && (
                                            <div>
                                              {plotsHamburgerMenu(
                                                plotId,
                                                index,
                                                selectedFlightDate,
                                                "right"
                                              )}
                                            </div>
                                          )}
                                          <div
                                            id={`closeIconRight${index}`}
                                            onClick={() => {
                                              if (comparePlotExpanded1) {
                                                if (
                                                  comparePlotsImages1.filter((id) => id != plotId)
                                                    ?.length === 0
                                                ) {
                                                  setComparePlotReps1(), setComparePlotTreatment1();
                                                }
                                                setComparePlotsImages1(
                                                  comparePlotsImages1.filter((id) => id != plotId)
                                                );
                                                setComparePlotExpanded1();
                                              } else {
                                                if (
                                                  comparePlotsImages2.filter((id) => id != plotId)
                                                    ?.length === 0
                                                ) {
                                                  setComparePlotReps2(), setComparePlotTreatment2();
                                                }
                                                setComparePlotsImages2(
                                                  comparePlotsImages2.filter((id) => id != plotId)
                                                );
                                                setComparePlotExpanded2();
                                              }
                                            }}
                                            className="plot-image-cancel-icon"
                                            style={{ top: "24px", right: "24px" }}>
                                            <X height={24} width={24} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                // </div>
                              }
                            </div>
                          </div>
                        )}
                      </Col>
                    ) : null}

                    {navbarButtons.includes("Mobile Images") ||
                    ((customTabletDimensions() || isMobileOnly) &&
                      tabletNavbarButton === "Mobile Images") ? (
                      <Col
                        data-testid="mobile-images-container"
                        xs={12}
                        style={{
                          borderLeft: "1px solid rgb(249, 249, 249)",
                          order: 1
                        }}
                        id={navbarButtons.findIndex((i) => i === "Mobile Images")}>
                        {
                          // openEnv &&
                          (navbarButtons.includes("Mobile Images") ||
                            tabletNavbarButton === "Mobile Images") && (
                            <div
                              style={{
                                position: "fixed",
                                backgroundColor: "#FFF",
                                zIndex: 997,
                                top: "120px",
                                width: "100%",
                                height: "calc(100% - 120px)"
                              }}>
                              <div
                                className="panel-title-bar d-flex align-items-center"
                                role="button"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  padding: "10px 30px",
                                  color: "#0D1A40",
                                  backgroundColor: "#F9F9F9"
                                }}>
                                <span className="text-left mr-auto font-size-medium">
                                  Mobile{" "}
                                  <span style={{ marginLeft: "14px", fontWeight: 400 }}>
                                    {formatFlightDateWithoutTimeStamp(selectedRatingDate)}
                                  </span>
                                </span>

                                <div className="d-flex align-items-center">
                                  {selectedMobileImagePlots?.length > 0 && (
                                    <div
                                      className="plot-download-icon-container"
                                      onMouseEnter={() => setPlotImageDownload(true)}
                                      onMouseLeave={() => setPlotImageDownload(false)}>
                                      <img src={downloadIcon} height={24} width={24} />
                                      {plotImageDownload && (
                                        <div className="plot-download-custom-tooltip">
                                          <span className="plot-download-tooltip-text">
                                            Download all plot images
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  <div
                                    ref={selectPlotFilterButtonRef}
                                    style={{
                                      cursor: "pointer",
                                      paddingRight: "12px",
                                      paddingBottom: "2px"
                                    }}>
                                    <Sliders
                                      onClick={() =>
                                        setIsMobileImagesSelectPlotOpen(
                                          !isMobileImagesSelectPlotOpen
                                        )
                                      }
                                      height={16}
                                      width={16}
                                    />
                                  </div>
                                  <span>
                                    <X
                                      data-testid="plot-images-close-button"
                                      onClick={() => {
                                        // setOpenEnv(false);
                                        unsortedButtons = ["Drone"];
                                        setNavbarButtons(["Drone"]);
                                        setSelectedMobileImagePlots([]);
                                        setMapInitiated(false);
                                        handleTopButtonClick("Drone");
                                        setTimeout(() => {
                                          if (mapLayout) {
                                            if (typeof mapLayout.invalidateSize === "function") {
                                              mapLayout.invalidateSize();
                                            }
                                          }
                                        }, 100);
                                      }}
                                      style={{ marginLeft: "10px", cursor: "pointer" }}
                                      color="#0D1A40"
                                      width={24}
                                      height={24}
                                    />
                                  </span>
                                </div>
                              </div>

                              <div ref={mapplotContainerRef} className={plotImageWrapperClass}>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "30px",
                                    zIndex: 999
                                  }}>
                                  <button
                                    ref={mobileDatesRef}
                                    className="overlay-flight-icon"
                                    onClick={() =>
                                      setIsMobileImageFlightContainerVisible(
                                        !isMobileImageFlightContainerVisible
                                      )
                                    }
                                    style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <div className="overlay-flight-dropdown-toggle">
                                      <div
                                        className={
                                          selectedRatingDate
                                            ? "flight-overlay-button"
                                            : "flight-overlay-button-withoutdate"
                                        }>
                                        <img
                                          src={OverlayFlightIcon}
                                          alt=""
                                          height={16}
                                          style={{ marginBottom: "5px", color: "#0D1A40" }}
                                        />
                                        <div
                                          style={{
                                            marginLeft: "2px",
                                            color: "#0D1A40",
                                            fontSize: "10px",
                                            fontFamily: "Helvetica"
                                          }}>
                                          {selectedRatingDate
                                            ? formatFlightDateWithoutTimeStamp(selectedRatingDate)
                                            : " "}
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                </span>
                                {isMobileImageFlightContainerVisible && (
                                  <div
                                    className="mobile-image-dates-container"
                                    style={{ background: "white" }}>
                                    <div
                                      ref={mobileDatesContainerRef}
                                      className="baseOF-dropdown d-flex flex-column justify-content-between">
                                      <div
                                        className="d-flex justify-content-between align-items-center"
                                        style={{ height: "35px", paddingLeft: "12px" }}>
                                        <div className="flight-menu-button">
                                          <span>
                                            <img
                                              src={mdiCalendarIcon}
                                              alt=""
                                              height={22}
                                              style={{
                                                paddingLeft: "7px",
                                                color: "#0D1A40",
                                                width: "30px"
                                              }}
                                            />
                                          </span>
                                          <span
                                            style={{
                                              marginLeft: "12px",
                                              color: "#0D1A40",
                                              fontSize: "12px"
                                            }}>
                                            Mobile Date
                                          </span>
                                        </div>
                                      </div>
                                      <div className="dd-flight-title">
                                        <InfoCircleFill
                                          data-testid="info-circle-fill"
                                          fill="#FFB71E"
                                          height={20}
                                          width={20}
                                        />{" "}
                                        &nbsp;&nbsp;Select a date to show Mobile image(s)
                                      </div>
                                      <div style={{ paddingLeft: "20px" }}>
                                        {ratingDates?.map((option) => (
                                          <div className="p-1" key={option}>
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="radio"
                                                name="options"
                                                checked={selectedRatingDate === option}
                                                onChange={() => setSelectedRatingDate(option)}
                                                id={`radio-${option}`}
                                                value={option}
                                              />
                                              <label
                                                htmlFor={`radio-${option}`}
                                                className="font-size-small deep-cove-color cursor-pointer">
                                                {formatFlightDate(option)}
                                              </label>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {isMobileImagesSelectPlotOpen ? (
                                  <div
                                    ref={selectPlotDropdownRef}
                                    className="select-plots-dropdown"
                                    aria-labelledby="multiSelectDropdown">
                                    <div className="select-plot-info">
                                      <InfoCircleFill fill="#FFB71E" height={14} width={14} />
                                      <div className="select-plot-text">Select upto 8 plots</div>
                                    </div>
                                    <div
                                      className="font-weight-700 font-size-small"
                                      style={{
                                        paddingTop: "4px"
                                      }}>
                                      Plots
                                    </div>
                                    <div>
                                      {plotIdsForPlotImage?.map(({ plotid }) => (
                                        <Form.Check
                                          className="custom-checkbox custom-checkbox-viewer"
                                          style={{ color: "#0D1A40" }}
                                          key={0}
                                          type="checkbox">
                                          <Form.Check.Input
                                            type="checkbox"
                                            onChange={selectMobileImagePlots}
                                            checked={
                                              selectedMobileImagePlots?.includes(
                                                plotid.toString()
                                              ) &&
                                              Object.keys(
                                                datesAndPlotIdsMapping?.[selectedRatingDate] || {}
                                              )?.includes(plotid.toString())
                                            }
                                            label={plotid}
                                            value={plotid}
                                            disabled={
                                              (selectedMobileImagePlots?.length === 8 &&
                                                !selectedMobileImagePlots?.includes(
                                                  plotid.toString()
                                                )) ||
                                              !Object.keys(
                                                datesAndPlotIdsMapping?.[selectedRatingDate] || {}
                                              )?.includes(plotid.toString())
                                            }
                                            id={plotid.toString()}
                                          />
                                          <Form.Check.Label
                                            htmlFor={plotid.toString()}
                                            style={{ cursor: "pointer" }}>
                                            {plotid}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))}
                                    </div>
                                  </div>
                                ) : null}
                                {selectedMobileImagePlots?.length === 0 &&
                                showMobileImageMessage ? (
                                  <div
                                    ref={mobileImageMessageRef}
                                    className="plot-images-message mobile-image-message">
                                    <div className="d-flex align-items-center justify-content-between ">
                                      <span style={{ marginLeft: "8px", marginRight: "10px" }}>
                                        <InfoCircleFill height={20} width={20} />
                                      </span>
                                      <span className="mr-4 font-size-small font-weight-700">
                                        Please select at least one plot to see the plot images
                                      </span>
                                      <span>
                                        <X
                                          onClick={() => setShowMobileImageMessage(false)}
                                          width={24}
                                          height={24}
                                          style={{
                                            height: "24px",
                                            width: "24px",
                                            marginLeft: "20px",
                                            cursor: "pointer"
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {(mobileImageExpandedPlotId
                                  ? [mobileImageExpandedPlotId]
                                  : selectedMobileImagePlots
                                )?.map((plotId, index) => (
                                  <div
                                    key={plotId}
                                    className={
                                      index === 0 ? "image-wrapper first-image" : "image-wrapper"
                                    }
                                    style={{
                                      position: "relative",
                                      margin:
                                        selectedMobileImagePlots.length <= 2
                                          ? "0px 4px 0px 0px"
                                          : "0px 4px 4px 0",
                                      overflow: "hidden",
                                      width:
                                        mobileImageExpandedPlotId ||
                                        selectedMobileImagePlots?.length === 1
                                          ? "100%"
                                          : "calc(50% - 4px)",
                                      height: "100%",
                                      border:
                                        plotId === scrollableImagePlotId
                                          ? "3px solid rgb(255,0,255)"
                                          : ""
                                    }}>
                                    {copyToClipBoardLoader && plotImagesDotsMenu === plotId && (
                                      <div className="d-flex justify-content-center align-items-center p-3 copytoclipboard-spinner">
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                          ref={spinnerRef}></div>
                                      </div>
                                    )}
                                    <ImageSliderWithZoom
                                      images={imageDataForDownload?.[plotId] || []}
                                      setScrollableImagePlotId={setScrollableImagePlotId}
                                      setCurrentMobileImageIndex={setCurrentMobileImageIndex}
                                      plotId={plotId}
                                      numberOfImages={
                                        datesAndPlotIdsMapping[selectedRatingDate]?.[plotId]?.length
                                      }
                                    />
                                    <div
                                      id={`hidePlots${index}`}
                                      className="plot-image-summary"
                                      style={{ display: "block", left: "70px" }}>{`${plotId} (${
                                      trialType === "manual"
                                        ? plotDetails?.find((plot) => plot.plotNumber == plotId)
                                            ?.treatmentNumber
                                        : dronePlotDetails[selectedOption]?.find(
                                            (plot) => plot.plotId == plotId
                                          )?.treatmentNumber || "NA"
                                    })`}</div>
                                    <div
                                      className="plot-image-expand-icon"
                                      id={`flightDate${index}`}
                                      style={{ visibility: "visible" }}
                                      onClick={() => {
                                        if (mobileImageExpandedPlotId === plotId) {
                                          setMobileImageExpandedPlotId();
                                        } else {
                                          setMobileImageExpandedPlotId(plotId);
                                          selectExpansion(plotId);
                                        }
                                      }}>
                                      {mobileImageExpandedPlotId === plotId ? (
                                        <ArrowsAngleContract height={16} width={16} />
                                      ) : (
                                        <ArrowsAngleExpand height={16} width={16} />
                                      )}
                                    </div>

                                    {selectedMobileImagePlots?.length > 0 && (
                                      <div
                                        id={`threeDots${index}`}
                                        className="plot-image-three-dots"
                                        style={{
                                          cursor:
                                            imageDataForDownload?.[plotId]?.length > 0
                                              ? "pointer"
                                              : "default"
                                        }}
                                        onClick={() => {
                                          if (imageDataForDownload?.[plotId]?.length > 0) {
                                            setPlotImagesDotsMenu(plotId);
                                          }
                                        }}>
                                        <ThreeDots width={16} height={16} />
                                      </div>
                                    )}
                                    {plotImagesDotsMenu === plotId && (
                                      <div>
                                        {plotsHamburgerMenu(
                                          plotId,
                                          index,
                                          selectedRatingDate,
                                          "left",
                                          true,
                                          trialType === "manual"
                                            ? plotDetails?.find((plot) => plot.plotNumber == plotId)
                                                ?.treatmentNumber
                                            : dronePlotDetails[selectedOption]?.find(
                                                (plot) => plot.plotId == plotId
                                              )?.treatmentNumber || "NA"
                                        )}
                                      </div>
                                    )}

                                    <div
                                      id={`closeIcon${index}`}
                                      style={{ visibility: "visible" }}
                                      className="plot-image-cancel-icon">
                                      <X
                                        height={24}
                                        width={24}
                                        onClick={() => {
                                          setSelectedMobileImagePlots((prevSelectedPlots) =>
                                            prevSelectedPlots.filter((item) => item !== plotId)
                                          );
                                          setMobileImageExpandedPlotId();
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        }
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
              {navbarButtons.includes("Drone") && (
                <>
                  {isDesktop && !customTabletDimensions() && (
                    <WebTools
                      selectedOption={selectedOption}
                      ShowFullMap={ShowFullMap}
                      handleFeedback={handleFeedback}
                      trialSummary={trialSummary}
                      measurementToolVisible={measurementToolVisible}
                      switchMeasurementTool={switchMeasurementTool}
                      measurementIcon={measurementIcon}
                      isShowFeedback={isShowFeedback}
                      assessmentDateType={assessmentDateType}
                      handleExpand={handleExpand}
                      expandIcon={expandIcon}
                      droneExpand={droneExpand}
                      rotateMapZeroDegreeClick={rotateMapZeroDegree}
                      rotateMapZeroDegreeDisable={trialNotSelected}
                      rotateMapZeroDegreeHide={trialType === "manual"}
                    />
                  )}
                </>
              )}
              <div className="modalContent">
                <Modal
                  show={trialSummary}
                  centered
                  className={
                    isMobile
                      ? "mobileTrialSummary"
                      : customMobileLandscape
                      ? "landScapeTrialSummary"
                      : !(customMobilePortrait || customMobileLandscape || customTabletDimensions())
                      ? "desktopTrialSummary"
                      : "tabTrialSummary"
                  }
                  onHide={() => {
                    setTrialSummary(false);
                  }}>
                  <Modal.Body className="py-0">
                    <div className="close-button" onClick={() => setTrialSummary(false)}>
                      &times;
                    </div>
                    <div>
                      <NewViewerTrialSummary
                        trail={selectedOption}
                        droneFlightDates={droneFlightDates}
                        target={pestData}
                        isMobile={customMobilePortrait}
                        isMobileLandscape={customMobileLandscape}
                        isTablet={customTabletDimensions()}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {isShowFeedback ? (
                <FeedbackForm
                  isShowFeedback={isShowFeedback}
                  setIsShowFeedback={setIsShowFeedback}
                  handleFeedbackClose={handleFeedbackClose}
                  handleCloseFeedback={handleCloseFeedback}
                  selectedTrail={selectedOption}
                  selectedFlightDate={selectedFlightDate}
                  collectionId={collectionId}
                  collectionName={viewAPIData?.site_name}
                  isTable={isTablet}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  isDesktop={isDesktop}
                />
              ) : null}
              {isShowThankYouModel ? (
                <ThankYouModal
                  isShowThankYouModel={isShowThankYouModel}
                  handleClose={handleClose}
                />
              ) : null}
              {isShowRatingdescription && ratingDescriptions ? (
                <RatingDescription
                  isRatingDescription={isShowRatingdescription}
                  handleRatingClose={handleCloseRatingDescription}
                  ratingDescriptions={ratingDescriptions}
                  ratingDescriptionsLoaded={ratingDescriptionsLoaded}
                  isMobileLandscape={customMobileLandscape}
                  isMobile={customMobilePortrait}
                  isTablet={customTabletDimensions()}
                  isDesktop={isDesktop}
                />
              ) : null}
              {showReportingModal || successModal ? (
                <ReportModal
                  reportingPlotId={reportingPlotId}
                  reportingFlightDate={reportingFlightDate}
                  selectedOption={selectedOption}
                  assessmentDateType={assessmentDatesAndTypes}
                  showReportingModal={showReportingModal}
                  setShowReportingModal={setShowReportingModal}
                  successModal={successModal}
                  setSuccessModal={setSuccessModal}
                  deepLinkUrl={deepLinkUrl}
                  setOpenTrialReportModal={setOpenTrialReportModal}
                  showMobileImagesReportModal={showMobileImagesReportModal}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
