const clientId = process.env.REACT_APP_CLIENT_ID;
const tenantId1 = process.env.REACT_APP_TENANT_ID1;
export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId1}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  knownAuthorities: ["login.microsoftonline.com"], // Add your authority here
};
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", "openid", "profile"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
      mandatorySignIn: true,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          scopes: ["openid", "profile", "aws.cognito.signin.user.admin"],
          redirectSignIn: [`${process.env.REACT_APP_BASE_URL}/app`],
          redirectSignOut: [`${process.env.REACT_APP_BASE_URL}/app`],
          responseType: "token",
          "redirectUri ": ["http://localhost:3000/app"]
        }
      }
    }
  }
};
