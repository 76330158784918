import { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

let imageClicked = false;
export const ImageSliderWithZoom = ({
  images,
  initialIndex = 0,
  setCurrentMobileImageIndex,
  width = "100%",
  height = "100%",
  maxWidth = "100%",
  maxHeight = "100%",
  setScrollableImagePlotId,
  plotId,
  numberOfImages,
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [zoomLevels, setZoomLevels] = useState(Array(images.length).fill(1)); // Zoom levels for each image
  const [transformOrigins, setTransformOrigins] = useState(
    Array(images.length).fill("center center") // Transform origin for each image
  );
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [offsets, setOffsets] = useState(Array(images.length).fill({ x: 0, y: 0 })); // Offsets for each image
  const imageRef = useRef(null);
  const imageWrapperRef = useRef(null);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setCurrentMobileImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    setCurrentMobileImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    initialIndex = 0;
    setCurrentIndex(initialIndex);
    setCurrentMobileImageIndex(initialIndex);
    setZoomLevels(Array(images.length).fill(1));
    setDragStart({ x: 0, y: 0 });
    setTransformOrigins(Array(images.length).fill("center center"));
    setOffsets(Array(images.length).fill({ x: 0, y: 0 }));
    setDragging(false);
  }, [images.length]);

  // Handle mouse wheel zoom
  const handleWheel = (e) => {
    // Only apply zoom to the current image
    if (e.target !== imageRef.current || !imageClicked) return;
    e.preventDefault();
    // Get the mouse position relative to the image
    const { left, top, width, height } = imageRef.current.getBoundingClientRect();
    const mouseX = e.clientX - left;
    const mouseY = e.clientY - top;
    const xPercent = mouseX / width;
    const yPercent = mouseY / height;

    // Update zoom level for the current image based on wheel scroll
    setZoomLevels((prevZoomLevels) => {
      const newZoomLevels = [...prevZoomLevels];
      if (e.deltaY < 0) {
        // Zoom In
        newZoomLevels[currentIndex] = Math.min(newZoomLevels[currentIndex] + 0.5, 5);
      } else if (e.deltaY > 0) {
        // Zoom Out
        newZoomLevels[currentIndex] = Math.max(newZoomLevels[currentIndex] - 0.5, 1);
      }
      return newZoomLevels;
    });

    // Update transform origin for the current image (zoom towards mouse position)
    setTransformOrigins((origins) => {
      const newOrigins = [...origins];
      newOrigins[currentIndex] = `${xPercent * 100}% ${yPercent * 100}%`;
      return newOrigins;
    });
  };

  // Start dragging
  const handleMouseDown = (e) => {
    if (zoomLevels[currentIndex] === 1) return; // Only allow dragging if zoomed in
    setDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  // Handle mouse movement while dragging
  const handleMouseMove = (e) => {
    if (!dragging) return; // If dragging is not active, do nothing

    const deltaX = e.clientX - dragStart?.x;
    const deltaY = e.clientY - dragStart?.y;

    // Update the offset for the current image
    setOffsets((prevOffsets) => {
      const newOffsets = [...prevOffsets];
      newOffsets[currentIndex] = {
        x: prevOffsets[currentIndex]?.x + deltaX,
        y: prevOffsets[currentIndex]?.y + deltaY
      };
      return newOffsets;
    });

    // Update drag start position for the next move
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  // Stop dragging
  const handleMouseUp = () => {
    setDragging(false); // Stop dragging
  };
  const handleImageClick = () => {
    imageClicked = true;
    setScrollableImagePlotId(plotId);
  };
  const handleImageLeave = () => {
    imageClicked = false;
    setScrollableImagePlotId();
  };
  // Double-click handler to reset image to its original position and zoom
  const handleDoubleClick = () => {
    // Reset zoom to 1
    setZoomLevels((prevZoomLevels) => {
      const newZoomLevels = [...prevZoomLevels];
      newZoomLevels[currentIndex] = 1;
      return newZoomLevels;
    });

    // Reset offsets to 0 (center position)
    setOffsets((prevOffsets) => {
      const newOffsets = [...prevOffsets];
      newOffsets[currentIndex] = { x: 0, y: 0 };
      return newOffsets;
    });
  };

  useEffect(() => {
    window.addEventListener("wheel", handleWheel, { passive: false });

    // Add mousemove and mouseup listeners
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("wheel", handleWheel);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, dragStart, currentIndex]);

  useEffect(() => {
    imageWrapperRef?.current?.addEventListener("click", handleImageClick);
    imageWrapperRef?.current?.addEventListener("mouseleave", handleImageLeave);
    imageWrapperRef?.current?.addEventListener("dblclick", handleDoubleClick);

    // Cleanup event listeners on component unmount
    return () => {
      imageWrapperRef?.current?.removeEventListener("click", handleImageClick);
      imageWrapperRef?.current?.removeEventListener("mouseleave", handleImageLeave);
      imageWrapperRef?.current?.removeEventListener("dblclick", handleDoubleClick);
    };
  }, [images.length, currentIndex]);

  return (
    <div
      style={{
        position: "relative",
        width: width,
        height: height,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        margin: "auto",
        overflow: "hidden"
      }}>
      {images.length !== numberOfImages && (
        <div className="d-flex justify-content-center align-items-center p-3 copytoclipboard-spinner">
          <div className="spinner-border text-warning" role="status"></div>
        </div>
      )}
      {images.length > 0 && (
        <div ref={imageWrapperRef}>
          <div
            style={{
              width: "100%",
              height: height,
              overflow: "hidden",
              position: "relative"
            }}>
            <img
              ref={imageRef}
              src={images[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              style={{
                width: "100%",
                height: "100%",
                transform: `scale(${zoomLevels?.[currentIndex]}) translate(${offsets?.[currentIndex]?.x}px, ${offsets?.[currentIndex]?.y}px)`,
                transformOrigin: transformOrigins[currentIndex],
                transition: "transform 0.3s ease",
                objectFit: "cover",
                display: "block",
                margin: "0 auto",
                cursor: zoomLevels[currentIndex] > 1 ? "grab" : "default",
                userSelect: "none", // Disable text selection
                WebkitUserDrag: "none"
              }}
              onMouseDown={handleMouseDown}
            />
          </div>

          {images?.length > 1 && (
            <div className="image-navigation-wrapper">
              <div className="cursor-pointer" onClick={prevImage}>
                <ChevronLeft color="#0D1A40" height={16} width={16} />
              </div>

              <div>
                {currentIndex + 1} / {images.length}
              </div>

              <div className="cursor-pointer" onClick={nextImage}>
                <ChevronRight color="#0D1A40" height={16} width={16} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ImageSliderWithZoom.propTypes = {
  images: propTypes.any,
  initialIndex: propTypes.any,
  width: propTypes.any,
  height: propTypes.any,
  maxWidth: propTypes.any,
  maxHeight: propTypes.any,
  setScrollableImagePlotId: propTypes.any,
  plotId: propTypes.any,
  numberOfImages: propTypes.any,
  setCurrentMobileImageIndex: propTypes.any
};
